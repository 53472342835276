import { API, FlowAPI, ReconResolveAPI } from "URL.js";
import axios from "axios";

//Variables data

import {
  landingPageReconCardData,
  landingPageResolveCardData,
  reconTrendsDataDashBoard,
  resolveOpenTrendsDataDashBoard,
  resolveCloseTrendsDataDashBoard,
} from "variables/ReconUnitLevelData.js";

//const axiosService = AxiosService.getService();

export const fetchReconUnitsData = (userId, callback) => {
  let fetchedReconUnitData = [];
  let fetchedDropDownData = [];

  axios
    .get(API + "/reconunits/byuser/" + userId)
    .then((response) => {
      console.log(":::::::::::::::::", { response });
      response.data.forEach((item) => {
        fetchedReconUnitData.push({
          ...item,
          reconUnitId: item.reconUnitId,
          clientReconName: item.clientReconName,
          identicalData: item.identicalData,
          headerData: item.headerData,
          workflowEnabled: item.workflowEnabled,
          reconEnabled: item.reconEnabled,
          multimatchReqd: item.multimatchReqd,
          oneToMany: item.oneToMany,
          manyToOne: item.manyToOne,
          multimatchAggrBuffer: item.multimatchAggrBuffer,
          dataSourceId1: item.dataSourceId1,
          dataSrcConfigId1: item.dataSrcConfigId1,
          dataSourceId2: item.dataSourceId2,
          dataSrcConfigId2: item.dataSrcConfigId2,
          createdBy: item.createdBy,
          createdDate: item.createdDate || item.updatedDate,
          updatedDate: item.updatedDate,
          mlEnabled: item.mlEnabled,
        });
        fetchedDropDownData.push({
          value: item.reconUnitId,
          label: item.clientReconName,
          isdisabled: false,
          dataSrcConfigId1: item.dataSrcConfigId1,
          dataSrcConfigId2: item.dataSrcConfigId2,
          ...item,
        });
      });
      callback(userId, fetchedReconUnitData, fetchedDropDownData, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(userId, fetchedReconUnitData, fetchedDropDownData, false);
    });
};

export const fetchLandingReconData = (userId, callback) => {
  axios
    .get(API + "/reconmetrics/" + userId + "/")
    .then((response) => {
      response.data.forEach((item) => {
        landingPageReconCardData.push(item);
        //return console.log("fetched landingPageReconCardData:{}");
      });
      callback(landingPageReconCardData, true);
    })
    .catch((error) => {
      console.log("Exception occured in fetchLandingReconData::{}", error);
      callback(landingPageReconCardData, false);
    });
};

export const fetchLandingResolveData = (userId, callback) => {
  axios
    .get(ReconResolveAPI + "/flowquery/keymetrics/" + userId + "/")
    .then((response) => {
      landingPageResolveCardData.flowUnitsConfigured =
        response.data.flowUnitsConfigured;
      landingPageResolveCardData.flowUnitsConfiguredTitle =
        response.data.flowUnitsConfiguredTitle;
      landingPageResolveCardData.openResolveItemsCount =
        response.data.openResolveItemsCount;
      landingPageResolveCardData.openResolveItemsTitle =
        response.data.openResolveItemsTitle;
      callback(landingPageResolveCardData, true);
    })
    .catch((error) => {
      console.log("Exception occured in fetchLandingResolveData::{}", error);
      callback(landingPageResolveCardData, false);
    });
};

export const fetchResolveTrendsData = (
  userId,
  userDepartmentName,
  selectedFlowDefnIdId,
  orgId,
  userAssignedReconUnits,
  callback
) => {
  var arrayLength = userAssignedReconUnits.length;
  let passReconUnits = "";
  for (var i = 0; i < arrayLength; i++) {
    passReconUnits =
      passReconUnits + "&userAssignedReconUnits=" + userAssignedReconUnits[i];
  }
  let resolveOpenTrendsData = { labels: [], series: [[]] }; //clearing the array so that it is done freshly
  let resolveCloseTrendsData = { labels: [], series: [[]] }; //clearing the array so that it is done freshly
  let restAPIUrl =
    FlowAPI +
    "/insights/fetchqueuelevel?assignedDepartment=" +
    userDepartmentName +
    "&flowDefnKeyName=" +
    selectedFlowDefnIdId +
    "&orgId=" +
    orgId +
    passReconUnits +
    "&userId=" +
    userId;

  axios
    .get(restAPIUrl)
    .then((response) => {
      if (response.data && response.status === 200) {
        let resolveUnitTrendLength = [];
        var openRec = 0;
        var closeRec = 0;

        response.data.dayWiseOpenCloseItems.forEach((data, index) => {
          resolveUnitTrendLength.push(
            response.data.dayWiseOpenCloseItems.length
          );

          resolveOpenTrendsData.labels.push(data.dayOfWeek);

          resolveOpenTrendsData.series[openRec].push(data.dayWiseOpens);

          resolveCloseTrendsData.labels.push(data.dayOfWeek);
          resolveCloseTrendsData.series[closeRec].push(data.dayWiseClosures);
        });

        resolveOpenTrendsDataDashBoard.labels = resolveOpenTrendsData.labels;
        resolveOpenTrendsDataDashBoard.series = resolveOpenTrendsData.series;
        resolveCloseTrendsDataDashBoard.labels = resolveCloseTrendsData.labels;
        resolveCloseTrendsDataDashBoard.series = resolveCloseTrendsData.series;

        //const isAllZero = resolveUnitTrendLength.every(item => item === 0);

        callback(
          resolveOpenTrendsDataDashBoard,
          resolveCloseTrendsDataDashBoard,
          true
        );
      }
    })
    .catch((error) => {
      console.log(
        "Exception occured in resolveTrends Fetching",
        error.response
      );
      callback(
        resolveOpenTrendsDataDashBoard,
        resolveCloseTrendsDataDashBoard,
        false
      );
    });
};

export const fetchTrendMetrices = (
  tenor,
  trendType,
  reconUnitId,
  userId,
  callback
) => {
  let urlTenor = "";
  let urlTrendType = "";

  if (tenor === "1week") {
    urlTenor = "1W";
  } else if (tenor === "1month") {
    urlTenor = "1M";
  } else if (tenor === "3months") {
    urlTenor = "3M";
  }

  if (trendType === "optimizer") {
    urlTrendType = "optimizerTrend";
  } else if (trendType === "recon") {
    urlTrendType = "reconTrend";
  }

  let reconTrendsData = { labels: [], series: [[]] }; //clearing the array so that it is done freshly
  axios
    .get(
      API +
        "/reconmetrics/trendbyreconunit/" +
        userId +
        "/" +
        urlTenor +
        "/" +
        reconUnitId +
        "/" +
        urlTrendType
    )
    .then((response) => {
      if (response.data && response.status === 200) {
        let reconUnitTrendLength = [];
        response.data.reconUnitTrend.forEach((data, index) => {
          reconUnitTrendLength.push(response.data.reconUnitTrend.length);

          reconTrendsData.labels.push(new Date(data.trendDateTime));
          reconTrendsData.series[0].push(data.trendValue);
        });

        reconTrendsDataDashBoard.labels = reconTrendsData.labels;
        reconTrendsDataDashBoard.series = reconTrendsData.series;

        callback(reconTrendsDataDashBoard, true);
      }
    })
    .catch((error) => {
      console.log("Exception occured ", error.response);
      callback(reconTrendsDataDashBoard, false);
    });
};

export const fetchCombinedTrendMetrices = (
  tenor,
  trendType,
  userId,
  callback
) => {
  let urlTenor = "";
  let urlTrendType = "";
  if (tenor === "1week") {
    urlTenor = "1W";
  } else if (tenor === "1month") {
    urlTenor = "1M";
  } else if (tenor === "3months") {
    urlTenor = "3M";
  }

  if (trendType === "optimizer") {
    urlTrendType = "optimizerTrend";
  } else if (trendType === "recon") {
    urlTrendType = "reconTrend";
  }

  let reconTrendsData = { labels: [], series: [[]] }; //clearing the array so that it is done freshly
  axios
    .get(API + "/reconmetrics/combinedrecontrend/" + userId + "/" + urlTenor)
    .then((response) => {
      if (response.data && response.status === 200) {
        response.data.forEach((data, index) => {
          //console.log("response.data.reconUnitTrend::", response.data.reconUnitTrend);

          reconTrendsData.series[0].push(data.trendValue);
          //   if (window.innerWidth < 765) {
          //     const month = [
          //       "Jan",
          //       "Feb",
          //       "Mar",
          //       "Apr",
          //       "May",
          //       "June",
          //       "July",
          //       "Aug",
          //       "Sept",
          //       "Oct",
          //       "Nov",
          //       "Dec",
          //     ];

          //     const d = new Date();
          //     let monthVal = month[d.getMonth(data.trendDateTime)];
          //     let dateVal = d.getDate();
          //     let timeVal = d.toLocaleTimeString();
          //     reconTrendsData.labels.push(`${monthVal} ${dateVal} ${timeVal}`);
          //   } else {
          let dateVal = new Date(data.trendDateTime);
          reconTrendsData.labels.push(dateVal.toUTCString());
          // }
        });

        reconTrendsDataDashBoard.labels = reconTrendsData.labels;
        reconTrendsDataDashBoard.series = reconTrendsData.series;

        callback(reconTrendsDataDashBoard, true);
      }
    })
    .catch((error) => {
      console.log("Exception occured ", error.response);
      callback(reconTrendsDataDashBoard, false);
    });
};
