import React from 'react';

const ToggleSwitch = ({ isOn, handleToggle, value }) => {
  return (
    <div className='custom-switch'>
    <label className="switch">
      <input type="checkbox" checked={isOn} value={value} onChange={handleToggle} />
      <span className="slider round"></span>
    </label>
    </div>
  );
}

export default ToggleSwitch;
