import React from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import { alpha } from '@mui/material/styles';
// @material-ui/core components

import MUIDataTable from "mui-datatables";

// import {getMuiTheme} from "assets/jss/material-dashboard-react/components/muiTableStyle.js";

const useStyles = makeStyles((theme) => ({
  filterActive: {
    backgroundColor: theme.palette.background.card, //alpha(theme.palette.primary.main, 0.2),
    //padding: 4,
    borderRadius: "5px",
    color: theme.palette.primary.main
  },
  muiTableWrapper: {
    '& td.MuiTableCell-root': {
      borderColor: theme.palette.border.secondary,
      padding: '6px 10px'
    },
    '& th.MuiTableCell-root':{
      borderColor: theme.palette.border.primary,
      backgroundColor: theme.palette.common.bgShade
    },
    '& th.MuiTableCell-root, td.MuiTableCell-root, .MuiTypography-root':{
      color: theme.palette.text.primary
    },
    '& .MuiPaper-root':{
      background: theme.palette.background.card
    }
  }
}));

export default function SAYAMUIDataTable(props) {
  const { title, columns, data, options, filterActive } = props;
  const classes = useStyles();
  const components = {
    icons: {
      FilterIcon: function (props) {
        if (filterActive) return <FilterListIcon className={classes.filterActive} />;
        return <FilterListIcon />;
      }
    }
  };

  return (
    <div className={classes.muiTableWrapper}>
      {
        <MUIDataTable
          title={title}
          columns={columns}
          data={data}
          options={options}
          components={components}
        ></MUIDataTable>
      }
    </div>
  );
}
SAYAMUIDataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  options: PropTypes.object,
  components: PropTypes.object
};
