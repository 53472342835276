import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Switch, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IconButton } from '@mui/material';
//import Hidden from "@material-ui/core/Hidden";
// @mui/icons-material
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';;
// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import RTLNavbarLinks from "./RTLNavbarLinks";
import Button from "../CustomButtons/Button";

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  let location = useLocation()

  function makeBrand() {
    let name;
    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  // React.useEffect(() => {
  //   console.log('location', location);
  // }, [location]);

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {/* Added Toggle menu icon for side menu toggle action */}
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            className={classes.toggleMenuIcon}
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton> */}
      
          <Box sx={{ display: { xl: 'block', xs: 'none' } }}>
            <span  className={classes.title}>
              {makeBrand()}
            </span>
          </Box>
        </div>
        <Box>
          {props.rtlActive ? (
            <RTLNavbarLinks />
          ) : (
            <AdminNavbarLinks isMobileHandler={props.handleDrawerToggle} />
          )}
        </Box>
        {/* <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
