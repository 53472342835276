import axios from "axios";
import { USERAPI } from "URL";

export const userPhotoUpload = async (data, userId, callback) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      `${USERAPI}/user/photo`,
      data,
      config
    );
    if (response.status === 200) {
    
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
    callback(`${error.message}`, "error");
  }
};

export const getUserPhoto = async (orgId, callback) => {
  try {
    const response = await axios.get(`${USERAPI}/user/photo/${orgId}`);
    if (response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};
