import {
  blackColor,
  whiteColor,
  hexToRgb,
  cardBoxShadow
} from "assets/jss/material-dashboard-react.js";
import { useTheme } from '@mui/material/styles';

export default function cardBodyStyle() {
  const theme = useTheme();
return{
  cardBody: {
    padding: "0.9375rem 0px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    borderRadius: "3px",
    '& .MuiTypography-h6': {
      paddingLeft: 0,
      marginLeft: '-8px'
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px"
    },
    '& .ct-line': {
      stroke: "rgba(" + hexToRgb(theme.palette.primary.main) + ", 0.5) !important",
      filter: "drop-shadow(0px 5px 4px rgba(0, 0, 0, 0.4))"
    },
    '& .ct-point': {
      stroke: theme.palette.secondary.light + ' !important'
    },
    '& .ct-point': {
      strokeLinecap: 'square'
    },
    '& .MuiCircularProgress-root':{ //*****Loader******
      color: theme.palette.primary.main,
      marginRight: 10
    },
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('lg')]: {
        padding: 8
      },
    }
  },
  cardBodyPlain: {
    paddingLeft: "5px",
    paddingRight: "5px"
  },
  cardBodyProfile: {
    marginTop: "15px"
  },
  // primaryCardBody: {
  //   "&:not($cardHeaderIcon)": {
  //     background: "rgba(" + hexToRgb(theme.palette.background.card) + ", 0.6)",
  //     // boxShadow: theme.palette.common.shadow
  //   }
  // },
};

}
