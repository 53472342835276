import axios from "axios";
import { USERAPI } from "URL.js";

import {
  userLoginDetails,
  forgotPasswordDetails,
  userDetailsInitialState
} from "variables/UserDetails.js";
import AxiosService from "AxiosService.js";

const axiosService = AxiosService.getService();

export const fetchMenuHierarchyById = (userId, callback) => {
  axios
    .get(USERAPI + "/menu-hierarchy/" + userId)
    .then((response) => {
      
      if (response.status === 200) {
      }
     
      callback(response.data, true);
    })
    .catch((error) => {
      console.log("Got into catch of MenuHierarchy");
      //callback(response.data, false);
      console.log(error);
    });
};