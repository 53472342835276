import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Chip, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// Define styles using makeStyles
const useStyles = makeStyles({
  // Override the MuiPaper-root and related classes
  tableContainer: {
    boxShadow: "none", // Removes box shadow for MuiPaper-elevation
    borderRadius: "0px", // Removes border-radius for MuiPaper-rounded
    border: "1px solid #f5f5f5", // Add border if needed
  },
  headerCell: {
    padding: "8px 16px", // Adjust header padding
    fontWeight: "bold", // Bold header
    fontSize: "14px", // Font size for header
    backgroundColor: "#f5f5f5", // Light background for header
  },
  bodyCell: {
    padding: "3px 10px", // Adjust cell padding
    fontSize: "14px", // Font size for table body
  },
  chipSuccess: {
    backgroundColor: "#ebf8e8",
    color: "#5ec615",
  },
  chipFailure: {
    backgroundColor: "#fbebeb",
    color: "#e64142",
  },
  chipPending: {
    backgroundColor: "#fdf9e7",
    color: "#edbe15",
  },
  paperRoot: {
    boxShadow: "none", // This will override the elevation for MuiPaper-elevation
    borderRadius: "0", // Removes rounded corners
    border: "1px solid #ddd", // Optionally add a border
  },
});

// Function to capitalize and add spaces between words
const capitalize = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters in camelCase
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

// Function to get status chip with MUI styles
const getStatusChip = (status, classes) => {
  switch (status) {
    case "Failure":
      return <Chip label="Failure" className={classes.chipFailure} />;
    case "SUCCESS":
      return <Chip label="Success" className={classes.chipSuccess} />;
    case "Pending":
      return <Chip label="Pending" className={classes.chipPending} />;
    default:
      return <Chip label={status} />;
  }
};

const DataIngestionTable = ({ auditData }) => {
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);

  const classes = useStyles(); // Use the defined styles

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => setLoading(false), 100);
    return () => clearTimeout(timer);
  }, [auditData]);

  useEffect(() => {
    if (auditData && auditData.length > 0) {
      const dynamicColumns = Object.keys(auditData[0]).map((key) => {
        return {
          name: key,
          label: capitalize(key),
          options: {
            filter: true,
            sort: true,
            setCellProps: () => ({
              className: classes.bodyCell, // Apply body cell styles
            }),
            setCellHeaderProps: () => ({
              className: classes.headerCell, // Apply header cell styles
            }),
            ...(key === "upload_status"
              ? {
                  customBodyRender: (value) => getStatusChip(value, classes),
                  filterType: "dropdown",
                }
              : {}),
          },
        };
      });
      setColumns(dynamicColumns);
    }
  }, [auditData, classes]);

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRows: "none",
    rowsPerPage: 10, 
    //rowsPerPageOptions: [4, 10, 20],
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    pagination: true,
    paginationOptions: {
      labelRowsPerPage: "Rows per page",
    },
    rowHover: true,
    setTableProps: () => ({
      className: classes.tableContainer, // Apply table container styles
    }),
    textLabels: {
      body: {
        noMatch: loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="body2" sx={{ ml: 2 }}>
              Loading data...
            </Typography>
          </Box>
        ) : (
          "No data to display"
        ),
      },
    },
  };

  return (
    <MUIDataTable
      title={"Upload History"}
      data={loading ? [] : auditData}
      columns={columns}
      options={options}
      components={{
        Table: (props) => (
          <div className={classes.paperRoot}>
            <MUIDataTable {...props} />
          </div>
        ),
      }}
    />
  );
};

export default DataIngestionTable;
