import React, { useContext, useEffect } from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@mui/styles";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
  Close,
  SettingsOutlined,
  PowerSettingsNewOutlined,
  FileUploadOutlined,
} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import { useAuthContext } from "@asgardeo/auth-react";
// @mui/icons-material
import Divider from "@mui/material/Divider";
import sampleLogo from "../../assets/img/sample-logo.png";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { WorkflowContext } from "context/Workflow/WorkflowContext";
import AxiosService from "../../AxiosService";
import SayaSwitch from "../Switch/CustomSwitch";
import { getOrgnizationLogo } from "../../action-handlers/OrganizationHandler";
import {
  fetchUserDetailsByLogin,
  logoutToCognito,
} from "../../action-handlers/LoginActionsHandler";
import { notificationParams } from "../../variables/general";
import Snackbar from "../Snackbar/Snackbar";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardFooter from "../Card/CardFooter";
import Button from "../CustomButtons/Button";
import { imageUpload } from "../utils";
import {
  userPhotoUpload,
  getUserPhoto,
} from "../../action-handlers/UserPhotoUpload";
import PopoverComponent from "../Popover/Popover";
import { fetchworkflows } from "action-handlers/WorkflowActionHandler";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [isPopupOpen, setPopup] = React.useState(false);
  const [imageFile, setImageFile] = React.useState("");
  const [photoImage, setPhotoImage] = React.useState("");
  const [imageError, setImageError] = React.useState("");
  const [notificationObj, setNotificationObj] =
    React.useState(notificationParams);
  const [userPhotoDetails, setUserPhotoDetils] = React.useState({});
  const [openNotification, setOpenNotification] = React.useState(null);
  const [dateTimeText, setDateTime] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [companyLogoFile, setCompanyLogo] = React.useState(null);
  const [companyLogoFileFormat, setCompanyLogoFileFormat] =
    React.useState(null);
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const WorkflowDataContext = useContext(WorkflowContext);
  const axiosService = AxiosService.getService();
  const iamProvider = axiosService.getIamProvider();
  const { signOut } = useAuthContext();
  const allowedPermissions =
    (UserAuthContext.state?.menuHierarchy || []).filter(
      (mh) => mh.name === "Settings"
    )[0]?.allowedPermissions || [];

  // To display Login date time details on CDM
  useEffect(() => {
    let showDateTime = getDateTime();
    setDateTime(showDateTime);
    const theme = localStorage.getItem("theme");
    setThemeVal(theme === "dark" ? true : false);
    UserAuthContext.setThemeType(theme ? theme : "light");
  }, []);

  const getUserProfilePhoto = async (userId) => {
    getUserPhoto(userId, (res) => {
      setUserPhotoDetils(res);
    });
  };

  useEffect(() => {
    UserAuthContext.state?.userId !== 0 &&
      getUserProfilePhoto(UserAuthContext.state?.userId);
  }, [UserAuthContext.state?.userId]);

  useEffect(() => {
    const { clientOrgId, loginName } = UserAuthContext.state;
    if (clientOrgId && clientOrgId !== 0) {
      getOrgnizationLogo(UserAuthContext.state.clientOrgId, getLogoCallback);
    } else if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, [UserAuthContext.state?.loginName]);

  const saveWorkflowData = (Workflows) => {
    WorkflowDataContext.saveWorkflows(Workflows);
  };

  const fetchUserDetails = (userData) => {
    // window.sessionStorage.setItem('userData', JSON.stringify(userData));
    UserAuthContext.saveUserDetailsAuthState(userData);
    fetchworkflows(userData.roleId, saveWorkflowData);
    getOrgnizationLogo(userData.clientOrgId, getLogoCallback);
  };

  const getLogoCallback = (logoData) => {
    setCompanyLogo(logoData.logoContent);
    setCompanyLogoFileFormat(logoData.imageType);
  };

  const isWso2Provider = () => {
    return iamProvider === "WSO2_IS";
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const setIsInitLogin = (value) => {
    if (isWso2Provider()) {
      sessionStorage.setItem("isInitLogin", value);
    }
  };

  const handleLogout = () => {
    axiosService.clearToken();

    setOpenProfile(null);

    if (isWso2Provider()) {
      signOut();
      setIsInitLogin("false");
    }
  };

  const cognitoLogout = () => {
    if (sessionStorage.iamProvider === "AWS_COGNITO") {
      logoutToCognito(sessionStorage.loginName, (flag) => {
        console.log("=================>>>>> AWS_COGNITO logout success", flag);
      });
      sessionStorage.clear();
    } else {
      signOut();
    }

    window.location.href = "login";
    setIsInitLogin("false");
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleHeaderPopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHeaderPopoverClose = () => {
    setAnchorEl(null);
  };

  const [themeVal, setThemeVal] = React.useState(false);
  const handleThemeChange = () => {
    setThemeVal(!themeVal);

    if (document.body.classList.contains("body-dark-theme")) {
      document.body.classList.remove("body-dark-theme");
    }
    if (document.body.classList.contains("body-light-theme")) {
      document.body.classList.remove("body-light-theme");
    }

    document.body.classList.add(
      themeVal ? "body-light-theme" : "body-dark-theme"
    );
    localStorage.setItem("theme", themeVal ? "light" : "dark");
    UserAuthContext.setThemeType(themeVal ? "light" : "dark");
  };

  const getDateTime = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    let hours = today.getHours();
    let minutes = today.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;

    let dateTime = `${date} ${strTime}`;

    return dateTime;
  };

  const headerPopoverOpen = Boolean(anchorEl);
  const headerPopoverId = headerPopoverOpen ? "simple-popover" : undefined;

  var company = (
    <a
      href="#company#"
      onClick={(e) => e.preventDefault()}
      className={classNames(classes.logoLink, {})}
      target="_blank"
    >
      <div
        className={classes.logoImage}
        style={{
          backgroundImage:
            companyLogoFile &&
            `url(data:${companyLogoFileFormat};base64,${companyLogoFile})`,
        }}
      >
        {!companyLogoFile && <img src={sampleLogo} />}
        {/* <img
          src={
            companyLogoFile
              ? "data:" + companyLogoFileFormat + ";base64," + companyLogoFile
              : sampleLogo
          }
          width={companyLogoFile ? "50px" : "52px"}
          alt="company"
          className={classes.img}
          //style={{ opacity: companyLogoFile ? 1 : 0 }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = sampleLogo;
          }}
        /> */}
      </div>
    </a>
  );

  const openPopup = () => {
    setPopup(true);
  };

  const closePopup = () => {
    setImageError("");
    setImageFile("");
    setPhotoImage("");
    setPopup(false);
  };

  const imgUploadCallback = (isSuccess, data, file, eleName) => {
    if (isSuccess) {
      setImageFile(file);
      setPhotoImage(data);
    } else {
      setImageError(data);
      setImageFile("");
      setPhotoImage("");
    }
  };

  const onFileChange = (e) => {
    let imgLimitWidth = 100;
    let imgLimitHeight = 100;
    let whFlag, sizeFlag;

    imageUpload(
      e,
      imgUploadCallback,
      imgLimitWidth,
      imgLimitHeight,
      true,
      true
    );
    // e.target.value = "";
  };

  const uploadPhoto = async () => {
    if (imageFile) {
      const file = new FormData();
      file.append("userId", UserAuthContext.state.userId);
      file.append("photoContent", imageFile);
      userPhotoUpload(file, UserAuthContext.state.userId, (res, error) => {
        closePopup();
        nofifyHandler(res, error ? "danger" : "success");
        getUserProfilePhoto(UserAuthContext.state?.userId);
      });
    } else {
      nofifyHandler("Please select a Photo", "danger");
    }
  };

  const nofifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const handleCloseNotification = () => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  return (
    <div>
      <div className={classes.searchWrapper}>
        <span
          id="app-logout"
          onClick={handleLogout}
          style={{ position: "absolute", top: -9999 }}
        >
          Logout
        </span>
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.hamburgerMobileIcon}
          onClick={props.isMobileHandler}
        >
          <MenuIcon />
        </IconButton> */}
        <div>
          <div className={classes.welcomeText}>
            <div>
              {userPhotoDetails?.photoContent ? (
                <img
                  src={`data:${userPhotoDetails.imageType};base64,${userPhotoDetails.photoContent}`}
                  alt="Avatar"
                  className={classes.userProfileStyles}
                />
              ) : (
                <div className={classes.nameCircle}>
                  {UserAuthContext?.state?.loginName?.slice(0, 2).toUpperCase()}
                </div>
              )}
            </div>
            <div>
              <span className={classes.welcomeTextLg}>Welcome to SAYA </span>
              <span className={classes.welcomeTextXs}>Hi- </span>
              <span className={classes.userName}>
                {" "}
                {UserAuthContext.state.loginName}{" "}
              </span>

              {/* <b className={classes.userText}>
                  {UserAuthContext.state.loginName}
                </b> */}
            </div>

            {company}
          </div>
          <Menu
            id={headerPopoverId}
            anchorEl={anchorEl}
            keepMounted
            open={headerPopoverOpen}
            onClose={handleHeaderPopoverClose}
            classes={{
              paper: classes.headerMenu,
            }}
          >
            {allowedPermissions?.includes("view") && (
              <>
                <MenuItem disabled onClick={handleHeaderPopoverClose}>
                  SAYA Platform version:{" "}
                  {(sessionStorage?.spDetails &&
                    JSON.parse(sessionStorage?.spDetails)?.versionNo) ||
                    "22.3"}{" "}
                  (AI Enabled)
                </MenuItem>
                {allowedPermissions?.includes("upload user photo") && (
                  <MenuItem onClick={openPopup}>
                    <FileUploadOutlined /> Upload User Photo
                  </MenuItem>
                )}

                {allowedPermissions?.includes("theme change") && (
                  <MenuItem className={classes.themeSwitch}>
                    <SayaSwitch
                      value={themeVal}
                      id="theme-change"
                      handleToggle={handleThemeChange}
                      labelText={"Dark Mode"}
                    />
                  </MenuItem>
                )}
              </>
            )}
            <Divider className={classes.devider} />
            <MenuItem onClick={cognitoLogout}>
              <PowerSettingsNewOutlined className={classes.menuIcon} /> Logout
            </MenuItem>
            {/* <MenuItem disabled onClick={handleHeaderPopoverClose}>
                Upload Company logo
              </MenuItem>
              <MenuItem disabled onClick={handleHeaderPopoverClose}>
                Documentation
              </MenuItem> */}

            {/* <MenuItem onClick={handleLogout}>
                <PowerSettingsNewOutlinedIcon className={classes.menuIcon} />
                Logout
              </MenuItem> */}
          </Menu>
        </div>

        <span
          className={classes.iconWrapper}
          onClick={handleHeaderPopoverClick}
        >
          <SettingsOutlined />
        </span>

        {isPopupOpen && (
          <Dialog
            open={isPopupOpen}
            onClose={closePopup}
            aria-labelledby="draggable-dialog-title"
          >
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
              Upload User Photo
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setPopup(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogWindow}>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card plain>
                      <CardBody>
                        <Box>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <InputLabel>
                                User Photo{" "}
                                <PopoverComponent
                                  wrapperWidth={400}
                                  wrapperHeight={300}
                                  className={classes.popoverWrap}
                                  imgSrc=""
                                  imgWidth={450}
                                  msgTxt="Recommended size for logo is width : 100 and Height: 100 and image should be no larger than 1MB"
                                />
                              </InputLabel>
                              <FormControl
                                fullWidth
                                sx={{ m: 1 }}
                                variant="filled"
                                className={classes.uploadField}
                              >
                                <TextField
                                  id="file"
                                  type="file"
                                  name="photo"
                                  variant="outlined"
                                  inputProps={{ accept: "image/*" }}
                                  onChange={(event) => onFileChange(event)}
                                />

                                {!photoImage && imageError && (
                                  <p className={classes.errorMessgage}>
                                    {imageError}
                                  </p>
                                )}
                                <div className={classes.logoimagePreview}>
                                  {
                                    <img
                                      style={{
                                        opacity:
                                          userPhotoDetails.photoContent ||
                                          photoImage
                                            ? 1
                                            : 0,
                                      }}
                                      src={
                                        photoImage
                                          ? photoImage
                                          : "data:" +
                                            userPhotoDetails.imageType +
                                            ";base64," +
                                            userPhotoDetails.photoContent
                                      }
                                    />
                                  }
                                </div>
                              </FormControl>
                            </GridItem>
                          </GridContainer>
                        </Box>
                      </CardBody>
                      <CardFooter>
                        <Grid container justify="center" spacing={1}>
                          <GridItem style={{ marginLeft: "-10px" }}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={uploadPhoto}
                            >
                              Upload
                            </Button>
                          </GridItem>
                        </Grid>
                      </CardFooter>
                    </Card>
                  </GridItem>
                </GridContainer>
              </form>
            </DialogContent>
          </Dialog>
        )}
        {notificationObj && (
          <Snackbar
            place={notificationObj.place}
            color={notificationObj.color}
            icon={notificationObj.icon}
            message={notificationObj.message}
            open={notificationObj.open}
            closeNotification={handleCloseNotification}
            close={notificationObj.close}
            autoHideDuration={3000}
          />
        )}
      </div>
    </div>
  );
}
