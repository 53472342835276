import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PropTypes from "prop-types";

const styles = (theme) => ({
  "@global": {
    ".body-no-scroll": {
      overflow: "auto !important",
    },
  },
  topDialog: {
    top: "-95px",
  },
  titleWrapper: {
    "& h2": {
      display: "flex",
      justifyContent: "space-between",
      "& button": {
        padding: 0,
      },
    },
  },
  tableWrapper: {
    minWidth: 500,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderCollapse: "unset",
  },
});

const useStyles = makeStyles(styles);

export default function SayaDialogSuggestMatchRules(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    dialogTitle,
    labelledRuleDTO,
    handleRuleChange,
    loadingState,
  } = props;

  useEffect(() => {
    return () => {
      console.log(
        "Component Suggest Match unloading cleanup to be done here!!!"
      );
    };
  }, []);

  useEffect(() => {
    // Add the 'body-no-scroll' class to the body when the Dialog is open
    if (open) {
      document.body.classList.add("body-no-scroll");
    } else {
      // Remove the 'body-no-scroll' class when the Dialog is closed
      document.body.classList.remove("body-no-scroll");
    }

    // Cleanup the class when the component unmounts
    return () => {
      document.body.classList.remove("body-no-scroll");
    };
  }, [open]);


  return (
    <Dialog
      //fullScreen
      classes={{
        paper: classes.topDialog,
      }}
      open={open}
      scroll={"body"}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      style={{ minHeight: 250 }}
      hideBackdrop
    >
      <DialogTitle
        id="draggable-dialog-title"
        style={{ cursor: "move" }}
        className={classes.titleWrapper}
      >
        {dialogTitle}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ float: "right", color: "red" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ minHeight: "120px" }}>
        {/* <DialogContent style={{ height: "550px", width: "550px"}}> */}
        <Table className={classes.tableWrapper}>
          <TableBody>
            {labelledRuleDTO?.length > 0 ? (
              labelledRuleDTO?.map((item, index) => (
                <TableRow
                  key={index}
                  className="tblFont"
                  style={{ height: "2px", padding: "4px" }}
                >
                  <TableCell
                    scope="row"
                    style={{ height: "2px", padding: "4px" }}
                  >
                    {item.columnIdSrc1}
                  </TableCell>
                  <TableCell style={{ height: "2px", padding: "4px" }}>
                    {item.columnIdSrc2}
                  </TableCell>
                  <TableCell style={{ height: "2px", padding: "4px" }}>
                    <Switch
                      key={index}
                      //    checked={this.state.switchState['switch-' + index]}
                      checked={item.matchCriteria}
                      onChange={(event, checked) =>
                        handleRuleChange(index, event, checked)
                      }
                      value="checkedB"
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center", padding: "20px" }}>
                  {loadingState ? "Loading..." : ""}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

SayaDialogSuggestMatchRules.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  labelledRuleDTO: PropTypes.arrayOf(PropTypes.object),
  handleRuleChange: PropTypes.func,
};
