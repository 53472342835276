import { useState, useRef, useEffect } from "react";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MetaForm from "../MetaForm";
import { Grid, Typography, Box } from "@mui/material";
import { getUIComponents } from "action-handlers/MetaDataActionHandler";
import { fetchMetaConfig } from "action-handlers/MetaDataActionHandler";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};

const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    disabled={props.isDisable}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .action-menu:hover": {
    color: "red",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const AccordionList = ({ row, index, handleOpenActionMenu }) => {
  const [expanded, setExpanded] = useState("");
  const [uiComponents, setUIComponents] = useState([]);
  const [metaDataConfig, setMetaDataConfig] = useState({});
  const [metaDataConfigOptions, setMetaDataConfigOptions] = useState([]);

  const getUIComponentsData = async (inputData = {}) => {
    getUIComponents(inputData?.dataType || "STRING", (data, status) => {
      setUIComponents(data);
    });
  };

  const fetchMetaDataForColumn = async (inputData = {}) => {
    fetchMetaConfig(inputData?.metaDataId, (res) => {
      const config = res?.metaDataUIConfig || {};
      setMetaDataConfigOptions(res?.metaDataUISubConfigList || []);
      setMetaDataConfig(config);
    });
  };

  const handleChange = (panel, data) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (!expanded && panel.includes("panel")) {
      getUIComponentsData(data);
      fetchMetaDataForColumn(data);
    }
  };

  const clickHandler = (event, row) => {
    handleOpenActionMenu(event, row);
  };

  const positiveStyles = {
    fontSize: "13px",
    backgroundColor: "#ebf9f3",
    borderRadius: "5px",
    padding: "2px 10px",
    color: "#7acd5a",
    border: "1px solid #bbe19e",
    fontWeight: "500",
    textTransform: "capitalize",
  };

  const negativeStyles = {
    fontSize: "13px",
    backgroundColor: "#ffefef",
    borderRadius: "5px",
    padding: "2px 10px",
    color: "#ff9f9f",
    border: "1px solid #f7d2d2",
    fontWeight: "500",
    textTransform: "capitalize",
  };

  return (
    <Accordion
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`, row)}
      key={index}
    >
      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Grid container>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "14px" }}>{row?.columnId}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ paddingLeft: "12px", fontSize: "14px" }}
              >{`${row?.columnName}`}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  paddingLeft: "12px",
                  fontSize: "14px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {row?.dataType?.toLowerCase() === "string" && (
                  <ArticleOutlinedIcon sx={{ mr: 1, color: "#0b99bf" }} />
                )}
                {row?.dataType === "NUMERIC" && (
                  <PinOutlinedIcon sx={{ mr: 1, color: "#d980a7" }} />
                )}
                {row?.dataType === "DATE" && (
                  <CalendarMonthOutlinedIcon sx={{ mr: 1, color: "#3eb093" }} />
                )}
                {row?.dataType === "DOCUMENT" && (
                  <DriveFolderUploadOutlinedIcon
                    sx={{ mr: 1, color: "#ac8bc0" }}
                  />
                )}

                {`${row?.dataType || "STRING"}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={row?.mandatory ? positiveStyles : negativeStyles}>
                {`${row?.mandatory}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {row?.visible ? (
                  <RemoveRedEyeOutlinedIcon sx={{ color: "#3eb093" }} />
                ) : (
                  <VisibilityOffOutlinedIcon sx={{ color: "#d980a7" }} />
                )}
                {/* {`${row?.visible}`} */}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            key={"actions"}
            className="action-menu"
            onClick={(e) => clickHandler(e, row)}
            noWrap
            sx={{
              mr: { md: 2, xs: "-10px" },
              cursonr: "pointer",
              flexGrow: 1,
              fontWeight: 300,
              color: "#000",
              cursor: "pointer",
            }}
          >
            <MoreHorizIcon />
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: "#f9f9f9" }}>
        {expanded === `panel${index}` && (
          <Box>
            {row && (
              <MetaForm
                rowData={row}
                key={row.columnId}
                uiComponents={uiComponents}
                metaDataConfig={metaDataConfig}
                metaDataConfigOptions={metaDataConfigOptions}
                fetchMetaDataForColumn={fetchMetaDataForColumn}
              />
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionList;
