import { alpha } from '@mui/material/styles';
import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  grayColor,
} from "assets/jss/material-dashboard-react.js";
import { useTheme } from '@mui/material/styles';
export default function cardIconStyle() {
  const theme = useTheme();
  return {
    cardIcon: {
      background: alpha(theme.palette.primary.main, 0.12),
      boxShadow: theme.palette.common.shadow,
      "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader":
        {
          borderRadius: "3px",
          // backgroundColor: grayColor[0],
          padding: "5px",
          marginTop: "0px",
          marginRight: "15px",
          [theme.breakpoints.down('lg')]: {
            marginRight: "5px",
          },
          float: "left",
          color: theme.palette.primary.contrastText,
        },
    },
    plainIcon: {
      background: "transparent",
      boxShadow: "none !important",
      '& svg': {
        color: alpha(theme.palette.primary.main, 0.7),
        width: '50px !important',
        height: '50px !important',
        [theme.breakpoints.down('lg')]: {
          width: '35px !important',
          height: '35px !important',
        },
      }
    },

    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    roseCardHeader,
  };
}