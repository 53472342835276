import React, { useState } from "react";
import { TableCell, Popover, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function truncateTextWithPopover(text, limit) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!text) {
    return "";
  }

  if (text.length <= limit) {
    return text;
  }

  const truncatedText = text.slice(0, limit);
  const popoverText = text;

  return (
    <>
      <div
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        disableTouchListener
        disableFocusListener
        disableHoverListener
        PopperProps={{
          disablePortal: true,
        }}
        onOpen={handlePopoverOpen}
        onClose={handlePopoverClose}
      >
        <span>{truncatedText}...</span>
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography style={{maxWidth: 450}}>{popoverText}</Typography>
        </Popover>
      </div>
    </>
  );
}

function CustomTableCell({ content, limit }) {
  return <div>{truncateTextWithPopover(content, limit)}</div>;
}

export default CustomTableCell;
