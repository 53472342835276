import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import SAYACustomInput from "../../components/CustomInput/SAYACustomInput";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import { initLoginFormState } from "../../variables/UserDetails";
//import actions from action handlers
import { forgotPasswordHandler } from "../../action-handlers/LoginActionsHandler";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
export default function ForgotPassword(props) {
  const classes = useStyles();

  const [state, setUserForgotPasswordState] = useState(initLoginFormState);
  //const [forgotPasswordState, setForgotPasswordState] = useState(forgotPasswordDetails);

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (state.input.userName !== "" && state.input.userName !== undefined) {
      let userobject = {
        userName: state.input.userName,
      };
      forgotPasswordHandler(userobject, callbackForgotPassword);
    } else {
      const newUserForgotNameState = { ...initLoginFormState };
      newUserForgotNameState.validationErrs.userName =
        "User Name cannot be empty !";
      setUserForgotPasswordState(newUserForgotNameState);
      
    }
  };

  const handleForgotPasswordSuccess = () => {
    /***TODO pending to route to next confirmforgot password */
    
    window.location.assign("/saya-platform/confirmforgotpassword");
  };

  const callbackForgotPassword = (passwordResponse) => {
   
    //setForgotPasswordState(passwordResponse.isSuccess);
    //console.log("Forgotpassword state", forgotPasswordState)
    if (!passwordResponse.isSuccess) {
      const newUserForgotNameState = { ...initLoginFormState };
      newUserForgotNameState.validationErrs.userName =
        "Unable to send confirmation code !, please enter valid user name";
      setUserForgotPasswordState(newUserForgotNameState);
      
    } else {
      //this means the forgot password is successfully executed and sent a confirmation code to the user
      
      handleForgotPasswordSuccess();
    }
  };

  const handleChange = (e) => {
    const newUserForgotNameState = { ...initLoginFormState };
    newUserForgotNameState.input.userName = e.target.value;
    newUserForgotNameState.validationErrs.userName = ""; //resetting the errors
    setUserForgotPasswordState(newUserForgotNameState);
  };

  useEffect(() => {
    let initialUserState = { ...initLoginFormState };
    if (
      props?.location?.state !== undefined &&
      props?.location?.state?.input?.userName !== "" &&
      props?.location?.state?.input?.userName !== undefined
    ) {
      initialUserState.input.userName = props.location.state.input.userName;
    }
    setUserForgotPasswordState(initialUserState);
  }, [props]);
  return (
    <div>
      <form onSubmit={handleSubmit} noValidate xs={12} sm={12} md={8}>

        <GridContainer justifyContent="center" style={{marginTop:'60px'}}>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="primary" >
                <h4 >Forgot Password</h4>
                <p >
                  Please input user name, we will send you the confirmation code
                  to your registered email address.
                </p>
              </CardHeader>

              <CardBody>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={10} md={8}>
                    <GridContainer>
                      <br />
                      <br />
                      <br />
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={9} md={8}>
                        <p style={{margin: 0}}>User Name</p>
                        <SAYACustomInput
                          state={initLoginFormState}
                          handleChange={handleChange}
                          labelText=""
                          id="userName"
                          position="center"
                          inputProps={{
                            style: { textAlign: "center", marginTop: '5px' },
                            //defaultValue: props.localtion.state.userName
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          margin="normal"
                        />
                        <span
                          style={{ color: "red" }}
                          className="validation-errors"
                        >
                          {state.validationErrs.userName}
                        </span>
                      </GridItem>
                    </GridContainer>
                    <div>
                      <br />
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Send COnfirmation Code
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </div>
                    <GridContainer>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <br />
                <Footer />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
