import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ShowToastMessage = ({ isOpen = false, message = '', handleToastClose, color = 'success' }) => {
  return (
    <Snackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
      onClose={handleToastClose}
    >
      <Alert
        onClose={handleToastClose}
        severity={color} // directly pass the color
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default React.memo(ShowToastMessage);
