// ##############################
// // // Tasks for TasksCard - see Dashboard view
// #############################

var bugs = [
  'Sign contract for "What are conference organizers afraid of?"',
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  "Create 4 Invisible User Experiences you Never Knew About"
];
var website = [
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];
var server = [
  "Lines From Great Russian Literature? Or E-mails From My Boss?",
  "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
  'Sign contract for "What are conference organizers afraid of?"'
];

var notificationParams = {
  message: "",
  color: "success",
  close: true,
  place: "tr",
  open: false,
  rtlActive: false
};

var columns = [];
var data = [{}];

const supportedDataTypes = [
  {
    value: 1,
    label: "Images",
    isdisabled: false,
    acceptFormat: "image/*",
    dropZoneText: "Drag'n'drop images, or click to select files"
  },
  {
    value: 2,
    label: "Text Files",
    isdisabled: false,
    acceptFormat: "text/*",
    dropZoneText: "Drag'n'drop Text files, or click to select files"
  },
  {
    value: 3,
    label: "CSV Files",
    isdisabled: false,
    acceptFormat:
      ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
    dropZoneText: "Drag'n'drop CSV files, or click to select files"
  },
  {
    value: 4,
    label: "Excel Files",
    isdisabled: false,
    acceptFormat: "application/vnd.ms-excel",
    dropZoneText: "Drag'n'drop Excel files, or click to select files"
  },
  {
    value: 5,
    label: "SWIFT",
    isdisabled: false,
    acceptFormat: "text/*",
    dropZoneText: "Drag'n'drop SWIFT text, or click to select files"
  },
  {
    value: 6,
    label: "PDF",
    isdisabled: false,
    acceptFormat: "application/pdf",
    dropZoneText: "Drag'n'drop PDFs, or click to select files"
  },
  {
    value: 7,
    label: "JSON",
    isdisabled: false,
    acceptFormat: "application/json",
    dropZoneText: "Drag'n'drop JSON, or click to select files"
  }
];
const supportedLanguages = [
  { value: "eng", label: "English", isdisabled: false },
  { value: "ind", label: "Indonesian", isdisabled: false },
  { value: "fra", label: "French", isdisabled: false },
  { value: "spa", label: "Spanish", isdisabled: false },
  { value: "ara", label: "Arabic", isdisabled: false },
  { value: "hin", label: "Hindi", isdisabled: false }
];

module.exports = {
  // these 3 are used to create the tasks lists in TasksCard - Dashboard view
  bugs,
  website,
  server,
  notificationParams,
  columns,
  data,
  supportedLanguages,
  supportedDataTypes
};
