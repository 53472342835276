/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://saya.3cortex.com
* Copyright 2021 3CORTEX TECHNOLOGIES INDIA PRIVATE LIMITED (https://www.3cortex.com)
* Licensed - 3CORTEX TECHNOLOGIES ALL RIGHTS RESERVED

* Coded by Rajendra

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @mui/icons-material
import {
  Dashboard,
  Person,
  LibraryBooks,
  BubbleChart,
  CloudUploadOutlined,
  Language,
  LockOpen,
  NewReleases,
  Storage,
  PersonOutlineOutlined,
  KeyboardCommandKey,
  Equalizer,
  PublishedWithChanges,
  SettingsOutlined,
} from "@mui/icons-material";
import DashboardPage from "views/Dashboard/Dashboard.js";
import ReconXWorkSpace from "views/ReconXWorkspace/ReconXWorkspace";
import ResolveXWorkSpace from "views/ResolveXWorkspace/ResolveXWorkspace";
import AnalytiX from "views/AnalytiX/AnalytiXHome";
import AdminHome from "views/AdminPages/AdminHome";
import LoginPage from "views/LoginPage/LoginPage";
import ForgotPassword from "views/LoginPage/ForgotPassword.js";
import ConfirmForgotPassword from "views/LoginPage/ConfirmForgotPassword.js";
import DataIngestion from "views/DataIngestion/DataIngestion";
import RTLPage from "views/RTLPage/RTLPage.js";
import ReconProcessHome from "views/AdminPages/ReconProcessSetup/ReconProcessHome";
import ReconProcessCrud from "views/AdminPages/ReconProcessSetup/ReconProcessCrud";
import ServiceProviderHome from "./views/ServiceProvider/ServiceProvider";
import SignUpPage from "views/Registration/SignUpPage";
import Departments from "views/Departments/Departments";
import FirstTimePasswordChange from "views/Registration/FirstTimePasswordChange";
import IdPLoginPage from "views/LoginPage/IdPLoginPage";
import IdPLogoutPage from "views/LoginPage/IdPLogoutPage";
import AxiosService from "AxiosService";
import Organizations from "views/Organization/Organizations";
import UsersHome from "views/Registration/UsersHome";
import Workflow from "views/Workflow/Workflow";
import WorkflowDetails from "views/Workflow/WorkflowDetails";
import ConfigureMetaData from "views/MetaData/ConfigureMetaData";
import ManualUpload from "views/DataIngestion/ManualUpload";
import DataIngestionOMR from "views/DataIngestion/DataIngestion-OMR-Legacy";

const axiosService = AxiosService.getService();

const dashboardRoutes = [
  {
    path: "/settings",
    name: "Settings",
    rtlName: "لوحة القيادة",
    icon: SettingsOutlined,
    component: "",
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/saya-reconx",
    name: "ReconX",
    rtlName: "سايا ReconX",
    icon: KeyboardCommandKey,
    component: ReconXWorkSpace,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/saya-resolvex",
    name: "ResolveX",
    rtlName: "سايا ResolveX",
    icon: PublishedWithChanges,
    component: ResolveXWorkSpace,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/saya-analytix",
    name: "AnalytiX",
    rtlName: "سايا AnalytiX",
    icon: Equalizer,
    component: AnalytiX,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/saya-admin",
    name: "Admin",
    rtlName: "سايا الادارة",
    icon: PersonOutlineOutlined,
    component: AdminHome,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  // {
  //   path: "/datax",
  //   name: "Data Ingestion", //change Data Ingestion
  //   rtlName: "طباعة",
  //   icon: CloudUploadOutlined,
  //   component: DataIngestion,
  //   layout: "/saya-platform",
  //   invisible: true,
  //   showInFullPage: false,
  // },
  {
    path: "/data-ingestion",
    name: "Data Ingestion",
    rtlName: "طباعة",
    icon: CloudUploadOutlined,
    component: ManualUpload,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/ops-home/ops-crud",
    name: "Create/ Modify Reconciliation Process",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: ReconProcessCrud,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/login",
    name: "SAYA Login",
    rtlName: "تسجيل الدخول سايا",
    icon: LockOpen,
    component:
      axiosService.getIamProvider() !== "AWS_COGNITO"
        ? IdPLoginPage
        : LoginPage,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: true,
  },
  {
    path: "/logout",
    name: "SAYA Logout",
    rtlName: "تسجيل الدخول سايا",
    icon: LockOpen,
    component:
      axiosService.getIamProvider() !== "AWS_COGNITO"
        ? IdPLogoutPage
        : LoginPage,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: true,
  },
  {
    path: "/forgot-password",
    name: "SAYA Forgot Password",
    rtlName: "نسيت كلمة المرور",
    icon: LockOpen,
    component: ForgotPassword,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: true,
  },
  {
    path: "/confirmforgotpassword",
    name: "Confirm Forgot Password",
    rtlName: "تأكيد نسيت كلمة المرور",
    icon: LockOpen,
    component: ConfirmForgotPassword,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: true,
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/change-temp-password",
    name: "Change Temporary Password",
    rtlName: "",
    icon: LockOpen,
    component: FirstTimePasswordChange,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/organizations",
    name: "Organisations",
    rtlName: "",
    icon: Person,
    component: Organizations,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/sign-up",
    name: "Users",
    rtlName: "",
    icon: Person,
    component: SignUpPage,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/departments",
    name: "Departments",
    rtlName: "",
    icon: Person,
    component: Departments,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/ops-home",
    name: "Ops process",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: ReconProcessHome,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/users",
    name: "Users",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: UsersHome,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/service-provider",
    name: "Service Provider",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: ServiceProviderHome,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/workflows",
    name: "Flow Design",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: Workflow,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/workflows/configure",
    name: "Workflow Configure",
    rtlName: "سايا الادارة",
    icon: NewReleases,
    component: WorkflowDetails,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/metadata",
    name: "Meta Data",
    rtlName: "",
    icon: PersonOutlineOutlined,
    component: ConfigureMetaData,
    layout: "/saya-platform/admin",
    invisible: true,
    showInFullPage: false,
  },
  {
    path: "/data-upload",
    name: "Data Upload",
    rtlName: "",
    icon: NewReleases,
    component: ManualUpload,
    layout: "/saya-platform/datax",
    invisible: true,
    showInFullPage: false,
  },
  /*{
  /*{
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/saya-platform",
    invisible: true,
    showInFullPage: false
  },
 {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
    invisible: true,
    showInFullPage: false
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
    invisible: true,
    showInFullPage: false
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
    invisible: true,
    showInFullPage: false
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
    invisible: true,
    showInFullPage: false
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl",
    invisible: true,
    showInFullPage: false
  }*/
];

export default dashboardRoutes;
