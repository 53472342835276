import React, { useReducer } from "react";
import { ReconXContext } from "./ReconXContext";
import ReconXReducer from "./ReconXReducer";

const ReconXState = ({ children }) => {
  const reconXInitialState = {
    selectedReconUnit: "",
    selectedReconUnitName: "",
    globalDropDownData: [],
    selectedRecData: {},
    ruleUnlabelledDTO: [],
    suggestedMatchesFinal: [],
    globalReconSuggestTableData: [],
    globalMetaDataSrcColNames: [],
    globalMetaDataSrcColIds: [],
    globalReconColumns: [],
    tenorPeriod: {},
    selectedReconStatus: "ALL",
    globalDataSrcData: [
      { value: 1, label: "Data Source -1", isdisabled: false },
      { value: 2, label: "Data Source -2", isdisabled: false },
    ],
    reconData: [
      {
        label: "All Records",
        value: "ALL",
      },
      { label: "Open Records", value: "OPEN" },
      { label: "Reconciled records", value: "RECONCILED" },
    ],
    tablePagination: {
      pageIndex: 0,
      pageSize: 500,
    },
  };

  const [state, dispatch] = useReducer(ReconXReducer, reconXInitialState);

  function saveReconProcessesData(recon_processes) {
    dispatch({
      type: "SAVE_RECON_PROCESSES_DATA",
      payload: recon_processes,
    });
  }

  return (
    <ReconXContext.Provider
      value={{
        state,
        saveReconProcessesData,
      }}
    >
      {children}
    </ReconXContext.Provider>
  );
};

export default ReconXState;
