import { USERAPI } from "URL.js";
import axios from "axios";

/**
 * Service to fetch identity configurations.
 * 
 * @author arjun.b
 */
const IdentityService = (function () {

    let _instance;

    function _getInstance() {
        if (!_instance) {
            _instance = this;
        }
        return _instance;
    }

    async function _fetchIdentityConfig() {
        const origin = window.location.protocol +"//"+ window.location.host;
        console.info("Origin for IAM config ", origin);
        return axios.get(USERAPI + '/iam/integration/config?origin=' + origin);
    }

    return {
        getInstance: _getInstance,
        fetchIdentityConfig: _fetchIdentityConfig
    }

})();

export default IdentityService;