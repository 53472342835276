import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor
} from "assets/jss/material-dashboard-react.js";

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "10px",
    paddingRight: '0 !important',
    zIndex: "1029",
    //color: primaryColor[3],
    border: "0",
    borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  container: {
    ...container,
    minHeight: "50px"
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "30px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    paddingLeft: 28,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      fontSize: "18px"
    },
    "&:hover,&:focus": {
      background: "transparent",
      color: theme.palette.text.primary
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  toggleMenuIcon: {
    marginLeft: 0
  }
});

export default headerStyle;
