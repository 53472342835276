import PropTypes from "prop-types";
import { useMemo, useContext, useState, useEffect } from "react";
// @mui
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import paletteLight from "./paletteLight";
import paletteDark from "./paletteDark";
import shadows from "./shadows";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import customShadows from "./customShadows";
import ComponentsOverrides from "./overrides";
//import shadows, { customShadows } from "./shadows";
import { UserAuthDetailsContext } from "../context/LoginPageContext/UserAuthDetailsContext";
import { fetchServiceProvidersById } from "../action-handlers/ServiceProviderHandler";
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default function ThemeConfig({ children }) {
  const UserAuthContext = useContext(UserAuthDetailsContext);

  const [palette, setIsLight] = useState(paletteLight);

  const setSPListHandler = (data) => {
    let spData = data[0];
    const pColor = spData.primaryColor;
    const sColor = spData.secondaryColor;

    let paletteLightObj = {
      ...paletteLight,
      primary: { ...paletteLight.primary, main: pColor },
      secondary: { ...paletteLight.secondary, main: sColor },
    };
    let paletteDarkObj = {
      ...paletteDark,
      primary: { ...paletteDark.primary, main: pColor },
      secondary: { ...paletteDark.secondary, main: sColor },
    };

    if (UserAuthContext?.state?.themeType === "light") {
      setIsLight(paletteLightObj);
    } else {
      setIsLight(paletteDarkObj);
    }
  };

  // useEffect(() => {
  //   const spDetails = localStorage.spDetails ? JSON.parse(localStorage.spDetails) : null;
  //   spDetails !== null && setSPListHandler([spDetails]);
  // }, [UserAuthContext?.state?.themeType]);

  useEffect(() => {
    // Get Service Provider details to get Primary and Secondary colors
    const Uid = UserAuthContext?.state?.userId;
    if (Uid) {
      fetchServiceProvidersById(Uid, setSPListHandler);
    }
  }, [UserAuthContext.state?.userId, UserAuthContext?.state?.themeType]);

  const themeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [palette]
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);
  // const theme = createTheme({
  //   ...themeOptions,
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           fontSize: '5rem',
  //           color: 'red'
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
