import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./ReconStyles";

function AuditInfo({ auditData = [] }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const columns = ["ID", "Action Name", "User Notes", "User Name", "Created Date"];

  // return auditData.map((ad) => <li key={ad.auditId}>{ad.userNotes}</li>);

  return (
    <TableContainer className={classes.auditInfoWrapper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column} style={{width: column === "User Notes" ? 450 : 'auto'}}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {auditData?.map((row, index) => (
            <TableRow key={row.auditId}>
              <TableCell>{row.auditId}</TableCell>
              <TableCell>{row.actionName}</TableCell>
              <TableCell>{row.userNotes}</TableCell>
              <TableCell>{row.userName}</TableCell>
              <TableCell>{row.createdDate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default AuditInfo;
