import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";

import {
  AddToPhotos,
  DeviceHub,
  EditRounded,
  BorderColorOutlined,
  AddBoxOutlined,
  Close,
  Delete,
} from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Button from "../../components/CustomButtons/Button";
// core components
import CardIcon from "../../components/Card/CardIcon";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import {
  getDepartmentIdValues,
  getDepartmentByDepartmentId,
  createModifyDepartment,
  deleteDepartment,
} from "../../action-handlers/DepartmentHandler";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { departmentValidations } from "../../utils/validationSchema";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import ConfirmationOverlay from "../../components/ConfirmOverlay/ConfirmationOverlay";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import { ButtonGroup, Menu, MenuItem, Tooltip } from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTheme } from "@mui/material/styles";
const styles = (theme) => {
  return {
    cardCategoryWhite: {
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "500",
      marginBottom: "3px",
      textDecoration: "none",
    },
    formBg: {
      background: theme.palette.grey[500_12],
      borderRadius: "5px",
      padding: "20px",
    },
    stats: {
      "& svg": {
        marginLeft: "3px",
        marginRight: "3px",
        top: "0px",
      },
      display: "inline-flex",
      fontSize: "12px",
    },
    dialogWindow: {
      minWidth: "auto",
    },
    errorMessgage: {
      fontSize: 12,
      color: theme.palette.error.main,
      margin: "0px 0px 10px 0",
    },
    cardTitle: {
      color: theme.palette.text.primary,
    },
    imagePreview: {
      width: 100,
      "& img": {
        width: "100%",
      },
    },
    loaderImg: {
      position: "relative",
      left: "48%",
      top: 100,
    },
    logoImage: {
      float: "left",
      border: "1px solid " + theme.palette.grey[300],
    },
    iconSpacing: {
      marginRight: "8px",
      position: "relative",
      top: "5px",
    },
  };
};

const useStyles = makeStyles(styles);

export default function Organizations() {
  const [departmentList, setDepartmentList] = useState([]);
  const [openDepartmentDialog, setDepartmentDialog] = useState(false);
  const [departmentItemObj, setDepartmentItem] = useState(false);
  const [isEditDepartment, setEditDepartment] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [loaderState, setLoaderState] = useState(true);
  const [fieldError, setFieldError] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [departmentIdVal, setDepartmentId] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  let itemId = React.useRef({ deleteId: "" });
  const [showConfirmOverlay, setConfirmOverlay] = React.useState(false);

  const location = useLocation();
  const allowedPermissions =
    JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const UserAuthContext = useContext(UserAuthDetailsContext);

  useEffect(() => {
    const { loginName } = UserAuthContext.state;
    if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, [UserAuthContext.state]);

  const fetchUserDetails = (userList) => {
    setUserDetails(userList);
    getDepartmentList(userList);
  };

  const setDepartmentListHandler = (departmentList) => {
    if (departmentList && departmentList.length && departmentList.length > 0) {
      setDepartmentList(departmentList);
    }
    setLoaderState(false);
  };

  const getDepartmentList = (userObj) => {
    setLoaderState(true);
    getDepartmentIdValues(userObj.userId, setDepartmentListHandler);
  };

  const createModifyOrgHandler = (departmentId) => {
    setDepartmentId(departmentId);
    getDepartmentByDepartmentId(departmentId, getDepertmentItem);
  };

  const getDepertmentItem = (departmentItemData) => {
    setDepartmentItem(departmentItemData);
    setEditDepartment(true);
    setDepartmentDialog(true);
  };

  const handleCloseOrgDialog = () => {
    setDepartmentDialog(false);
    setDepartmentName("");
  };

  const createNewOrgHandler = () => {
    setEditDepartment(false);
    setDepartmentDialog(true);
  };

  const submitFormHanldler = (formData) => {
    let reqObj = formData;
    reqObj.ocStatus = "O"; // Temporary param till backend change
    reqObj.organisationId = userDetails.clientOrgId;
    reqObj.createdBy = userDetails.userId;

    if (isEditDepartment) {
      reqObj.departmentId = departmentItemObj.departmentId;
    }

    createModifyDepartment(reqObj, isEditDepartment, createModifyCallback);
  };

  const createModifyCallback = (resStatus) => {
    if (resStatus) {
      nofifyHandler("Department Created Successfully");
      handleCloseOrgDialog();
      getDepartmentList(userDetails);
    } else {
      nofifyHandler(
        "Unable to create department, please contact sales@3cortex.com !",
        "danger"
      );
      handleCloseOrgDialog();
    }
  };

  const nofifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const deleteDepartmentHandler = () => {
    deleteDepartment(itemId.current.deleteId, deleteConfirm);
  };

  const deleteDeptHandler = (deptId) => {
    itemId.current.deleteId = deptId;

    openOverlay(true);
    handleClose();
  };
  const deleteConfirm = () => {
    //window.location.reload();
    nofifyHandler("Department Deleted Successfully");
    closeOverlay();
    handleCloseOrgDialog();

    getDepartmentList(userDetails);
  };

  const nullHandler = (val) => {
    if (val === null) {
      return "";
    }
    return val;
  };

  const handleCloseNotification = () => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  const openOverlay = () => {
    console.log(departmentIdVal);
    setConfirmOverlay(true);
  };

  const closeOverlay = () => {
    setConfirmOverlay(false);
  };
  const [showTableView, setShowTableView] = useState(false);
  const handleButtonClick = (viewType) => {
    setShowTableView(viewType === "table");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, deptId) => {
    setAnchorEl(event.currentTarget);
    setDepartmentId(deptId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  return (
    <div>
      <GridContainer style={{ marginBottom: "15px" }}>
        {allowedPermissions.includes("add") && (
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Button onClick={createNewOrgHandler} variant="contained">
              <AddToPhotos />
              Create new department
            </Button>
          </GridItem>
        )}
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ButtonGroup>
              <Button
                variant={showTableView ? "outlined" : "contained"}
                onClick={() => handleButtonClick("card")}
              >
                <Tooltip title="Card View">
                  <GridViewIcon />
                </Tooltip>
              </Button>

              <Button
                variant={showTableView ? "contained" : "outlined"}
                onClick={() => handleButtonClick("table")}
              >
                <Tooltip title="Table View">
                  <TableRowsIcon />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        </GridItem>
      </GridContainer>
      {showTableView ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <SAYAMUIDataTable
              title="Departments Data"
              data={departmentList}
              options={{
                selectableRows: "none",
                filter: true,
                viewColumns: true,
                print: true,
                download: true,
              }}
              columns={[
                {
                  name: "id",
                  label: "Department Id",
                },
                {
                  name: "value",
                  label: "Department Name",
                },
                ...(allowedPermissions.includes("modify") ||
                allowedPermissions.includes("delete")
                  ? [
                      {
                        name: "actions",
                        label: "Actions",
                        options: {
                          sort: false,
                          filter: false,
                          customBodyRender: (value, tableMeta) =>
                            allowedPermissions.includes("view") ||
                            allowedPermissions.includes("modify") ||
                            allowedPermissions.includes("delete") ? (
                              <div>
                                <IconButton
                                  id="basic-button"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleClick(e, tableMeta.rowData[0])
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  {allowedPermissions.includes("modify") && (
                                    <MenuItem
                                      onClick={() => {
                                        createModifyOrgHandler(departmentIdVal);
                                        handleClose();
                                      }}
                                    >
                                      Edit
                                    </MenuItem>
                                  )}
                                  {allowedPermissions.includes("delete") && (
                                    <MenuItem
                                      className={classes.deletBtn}
                                      onClick={() =>
                                        deleteDeptHandler(departmentIdVal)
                                      }
                                    >
                                      Delete
                                    </MenuItem>
                                  )}
                                </Menu>
                              </div>
                            ) : null,
                        },
                      },
                    ]
                  : []),
              ]}
            />
          </GridItem>
          <Dialog
            // fullScreen={fullScreen}
            open={openDepartmentDialog}
            onClose={handleCloseOrgDialog}
            aria-labelledby="draggable-dialog-title"
            className={classes.dialogPaper}
            // aria-labelledby="responsive-dialog-title"
          >
            {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
            <DialogTitle
              style={{ cursor: "move", padding: 0 }}
              id="draggable-dialog-title"
            >
              {/* {dialogTitle} */}

              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCloseOrgDialog}
                style={{ float: "right" }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogWindow}>
              <GridContainer>
                <Formik
                  initialValues={{
                    departmentName: isEditDepartment
                      ? nullHandler(departmentItemObj.departmentName)
                      : "",
                    departmentDlEmail: isEditDepartment
                      ? nullHandler(departmentItemObj.departmentDlEmail)
                      : "",
                    escalationEmailAddress: isEditDepartment
                      ? nullHandler(departmentItemObj.escalationEmailAddress)
                      : "",
                  }}
                  validationSchema={departmentValidations}
                  onSubmit={(values) => {
                    submitFormHanldler(values);
                    console.log("onSubmit", JSON.stringify(values, null, 2));
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  }) => (
                    <GridItem xs={12} sm={12} md={12}>
                      <form onSubmit={handleSubmit}>
                        <Card plain>
                          <CardHeader color="primary" plain>
                            <h4
                              className={
                                classes.cardTitleWhite + " modal-title-flag"
                              }
                            >
                              {isEditDepartment ? (
                                <BorderColorOutlined
                                  className={classes.iconSpacing}
                                />
                              ) : (
                                <AddBoxOutlined
                                  className={classes.iconSpacing}
                                />
                              )}
                              {isEditDepartment ? "Edit" : "Create"} Department
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <Box>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                  {fieldError && (
                                    <p className={classes.errorMessgage}>
                                      Please enter Required fields
                                    </p>
                                  )}
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      id="departmentName"
                                      label="Department Name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.departmentName}
                                      helperText={
                                        touched.departmentName
                                          ? errors.departmentName
                                          : ""
                                      }
                                      error={
                                        touched.departmentName &&
                                        Boolean(errors.departmentName)
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      id="departmentDlEmail"
                                      label="DepartmentDl Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.departmentDlEmail}
                                      helperText={
                                        touched.departmentDlEmail
                                          ? errors.departmentDlEmail
                                          : ""
                                      }
                                      error={
                                        touched.departmentDlEmail &&
                                        Boolean(errors.departmentDlEmail)
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <FormControl
                                    fullWidth
                                    sx={{ m: 1 }}
                                    variant="filled"
                                  >
                                    <TextField
                                      id="escalationEmailAddress"
                                      label="Escalation Email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.escalationEmailAddress}
                                      helperText={
                                        touched.escalationEmailAddress
                                          ? errors.escalationEmailAddress
                                          : ""
                                      }
                                      error={
                                        touched.escalationEmailAddress &&
                                        Boolean(errors.escalationEmailAddress)
                                      }
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </FormControl>
                                </GridItem>
                                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                    <TextField
                      id="createdDate"
                      label="Created Date"
                      type="datetime-local"
                      margin="dense"
                      variant="outlined"
                      value={values.createdDate}
                      helperText={touched.createdDate ? errors.createdDate : ""}
                      error={touched.createdDate && Boolean(errors.createdDate)}
                      defaultValue={selectedDate}
                      className={classes.textField}
                      //onChange={handleDateChange}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </FormControl>
                </GridItem> */}
                              </GridContainer>
                            </Box>
                          </CardBody>
                          <CardFooter>
                            <Grid
                              container
                              justifyContent={"center"}
                              spacing={1}
                            >
                              {((!isEditDepartment &&
                                allowedPermissions.includes("add")) ||
                                (isEditDepartment &&
                                  allowedPermissions.includes("modify"))) && (
                                <GridItem style={{ marginLeft: "-10px" }}>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                    sx={{ xs: { padding: "10px 25px" } }}
                                  >
                                    SUBMIT
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.closeButton}
                                    onClick={handleCloseOrgDialog}
                                    sx={{ xs: { padding: "10px 25px" } }}
                                  >
                                    CANCEL
                                  </Button>
                                  {/* <Button color="secondary" variant="contained" onClick={handleReset}>
                    CLEAR
                  </Button> */}
                                </GridItem>
                              )}
                            </Grid>
                          </CardFooter>
                        </Card>
                      </form>
                    </GridItem>
                  )}
                </Formik>
              </GridContainer>
            </DialogContent>
            {/* <DialogActions></DialogActions> */}
          </Dialog>
        </GridContainer>
      ) : (
        <>
          {allowedPermissions.includes("view") ||
          allowedPermissions.includes("modify") ||
          allowedPermissions.includes("delete") ? (
            <>
              <GridContainer>
                {departmentList && departmentList.length > 0 ? (
                  departmentList.map((departmentItem, index) => (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      //style={{ cursor: "pointer" }}
                      //onClick={() => createModifyOrgHandler(departmentItem.id)}
                    >
                      <Card>
                        <CardHeader color="primary" stats icon>
                          <CardIcon color="primary" plain>
                            <DeviceHub />
                          </CardIcon>

                          <p className={classes.cardCategory}>
                            Department Id: {departmentItem.id}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {departmentItem.value}
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <IconButton
                              onClick={() =>
                                createModifyOrgHandler(departmentItem.id)
                              }
                              disableElevation
                            >
                              <EditRounded />
                            </IconButton>

                            {allowedPermissions.includes("delete") && (
                              <IconButton
                                onClick={() =>
                                  deleteDeptHandler(departmentItem.id)
                                }
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))
                ) : (
                  <>
                    {[1, 2, 3].map((index) => (
                      <GridItem xs={12} sm={6} md={4} key={index}>
                        <Card style={{ width: "100%", marginRight: 20 }}>
                          <CardHeader color="primary" stats icon>
                            <CardIcon
                              color="primary"
                              style={{ background: "transparent" }}
                            >
                              <Skeleton
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            </CardIcon>

                            <p className={classes.cardCategory}>
                              <Skeleton
                                animation="wave"
                                style={{ float: "right" }}
                                width={140}
                              />
                            </p>
                            <h3 className={classes.cardTitle}>
                              <Skeleton animation="wave" width={50} />
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes.stats}>
                              <Skeleton animation="wave" width={150} />
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                  </>
                )}
                {loaderState && (
                  <GridItem xs={12} sm={12} md={12}>
                    <CircularProgress className={classes.loaderImg} />
                  </GridItem>
                )}
              </GridContainer>
              <Dialog
                // fullScreen={fullScreen}
                open={openDepartmentDialog}
                onClose={handleCloseOrgDialog}
                aria-labelledby="draggable-dialog-title"
                className={classes.dialogPaper}
                // aria-labelledby="responsive-dialog-title"
              >
                {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
                <DialogTitle
                  style={{ cursor: "move", padding: 0 }}
                  id="draggable-dialog-title"
                >
                  {/* {dialogTitle} */}

                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleCloseOrgDialog}
                    style={{ float: "right" }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogWindow}>
                  <GridContainer>
                    <Formik
                      initialValues={{
                        departmentName: isEditDepartment
                          ? nullHandler(departmentItemObj.departmentName)
                          : "",
                        departmentDlEmail: isEditDepartment
                          ? nullHandler(departmentItemObj.departmentDlEmail)
                          : "",
                        escalationEmailAddress: isEditDepartment
                          ? nullHandler(
                              departmentItemObj.escalationEmailAddress
                            )
                          : "",
                      }}
                      validationSchema={departmentValidations}
                      onSubmit={(values) => {
                        submitFormHanldler(values);
                        console.log(
                          "onSubmit",
                          JSON.stringify(values, null, 2)
                        );
                      }}
                    >
                      {({
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                      }) => (
                        <GridItem xs={12} sm={12} md={12}>
                          <form onSubmit={handleSubmit}>
                            <Card plain>
                              <CardHeader color="primary" plain>
                                <h4
                                  className={
                                    classes.cardTitleWhite + " modal-title-flag"
                                  }
                                >
                                  {isEditDepartment ? (
                                    <BorderColorOutlined
                                      className={classes.iconSpacing}
                                    />
                                  ) : (
                                    <AddBoxOutlined
                                      className={classes.iconSpacing}
                                    />
                                  )}
                                  {isEditDepartment ? "Edit" : "Create"}{" "}
                                  Department
                                </h4>
                              </CardHeader>
                              <CardBody>
                                <Box>
                                  <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                      {fieldError && (
                                        <p className={classes.errorMessgage}>
                                          Please enter Required fields
                                        </p>
                                      )}
                                      <FormControl
                                        fullWidth
                                        sx={{ m: 1 }}
                                        variant="filled"
                                      >
                                        <TextField
                                          id="departmentName"
                                          label="Department Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.departmentName}
                                          helperText={
                                            touched.departmentName
                                              ? errors.departmentName
                                              : ""
                                          }
                                          error={
                                            touched.departmentName &&
                                            Boolean(errors.departmentName)
                                          }
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <FormControl
                                        fullWidth
                                        sx={{ m: 1 }}
                                        variant="filled"
                                      >
                                        <TextField
                                          id="departmentDlEmail"
                                          label="DepartmentDl Email"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.departmentDlEmail}
                                          helperText={
                                            touched.departmentDlEmail
                                              ? errors.departmentDlEmail
                                              : ""
                                          }
                                          error={
                                            touched.departmentDlEmail &&
                                            Boolean(errors.departmentDlEmail)
                                          }
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                      <FormControl
                                        fullWidth
                                        sx={{ m: 1 }}
                                        variant="filled"
                                      >
                                        <TextField
                                          id="escalationEmailAddress"
                                          label="Escalation Email"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.escalationEmailAddress}
                                          helperText={
                                            touched.escalationEmailAddress
                                              ? errors.escalationEmailAddress
                                              : ""
                                          }
                                          error={
                                            touched.escalationEmailAddress &&
                                            Boolean(
                                              errors.escalationEmailAddress
                                            )
                                          }
                                          margin="dense"
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </FormControl>
                                    </GridItem>
                                    {/* <GridItem xs={12} sm={12} md={12}>
                                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                  <TextField
                                    id="createdDate"
                                    label="Created Date"
                                    type="datetime-local"
                                    margin="dense"
                                    variant="outlined"
                                    value={values.createdDate}
                                    helperText={touched.createdDate ? errors.createdDate : ""}
                                    error={touched.createdDate && Boolean(errors.createdDate)}
                                    defaultValue={selectedDate}
                                    className={classes.textField}
                                    //onChange={handleDateChange}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                </FormControl>
                              </GridItem> */}
                                  </GridContainer>
                                </Box>
                              </CardBody>
                              <CardFooter>
                                <Grid
                                  container
                                  justifyContent={"center"}
                                  spacing={1}
                                >
                                  {((!isEditDepartment &&
                                    allowedPermissions.includes("add")) ||
                                    (isEditDepartment &&
                                      allowedPermissions.includes(
                                        "modify"
                                      ))) && (
                                    <GridItem style={{ marginLeft: "-10px" }}>
                                      <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        sx={{ xs: { padding: "10px 25px" } }}
                                      >
                                        SUBMIT
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        className={classes.closeButton}
                                        onClick={handleCloseOrgDialog}
                                        sx={{ xs: { padding: "10px 25px" } }}
                                      >
                                        CANCEL
                                      </Button>
                                      {/* <Button color="secondary" variant="contained" onClick={handleReset}>
                                  CLEAR
                                </Button> */}
                                    </GridItem>
                                  )}
                                </Grid>
                              </CardFooter>
                            </Card>
                          </form>
                        </GridItem>
                      )}
                    </Formik>
                  </GridContainer>
                </DialogContent>
                {/* <DialogActions></DialogActions> */}
              </Dialog>
            </>
          ) : null}
        </>
      )}
      {notificationObj && (
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
          autoHideDuration={3000}
        />
      )}
      {showConfirmOverlay && (
        <ConfirmationOverlay
          {...{
            isOverlayOpen: showConfirmOverlay,
            closeOverlay,
            handler: deleteDepartmentHandler,
            title: "DELETE",
            message: `Are you sure you want to delete? Once you delete you will loss all the data associated to the record.`,
          }}
        />
      )}
    </div>
  );
}
