import { createContext } from "react";

const reconXInitialState = {
  selectedReconUnit: "",
  selectedReconUnitName: "",
  globalDropDownData: [],
  selectedRecData: {},
  ruleUnlabelledDTO: [],
  suggestedMatchesFinal: [],
  globalReconSuggestTableData: [],
  globalMetaDataSrcColNames: [],
  globalMetaDataSrcColIds: [],
  globalReconColumns: [],
  tenorPeriod: {},
  selectedReconStatus: "ALL",
  globalDataSrcData: [
    { value: 1, label: "Data Source -1", isdisabled: false },
    { value: 2, label: "Data Source -2", isdisabled: false },
  ],
  reconData: [
    {
      label: "All Records",
      value: "ALL",
    },
    { label: "Open Records", value: "OPEN" },
    { label: "Reconciled records", value: "RECONCILED" },
  ],
  tablePagination: {
    pageIndex: 0,
    pageSize: 500,
  },
};
export const ReconXContext = createContext(reconXInitialState);
