import React, { useState, useEffect } from "react";
import { alpha } from "@mui/material/styles";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { Comment } from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import EjectIcon from "@mui/icons-material/Eject";
import Button from "@mui/material/Button";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material/styles";

const style = {
  position: "absolute",
  borderRadius: "10px",
  minHeight: "350px",
  maxHeight: "85vh",
  overflow: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  background: "linear-gradient(138.18deg, #eae8fd 0%, #fce5e6 94.44%)",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "#000",
  "& ul": {
    listStyleType: "square",
    listStylePosition: "inside",
    paddingBottom: "10px",
    "&:empty": {
      display: 'none'
    }
  },
  "& span.imp": {
    color: '#0993af'
  },
  "& p": {
    marginTop: 0,
    marginBottom: "10px",
    "&:empty": {
      display: 'none'
    }
  },
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#fce5e6",
    borderRadius: "10px",
    marginRight: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
};

const TextHighlighter = ({ text }) => {
  const [highlightedText, setHighlightedText] = useState(text); // Use this state to hold the highlighted text

  const [originalText, setOriginalText] = useState("");
  const [utterance, setUtterance] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (text) {
      setOriginalText(text);
    }
  }, [text]);

  const handleButtonClick = () => {
    const synth = window.speechSynthesis;
    if (!synth) {
      console.error("Text to speech is not supported in this browser.");
      return;
    }

    if (utterance) {
      synth.cancel(); // Ensure previous speech is stopped before starting a new one
    }

    const newUtterance = new SpeechSynthesisUtterance(text);
    newUtterance.onboundary = (event) => {
      highlight(event.charIndex, event.charLength);
    };

    setUtterance(newUtterance);
    synth.speak(newUtterance);
  };

  const handlePauseButtonClick = () => {
    if (utterance) {
      window.speechSynthesis.pause();
      setIsPaused(true);
    }
  };

  const handleResumeButtonClick = () => {
    if (utterance) {
      window.speechSynthesis.resume();
      setIsPaused(false);
    }
  };

  const handleStopButtonClick = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
      setUtterance(null);
      setIsPaused(false);
    }
  };

  const highlight = (charIndex, charLength) => {
    const startTag = `<span style="background-color:#dd3c7c;color:#fff;">`;
    const endTag = `</span>`;
    const before = text.substring(0, charIndex);
    const highlight = text.substring(charIndex, charIndex + charLength);
    const after = text.substring(charIndex + charLength);
    setHighlightedText(`${before}${startTag}${highlight}${endTag}${after}`);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          top: "-66px",
          position: "absolute",
          right: "0",
        }}
      >
        <Box style={{ display: "flex" }} justifyContent={"flex-end"}>
          {/* Play Button */}
          <Button
            onClick={handleButtonClick}
            variant="outlined"
            style={{ marginRight: "5px", minWidth: 45 }}
            size="small"
            id="playbtn"
          >
            <PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: 20 }} />
          </Button>
          {/* Pause Button */}
          <Button
            onClick={handlePauseButtonClick}
            disabled={isPaused}
            variant="outlined"
            style={{ marginRight: "5px", minWidth: 45 }}
            size="small"
          >
            <PauseCircleOutlineOutlinedIcon sx={{ fontSize: 20 }} />
          </Button>
          {/* Resume Button */}
          <Button
            onClick={handleResumeButtonClick}
            disabled={!isPaused}
            variant="outlined"
            style={{ marginRight: "5px", minWidth: 45 }}
            size="small"
          >
            <EjectIcon style={{ rotate: "90deg" }} sx={{ fontSize: 20 }} />
          </Button>
          {/* Stop Button */}
          <Button
            onClick={handleStopButtonClick}
            variant="outlined"
            style={{ marginRight: "5px", minWidth: 45 }}
            size="small"
            id="stopBtn"
          >
            <StopCircleOutlinedIcon sx={{ fontSize: 20 }} />
          </Button>
        </Box>
      </div>
      {/* Text content */}
      {/* <div dangerouslySetInnerHTML={{ __html: text }} /> */}
      <div dangerouslySetInnerHTML={{ __html: highlightedText }} />{" "}
      {/* Display the highlighted text */}
    </div>
  );
};

export default function SayaEthics({ isModal, text, hideModalHandler }) {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const theme = useTheme();
  const [playEnabled, setPlayEnabled] = React.useState(null);
  // Detecting window refresh or close event
  window.addEventListener("beforeunload", function (event) {
    // Stop speech synthesis voice
    window.speechSynthesis.cancel();
  });

  const handleClose = () => {
    let stopBtn = document.getElementById("stopBtn");
    stopBtn.click();
    setOpen(false);
    hideModalHandler();
  };

  useEffect(() => {
    const timerID = setTimeout(() => {
      setLoader(false);
    }, 5000);

    return () => clearTimeout(timerID);
  }, []);

  React.useEffect(() => {
    setOpen(isModal);
  }, [isModal]);

 

  React.useEffect(() => {
    if (isModal || text) {
      setTimeout(() => {
        let playbtn = document.getElementById("playbtn");
        //setPlayEnabled(playbtn);
        playbtn?.click();
      }, 1000);
    }
  }, [text, isModal]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              position: "absolute",
              minWidth: "20px",
              right: "5px",
              top: "5px",
              padding: "1px",
              opacity: "0.5",
            }}
            onClick={handleClose}
          >
            <CloseIcon style={{ fontSize: "12px" }} />
          </Button>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              marginBottom: "10px",
              paddingBottom: "15px",
              borderBottom: "1px solid #ccc",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                color: theme.palette.primary.main,
              }}
            >
              <SmartToyOutlinedIcon style={{ marginRight: "10px" }} />
              <span>Saya AI Ethics</span>
            </Box>
          </Typography>

          {loader ? (
            <Box style={{ display: "flex" }} justifyContent={"center"}>
              <Comment
                visible={true}
                height="80"
                width="80"
                ariaLabel="comment-loading"
                wrapperStyle={{}}
                wrapperClass="comment-wrapper"
                color="#fff"
                backgroundColor={alpha(theme.palette.primary.main, 0.6)}
              />
            </Box>
          ) : (
            <Box sx={{ paddingTop: "10px" }}>
              <TextHighlighter text={text} />
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
