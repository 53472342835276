import { USERAPI } from "URL.js";
import axios from "axios";
import {
  userLoginDetails,
  forgotPasswordDetails,
  userDetailsInitialState
} from "variables/UserDetails.js";
import AxiosService from "AxiosService.js";

const axiosService = AxiosService.getService();

export const getOrganizations = async (usedId, fetchOrgList) => {
  try {
    const response = await axios.get(USERAPI + "/organisation/all/" + usedId);
   
    if (response.status === 200) {
      fetchOrgList(response.data);
    }
  } catch (error) {
    console.error(error.message);
    fetchOrgList(false);
  }
};

export const createModifyOrganization = async (params, isModify, callBack) => {
  const contentType = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  try {
    let apiType = isModify ? "modify" : "create";
    const response = await axios.post(USERAPI + "/organisation/" + apiType, params);
    if (response.status === 200) {
      console.log("create response.data", response.data);
      callBack(true);
    }
  } catch (error) {
    callBack(false);
    console.error(error.message);
  }
};

export const deleteOrgnization = async (orgId, callBack) => {
  try {
    const response = await axios.post(USERAPI + "/organisation/delete/" + orgId);
    if (response.status === 200) {
      callBack(response.data);
    }
  } catch (error) {
    callBack(false);
    console.error(error.message);
  }
};

export const uploadImage = async (data, organisationId) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      USERAPI + "/organisation/logo?organisationId=" + organisationId,
      data,
      config
    );
    if (response.status === 200) {
      
      return "success";
    }
  } catch (error) {
    console.error(error);
    return "failure";
  }
};

export const getOrgnizationLogo = async (orgId, getLogoCallback) => {
  try {
    const response = await axios.get(USERAPI + "/organisation/" + orgId + "/logo");
    if (response.status === 200) {
      getLogoCallback(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const getOrganizationsIdValues = async (fetchOrgListValues) => {
  try {
    const response = await axios.get(USERAPI + "/organisation/id-values");
   
    if (response.status === 200) {
      fetchOrgListValues(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const getOrganizationsbyUserId = async (userId, fetchOrgListValues) => {
  try {
    const response = await axios.get(USERAPI + "/organisation/id-values/" + userId);
    console.log(response);
    if (response.status === 200) {
      fetchOrgListValues(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};
