import React, { useEffect, useState } from "react";
// @material-ui/core components

//import MaterialTable from "@material-ui/core/Table";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from "@mui/material/DialogActions";

import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { checkAccessHandler } from "../utils";
import Button from "../CustomButtons/Button";
import MaterialTable from "material-table";
const styles = (theme) => ({
  dialogWrapper: {
    "& button": {
      padding: 8,
      border: '1px solid transparent',
      borderRadius: 5,
    }
  },
  submitBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  cancelBtn: {
    border: `1px solid ${theme.palette.primary.main} !important`,
    color: theme.palette.text.primary 
  },
  modalWrapper: {
    background: theme.palette.background.paper,
    '& .MuiInputBase-root, .MuiIconButton-root': {
      color: theme.palette.text.primary
    },

    '& .MuiTableCell-root': {
      color: theme.palette.text.primary + ' !important',
      backgroundColor: theme.palette.background.card + ' !important',
    },
    "& .MuiPaper-root": {
      boxShadow: "none",
      //border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.background.card
    },
    "& table tr:empty": {
      display: "none",
    },
    "& table tr td": {
      padding: "5px 10px",
      fontSize: "14px",
      color: theme.palette.text.primary + ' !important'
    },
    "& .MuiToolbar-root": {
      padding: 0
    },
    "& .MuiTypography-h6": {
      paddingLeft: "10px",
      color: theme.palette.text.primary + ' !important',
    },
  },
  centerAlignDialogActions: {
    justifyContent: "center",
  },
});

const useStyles = makeStyles(styles);

export default function SayaReconRuleDialog(props) {
  const classes = useStyles();
  const [isRowEdited, setIsEditRowFlag] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [ruleAddAccess, setRuleAddAccess] = useState(false);

  const {
    showRulesWindow,
    handleCloseReconRules,
    dialogTitle,
    tableTitle,
    ruleColumns,
    rulesData,
    ruleTableOptions,
    setRuleDataCallback,
    handleSaveRule,
    routeData,
    action,
  } = props;

  useEffect(() => {
    if (showRulesWindow) {
      setIsEditRowFlag(false);
    }
  }, [showRulesWindow]);

  const toggleButton = (newData, oldData) => {
    if (
      newData &&
      oldData &&
      JSON.stringify(newData) === JSON.stringify(oldData)
    ) {
      setIsEditRowFlag(false);
    } else {
      setIsEditRowFlag(true);
    }
  };

  const { allowedPermissions } = routeData;

  useEffect(() => {
    if (allowedPermissions) {
      const editAccessFlag = checkAccessHandler(
        "rules modification",
        allowedPermissions
      );
      const ruleAddAccessFlag = checkAccessHandler(
        "rules creation",
        allowedPermissions
      );
      setReadOnly(editAccessFlag);
      setRuleAddAccess(ruleAddAccessFlag);
    }
  }, [allowedPermissions]);

  return (
    <Dialog
      //fullScreen
      open={showRulesWindow}
      onClose={handleCloseReconRules}
      maxWidth="lg"
      className={classes.dialogWrapper}
      aria-labelledby="responsive-dialog-title"
    >
      {/* <DialogTitle id="responsive-dialog-title">{dialogTitle} </DialogTitle> */}
      <DialogContent>
        <div className={classes.modalWrapper} style={{ maxWidth: "100%", minHeight: '300px' }}>
          <MaterialTable
            title={dialogTitle}
            columns={ruleColumns.filter(column => column.showInUi ===  true).map(obj => {
              return {
                ...obj,
                title:
                  obj.titleToShow || obj.title
                    .replace(/[A-Z]/g, " $&")
                    .charAt(0)
                    .toUpperCase() +
                  obj.title.replace(/[A-Z]/g, " $&").slice(1),
              };
          })}
            data={rulesData}
            options={ruleTableOptions}
            editable={{
              onRowUpdateCancelled: (rowData) =>
                console.log("Row editing cancelled"),
              onRowAdd: ruleAddAccess
                ? (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        toggleButton(newData, oldData);

                        resolve();
                        const LocalData = [...rulesData];
                        // newData.hexScoreUse = false;
                        LocalData.push(newData);
                       
                    
                        setRuleDataCallback(LocalData);
                      }, 600);
                    })
                : false,

              onRowUpdate: readOnly
                ? (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        toggleButton(newData, oldData);

                        resolve();
                        if (oldData) {
                          const LocalData = [...rulesData];
                          LocalData[LocalData.indexOf(oldData)] = newData;
                        
                          setRuleDataCallback(LocalData);
                        }
                      }, 600);
                    })
                : false,
              onRowDelete: readOnly
                ? (newData, oldData) =>
                    new Promise((resolve) => {
                      setTimeout(() => {
                        toggleButton(newData, oldData);

                        resolve();
                        let LocalData = [...rulesData];
                        LocalData = LocalData.filter(
                          (item) => item.tableData.editing !== "delete"
                        );
                     
                        setRuleDataCallback(LocalData);
                      }, 600);
                    })
                : false,
            }}
          />
        </div>
      </DialogContent>
      <DialogActions classes={{ root: classes.centerAlignDialogActions }}>
        <Button
          onClick={handleSaveRule}
          variant="contained"
          color="primary"
          disabled={!isRowEdited}
          className={classes.submitBtn}
        >
          SAVE & EXIT
        </Button>
        <Button
          onClick={handleCloseReconRules}
          variant="outlined"
          color="primary"
          className={classes.cancelBtn}
        >
          CANCEL
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SayaReconRuleDialog.propTypes = {
  dialogTitle: PropTypes.string,
  showRulesWindow: PropTypes.bool.isRequired,
  handleCloseReconRules: PropTypes.func.isRequired,
  tableTitle: PropTypes.string,
  ruleColumns: PropTypes.arrayOf(PropTypes.object),
  rulesData: PropTypes.arrayOf(PropTypes.object),
  ruleTableOptions: PropTypes.object,
  setRuleDataCallback: PropTypes.func,
  handleSaveRule: PropTypes.func,
};
