// ----------------------------------------------------------------------

export default function Paper(theme) {
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          //boxShadow: theme.palette.common.shadow,
          backgroundColor: `${theme.palette.background.card}`,
          backgroundImage: "none",
          color: theme.palette.text.primary,
          "&.MuiDialog-paper": {
            background: theme.palette.background.card,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.background.paper + " !important"
              : theme.palette.common.white + " !important",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "&.MuiAppBar-root > div": {
            backgroundColor: "transparent !important",
          },
        },
      },
    },
  };
}
