import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Stepper,
  Step,
  StepLabel,
  styled,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { alpha } from "@mui/material/styles";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TextField from "@mui/material/TextField";
import axios from "axios";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { ReconXContext } from "context/ReconX/ReconXContext";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import { fetchReconUnitsData } from "action-handlers/DashboardActionHandler";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import { saveMetaDataAPI } from "action-handlers/ReconXActionHandler";
import { getMetaData } from "action-handlers/ReconXActionHandler";
import { useTheme } from "@mui/material/styles";
import { notificationParams } from "../../variables/general";
import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "../../action-handlers/NotificationsHandler";
import Snackbar from "../../components/Snackbar/Snackbar";
import { Puff } from "react-loader-spinner";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomDrawer, DrawerProvider } from "./Components/CustomDrawer";
import { makeStyles } from "@mui/styles";
import MetaForm from "./MetaForm";
import AccordionLists from "./Components/AccordionLists";
import CreateColumn from "./Components/CreateColumn";
import DynamicOverlay from "../../components/ConfirmationOverlay/DynamicOverlay";
import ConfirmationOverlay from "../../components/ConfirmationOverlay/ConfirmationOverlay";
// import { getUIComponents } from "action-handlers/MetaDataActionHandler";
import Overlay from "components/Overlay/Overlay";
import CreateNEditMetadataColumn from "./Components/CreateNEditMetadataColumn";
import ToastMessage from "../../components/UtilComponents/ToastMessage";

const dropdowStyles = `
.meta-table-row td{
    padding: 10px;
}
.meta-table-row:hover td{
  background-color: #f9f9f9 !important
}
.meta-table-row td p#Column1-helper-text{
  position: absolute;
  z-index: 0;
  top: -22px;
}
.meta-select > div > div > div{
    padding-top: 7px;
    padding-bottom: 7px;
}
`;

const styles = (theme) => ({
  activeRow: {
    "& td": {
      background: "#edf0f2 !important",
      "& input": {
        background: "#fff !important",
        borderRadius: "5px",
      },
    },
  },
});

// const TableRowComponent = ({
//   step,
//   indexNo,
//   row,
//   updateRow,
//   removeRow,
//   deleteRow,
//   validationError,
//   rowClickHandler,
//   isActive,
//   setActiveRow,
// }) => {
//   const dataTypes = [
//     { label: "STRING", value: "STRING" },
//     { label: "NUMERIC", value: "NUMERIC" },
//     { label: "DATE", value: "DATE" },
//     { label: "DOCUMENT", value: "DOCUMENT" },
//   ];

//   const requiredData = [
//     { label: "MANDATORY", value: true },
//     { label: "NOT MANDATORY", value: false },
//   ];

//   const visibilityData = [
//     { label: "TRUE", value: true },
//     { label: "FALSE", value: false },
//   ];

//   const handleRowDoubleClick = (rowData) => {
//     rowClickHandler(rowData, indexNo); // Update the selected row data on double click
//   };
//   const useStyles = makeStyles(styles);
//   const classes = useStyles();

//   return (
//     <TableRow
//       className={`meta-table-row  ${isActive ? classes.activeRow : ""}`}
//       onDoubleClick={() => handleRowDoubleClick(row)}
//     >
//       <TableCell>{indexNo + 1}</TableCell>
//       <TableCell>
//         <Typography sx={{ fontSize: "14px", paddingLeft: "5px" }}>
//           Column {indexNo + 1}
//         </Typography>
//       </TableCell>

//       <TableCell sx={{ minWidth: "240px" }}>
//         <TextField
//           id={row.columnId}
//           size="small"
//           //label={row.columnName}
//           defaultValue={row.columnName}
//           error={validationError} // Use error prop for conditional styling
//           helperText={validationError ? "Name required" : ""} // Optional: Add helper text
//           value={row.columnName}
//           onChange={(event) =>
//             updateRow("columnName", event.target.value, indexNo)
//           }
//         />
//       </TableCell>

//       {/* <TableCell className="meta-select" sx={{ minWidth: "180px" }}>
//         <SAYAComboBox
//           optionsData={dataTypes}
//           value={row.dataType}
//           defaultValue={row.dataType}
//           //   labelText="Data Type"
//           groupText="Data Type"
//           name="dataType"
//           handleChange={(event) =>
//             updateRow("dataType", event.target.value, indexNo)
//           }
//           height="18px"
//           headerCombo
//         />
//         <style>{dropdowStyles}</style>
//       </TableCell> */}

//       {/* <TableCell className="meta-select" sx={{ minWidth: "180px" }}>

//         <SAYAComboBox
//           optionsData={requiredData}
//           value={row.mandatory}
//           defaultValue={row.mandatory}
//           //   labelText="Data Type"
//           groupText="Data Type"
//           name="mandatory"
//           handleChange={(event) =>
//             updateRow("mandatory", event.target.value, indexNo)
//           }
//           height="18px"
//           headerCombo
//         />
//         <style>{dropdowStyles}</style>

//       </TableCell> */}
//       {/* <DrawerProvider>
//             <CustomDrawer
//                 anchorPos="right"
//                 icon={MoreHorizIcon}
//                 onIconClick={() => console.log('Icon clicked')}
//                 iconButtonProps={{ edge: "start", color: "inherit", 'aria-label': "menu" }}
//             >
//                 <div><MetaForm/></div>
//             </CustomDrawer>
//         </DrawerProvider> */}

//       <TableCell>
//         {row?.rowType === "new" ? (
//           <Button
//             size="small"
//             variant="contained"
//             color="secondary"
//             onClick={() => removeRow(indexNo)}
//           >
//             <DisabledByDefaultOutlinedIcon sx={{ margin: "0 !important" }} />
//           </Button>
//         ) : (
//           <></>
//         )}
//       </TableCell>
//     </TableRow>
//   );
// };

const dataTypes = [
  { label: "STRING", value: "STRING" },
  { label: "NUMERIC", value: "NUMERIC" },
  { label: "DATE", value: "DATE" },
  { label: "DOCUMENT", value: "DOCUMENT" },
];

const requiredData = [
  { label: "MANDATORY", value: true },
  { label: "NOT MANDATORY", value: false },
];

const visibilityData = [
  { label: "TRUE", value: true },
  { label: "FALSE", value: false },
];

const DynamicTable = ({
  activeTab,
  dropDownData,
  selectedReconUnit,
  reconObj,
}) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const ReconXContextData = useContext(ReconXContext);
  const { globalDropDownData } = ReconXContextData.state;

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId, reconUnitId } = UserAuthContext.state;

  const [notificationObj, setNotificationObj] = useState(notificationParams);

  const containerRef = useRef(null); // Reference to the scroll container
  const targetRef = useRef(null); // Reference to the target div you want to scroll
  const [validationErrors, setValidationErrors] = useState({}); // New state for tracking validation errors
  const [loader, setLoader] = useState(false);
  const [toastData, setToastData] = React.useState({
    isOpen: false,
    message: "",
    color: "",
  });
  const [activeRowIndex, setActiveRowIndex] = useState(null); // State to track the active row index
  const [anchorElAction, setAnchorElAction] = React.useState(null);
  // const [isOverlayOpen, setOverlay] = useState(false);
  // const [isDeleteRow, setDeleteRow] = React.useState(false);
  const [columnRow, setColumnRow] = useState({ columnName: "" });
  // const [uiComponents, setUIComponents] = useState([]);
  const [showOverlayMetaData, setOverlayMetaData] = useState(false);
  const [columnMetadata, setColumnMetadata] = useState({
    columnId: "",
    columnName: "",
    dataType: "",
    mandatory: "",
    visible: "",
  });

  const ref = useRef({ selectedItem: null });

  // const rowDbClickHandler = (data, ind) => {
  //   setSelectedRow(data);
  //   setActiveRowIndex(ind); // Update the active row index on double click
  // };

  const getColumnMetaData = async (activeTab, selectedReconUnit) => {
    setLoader(true);
    await getMetaData(activeTab, selectedReconUnit, (res) => {
      if (res?.length > 0) {
        setRows(res);
      } else {
        setLoader(false);
      }
    });
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  // const getMetaDataUIComponents = async () => {
  //   getUIComponents((data, status) => {
  //     setUIComponents(data);
  //   });
  // };

  useEffect(() => {
    if (selectedReconUnit) {
      setRows([]);
      getColumnMetaData(activeTab + 1, selectedReconUnit);
    }
  }, [activeTab, selectedReconUnit]);

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const addColumnMetaData = () => {
    if (rows.length >= 30) {
      setToastData({
        isOpen: true,
        message: "You can not add more than 30 columns",
        color: "error",
      });
      return;
    }
    setOverlayMetaData(true);
    const newColumnData = {
      rowType: "new",
      columnId: `column${rows.length + 1}`,
      columnName: "",
      dataType: "STRING",
      clientReconUnit: reconObj,
      dataSrcConfigId: activeTab + 1,
      mandatory: true,
      visible: true,
    };
    setColumnMetadata(newColumnData);
    // setRows([...rows, newRow]);
    // setTimeout(() => {
    //   if (containerRef.current && targetRef.current) {
    //     // Get the top position of the target element within the container
    //     const topPos = targetRef.current.offsetTop;

    //     // Scroll the container to the target element's top position plus an additional 10 pixels
    //     containerRef.current.scrollTo({
    //       top: topPos,
    //       behavior: "smooth",
    //     });
    //   }
    // }, 100);
  };

  // const updateRow = (field, value, index, resetHandler) => {
  //   const newRow = [...rows];
  //   newRow[index] = { ...newRow[index], [field]: value };

  //   setRows(newRow);
  // };

  // const removeRow = (index) => {
  //   setRows(rows.filter((_, i) => i !== index));
  // };

  // useEffect(() => {
  //   getMetaDataUIComponents();
  // }, []);

  // Use useEffect to scroll to the bottom of the div after the component has rendered

  // const showConfirmationOverlay = () => {
  //   setDeleteRow(true);
  // };

  // const deleteRow = (rd = {}) => {
  //   ref.current.id = rd?.dataSubmissionId;
  // };

  // const saveMetaData = async () => {
  //   // Check for empty column names in existing rows
  //   const errors = {};
  //   let isValid = true;
  //   rows.forEach((row, index) => {
  //     if (!row.columnName.trim()) {
  //       errors[index] = true;
  //       isValid = false;
  //     }
  //   });

  //   setValidationErrors(errors); // Update validation errors state
  //   if (isValid) {
  //     const payload = {
  //       reconMetaDataList: [...rows],
  //       loggedInUserID: JSON.parse(sessionStorage.userData)?.userId,
  //     };

  //     await saveMetaDataAPI(payload, (res) => {
  //       if (res.responseStatus === "SUCCESS") {
  //         showSuccessNotification(res.responseMessage, callbackNotification);
  //         getColumnMetaData(activeTab + 1, selectedReconUnit);
  //       } else {
  //         showFailureNotification(res.responseMessage, callbackNotification);
  //       }
  //     });
  //   } else {
  //     if (containerRef.current && targetRef.current) {
  //       // Get the top position of the target element within the container
  //       const topPos = targetRef.current.offsetTop;

  //       // Scroll the container to the target element's top position plus an additional 10 pixels
  //       containerRef.current.scrollTo({
  //         top: topPos,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // };

  const handleOpenActionMenu = (event, obj) => {
    event.stopPropagation();
    ref.current.selectedItem = obj;
    setAnchorElAction(event.currentTarget);
  };

  // const openOverlay = () => {
  //   setOverlay(true);
  // };

  // const closeOverlay = () => {
  //   setOverlay(false);
  //   ref.current.selectedItem = null;
  // };

  const closeOverlayMetaData = () => {
    ref.current.selectedItem = null;
    setOverlayMetaData(false);
    setColumnMetadata({
      columnId: "",
      columnName: "",
      dataType: "",
      mandatory: "",
      visible: "",
    });
  };

  // const closeConfirmationOverlay = () => {
  //   setDeleteRow(false);
  //   setAnchorElAction(null);
  // };

  const handleCloseActionMenu = () => {
    ref.current.selectedItem = {};
    setAnchorElAction(null);
  };

  const editMetadataColumn = (sl, index) => {
    const targetObj = ref.current.selectedItem;
    setOverlayMetaData(true);
    setAnchorElAction(null);
    setColumnMetadata(ref.current.selectedItem);
  };

  // const creatAndEditColumns = async () => {
  //   try {
  //     if (columnRow?.columnName.trim() === "") {
  //       setToastData({
  //         isOpen: true,
  //         message: "Please enter Column Name",
  //         color: "red",
  //       });
  //       return;
  //     }

  //     const payload = {
  //       loggedInUserDTO: captureLoggedInUserDetailsDTO(),
  //       riskParamMaster: columnRow,
  //     };

  //     //const response = await axios.post(`${HARIT_ORIGIN_API}emissionConfig/saveRiskParam`, payload);
  //     if (
  //       response.status === 200 &&
  //       response.data.responseStatus == "SUCCESS"
  //     ) {
  //       setToastData({
  //         isOpen: true,
  //         message: response.data.responseMessage || "Success",
  //       });
  //       closeOverlay();
  //       ref.current.selectedItem = null;
  //     } else if (
  //       response.status === 200 &&
  //       response.data.responseStatus === "FAILURE"
  //     ) {
  //       setToastData({
  //         isOpen: true,
  //         message: response.data.responseMessage || "Failure",
  //         color: "red",
  //       });
  //       setEdit(false);
  //     } else {
  //       setToastData({ isOpen: true, message: "Failure", color: "red" });
  //     }
  //   } catch (error) {
  //     setToastData({
  //       isOpen: true,
  //       message: "Unable to process.Please contact Support!!",
  //       color: "red",
  //     });
  //   }
  // };

  // const deleteColumn = async () => {
  //   try {
  //     const payload = {
  //       loggedInUserDTO: captureLoggedInUserDetailsDTO(),
  //     };
  //     // const response = await axios.post(
  //     //   `${HARIT_ORIGIN_API}emissionConfig/closeRiskParam?riskCategoryId=${ref.current.selectedItem.riskCategoryId}`,
  //     //   payload
  //     // );
  //     if (
  //       response.status === 200 &&
  //       response.data.responseStatus == "SUCCESS"
  //     ) {
  //       setToastData({
  //         isOpen: true,
  //         message: response.data.responseMessage || "Success",
  //       });
  //       ref.current.selectedItem = null;
  //       closeConfirmationOverlay();
  //       fetchRiskParams();
  //     } else if (
  //       response.status === 200 &&
  //       response.data.responseStatus === "FAILURE"
  //     ) {
  //       setToastData({
  //         isOpen: true,
  //         message: response.data.responseMessage || "Failure",
  //         color: "red",
  //       });
  //     } else {
  //       setToastData({ isOpen: true, message: "Failure", color: "red" });
  //     }
  //   } catch (error) {
  //     setToastData({
  //       isOpen: true,
  //       message: "Unable to process.Please contact Support!!",
  //       color: "red",
  //     });
  //   }
  // };

  const updateMetaDataColumnData = (event, fieldName) => {
    const { name, value } = event.target;
    setColumnMetadata((prev) => ({
      ...prev,
      [name || fieldName]: value,
    }));
  };

  const saveColumnMetadata = async () => {
    if (columnMetadata.columnName.trim() === "") {
      setToastData({
        isOpen: true,
        message: "Please enter Column Name",
        color: "error",
      });
      return;
    }

    let finalData = [];
    if (ref.current.selectedItem === null) {
      finalData = [...rows, columnMetadata];
    } else {
      const index = rows.findIndex(
        (row) => row.metaDataId == ref.current.selectedItem.metaDataId
      );
      if (index !== -1) {
        rows[index] = columnMetadata;
        finalData = rows;
      }
    }
    const payload = {
      reconMetaDataList: finalData,
      loggedInUserID: JSON.parse(sessionStorage.userData)?.userId,
    };

    await saveMetaDataAPI(payload, (res) => {
      if (res.responseStatus === "SUCCESS") {
        closeOverlayMetaData();
        showSuccessNotification(res.responseMessage, callbackNotification);
        getColumnMetaData(activeTab + 1, selectedReconUnit);
      } else {
        showFailureNotification(res.responseMessage, callbackNotification);
      }
    });
  };

  const handleToastClose = React.useCallback(() => {
    setToastData({ isOpen: false, message: "" });
  }, []);

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} justifyContent="center" sx={{ margin: "20px auto" }}>
          <Box
            sx={{
              width: "100%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "flex-end",
              boxShadow: "0px 2px 0px #f4f3f3",
              padding: "10px 0 10px",
              position: "relative",
              left: "-7px",
            }}
          >
            <Button
              disabled={rows?.length === 30}
              variant="outlined"
              color="primary"
              onClick={addColumnMetaData}
              size="small"
            >
              <AddBoxOutlinedIcon /> Add New Column
            </Button>
          </Box>

          {rows.length > 0 && (
            <Box
              sx={{
                background: '#fbfbfb',
                margin: '0',
                padding: '14px',
                paddingLeft: '38px',
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <GridContainer spacing={0}>
                <GridItem xs={12} md={2} disableSpacing>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Column
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={3} disableSpacing>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Field Name
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={3} disableSpacing>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Data Type
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={2} disableSpacing>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Is Required
                  </Typography>
                </GridItem>
                <GridItem xs={12} md={2} disableSpacing>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingLeft: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    Visibility
                  </Typography>
                </GridItem>
              </GridContainer>
              <div style={{ width: "50px" }}></div>
            </Box>
          )}

          {rows.map((row, index) => (
            <AccordionLists
              {...{ row, index, handleOpenActionMenu }} //uiComponents
              key={index}
            />
          ))}
        </Grid>
        {/* <Grid item xs={5} justifyContent="center">
          <Box>
            {selectedRow && (
              <MetaForm rowData={selectedRow} key={selectedRow.columnId} />
            )}
          </Box>
        </Grid> */}
      </Grid>
      <Menu
        sx={{ mt: "14px" }}
        key={"menu"}
        id={"menu-appbar"}
        anchorEl={anchorElAction}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElAction)}
        onClose={handleCloseActionMenu}
      >
        <MenuItem key={"edit"} onClick={editMetadataColumn}>
          <div style={{ color: "black", cursor: "pointer", display: "flex" }}>
            <ModeEditIcon />
            <span style={{ marginLeft: "5px" }}>Edit</span>
          </div>
        </MenuItem>

        {/* {ref.current.selectedItem !== null &&
          ref.current.selectedItem.paramName !== "FRAME NO/IMAGE NO" && (
            <MenuItem key={"delete"} onClick={showConfirmationOverlay}>
              <div
                style={{ color: "black", cursor: "pointer", display: "flex" }}
              >
                <DeleteOutlineIcon />
                <span style={{ marginLeft: "5px" }}>Delete</span>
              </div>
            </MenuItem>
          )} */}
      </Menu>
      {/* {isOverlayOpen && (
        <DynamicOverlay
          {...{
            isOverlayOpen,
            closeOverlay,
            title:
              columnRow.columnName !== "" ? "Edit Column" : "Create New Column",
            handler: creatAndEditColumns,
          }}
        >
          <CreateColumn {...{ columnRow, setColumnRow }} />
        </DynamicOverlay>
      )} */}

      {/* {isDeleteRow && (
        <ConfirmationOverlay
          {...{
            isOverlayOpen: isDeleteRow,
            closeOverlay: closeConfirmationOverlay,
            handler: deleteColumn,
            title: "Delete Column",
            message: "Are you sure you want to delete?",
          }}
        />
      )} */}

      {showOverlayMetaData && (
        <Overlay
          {...{
            isOverlayOpen: showOverlayMetaData,
            closeOverlay: closeOverlayMetaData,
            title: `${
              ref.current.selectedItem === null
                ? "New Metadata Column"
                : "Edit Metadata Column"
            }`,
            actions: true,
            handler: saveColumnMetadata,
          }}
        >
          <CreateNEditMetadataColumn
            {...{
              dataTypes,
              requiredData,
              visibilityData,
              columnMetadata,
              isEdit: ref.current.selectedItem !== null,
              updateMetaDataColumnData,
            }}
          />
        </Overlay>
      )}

      {toastData.isOpen && (
        <ToastMessage {...toastData} handleToastClose={handleToastClose} />
      )}

      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </Box>
  );
};

export default DynamicTable;
