// ----------------------------------------------------------------------

export default function Table(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.neutral,
          padding: "8px 14px",
          minWidth: "164px",
          "& button": {
            padding: "5px",
            lineHeight: "20px",
          },
        },
        root: {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.background.paper + " !important"
              : theme.palette.common.white + " !important",
        },
      },
    },
  };
}
