import {
  infoColor,
  dangerColor,
  successColor,
  hexToRgb,
  grayColor,
  whiteColor
} from "assets/jss/material-dashboard-react.js";
import { alpha } from '@mui/material/styles';

export default function checkboxAdnRadioStyle(theme) {
  return {
    switch_track: {
      backgroundColor: theme.palette.primary.light
    },
    switch_base: {
      color: infoColor[0],
      "&.Mui-disabled": {
        color: grayColor[6]
      },
      "&.Mui-checked": {
        color: infoColor[0]
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: whiteColor
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: grayColor[0]
      }
    },
    switch_primary: {
      "&.Mui-checked": {
        color: infoColor[0]
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.62)",
      }
    },
    root: {
      padding: "13px",
      "&:hover": {
        backgroundColor: "unset"
      }
    },
    labelRoot: {
      marginLeft: "-14px"
    },
    labelTxt: {
      color: theme.palette.text.primary,
      fontWeight: 300,
      [theme.breakpoints.down("md")]: {
        fontSize: 12
      }
    },
    /*checked: {
    color: successColor[0] + "!important"
  },*/
    checkedIcon: {
      width: "20px",
      height: "20px",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "3px",
      backgroundColor: theme.palette.common.white
    },
    uncheckedIcon: {
      width: "20px",
      height: "20px",
      color: "transparent",
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "3px",
      backgroundColor: theme.palette.common.white
    },
    radio: {
      color: dangerColor[0] + "!important"
    },
    radioChecked: {
      width: "20px",
      height: "20px",
      border: "1px solid " + successColor[0],
      borderRadius: "50%"
    },
    radioUnchecked: {
      width: "0px",
      height: "0px",
      padding: "10px",
      border: "1px solid rgba(" + hexToRgb(dangerColor[0]) + ", .54)",
      borderRadius: "50%"
    },
    formControl: {
      paddingBottom: "5px",
      margin: "0px 10px 0px 10px",
      position: "relative",
      verticalAlign: "unset"
    },
    formControlLabel: {
      color: grayColor[2]
    },
    // checked: {
    //   color: theme.palette.primary.lighter + "!important"
    // },
    headerSwitchBase: {
      color: alpha(theme.palette.common.white, 0.8),
      "&.Mui-disabled": {
        color: grayColor[6]
      },
      "&.Mui-checked": {
        color: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.62)",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        backgroundColor: whiteColor
      },
      "&.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.62)",
      },
      "& .MuiSwitch-thumb": {
        backgroundColor: theme.palette.primary.main
      }
    },
    headerSwitchTrack: {
      backgroundColor: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.62)",
    }
  };
}
