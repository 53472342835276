import React, { useState, useEffect } from "react";
// @material-ui/core components
//import MaterialTable from "@material-ui/core/Table";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Draggable from "react-draggable";
import Paper from '@mui/material/Paper';
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import CircularProgress from '@mui/material/CircularProgress';
import Button from "../CustomButtons/Button";
import { emailObjectData } from "../../variables/ResolveDataVariables";

const styles = {
  dialogPaper: {
    "& div > div": {
      minHeight: "60%",
      "@media (min-width: 0px) and (max-width: 599.95px)": {
        width: "358px !important",
      },
    },
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    fontFamily: "inherit",
  },
  inputCls: {
    width: "100%",
    borderRadius: "4px",
    outline: "none",
    border: "1px solid #ccc",
    marginBottom: "20px",
    height: "40px",
    padding: "10px 0px 10px 5px",
    fontFamily: "inherit",
  },
  textAreaCls: {
    width: "100%",
    borderRadius: "4px",
    outline: "none",
    border: "1px solid #ccc",
    marginBottom: "20px",
    padding: "10px 10px 10px 10px",
    height: "125px",
    boxSizing: "borderBox",
    fontFamily: "inherit",
  },
  footerActions: {
    display: "flex",
    justifyContent: "right",
  },
  closeButton: {
    position: "absolute",
    right: 6,
    top: 4,
  },
  dialogWindow: {
    width: 550,
    height: 250,
    "@media (min-width: 0px) and (max-width: 599.95px)": {
      width: "auto",
    },
  },
};

const useStyles = makeStyles(styles);

export default function SayaCustomEmailDialog(props) {
  const classes = useStyles();
  const {
    openFollowUpDialog,
    handleCloseFollowupDialog,
    dialogTitle,
    handleAddressChange,
    handleSubjectChange,
    handleMessageChange,
    toAddress,
    subject,
    message,
    followupFlowItem,
    loading,
  } = props;

  const [emailObject, setEmailObject] = useState(emailObjectData);

  const handleClose = () => {
    handleCloseFollowupDialog();
  };

  useEffect(() => {
    
    let emailData = emailObjectData;
    //emailData = emailObjectData;
    setEmailObject(emailData);
    
    return () => {
      console.log(
        "Component SayaCustomEmail unloading cleanup to be done here!!!"
      );
    };
  }, []);

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={openFollowUpDialog}
      onClose={handleCloseFollowupDialog}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className={classes.dialogPaper}
      // aria-labelledby="responsive-dialog-title"
    >
      {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {dialogTitle}

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogWindow}>
        <form onSubmit={followupFlowItem}>
          <div xs={12} sm={12} md={12}>
            <input
              type="email"
              className={classes.inputCls}
              value={toAddress}
              onChange={handleAddressChange}
              placeholder="Email Address"
              required
            />{" "}
            <br />
          </div>
          <div xs={12} sm={12} md={12}>
            <input
              type="text"
              className={classes.inputCls}
              value={subject}
              onChange={handleSubjectChange}
              placeholder="Subject"
              maxlength="128"
              required
            />{" "}
            <br />
          </div>
          <div xs={12} sm={12} md={12}>
            <textarea
              className={classes.textAreaCls}
              value={message}
              onChange={handleMessageChange}
              placeholder="Message"
              maxlength="320"
              required
            />{" "}
            <br />
          </div>
          <div xs={12} sm={12} md={12} className={classes.footerActions}>
            {loading && <CircularProgress />}
            <Button variant="contained" color="primary" type="submit">
              SEND
            </Button>
          </div>
        </form>
      </DialogContent>
      {/* <DialogActions></DialogActions> */}
    </Dialog>
  );
}

SayaCustomEmailDialog.propTypes = {
  openFollowUpDialog: PropTypes.bool.isRequired,
  handleCloseFollowupDialog: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  handleAddressChange: PropTypes.func,
  handleSubjectChange: PropTypes.func,
  handleMessageChange: PropTypes.func,
  toAddress: PropTypes.string,
  subject: PropTypes.string,
  message: PropTypes.string,
  followupFlowItem: PropTypes.func,
};
