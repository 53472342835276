import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { Button, IconButton, FormLabel, Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { transformToUpperCase } from "components/utils";
import axios from "axios";
import { API } from "URL.js";
import Zoom from "@mui/material/Zoom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}));
export default function DynamicFormClass(props) {
  let dataColumns =
    props.dataObj.headerNames === undefined ||
    props.dataObj.headerNames === null
      ? []
      : props.dataObj.headerNames;
  let dataValue =
    props.dataObj.txnDataDTO === undefined || props.dataObj.txnDataDTO === null
      ? []
      : props.dataObj.txnDataDTO;

  const classes = useStyles();
  const theme = useTheme();

  const downloadFile = async (
    reconUnitId,
    rawDataId,
    metaDataId = 5671,
    fileName
  ) => {
    try {
      const url = `${API}/uploadData/downloadFile/${reconUnitId}/${rawDataId}/${metaDataId}/${fileName}`;
      const response = await axios({
        url,
        method: "GET",
        responseType: "blob", // Important
      });

      // Create a new Blob object using the response data of the file
      const file = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      // Create an anchor element and trigger the download
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div style={{ paddingTop: "0px" }}>
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={1}>
          {dataColumns.map((tile, index) => (
            <Grid
              item
              xs={6}
              sm={4}
              md={2}
              key={index}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {tile.columnValue?.length > 24 ? (
                  <Tooltip
                    title={tile.columnValue}
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <FormLabel
                      title={tile.columnValue}
                      sx={{
                        display: "block",
                        width: "200px",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {transformToUpperCase(tile.columnValue)}
                    </FormLabel>
                  </Tooltip>
                ) : (
                  <FormLabel
                    title={tile.columnValue}
                    sx={{
                      display: "block",
                      width: "200px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {transformToUpperCase(tile.columnValue)}
                  </FormLabel>
                )}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <TextField
                    id="standard-read-only-input"
                    render={
                      dataValue &&
                      dataValue.length > 0 &&
                      !dataValue[0][tile.columnName] === null
                    }
                    //label={transformToUpperCase(tile.columnValue)}
                    defaultValue={
                      dataValue &&
                      dataValue.length > 0 &&
                      dataValue[0][tile.columnName]
                    }
                    margin="none"
                    InputProps={{
                      title: dataValue[0][tile.columnName],
                      readOnly: true,
                      sx: {
                        input: {
                          fontSize: "12px",
                          padding: "10px",
                        },
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ fontSize: "12px" }}
                    size="small"
                    variant="outlined"
                  />

                  {/* {(String(dataValue[0][tile.columnName]).includes(".pdf") ||
                String(dataValue[0][tile.columnName]).includes(".doc") ||
                String(dataValue[0][tile.columnName]).includes(".csv")) && (
                <IconButton sx={{borderRadius: '0', height: '34px', marginLeft: '5px'}}>
                  <FileDownloadOutlinedIcon onClick={()=>downloadFile(dataValue[0].reconUnitId, dataValue[0].rawDataId, 5671, String(dataValue[0][tile.columnName]))}/>
                </IconButton>
              )} */}

                  {tile.dataType.toLowerCase() === "document" &&
                    dataValue[0][tile.columnName] && (
                      <IconButton
                        className={classes.downLoadBtn}
                        sx={{
                          borderRadius: "0",
                          cursor: "pointer",
                          height: "37px",
                          marginLeft: "-40px",
                          marginTop: "2px",
                          border: "1px solid transparent",
                          borderColor:
                            theme.palette.type === "dark"
                              ? "transparent"
                              : "rgb(221 60 124 / 45%)",
                          borderRadius: "8px",
                          backgroundColor:
                            theme.palette.type === "dark"
                              ? "transparent"
                              : "#fef8fd",
                          "&:hover": {
                            backgroundColor:
                              theme.palette.type === "dark"
                                ? "transparent"
                                : "#fde6f1",
                          },
                        }}
                      >
                        <FileDownloadOutlinedIcon
                          sx={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            downloadFile(
                              dataValue[0].reconUnitId,
                              dataValue[0].rawDataId,
                              tile?.metaDataId,
                              String(dataValue[0][tile.columnName])
                            )
                          }
                        />
                      </IconButton>
                    )}
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </form>
    </div>
  );
}
