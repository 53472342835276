// @mui
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

let primaryColor = JSON.parse(localStorage.getItem('spDetails'))?.primaryColor || "#d92f67"; //#d92f67
let secondaryColor = JSON.parse(localStorage.getItem('spDetails'))?.secondaryColor || "#056d8a"; //#056d8a
let bodyBgColor = JSON.parse(localStorage.getItem('spDetails'))?.bodyBgColor || "#1a1344"; //#056d8a


// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8)
};

// const PRIMARY = {
//   lighter: "#e4eafe",
//   light: "#bda1e8", //1ba2b5 g2
//   main: "#5e35b1", // g1
//   dark: "#5e35b1", // Button Color
//   darker: "#5e35b1",
//   contrastText: "#fff"
// };
// const SECONDARY = {
//   lighter: "#D6E4FF",
//   light: "#84A9FF",
//   main: "#5e35b1",
//   dark: "#1939B7",
//   darker: "#091A7A",
//   contrastText: "#fff"
// };

// const PRIMARY = {
//   lighter: "#e4eafe",
//   light: "#0060df", //1ba2b5  g2
//   main: "#060632", //g1
//   dark: "#485ec4", // Button Color
//   darker: "#044293",
//   contrastText: "#fff"
// };
const PRIMARY = {
  lighter: "#e4eafe",
  light: "#ff6895", //1ba2b5
  main: primaryColor,
  dark: "#fd4070", // Button Color
  darker: "#a2003d",
  contrastText: "#fff"
};
const SECONDARY = {
  lighter: "#4d9bba",
  light: "#4d9bba",
  main: secondaryColor,
  dark: "#a81f61",
  darker: "#00425d",
  contrastText: "#fff"
};

const ICONS = {
  pink: "#ff2366",
  blue: "#6c62e8",
  green: "#60d291",
  yellow: "#ffd950",
};


const ICONS_BG = {
  pink: "#ffdae5",
  blue: "#eae7fd",
  green: "#e2f5ea",
  yellow: "#fff9e1",
};
// const PRIMARY = {
//   lighter: "#C8FACD",
//   light: "#34C38F", //1ba2b5 g2
//   main: "#00603D", // g1
//   dark: "#485ec4", // Button Color
//   darker: "#34C38F",
//   contrastText: "#fff"
// };
// const SECONDARY = {
//   lighter: "#D6E4FF",
//   light: "#84A9FF",
//   main: "#00acc1",
//   dark: "#1939B7",
//   darker: "#091A7A",
//   contrastText: "#fff"
// };
const INFO = {
  lighter: "#D0F2FF",
  light: "#74CAFF",
  main: "#1890FF",
  dark: "#0C53B7",
  darker: "#04297A",
  contrastText: "#fff"
};
const SUCCESS = {
  lighter: "#E9FCD4",
  light: "#AAF27F",
  main: "#54D62C",
  dark: "#229A16",
  darker: "#08660D",
  contrastText: GREY[800]
};
const WARNING = {
  lighter: "#FFF7CD",
  light: "#FFE16A",
  main: "#FFC107",
  dark: "#B78103",
  darker: "#7A4F01",
  contrastText: GREY[800]
};
const ERROR = {
  lighter: "#FFE7D9",
  light: "#FFA48D",
  main: "#FF4842",
  dark: "#B72136",
  darker: "#7A0C2E",
  contrastText: "#fff"
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const paletteDark = {
  type: "dark",
  common: {
    black: "#000",
    white: "#fff",
    blue: "#6fb6d3",
    shadow: `0 0 2px 0 ${alpha('#000', 0.8)}, 0 12px 24px -4px ${alpha('#000', 0.8)}`,
    bgShade: "#373068"
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  border: { primary:'#524b80', secondary: '#524b80'},
  icons: ICONS,
  iconsBg: ICONS_BG,
  gradients: GRADIENTS,
  divider: GREY[500_24],
  text: { primary: alpha('#fff', 0.8), secondary: GREY[600], disabled: GREY[500] },
  background: { paper: "#1a1445", card: "#1a1445", darkBlue: "#1a1344", default: alpha(bodyBgColor, 0.85), bgShade: "#ffebf6", neutral: GREY[200] },
  spacing: { paddingLeft: "150px" },
  fontFamily: {primary: 'Poppins'},
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default paletteDark;
