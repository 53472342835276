import { USERAPI } from "URL.js";
import axios from "axios";

export const getUsersList = async (userId, fetchUserList) => {
  try {
    const response = await axios.get(USERAPI + "/user/fetchusers/" + userId);
    if (response.status === 200) {
      let userData = response.data;
      userData.forEach(async function(item){
        const userImg = await getUserPhoto(item.userId);
        item.photo = userImg;
      });
      console.log('userData', userData);
      fetchUserList(userData);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const getUserPhoto = async (userIdVal) => {
  try {
    const response = await axios.get(USERAPI + "/user/photo/" + userIdVal);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error(error.message);
  }
};

