import { notificationParams } from "variables/general.js";
import AddAlert from '@mui/icons-material/AddAlert';
export const showAlertNotification = (messageToShow, callback) =>{
    let notificationDet = {...notificationParams};
    notificationDet.color = "info";
    notificationDet.open = true;
    notificationDet.message = messageToShow;
    callback(notificationDet);
    setTimeout(function() { 
     
        let notificationData = {...notificationParams};
        notificationData.open = false;
        callback(notificationData);
      }, 6000);
      console.log("Message blocked after 6 seconds...", notificationDet);
  }

export const showSuccessNotification = (messageToShow, callback) =>{
    let notificationDet = {...notificationParams};
    notificationDet.color = "success";
    notificationDet.open = true;
    notificationDet.message = messageToShow;
    callback(notificationDet);
    setTimeout(function() { 
      let notificationData = {...notificationParams};
      notificationData.open = false;
      callback(notificationData);
      }, 6000);
  }

export const showFailureNotification = (messageToShow, callback) =>{
    let notificationDet = {...notificationParams};
    notificationDet.color = "danger";
    notificationDet.open = true;
    notificationDet.message = messageToShow;
    notificationDet.icon = AddAlert;
    callback(notificationDet);
    setTimeout(function() { 
      let notificationData = {...notificationParams};
      notificationData.open = false;
      callback(notificationData);
    }, 6000);
    
  }