import React, { useReducer } from "react";
import { WorkflowContext } from "./WorkflowContext";
import WorkflowReducer from "./WorkflowReducer";

const WorkflowState = ({ children }) => {
  const workflowInitialState = {
    workflows: [],
    queues: [],
  };

  const [state, dispatch] = useReducer(WorkflowReducer, workflowInitialState);

  function saveWorkflows(workflows) {
    dispatch({
      type: "SAVE_WORKFLOW_DATA",
      payload: workflows,
    });
  }

  function saveQueues(queues) {
    dispatch({
      type: "SAVE_QUEUE_DATA",
      payload: queues,
    });
  }

  return (
    <WorkflowContext.Provider
      value={{
        state,
        saveWorkflows,
        saveQueues,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export default WorkflowState;
