import React, { useReducer } from "react";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext.js";

import UserAuthDetailsReducers from "context/LoginPageContext/UserAuthDetailsReducers.js";

const UserAuthDetailsState = ({ children }) => {
  const userDetailsInitialState = {
    isLoggedIn: false,
    userId: 0,
    userEmail: "",
    loginName: sessionStorage.getItem("loginName"),
    firstName: "",
    lastName: "",
    userToken: sessionStorage.getItem("token"),
    departmentId: 0,
    departmentName: "",
    clientOrgId: 0,
    parentClientOrgId: 0,
    clientShortName: "",
    roleId: "",
    errors: "",
    reconUnitId: "",
    resolveUnitId: "",
    reconUnitId: "",
    themeType: "light",
    menuHierarchy: "",
    originalMenuHierarchy: [],
    state: "",
    rowsPerPage: '',
    formData:[]
  };
  const [state, dispatch] = useReducer(
    UserAuthDetailsReducers,
    userDetailsInitialState
  );
  //const UserAuthContext = useContext(UserAuthDetailsContext);

  function saveUserDetailsAuthState(userAuthState) {
    dispatch({
      type: "SAVE_USER_AUTH_DATA",
      payload: userAuthState,
    });
  }

  function clearUserDetailsAuthState() {
    dispatch({
      type: "LOGOUT_STATE_DATA_CLEAR",
    });
  }

  function saveUserToken(userToken) {
    dispatch({
      type: "SUCCESS_SAVE_USER_TOKEN",
      payload: userToken,
    });
  }

  function setReconUnitId(reconId) {
    dispatch({
      type: "SET_RECON_UNIT_ID",
      payload: Number(reconId),
    });
  }

  function setReconDataSource(reconDS) {
    dispatch({
      type: "SET_RECON_DATA_SOURCE",
      payload: Number(reconDS),
    });
  }

  function setResolveUnitId(resolveId) {
    dispatch({
      type: "SET_RESOLVE_UNIT_ID",
      payload: resolveId,
    });
  }
  function setReconUnitId(reconUnitId) {
    dispatch({
      type: "SET_RECON_UNIT_ID",
      payload: reconUnitId,
    });
  }

  function setThemeType(themeVal) {
    dispatch({
      type: "SET_THEME_TYPE",
      payload: themeVal,
    });
  }

  function saveMenuHierarchyState(menuState) {
    dispatch({
      type: "SAVE_MENU_HIERARCHY_DATA",
      payload: menuState,
    });
  }

  function saveOriginalMenuHierarchyState(menuState) {
    dispatch({
      type: "SAVE_ORIGINAL_MENU_HIERARCHY_DATA",
      payload: menuState,
    });
  }

  function savePaginationRowsPerPage(count) {
    dispatch({
      type: "SAVE_PAGINATION_ROWS_PER_PAGE",
      payload: count,
    });
  }

  function saveFormData(data) {
    dispatch({
      type: "SAVE_FORM_DATA",
      payload: data,
    });
  }

  window.globalData = state;

  return (
    <UserAuthDetailsContext.Provider
      value={{
        state,
        saveUserDetailsAuthState,
        saveUserToken,
        clearUserDetailsAuthState,
        setReconUnitId,
        setReconDataSource,
        setResolveUnitId,
        setReconUnitId,
        setThemeType,
        saveMenuHierarchyState,
        saveOriginalMenuHierarchyState,
        savePaginationRowsPerPage,
        saveFormData
      }}
    >
      {children}
    </UserAuthDetailsContext.Provider>
  );
};

export default UserAuthDetailsState;
