import React from "react";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
//import Switch from "@material-ui/core/Switch";
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';
import PropTypes from "prop-types";

//core components
import styles from "../../assets/jss/material-dashboard-react/checkboxAdnRadioStyle";

const useStyles = makeStyles(styles);

export default function CustomSwitch(props) {
  const classes = useStyles();

  const { labelText, id, handleToggle, value, isDisabled, headerSwitch } =
    props;

  const headerswitchBaseClass = classNames({
    [classes.headerSwitchBase]: headerSwitch,
  });
  const headerswitchTrackClass = classNames({
    [classes.headerSwitchTrack]: headerSwitch,
  });

  return (
    <FormControlLabel
      //className={switchClasses}
      control={
        <Switch
          tabIndex={-1}
          onClick={handleToggle}
          id={id}
          checked={value}
          disabled={isDisabled}
          classes={{
            checked: classes.checked,
            track: classes.switch_track + " " + headerswitchTrackClass,
            switchBase: classes.switch_base + " " + headerswitchBaseClass,
            colorPrimary: classes.switch_primary,
          }}
        />
      }
      label={<div className={classes.labelTxt}>{labelText}</div>}
    />
  );
}

CustomSwitch.propTypes = {
  labelText: PropTypes.string,
  handleToggle: PropTypes.func,
  value: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
