// @mui
import { GlobalStyles as MUIGlobalStyles } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { hexToRgb } from "assets/jss/material-dashboard-react.js";
// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
        },
        html: {
          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          WebkitOverflowScrolling: "touch",
        },
        body: {
          // "* ::-webkit-scrollbar": {
          //   width: "1em",
          // },
          // "* ::-webkit-scrollbar-track": {
          //   "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
          // },

          // "* ::-webkit-scrollbar-thumb": {
          //   backgroundColor: "darkgrey",
          //   outline: "1px solid slategrey",
          // },
          "& .ct-series-a .ct-slice-pie": {
            /* fill of the pie slieces */
            //fill: "hsl(120deg 40% 60% / 55%)",
            fill: '#75d798',
            /* give your pie slices some outline or separate them visually by using the background color here */
            stroke: "white",
            /* outline width */
            strokeWidth: "4px",
          },
          "& .ct-series-b .ct-slice-pie": {
            /* fill of the pie slieces */
            //fill: "hsl(120deg 40% 60% / 55%)",
            fill: 'rgb(117 215 152 / 55%)',
            /* give your pie slices some outline or separate them visually by using the background color here */
            stroke: "white",
            /* outline width */
            strokeWidth: "4px",
          },

          margin: 0,
          padding: 0,
          width: "100%",
          height: "100%",
          backgroundColor: theme.palette.background.default,
          "& a": {
            color: theme.palette.primary.main,
          },
          "& .card-title-flag": {
            position: "relative",
            "&:after": {
              content: '""',
              backgroundColor: theme.palette.primary.main,
              position: "absolute",
              left: "-20px",
              top: "3px",
              width: "4px",
              height: "23px",
            },
          },
          "& .modal-title-flag": {
            position: "relative",
            "&:after": {
              content: '""',
              backgroundColor: theme.palette.primary.main,
              position: "absolute",
              left: "-24px",
              top: "5px",
              width: "4px",
              height: "28px",
            },
          },

          "& .shade-wrapper": {
            borderWidth: "2px",
            borderColor: theme.palette.common.white,
            borderStyle: "solid",
            borderRadius: "6px",
            background:
              "rgba(" + hexToRgb(theme.palette.primary.main) + ",.09)",
            boxShadow: theme.palette.common.shadow,
            padding: 5,
            height: "46px",
            width: "46px",
            marginLeft: "12px",
            [theme.breakpoints.down("md")]: {
              height: "36px",
              width: "36px",
            },
            "& svg": {
              color: theme.palette.primary.main,
              marginRight: "12px",
              width: "100%",
              display: "inline-flex",
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                height: "auto",
              },
            },
          },
        },
        "#root": {
          width: "100%",
          height: "100%",
        },
        input: {
          "&[type=number]": {
            MozAppearance: "textfield",
            "&::-webkit-outer-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
            "&::-webkit-inner-spin-button": {
              margin: 0,
              WebkitAppearance: "none",
            },
          },
        },
        img: {
          display: "block",
          maxWidth: "100%",
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  );

  return inputGlobalStyles;
}
