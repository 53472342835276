import React, { Component } from "react";
import { Navigate } from "react-router-dom";
//import { Link } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { withStyles } from "@material-ui/core/styles";

import axios from "axios";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import Button from "../../components/CustomButtons/Button";
import CardBody from "../../components/Card/CardBody";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { USERAPI } from "./../../URL";

const useStyles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  loginBtnCls: {
    background: "#1F2540 0% 0% no-repeat padding-box",
    color: "#fff",
    borderRadius: "6px",
    padding: "10px 50px",
    cursor: "pointer",
    marginTop: "40px",
    marginRight: "10px",
  },
  inputCls: {
    width: "100%",
    borderRadius: "4px",
    outline: "none",
    border: "1px solid #ccc",
    marginBottom: "20px",
    height: "40px",
    padding: "10px 0px 10px 5px",
    fontFamily: "inherit",
  },
  errors: {
    color: "red",
    position: "relative",
    bottom: "16px",
  },
  message: {
    marginBottom: 20,
  },
});

class FirstTimePasswordChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      exceptionOccured: "",
      userName: "",
      oldPassword: "",
      formData: {},
      firstTimeLogin: false,
      errors: {},
    };
  }

  componentDidMount() {
    //setting the passed props as part of teh mount
    //TODO will try later
    this.state.formData.userName = this.state.userName;
    this.state.formData.oldPassword = this.state.oldPassword;
  }

  changeTempPassowrd() {
    if (this.handleValidation()) {
      let passwordrequest = {
        userName: this.state.formData.userName,
        oldPassword: this.state.formData.oldPassword,
        newPassword: this.state.formData.newPassword,
      };

      // const endPoint = USERAPI + '/user/change_temp_password'
      // axiosService.getAxios(endPoint, passwordrequest)

      axios.post(USERAPI + "/user/change_temp_password", passwordrequest).then(
        (response) => {
          if (response.status === 200) {
            this.setState({
              success: true,
              exceptionOccured: "Password Changed Successfully",
              formData: {},
              errors: {},
            });
          
          }
        },
        (error) => {
          console.log(error);
          this.setState({
            exceptionOccured:
              "Password entered is not meeting the security criteria!",
          });
        }
      );
    }
  }

  handleValidation() {
    let event = this.state.formData;
    let errors = {};
    let formIsValid = true;

    if (!event["userName"]) {
      formIsValid = false;
      errors["userName"] = "User Name cannot be empty !";
    }

    if (!event["oldPassword"]) {
      formIsValid = false;
      errors["oldPassword"] = "Old Password cannot be empty !";
    }

    if (!event["newPassword"]) {
      formIsValid = false;
      errors["newPassword"] = "New Password cannot be empty !";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(data, event) {
    let objData = this.state.formData;
    objData[data] = event.target.value;
    this.setState({ objData }, () => this.handleValidation());
  }

  render() {
    const { classes } = this.props;
    return this.state.success ? (
      // <Redirect to="/" />
      <Navigate to="/login" replace={true} />
    ) : (
      <GridContainer style={{justifyContent: 'center'}}>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4>
                First Time Login !!!, Change your password
              </h4>
            </CardHeader>

            <CardBody>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <GridItem xs={12} sm={12} md={6}>
                  <Box className={classes?.formBg}>
                    <div className="loginBg">
                      <div className="container loginBox">
                        <div className="cardCls">
                          <p className={classes?.message}>
                            Password should contain atleast one capital letter,
                            one special character, one numeric and minimum 8
                            characters
                          </p>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 loginFormCls">
                              <input
                                type="email"
                                defaultValue={this.state.formData.userName}
                                onChange={this.handleChange.bind(
                                  this,
                                  "userName"
                                )}
                                className={classes?.inputCls}
                                placeholder="User Name"
                              />{" "}
                              <br />
                              <div className={classes?.errors}>
                                {this.state.errors["userName"]}
                              </div>
                              <input
                                type="password"
                                defaultValue={this.state.formData.oldPassword}
                                onChange={this.handleChange.bind(
                                  this,
                                  "oldPassword"
                                )}
                                className={classes?.inputCls}
                                placeholder="Old Password"
                              />{" "}
                              <br />
                              <div className={classes?.errors}>
                                {this.state.errors["oldPassword"]}
                              </div>
                              <input
                                type="password"
                                defaultValue={this.state.formData.newPassword}
                                onChange={this.handleChange.bind(
                                  this,
                                  "newPassword"
                                )}
                                className={classes?.inputCls}
                                placeholder="New Password"
                              />{" "}
                              <br />
                              <div className={classes?.errors}>
                                {this.state.errors["newPassword"]}
                              </div>
                              <Grid container justify="flex-end" spacing={1}>
                                <GridItem style={{ marginRight: "-10px" }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.changeTempPassowrd.bind(this)}
                                  >
                                    SUBMIT
                                  </Button>
                                </GridItem>
                              </Grid>
                              <br />
                              <div className={classes?.errors}>
                                {this.state.exceptionOccured}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </GridItem>
              </Box>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
export default withStyles(useStyles)(FirstTimePasswordChange);

