import React, { useEffect, useState, useContext } from "react";
// @material-ui/core components
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Skeleton from "@mui/material/Skeleton";
import { AddToPhotos, DeviceHub } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
// core components
import CardIcon from "../../components/Card/CardIcon";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardFooter from "../../components/Card/CardFooter";
import { getUsersList } from "../../action-handlers/UserActionHandler";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../variables/general";
import Button from "../../components/CustomButtons/Button";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import SAYASearchBox from "components/CustomSearchBox/CustomSearchBox";
import {
  ButtonGroup,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  TextField,
} from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SAYACustomInput from "../../components/CustomInput/SAYACustomInput";
const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formBg: {
    background: theme.palette.grey[500_12],
    borderRadius: "5px",
    padding: "20px",
  },
  stats: {
    "& svg": {
      marginLeft: "3px",
      marginRight: "3px",
    },
    display: "inline-flex",
    fontSize: "12px",
  },
  dialogWindow: {
    minWidth: "auto",
  },
  errorMessgage: {
    fontSize: 12,
    color: theme.palette.error.main,
    margin: "0px 0px 10px 0",
  },
  cardTitle: {
    color: theme.palette.text.primary,
  },
  imagePreview: {
    width: 100,
    "& img": {
      width: "100%",
    },
  },
  loaderImg: {
    position: "relative",
    left: "48%",
    top: 100,
  },
  logoImage: {
    float: "left",
    border: "1px solid " + theme.palette.grey[300],
  },
});

const useStyles = makeStyles(styles);

export default function Organizations() {
  const [departmentList, setDepartmentList] = useState([]);
  const [isEditDepartment, setEditDepartment] = useState(false);
  const [departmentName, setDepartmentName] = useState("");
  const [loaderState, setLoaderState] = useState(true);
  const [isSignUp, setSignUpPage] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [showTableView, setShowTableView] = useState(false);
  const [userListData, setUserList] = useState("");
  const [usId, setUsId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const navigate = useNavigate();

  const location = useLocation();
  const allowedPermissions =
    JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];

  useEffect(() => {
    const { loginName } = UserAuthContext.state;
    if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, [UserAuthContext.state]);

  const fetchUserDetails = (userList) => {
    setUserDetails(userList);
    getUserListHandler(userList);
  };

  const getUserListHandler = (userObj) => {
    setLoaderState(true);
    getUsersList(userObj.userId, setUserListHandler);
  };

  const setUserListHandler = (res) => {
    setLoaderState(false);
    setUserList(res);
  };

  const createUserHandler = (e) => {
    e.preventDefault();
    setSignUpPage(true);
  };

  const editUSer = (userItem) => {
    navigate("/saya-platform/admin/sign-up", {
      state: { userItem: JSON.stringify(userItem) },
    });
  };

  const handleButtonClick = (viewType) => {
    setShowTableView(viewType === "table");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, useid) => {
    setAnchorEl(event.currentTarget);
    setUsId(useid);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let tblColumns = [
    {
      name: "photoContent",
      label: "Logo",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) =>
          value ? (
            <img src={`data:image/png;base64,${value}`} alt="Logo" width="30" />
          ) : (
            <DeviceHub />
          ),
      },
    },
    {
      name: "userId",
      label: "User Id",
    },
    {
      name: "firstName",
      label: "First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      name: "email",
      label: "Email",
    },

    ...(allowedPermissions.includes("modify")
      ? [
          {
            name: "actions",
            label: "Actions",
            options: {
              sort: false,
              filter: false,
              customBodyRender: (value, tableMeta) =>
                allowedPermissions.includes("view") ||
                allowedPermissions.includes("modify") ||
                allowedPermissions.includes("delete") ? (
                  <div>
                    <IconButton
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleClick(e, tableMeta.rowData[1])}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          editUSer(
                            userListData.find((item) => item.userId === usId)
                          );
                        }}
                      >
                        Edit
                      </MenuItem>
                    </Menu>
                  </div>
                ) : null,
            },
          },
        ]
      : []),
  ];

  const classes = useStyles();
  if (isSignUp) {
    // return <Redirect to="/saya-platform/admin/sign-up" />;
    navigate("/saya-platform/admin/sign-up");
  } else {
    return (
      <div>
        <GridContainer style={{ marginBottom: "15px" }}>
          {allowedPermissions.includes("add") && (
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Button onClick={createUserHandler} variant="contained">
                <AddToPhotos />
                Create User
              </Button>
            </GridItem>
          )}
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {!showTableView ? (
                <div style={{ paddingRight: "10px" }}>
                  <SAYASearchBox
                    searchText={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              ) : null}
              <ButtonGroup>
                <Button
                  variant={showTableView ? "outlined" : "contained"}
                  onClick={() => handleButtonClick("card")}
                >
                  <Tooltip title="Card View">
                    <GridViewIcon />
                  </Tooltip>
                </Button>

                <Button
                  variant={showTableView ? "contained" : "outlined"}
                  onClick={() => handleButtonClick("table")}
                >
                  <Tooltip title="Table View">
                    <TableRowsIcon />
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </div>
          </GridItem>
        </GridContainer>
        {showTableView ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <SAYAMUIDataTable
                title="User Data"
                data={userListData}
                options={{
                  selectableRows: "none",
                  filter: true,
                  viewColumns: true,
                  print: true,
                  download: true,
                }}
                columns={tblColumns}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <>
            <GridContainer>
              {userListData && userListData.length > 0 ? (
                userListData
                  .filter(
                    (userItem) =>
                      userItem.firstName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      userItem.email
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((userItem, index) => (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => editUSer(userItem)}
                    >
                      <Card>
                        <CardHeader color="primary" stats icon>
                          {userItem.photoContent ? (
                            <img
                              src={
                                "data:" +
                                userItem.imageType +
                                ";base64," +
                                userItem.photoContent
                              }
                              width="60px"
                              className={classes.logoImage}
                            />
                          ) : (
                            <CardIcon color="primary" plain>
                              <DeviceHub />
                            </CardIcon>
                          )}
                          <p className={classes.cardCategory}>
                            User Id: {userItem.userId}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {userItem.firstName}
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            {/* <EditRoundedIcon /> */}
                            {userItem.email}
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))
              ) : (
                <>
                  {[1, 2, 3].map((index) => (
                    <GridItem xs={12} sm={6} md={4} key={index}>
                      <Card style={{ width: "100%", marginRight: 20 }}>
                        <CardHeader color="primary" stats icon>
                          <CardIcon
                            color="primary"
                            style={{ background: "transparent" }}
                          >
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          </CardIcon>

                          <p className={classes.cardCategory}>
                            <Skeleton
                              animation="wave"
                              style={{ float: "right" }}
                              width={140}
                            />
                          </p>
                          <h3 className={classes.cardTitle}>
                            <Skeleton animation="wave" width={50} />
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <Skeleton animation="wave" width={150} />
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))}
                </>
              )}
              {userListData.length > 0 &&
                userListData.filter(
                  (userItem) =>
                    userItem.firstName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    userItem.email
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                ).length === 0 && (
                  <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{ textAlign: "center" }}>No results found</h4>
                  </GridItem>
                )}
              {loaderState && (
                <GridItem xs={12} sm={12} md={12}>
                  <CircularProgress className={classes.loaderImg} />
                </GridItem>
              )}
            </GridContainer>
          </>
        )}
      </div>
      // <div>
      //   <GridContainer>
      //     <GridItem xs={12} sm={6} md={3} style={{ cursor: "pointer" }} onClick={createNewOrgHandler}>
      //       <Card headerCard>
      //         <CardHeader color="danger" stats icon>
      //           <CardIcon color="info">
      //             <AddToPhotosIcon />
      //           </CardIcon>
      //           <p className={classes.cardCategory}></p>
      //           <h3 className={classes.cardTitle}>Create New Department</h3>
      //         </CardHeader>
      //       </Card>
      //     </GridItem>
      //   </GridContainer>
      //   <GridContainer>
      //     {departmentList &&
      //       departmentList.length > 0 &&
      //       departmentList.map((departmentItem, index) => (
      //         <GridItem
      //           xs={12}
      //           sm={6}
      //           md={4}
      //           key={index}
      //           style={{ cursor: "pointer" }}
      //           onClick={() => createModifyOrgHandler(departmentItem.id)}
      //         >
      //           <Card>
      //             <CardHeader color="primary" stats icon>
      //               <CardIcon color="primary">
      //                 <DeviceHubIcon />
      //               </CardIcon>

      //               <p className={classes.cardCategory}>Department Id: {departmentItem.id}</p>
      //               <h3 className={classes.cardTitle}>{departmentItem.value}</h3>
      //             </CardHeader>
      //             <CardFooter stats>
      //               <div className={classes.stats}>
      //                 <EditRoundedIcon />
      //                 View/ Modify Details...
      //               </div>
      //             </CardFooter>
      //           </Card>
      //         </GridItem>
      //       ))}
      //     {loaderState && (
      //       <GridItem xs={12} sm={12} md={12}>
      //         <CircularProgress className={classes.loaderImg} />
      //       </GridItem>
      //     )}
      //   </GridContainer>
      //   <Dialog
      //     // fullScreen={fullScreen}
      //     open={openDepartmentDialog}
      //     onClose={handleCloseOrgDialog}
      //     aria-labelledby="draggable-dialog-title"
      //     className={classes.dialogPaper}
      //     // aria-labelledby="responsive-dialog-title"
      //   >
      //     {/* <DialogTitle  id="responsive-dialog-title">Follow Up Email</DialogTitle> */}
      //     <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
      //       {/* {dialogTitle} */}

      //       <IconButton
      //         aria-label="close"
      //         className={classes.closeButton}
      //         onClick={handleCloseOrgDialog}
      //       >
      //         <CloseIcon />
      //       </IconButton>
      //     </DialogTitle>
      //     <DialogContent className={classes.dialogWindow}>
      //       <GridContainer>
      //         <Formik
      //           initialValues={{
      //             departmentName: isEditDepartment
      //               ? nullHandler(departmentItemObj.departmentName)
      //               : "",
      //             departmentDlEmail: isEditDepartment
      //               ? nullHandler(departmentItemObj.departmentDlEmail)
      //               : "",
      //             escalationEmailAddress: isEditDepartment
      //               ? nullHandler(departmentItemObj.escalationEmailAddress)
      //               : ""
      //           }}
      //           validationSchema={departmentValidations}
      //           onSubmit={(values) => {
      //             submitFormHanldler(values);
      //             console.log("onSubmit", JSON.stringify(values, null, 2));
      //           }}
      //         >
      //           {({
      //             values,
      //             touched,
      //             errors,
      //             isSubmitting,
      //             handleChange,
      //             handleBlur,
      //             handleSubmit,
      //             handleReset
      //           }) => (
      //             <GridItem xs={12} sm={12} md={12}>
      //               <form onSubmit={handleSubmit}>
      //                 <Card>
      //                   <CardHeader color="primary">
      //                     <h4 className={classes.cardTitleWhite}>
      //                       {isEditDepartment ? "Edit" : "Create"} Department
      //                     </h4>
      //                   </CardHeader>
      //                   <CardBody>
      //                     <Box>
      //                       <GridContainer>
      //                         <GridItem xs={12} sm={12} md={12}>
      //                           {fieldError && (
      //                             <p className={classes.errorMessgage}>
      //                               Please enter Required fields
      //                             </p>
      //                           )}
      //                           <FormControl fullWidth sx={{ m: 1 }} variant="filled">
      //                             <TextField
      //                               id="departmentName"
      //                               label="Department Name"
      //                               onChange={handleChange}
      //                               onBlur={handleBlur}
      //                               value={values.departmentName}
      //                               helperText={touched.departmentName ? errors.departmentName : ""}
      //                               error={touched.departmentName && Boolean(errors.departmentName)}
      //                               margin="dense"
      //                               variant="outlined"
      //                               fullWidth
      //                             />
      //                           </FormControl>
      //                         </GridItem>
      //                         <GridItem xs={12} sm={12} md={12}>
      //                           <FormControl fullWidth sx={{ m: 1 }} variant="filled">
      //                             <TextField
      //                               id="departmentDlEmail"
      //                               label="DepartmentDl Email"
      //                               onChange={handleChange}
      //                               onBlur={handleBlur}
      //                               value={values.departmentDlEmail}
      //                               helperText={
      //                                 touched.departmentDlEmail ? errors.departmentDlEmail : ""
      //                               }
      //                               error={
      //                                 touched.departmentDlEmail && Boolean(errors.departmentDlEmail)
      //                               }
      //                               margin="dense"
      //                               variant="outlined"
      //                               fullWidth
      //                             />
      //                           </FormControl>
      //                         </GridItem>
      //                         <GridItem xs={12} sm={12} md={12}>
      //                           <FormControl fullWidth sx={{ m: 1 }} variant="filled">
      //                             <TextField
      //                               id="escalationEmailAddress"
      //                               label="Escalation Email"
      //                               onChange={handleChange}
      //                               onBlur={handleBlur}
      //                               value={values.escalationEmailAddress}
      //                               helperText={
      //                                 touched.escalationEmailAddress
      //                                   ? errors.escalationEmailAddress
      //                                   : ""
      //                               }
      //                               error={
      //                                 touched.escalationEmailAddress &&
      //                                 Boolean(errors.escalationEmailAddress)
      //                               }
      //                               margin="dense"
      //                               variant="outlined"
      //                               fullWidth
      //                             />
      //                           </FormControl>
      //                         </GridItem>
      //                         {/* <GridItem xs={12} sm={12} md={12}>
      //                           <FormControl fullWidth sx={{ m: 1 }} variant="filled">
      //                             <TextField
      //                               id="createdDate"
      //                               label="Created Date"
      //                               type="datetime-local"
      //                               margin="dense"
      //                               variant="outlined"
      //                               value={values.createdDate}
      //                               helperText={touched.createdDate ? errors.createdDate : ""}
      //                               error={touched.createdDate && Boolean(errors.createdDate)}
      //                               defaultValue={selectedDate}
      //                               className={classes.textField}
      //                               //onChange={handleDateChange}
      //                               onChange={handleChange}
      //                               onBlur={handleBlur}
      //                               InputLabelProps={{
      //                                 shrink: true
      //                               }}
      //                             />
      //                           </FormControl>
      //                         </GridItem> */}
      //                       </GridContainer>
      //                     </Box>
      //                   </CardBody>
      //                   <CardFooter>
      //                     <Grid container justify="center" spacing={1}>
      //                       <GridItem style={{ marginRight: "-10px" }}>
      //                         <Button
      //                           type="submit"
      //                           variant="contained"
      //                           color="primary"
      //                           disabled={isSubmitting}
      //                         >
      //                           SUBMIT
      //                         </Button>
      //                         {/* <Button color="secondary" variant="contained" onClick={handleReset}>
      //                           CLEAR
      //                         </Button> */}
      //                       </GridItem>
      //                       {isEditDepartment && (
      //                         <GridItem style={{ marginRight: "-10px" }}>
      //                           <Button
      //                             variant="contained"
      //                             color="secondary"
      //                             className={classes.deletBtn}
      //                             onClick={deleteDepartmentHandler}
      //                           >
      //                             Delete
      //                           </Button>
      //                         </GridItem>
      //                       )}
      //                     </Grid>
      //                   </CardFooter>
      //                 </Card>
      //               </form>
      //             </GridItem>
      //           )}
      //         </Formik>
      //       </GridContainer>
      //     </DialogContent>
      //     {/* <DialogActions></DialogActions> */}
      //   </Dialog>
      //   {notificationObj && (
      //     <Snackbar
      //       place={notificationObj.place}
      //       color={notificationObj.color}
      //       icon={notificationObj.icon}
      //       message={notificationObj.message}
      //       open={notificationObj.open}
      //       closeNotification={handleCloseNotification}
      //       close={notificationObj.close}
      //       autoHideDuration={3000}
      //     />
      //   )}
      // </div>
    );
  }
}
