import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery, useTheme } from "@mui/material";

function a11yProps(index) {
  return {
    id: `reg-tab-${index}`,
    "aria-controls": `reg-tabpanel-${index}`,
  };
}

function ResolveXQueues({
  queuesData,
  handleTabChange,
  changeTab,
  activeTabName,
  activeTab,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sx"));
  return (
    <Box sx={{ borderTop: 0, borderColor: "divider" }}>
      <Tabs
        //variant={isMobile ? "scrollable" : "standard"}
        //scrollButtons={isMobile ? "auto" : "off"}
        //allowScrollButtonsMobile
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          },
        }}
        className="tabsWrapper"
        value={activeTab}
        onChange={handleTabChange}
        aria-label="message center status"
        TabIndicatorProps={{
          sx: {
            display: 'none',
            top: 0,
            height: 3,
          },
        }}
      >
        {queuesData.map((menu, index) => (
          <Tab
            iconPosition="start"
            label={menu.queueName}
            {...a11yProps(0)}
            onClick={() => changeTab(menu)}
            key={index}
          />
        ))}
      </Tabs>
    </Box>
  );
}
export default React.memo(ResolveXQueues);
