import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
// core components
import SayaSwitch from "../../components/Switch/CustomSwitch";
import SayaCombo from "../../components/CustomComboBox/SAYAComboBox";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import {
  showAlertNotification,
  showFailureNotification,
  showSuccessNotification,
} from "../../action-handlers/NotificationsHandler";
import CardBody from "../../components/Card/CardBody";
import {
  uploadOCRFile,
  uploadFile,
} from "../../action-handlers/DataIngestionActionHandler";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { USER_SESSION_TIME_OUT } from "../../messages/DashboardMessages";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";

import {
  supportedDataTypes,
  supportedLanguages,
} from "../../variables/general";
import FileDragDrop from "../../components/CustomSayaComponents/FileDragDrop";
import {
  defaultFont,
  container,
  primaryColor,
  grayColor,
  whiteColor,
} from "../../assets/jss/material-dashboard-react";

import { CheckAccess } from "../../components/Permissions/CheckAccess";


const styles = (theme) => {
  return {
    typo: {
      paddingLeft: "25%",
      marginBottom: "40px",
      position: "relative",
    },
    note: {
      bottom: "10px",
      color: "#c0c1c2",
      display: "block",
      fontWeight: "400",
      fontSize: "13px",
      lineHeight: "13px",
      left: "0",
      marginLeft: "20px",
      position: "absolute",
      width: "260px",
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      marginBottom: "3px",
      textDecoration: "none",
    },
    textAreaCls: {
      width: "100%",
      borderRadius: "4px",
      outline: "none",
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.card,
      border: `1px solid ${grayColor[4]}`,
      marginBottom: "20px",
      padding: "10px 10px 10px 10px",
      height: "125px",
      boxSizing: "border-box",
      fontFamily: "Poppins",
    },
  };
};

const useStyles = makeStyles(styles);

export default function DataIngestion(props) {
  const classes = useStyles();
  const [selectedDataType, setSelectedDataType] = useState(1);
  const [dataTypeDropDownData, setDataTypeDropDown] =
    useState(supportedDataTypes);
  const [selectedLanguage, setSelectedLanguage] = useState("eng");
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [languageDropDownData, setLanguageDropDownData] =
    useState(supportedLanguages);
  const [autoLearningValue, setAutoLearningValue] = useState(true);
  const [fileFormat, setFileFormat] = useState("image/*");
  const [dragDropText, setDragDropText] = useState(
    "Drag'n'drop images, or click to select files"
  );
  const [acceptedFileNames, setAcceptedFileNames] = useState([]);
  const [rejectedFileNames, setRejectedFileNames] = useState([]);
  const [extractedText, setExtractedText] = useState("");
  const [modifiedText, setModifiedText] = useState("");
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const minSize = 256;
  const maxSize = 1024000;
  var fileExtractedData = "";

  const handleLanguageChange = (e) => {
    
    setSelectedLanguage(e.target.value);
  };

  const handleDataTypeChange = (e) => {
    
    setSelectedDataType(e.target.value);
    let i = 0;
    for (i = 0; i < supportedDataTypes.length; i++) {
      
      if (supportedDataTypes[i].value === Number(e.target.value)) {
        setFileFormat(supportedDataTypes[i].acceptFormat);
        setDragDropText(supportedDataTypes[i].dropZoneText);
        i = supportedDataTypes.length + 1; //for exiting out of the loop
      }
    }
  };

  const handleAutoLearnToggle = () => {
    
    setAutoLearningValue(!autoLearningValue);
  };

  const handleDropFiles = (acceptedFiles, rejectedFiles, DropEvent) => {
    
    setRejectedFileNames(
      rejectedFiles.map((fileObj) =>
        String(fileObj.file.name).concat(" - " + fileObj.errors[0].message)
      )
    );
    if (Number(selectedDataType) === 1) {
      ///this means it is images so OCR file API to be called
      handleDropImageFiles(acceptedFiles);
    } else {
      handleIngestionFiles(acceptedFiles);
    }
  };

  useEffect(() => {
    
    //Validating if the usersession is still active with right token
    let userId = UserAuthContext.state.userId; ////"6";//TODO to set from logged iin user serssion
    if (
      UserAuthContext.state.userId === undefined ||
      UserAuthContext.state.userId === 0
    ) {
      //this means refresh or session of browser got refreshed. So lets fetch the user details from Database
      /// for doing lets get the loginName from sessionStorage see if it is available, try fetching the details
     
      if (
        UserAuthContext.state.loginName !== undefined ||
        UserAuthContext.state.loginName !== ""
      ) {
        //this means user had logged in earlier try fetching from sessionStorage loginName
        fetchUserDetailsByLogin(
          UserAuthContext.state.loginName,
          callBackFetchUserDetailsByLogin
        );
      }
    }

    return () => {
      console.log(
        "Component ResolveXXWorkSpace unloading cleanup to be done here!!!"
      );
    };
  }, []);

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    
    if (apiStatus && userDetailsState.userId !== 0) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      window.location.assign("/");
    }
  };

  const callbackLoadFile = (apiStatus) => {
   
    if (apiStatus) {
      showSuccessNotification(
        "Successfully uploaded the file(s) into SAYA Datalake! They will be processed as per scheduled workflows.",
        callbackNotification
      );
    } else {
      showFailureNotification(
        "Failed to upload the files to SAYA Datalake!!!",
        callbackNotification
      );
    }
  };

  const handleCloseNotification = () => {
    
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const handleIngestionFiles = (acceptedFiles) => {
    
    let fileNames = [];
    acceptedFiles.map((fileObj) => {
      
      fileNames.push(fileObj.name);
      //now call the OCR API one by one from the accepted list by sending the file Object

      uploadFile(fileObj, callbackLoadFile);
    });
    setAcceptedFileNames(fileNames);
  };

  const loadFile = (e) => {
   
    //setFileObject(e.target.files[0]);
    uploadOCRFile(e.target.files[0], selectedLanguage, callbackLoadOCRFile);
  };

  const handleDropImageFiles = (acceptedFiles) => {
    
    //setAcceptedFileNames(acceptedFiles.map((fileObj) => fileObj.name));
    setExtractedText(""); //this is resetting the data before we move for extracting
    setModifiedText("");
    fileExtractedData = "";
    let fileNames = [];
    acceptedFiles.map((fileObj) => {
      
      fileNames.push(fileObj.name);
      //now call the OCR API one by one from the accepted list by sending the file Object

      uploadOCRFile(fileObj, selectedLanguage, callbackLoadOCRFile);
    });
    setAcceptedFileNames(fileNames);
  };

  const callbackNotification = (notificationObj) => {
    
    setNotificationObj(notificationObj);
  };

  const callbackLoadOCRFile = (fileName, extractedOCRText, apiStatus) => {
    fileExtractedData = fileExtractedData + fileName + "\n";
    fileExtractedData =
      fileExtractedData + "==================================\n";
   
    fileExtractedData = fileExtractedData + String(extractedOCRText);
    
    if (apiStatus) {
      setExtractedText(fileExtractedData);
      setModifiedText(fileExtractedData);
      showSuccessNotification(
        fileName + " - Processed successfully by SAYA OCR Engine!",
        callbackNotification
      );
    } else {
      setExtractedText("");
      setModifiedText("");
      showFailureNotification(
        fileName +
          " - Failed due to insufficient training data for extracting the TEXT!!!",
        callbackNotification
      );
    }
  };

  const handleTextChange = (e) => {
    setModifiedText(e.target.value);
  };
  return (
    <Card style={{ padding: 20 }}>
      <CardHeader color="primary" plain>
        <GridContainer alignItems="center">
          <GridItem xs={6} sm={4} md={3}>
            <SayaCombo
              optionsData={dataTypeDropDownData}
              value={selectedDataType}
              defaultValue={selectedDataType}
              labelText="Data Type"
              groupText="Selected DataType"
              handleChange={handleDataTypeChange}
              className="Select"
              height="20px"
              headerCombo
            />
          </GridItem>
          <GridItem xs={6} sm={4} md={3}>
            <SayaCombo
              optionsData={languageDropDownData}
              value={selectedLanguage}
              defaultValue={selectedDataType}
              labelText="Languages"
              groupText="Selected Language"
              handleChange={handleLanguageChange}
              className="Select"
              height="30px"
              headerCombo
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <SayaSwitch
                value={autoLearningValue}
                id="auto-learn"
                handleToggle={handleAutoLearnToggle}
                labelText={"Auto Learning Enabled"}
                isDisabled={false}
                headerSwitch
              />
            </Box>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <CheckAccess {...props} action={"data upload"}>
          <FileDragDrop
            handleDrop={handleDropFiles}
            formatType={fileFormat}
            minSize={minSize}
            maxSize={maxSize}
            dragDropTextMessage={dragDropText}
            loadFile={loadFile}
          ></FileDragDrop>
        </CheckAccess>
        <div xs={12} sm={6} md={6}>
          <textarea
            className={classes.textAreaCls}
            readOnly
            value={extractedText}
            placeholder="OCR Extracted Message"
          />{" "}
          <br />
        </div>

        <div xs={12} sm={6} md={6}>
          <textarea
            className={classes.textAreaCls}
            value={modifiedText}
            onChange={handleTextChange}
            placeholder="OCR Modified Message"
          />{" "}
          <br />
        </div>
        <GridItem xs={12} sm={6} md={6}>
          <strong>Accepted Files:</strong>
          <ul>
            {acceptedFileNames.map((fileName) => (
              <li key={fileName}>{fileName}</li>
            ))}
          </ul>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <strong>Rejected Files:</strong>
          <ul>
            {rejectedFileNames.map((fileName) => (
              <li key={fileName}>{fileName}</li>
            ))}
          </ul>
        </GridItem>
      </CardBody>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </Card>
  );
}
