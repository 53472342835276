import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
//import { Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  MenuItem,
} from "@mui/material";

//import Button from "../../components/CustomButtons/Button";
import Button from "../../components/CustomButtons/Button";

// core components
import { hexToRgb } from "../../assets/jss/material-dashboard-react";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { signUpValidationsForm } from "../../utils/validationSchema";
import {
  fetchUserDetailsByLogin,
  createUser,
  changeEmail,
} from "../../action-handlers/LoginActionsHandler.js";
import { getOrganizationsbyUserId } from "../../action-handlers/OrganizationHandler";
import { getDepartmentIdValues } from "../../action-handlers/DepartmentHandler";
import { getRoleIdValues } from "../../action-handlers/RoleHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";

import { useTheme } from "@mui/material/styles";

const styles = () => {
  const theme = useTheme();
  return {
    card: {
      maxWidth: 800,
      marginTop: 0,
      marginBottom: 80,
      position: "relative",
    },
    container: {
      display: "Flex",
      justifyContent: "center",
    },
    actions: {
      float: "right",
      paddingRight: "15px",
      paddingBottom: "15px",
    },
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      marginBottom: "0",
      textDecoration: "none",
    },
    formBg: {
      background: theme.palette.grey[500_12],
      borderRadius: "5px",
      padding: "20px",
    },
    loginBtnCls: {
      background: "#1F2540 0% 0% no-repeat padding-box",
      color: "#fff",
      borderRadius: "6px",
      padding: "10px 50px",
      cursor: "pointer",
      marginTop: "40px",
      marginRight: "10px",
    },
    inputCls: {
      width: "100%",
      borderRadius: "4px",
      outline: "none",
      border: "1px solid #ccc",
      marginBottom: "20px",
      height: "50px",
      padding: "10px 0px 10px 5px",
      fontFamily: "inherit",
    },
    errors: {
      color: "red",
      position: "relative",
      bottom: "16px",
    },
    comboBox: {
      width: "100%",
    },
    formControl: {
      marginBottom: 20,
    },
    wrapper: {
      background: "rgba(" + hexToRgb(theme.palette.background.card) + ", 0.6)",
    },
    loaderImg: {
      position: "absolute",
      top: "30%;",
      zIndex: "9",
      left: "47%",
    },
    disableForm: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    selectField: {
      "& > div > div": {
        color: theme.palette.text.primary,
      },
    },
  };
};

const useStyles = makeStyles(styles);

const SignUpPage = () => {
  const classes = useStyles();
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const [userData, setUserData] = useState("");
  const [orgnizationList, setOrgList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [roleList, setRoleList] = useState("");
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [loaderState, setLoaderState] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { loginName } = UserAuthContext.state;
    if (loginName) {
      fetchUserDetailsByLogin(loginName, fetchUserDetails);
    }
  }, [UserAuthContext.state]);

  useEffect(() => {
    if (userData) {
      getDepartmentIdValues(userData.userId, fetchDepartmentList);

      getRoleIdValues(userData.userId, fetchRoleList);

      getOrganizationsbyUserId(userData.userId, fetchOrgList);
    }
  }, [userData]);
  const fetchUserDetails = (resObj) => {
    setUserData(resObj);
  };

  const fetchDepartmentList = (departmentData) => {
    setDepartmentList(departmentData);
  };

  const fetchRoleList = (roleData) => {
    setRoleList(roleData);
  };

  const fetchOrgList = (orgData) => {
    setOrgList(orgData);
  };

  const createUserHandler = (userObj) => {
    let payload;
    let userParamObj = userObj;
    setLoaderState(true);
    if (location.state !== null) {
      // userParamObj.userId = JSON.parse(location.state.userItem).userId;
      payload = { ...JSON.parse(location.state.userItem), ...userParamObj };
      delete payload.mstDepartment;
      delete payload.mstOrganisation;
      delete payload.mstRole;
    } else {
      payload = userParamObj;
    }

    try {
      const oldEmail = JSON.parse(location.state.userItem)?.email;
      if (oldEmail !== userObj.email) {
        changeEmail(payload.loginName, userObj.email);
      }
    } catch (ex) {
      console.log("Exception parsing data", ex);
    }
    createUser(payload, createUserCallback);
  };

  const createUserCallback = (response) => {
    setLoaderState(false);
    if (response) {
      nofifyHandler("User Created Successfully");
      setTimeout(() => {
        navigate(-1);
      }, 500);
      //getDepartmentList(userDetails);
    } else {
      nofifyHandler(
        "Unable to create User, please contact sales@3cortex.com !",
        "danger"
      );
    }
  };

  const nofifyHandler = (msg, colorVal = "success") => {
    setNotificationObj({
      ...notificationParams,
      message: msg,
      color: colorVal,
      open: true,
    });
    setTimeout(function () {
      setNotificationObj({
        ...notificationParams,
        color: colorVal,
        open: false,
      });
    }, 2000);
  };

  const handleCloseNotification = (event) => {
    setNotificationObj({
      ...notificationParams,
      open: false,
    });
  };

  let initialValues = {
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    organisationId: "",
    departmentId: "",
    roleId: "",
  };

  if (location.state !== null) {
    const userObj = JSON.parse(location.state?.userItem);
    initialValues = {
      userName: userObj.loginName,
      firstName: userObj.firstName,
      lastName: userObj.lastName,
      email: userObj.email,
      organisationId: userObj.mstOrganisation?.organisationId,
      departmentId: userObj.mstDepartment?.departmentId,
      roleId: userObj.mstRole?.roleId,
    };
  }

  return (
    <GridContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={signUpValidationsForm}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createUserHandler(values);
          setSubmitting(false);
          // resetForm();
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        }) =>
          orgnizationList &&
          orgnizationList.length > 0 &&
          departmentList &&
          departmentList.length > 0 &&
          roleList &&
          roleList.length > 0 && (
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.wrapper}>
                <CardHeader color="primary">
                  <h4>
                    {location.state !== null ? "Edit User" : "Register User"}
                  </h4>
                </CardHeader>

                <CardBody>
                  <div className={classes.container}>
                    <form onSubmit={handleSubmit}>
                      <Card
                        className={`${classes.card} ${
                          loaderState ? classes.disableForm : ""
                        }`}
                      >
                        <CardContent>
                          <GridContainer>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                id="userName"
                                label="Login Name"
                                value={values.userName}
                                onChange={handleChange}
                                className={classes.inputField}
                                onBlur={handleBlur}
                                helperText={
                                  touched.userName ? errors.userName : ""
                                }
                                error={
                                  touched.userName && Boolean(errors.userName)
                                }
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                disabled={location.state !== null}
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                id="email"
                                label="Email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.email ? errors.email : ""}
                                error={touched.email && Boolean(errors.email)}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                id="firstName"
                                label="First Name"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={
                                  touched.firstName ? errors.firstName : ""
                                }
                                error={
                                  touched.firstName && Boolean(errors.firstName)
                                }
                                margin="dense"
                                variant="outlined"
                                fullWidth
                              />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                id="lastName"
                                label="Last Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.lastName}
                                helperText={
                                  touched.lastName ? errors.lastName : ""
                                }
                                error={
                                  touched.lastName && Boolean(errors.lastName)
                                }
                                margin="dense"
                                variant="outlined"
                                fullWidth
                              />
                            </GridItem>

                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                select
                                id="organisationId"
                                label="Organization"
                                value={values.organisationId}
                                onChange={handleChange("organisationId")}
                                helperText={
                                  touched.organisationId
                                    ? errors.organisationId
                                    : ""
                                }
                                error={
                                  touched.organisationId &&
                                  Boolean(errors.organisationId)
                                }
                                margin="dense"
                                variant="outlined"
                                className={classes.selectField}
                                fullWidth
                              >
                                {orgnizationList &&
                                  orgnizationList.length > 0 &&
                                  orgnizationList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                select
                                id="departmentId"
                                label="Department"
                                value={values.departmentId}
                                onChange={handleChange("departmentId")}
                                helperText={
                                  touched.departmentId
                                    ? errors.departmentId
                                    : ""
                                }
                                error={
                                  touched.departmentId &&
                                  Boolean(errors.departmentId)
                                }
                                margin="dense"
                                variant="outlined"
                                className={classes.selectField}
                                fullWidth
                              >
                                {departmentList &&
                                  departmentList.length > 0 &&
                                  departmentList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                              <TextField
                                select
                                id="roleId"
                                label="Role"
                                value={values.roleId}
                                onChange={handleChange("roleId")}
                                helperText={touched.roleId ? errors.roleId : ""}
                                error={touched.roleId && Boolean(errors.roleId)}
                                margin="dense"
                                variant="outlined"
                                className={classes.selectField}
                                fullWidth
                              >
                                {roleList &&
                                  roleList.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.value}
                                    </MenuItem>
                                  ))}
                              </TextField>
                            </GridItem>
                          </GridContainer>
                        </CardContent>
                        <CardActions className={classes.actions}>
                          {loaderState && (
                            <CircularProgress className={classes.loaderImg} />
                          )}

                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting}
                          >
                            SUBMIT
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleReset}
                          >
                            CLEAR
                          </Button>
                        </CardActions>
                      </Card>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </GridItem>
          )
        }
      </Formik>
      {notificationObj && (
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
          autoHideDuration={3000}
        />
      )}
    </GridContainer>
  );
};

export default SignUpPage;
