import { API, FlowAPI, ReconResolveAPI, SAYAFLOW } from "URL.js";
import axios from "axios";

export const fetchResolveXActions = (userId, orgId, roleId, callback) => {
  let fetchedResolveXActionsConfig = [];

  axios
    .get(
      ReconResolveAPI +
        "/flowaction/fetchresolveactionsconfig?userId=" +
        userId +
        "&orgId=" +
        orgId +
        "&roleId=" +
        roleId
    )
    .then((response) => {
      response.data.forEach((item) => {
        fetchedResolveXActionsConfig.push({
          resolvexActionId: item.resolvexActionId,
          resolvexActionName: item.resolvexActionName,
          resolvexActionUri: item.resolvexActionUri,
        });
      });
      callback(fetchedResolveXActionsConfig, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(fetchedResolveXActionsConfig, false);
    });
};

export const resolveFlowItem = (resolveObject, callback) => {
  axios
    .post(FlowAPI + "/flowapi/resolve", resolveObject)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const triggerFollowupAction = (emailFollowupObj, callback) => {
  axios
    .post(`${ReconResolveAPI}/flowaction/followup`, emailFollowupObj)
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(false);
    });
};

export const fetchResolveXQueues = (userId, callback) => {
  let fetchResolveXQueues = [];

  axios
    .get(ReconResolveAPI + "/flowquery/fetchflowunits/byuser/" + userId)
    .then((response) => {
      response.data.forEach((item) => {
        fetchResolveXQueues.push({
          value: item.flowDefnKeyName,
          label: item.flowDefnName,
          isdisabled: false,
        });
      });
      callback(fetchResolveXQueues, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(fetchResolveXQueues, false);
    });
};

export const fetchLoadAuditData = (resolveId, resolveQueue, callback) => {
  // let tableDataRows = [];
  axios
    .get(
      `${SAYAFLOW}/ssfl/fetchwfauditinfo?entityPkey=${resolveId}&wfProcessId=${resolveQueue}`
    )
    .then((response) => {
      if (response.status === 200) {
        // response.data.forEach((data) => {
        //   let obj = [];
        //   obj.push(data.userName);
        //   obj.push(data.actionName);
        //   obj.push(data.queueName);
        //   obj.push(data.wfAuditId);
        //   obj.push(data.userNotes);
        //   obj.push(data.createdDate);
        //   tableDataRows.push(obj);
        // });
      }
      callback(resolveId, resolveQueue, response.data, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(
        resolveId,
        resolveItemStatus,
        resolveQueue,
        tableDataRows,
        false
      );
    });
};

export const fetchFormData = (
  resolveId,
  reconUnitId,
  reconUnitName,
  callback
) => {
  let formObject = {};
  axios
    .get(
      API +
        "/reconquery/flowunitbyid?rawDataId=" +
        resolveId +
        "&reconUnitId=" +
        reconUnitId
    )
    .then((response) => {
      if (response.status === 200) {
        formObject = response.data;
      }
      callback(resolveId, reconUnitId, reconUnitName, formObject, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(resolveId, reconUnitId, reconUnitName, formObject, false);
    });
};

export const fetchResolveRecords = (
  resolveQueue,
  userDepartment,
  setData,
  callback
) => {
  let resolveData = [];

  axios
    .get(
      FlowAPI +
        "/flowapi/" +
        setData +
        "?assignedDepartment=" +
        userDepartment +
        "&flowDefnKeyName=" +
        resolveQueue
    )
    .then((response) => {
      response.data.forEach((data) => {
        let obj = [];
        obj.push(data.flowDataId);
        obj.push(data.reconUnitId);
        obj.push(data.reconUnitName);
        obj.push(data.assignedDepartment);
        obj.push(data.flowDataDescription);
        obj.push(data.createdBy);
        obj.push(data.createdDate);
        resolveData.push(obj);
      });
      callback(resolveData, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(resolveData, false);
    });
};

export const fetchWorkflowQueues = (roleId, wfProcessId, callback) => {
  axios
    .get(
      `${SAYAFLOW}/config/fetchworkflowqueues?roleId=${roleId}&wfProcessId=${wfProcessId}`
    )
    .then((response) => {
      callback(response.data, true);
    })
    .catch((error) => {
      console.log("Exception occured while fetching Queues::{}", error);
      callback([], false);
    });
};

export const getResolveCount = (params = {}, callback) => {
  axios
    .post(
      `${API}/reconquery/fetchResolveCount?reconUnitId=${
        params.selectedReconUnit
      }&dataSrcConfigId=${params.activeDSTab + 1}&wfProcessId=${
        params.selectedResolveQueue
      }&queueId=${params.queueId}&roleId=${params.roleId}&departmentId=${
        params.departmentId
      }&loggedInUser=${params.userId}`,
      { filterColumnsDTOList: [] }
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data?.totalRows || 0);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(0);
    });
};

export const getResolveData = async (params = {}, callback) => {
  const rowsPerPage = params.pageSize || 100;
  const response = await axios.post(
    `${API}/reconquery/fetchResolveData?reconUnitId=${
      params.selectedReconUnit
    }&dataSrcConfigId=${params.activeDSTab + 1}&wfProcessId=${
      params.selectedResolveQueue
    }&queueId=${params.queueId}&roleId=${params.roleId}&departmentId=${
      params.departmentId
    }&loggedInUser=${params.userId}&pageNum=${
      params.pageParam || 0
    }&rowsPerPage=${rowsPerPage}`,
    { filterColumnsDTOList: [] }
  );
  return response.data;
};

export const getResolveWorkItemData = (
  roleId,
  wfProcessId,
  queueId,
  loggedInUserId,
  departmentId,
  reconUnitId,
  rawDataId,
  callback
) => {
  axios
    .get(
      `${API}/resolvequery/getResolveWorkItemData?roleId=${roleId}&wfProcessId=${wfProcessId}&queueId=${queueId}&loggedInUserId=${loggedInUserId}&departmentId=${departmentId}&reconUnitId=${reconUnitId}&rawDataId=${rawDataId}`
    )
    .then((response) => {
      callback(response.data, true);
    })
    .catch((error) => {
      console.log(
        "Exception occured while fetching getResolveWorkItemData::{}",
        error
      );
      callback([], false);
    });
};

export const fetchQueueActions = (roleId, wfProcessId, queueId, callback) => {
  axios
    .get(
      `${SAYAFLOW}/config/fetchqueueactions?wfProcessId=${wfProcessId}&queueId=${queueId}&roleId=${roleId}`
    )
    .then((response) => {
      callback(response.data, true);
    })
    .catch((error) => {
      console.log("Exception occured while fetching Queue Actions::{}", error);
      callback([], false);
    });
};

export const triggerDoAction = (payload, callback) => {
  axios
    .post(`${API}/recon/doaction`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback(response.data, false);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback({}, false);
    });
};

export const fetchWordflowFormData = (
  reconUnitId,
  dataSrcConfigId,
  callback
) => {
  let formObject = {};
  axios
    .get(API + "/recon/header/" + reconUnitId + "/" + dataSrcConfigId)
    .then((response) => {
      if (response.status === 200) {
        formObject = response.data;
      }

      callback(formObject, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(rawDataId, formObject, false);
    });
};

export const saveTxnDataApi = (formObj, callback) => {
  let formObject = {};
  axios
    .post(API + "/recon/saveTxnData", formObj)
    .then((response) => {
      if (response.status === 200) {
        formObject = response.data;
      }

      callback(formObject, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(rawDataId, formObject, false);
    });
};

export const fetchReconProcessesByWorkflow = (
  wfProcessId,
  userId,
  callback
) => {
  let fetchedReconUnitData = [];
  let fetchedDropDownData = [];

  axios
    .get(`${API}/reconunits/bywfprocess/${wfProcessId}/${userId}`)
    .then((response) => {
      response.data.forEach((item) => {
        fetchedReconUnitData.push({
          reconUnitId: item.reconUnitId,
          clientReconName: item.clientReconName,
          identicalData: item.identicalData,
          headerData: item.headerData,
          workflowEnabled: item.workflowEnabled,
          reconEnabled: item.reconEnabled,
          multimatchReqd: item.multimatchReqd,
          oneToMany: item.oneToMany,
          manyToOne: item.manyToOne,
          multimatchAggrBuffer: item.multimatchAggrBuffer,
          dataSourceId1: item.dataSourceId1,
          dataSrcConfigId1: item.dataSrcConfigId1,
          dataSourceId2: item.dataSourceId2,
          dataSrcConfigId2: item.dataSrcConfigId2,
          createdBy: item.createdBy,
          createdDate: item.createdDate,
          mlEnabled: item.mlEnabled,
        });
        fetchedDropDownData.push({
          value: item.reconUnitId,
          label: item.clientReconName,
          isdisabled: false,
          dataSrcConfigId1: item.dataSrcConfigId1,
          dataSrcConfigId2: item.dataSrcConfigId2,
          ...item,
        });
      });
      callback(userId, fetchedReconUnitData, fetchedDropDownData, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(userId, fetchedReconUnitData, fetchedDropDownData, false);
    });
};

export const saveFormLayout = (recUnitId, fromType, callback) => {
  let resbject = {};
  axios
    .post(
      `${API}/reconunits/updFormLayout?reconUnitId=${recUnitId}&formLayout=${fromType}`
    )
    .then((response) => {
      if (response.status === 200) {
        resbject = response.data;
      }

      callback(resbject, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(resbject, false);
    });
};
