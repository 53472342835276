import React, { useState, useEffect, useContext } from "react";
import { Box, Button, Tabs, Tab, Typography, Collapse } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  AddLinkOutlined,
  LayersClearOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import CardBody from "components/Card/CardBody";
import DynamicFormClass from "components/CustomSayaComponents/DynamicFormClass";
import { CheckAccess } from "components/Permissions/CheckAccess";
import SAYATable from "../../components/Table/MuiDataTable";
import SuggestMatch from "../../components/CustomDialog/SayaDialogSuggestMatchRules";
import { ReconXContext } from "context/ReconX/ReconXContext";
import styles from "./ReconStyles";
import { fetchRuleDTO } from "action-handlers/ReconXActionHandler";
import { fetchSuggestedMatches } from "action-handlers/ReconXActionHandler";
import { fetchMetaDataSrc } from "action-handlers/ReconXActionHandler";
import { showFailureNotification } from "action-handlers/NotificationsHandler";
import { API_RECON_QUERY_FAILED } from "messages/DashboardMessages";
import { API_FETCH_RULEDTO_FAILURE } from "messages/DashboardMessages";
import { notificationParams } from "variables/general";
import Snackbar from "components/Snackbar/Snackbar";
import AuditInfo from "./AuditInfo";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`recon-match-tabpanel-${index}`}
      aria-labelledby={`recon-match-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `recon-match-tab-${index}`,
    "aria-controls": `recon-match-tabpanel-${index}`,
  };
}

function ReconXMatches(props) {
  let linkOptions = {
    filterType: "dropdown",
    selectableRows: "none", //single |multiple| none
    rowsPerPage: 100,
    page: 0,
    filter: false,
    rowsPerPageOptions: [100, 500, 1000],
    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (evt) => {
        props.handleRowDoubleClick(row);
      },
    }),
  };

  console.log("props", props);

  const [showSuggestMatches, setShowSuggestMatches] = useState(false);
  const [labelledRuleDTO, setLabelledRuleDTO] = useState([]);
  const [linkTableOptions, setLinkTableOptions] = useState(linkOptions);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [value, setValue] = React.useState(0);
  const [isRuleDTOLoading, setRuleDTOLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const ReconXContextData = useContext(ReconXContext);

  const {
    selectedReconUnit,
    ruleUnlabelledDTO,
    selectedRecData,
    globalReconSuggestTableData: [],
    globalMetaDataSrcColNames,
    globalDataSrcData,
    globalMetaDataSrcColIds,
    suggestedMatchesFinal,
    dataSrcConfigId,
  } = ReconXContextData.state;

  const {
    rowDetailFormObject,
    // linkTableOptions = {},
    // suggestOptions = {},
    reconItemLabel,
    tableLinksColumns,
    tableLinksData,
    setTableLinksColumns,
    setTableLinksData,
    validateDataSourceMetaData,
    tableLinksData2,
    setTableLinksData2,
    tableLinksColumns2,
    auditData,
    formToggle,
    canFetchSuggestedMatches,
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    setLabelledRuleDTO([]);
  }, [canFetchSuggestedMatches]);

  const handleSuggestMatchSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    let globalReconSuggestTableData =
      ReconXContextData.state.globalReconSuggestTableData;
    let suggestedMatches = [];

    suggestedMatches[0] = selectedRecData; //this is to take care of the selected record on the form initially we populate
    //as we are going to transform every suggested matches records to selectedRecData as it is going to be overrided

    if (rowsSelected.length > 0) {
      //since the muitable data events is not able to access the state objects of current component residing on the globalData set
      rowsSelected.forEach((selectedItemIndex) => {
        const parsedSuggestedRowsData = {
          reconUnitId: undefined,
          dataSrcConfigId: undefined,
          reconLinkId: undefined,
          rawDataId: undefined,
          reconStatusId: undefined,
        };
        parsedSuggestedRowsData.reconUnitId =
          globalReconSuggestTableData[selectedItemIndex].reconUnitId;
        parsedSuggestedRowsData.dataSrcConfigId =
          globalReconSuggestTableData[selectedItemIndex].dataSrcConfigId;
        parsedSuggestedRowsData.reconLinkId =
          globalReconSuggestTableData[selectedItemIndex].reconLinkId;
        parsedSuggestedRowsData.rawDataId =
          globalReconSuggestTableData[selectedItemIndex].rawDataId;
        parsedSuggestedRowsData.reconStatusId =
          globalReconSuggestTableData[selectedItemIndex].reconStatusId;

        suggestedMatches.push(parsedSuggestedRowsData);
      });
      const payload = {
        suggestedMatchesFinal: suggestedMatches,
        globalReconSuggestTableData: globalReconSuggestTableData,
      };
      ReconXContextData.saveReconProcessesData(payload);
    }
  };

  const suggestOptions = {
    filterType: "dropdown",
    selectableRows: "multiple", //single |multiple| none
    rowsPerPage: 100,
    page: 0,
    print: false,
    filter: false,
    rowsPerPageOptions: [100, 500, 1000],
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      handleSuggestMatchSelection(
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      );
    },
  };

  const scrollTop = () => {
    //TODO this is not working soething to check later

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    window.scrollTop = 0;
  };

  const callbackFetchSuggestedMatches = (
    reconColumns,
    reconData,
    apiStatus
  ) => {
    if (apiStatus) {
      const payload = {
        globalReconSuggestTableData: reconData,
        globalReconColumns: reconColumns,
      };
      ReconXContextData.saveReconProcessesData(payload);
      setTableLinksData(reconData);
      setTableLinksColumns(reconColumns);
      linkOptions.count = reconData.length; //need to check
      setLinkTableOptions(linkOptions); //need to check
    } else {
      setTableLinksData([]);
      setTableLinksColumns([]);
      showFailureNotification(API_RECON_QUERY_FAILED, callbackNotification);
    }
    // setReconWorkSpaceLoading(false); //here we are not calling the select options to be enabled since it is in the form view
    scrollTop();
  };

  const handleRuleChange = (index, event, checked) => {
    let localRuleSetArray = [...labelledRuleDTO];
    localRuleSetArray[index].matchCriteria = event.target.checked;

    setLabelledRuleDTO(localRuleSetArray);

    let localunlabelledRuleSetArray = ruleUnlabelledDTO;
    localunlabelledRuleSetArray[index].matchCriteria = event.target.checked;
    const payload = {
      ruleUnlabelledDTO: localunlabelledRuleSetArray,
    };
    ReconXContextData.saveReconProcessesData(payload);
    // ruleUnlabelledDTO = localunlabelledRuleSetArray;

    fetchSuggestedMatches(
      selectedRecData.rawDataId,
      selectedRecData.reconUnitId,
      selectedRecData.dataSrcConfigId,
      localunlabelledRuleSetArray,
      callbackFetchSuggestedMatches
    );
  };

  const handleSuggestMatchesClose = () => {
    setShowSuggestMatches(false);
  };

  const populateLabelledRuleObject = (ruleDTO = []) => {
    const rule = ruleDTO.length > 0 ? ruleDTO : ruleUnlabelledDTO;
    let localRule = [];
    const gds = ReconXContextData.state.globalDataSrcData;
    for (let i = 0; i < rule.length; i++) {
      let localRuleObj = {};
      localRuleObj.columnIdSrc1 = rule[i].columnIdSrc1;
      localRuleObj.columnIdSrc2 = rule[i].columnIdSrc2;
      localRuleObj.cumPercMatchPerOneRec = rule[i].cumPercMatchPerOneRec;
      localRuleObj.hexScoreUse = rule[i].hexScoreUse;
      localRuleObj.matchCriteria = rule[i].matchCriteria;
      localRuleObj.percMatchPerOneRec = rule[i].percMatchPerOneRec;
      localRuleObj.percReconciled = rule[i].percReconciled;
      localRuleObj.ruleScore = rule[i].ruleScore;

      localRule[i] = localRuleObj;
    }

    //below blocak not executing. Need to check deepu
    localRule.forEach((item) => {
      for (let i = 0; i < globalMetaDataSrcColIds[gds[0].value].length; i++) {
        if (item.columnIdSrc1 === globalMetaDataSrcColIds[gds[0].value][i]) {
          item.columnIdSrc1 = globalMetaDataSrcColNames[gds[0].value][i];
          i = globalMetaDataSrcColIds[gds[0].value].length + 1; //this is done to break the loop immediately by setting i to array length
        }
      }
      for (let i = 0; i < globalMetaDataSrcColIds[gds[1].value].length; i++) {
        if (item.columnIdSrc2 === globalMetaDataSrcColIds[gds[1].value][i]) {
          item.columnIdSrc2 = globalMetaDataSrcColNames[gds[1].value][i];
          i = globalMetaDataSrcColIds[gds[1].value].length + 1; //this is done to break the loop immediately by setting i to array length
        }
      }
    });

    setLabelledRuleDTO(localRule);
  };

  const callbackFetchRuleDTO = (ruleDTO, apiStatus) => {
    const gds = ReconXContextData.state.globalDataSrcData;
    if (apiStatus) {
      const payload = {
        ruleUnlabelledDTO: ruleDTO,
      };
      console.log("==================>>>>>ruleUnlabelledDTO", ruleDTO);
      ReconXContextData.saveReconProcessesData(payload);

      //ensure we only call the rest API if the global MetaDataSrcColNames are null or without any elements
      if (
        globalMetaDataSrcColNames[gds[0].value] === undefined ||
        globalMetaDataSrcColNames[gds[0].value].length === 0
      ) {
        fetchMetaDataSrc(
          selectedReconUnit,
          gds[0].value,
          ruleDTO,
          callbackFetchMetaDataSrc
        );
      } else {
        populateLabelledRuleObject(ruleDTO); ///TEST!!!!-done
      }
      if (
        globalMetaDataSrcColNames[gds[1].value] === undefined ||
        globalMetaDataSrcColNames[gds[1].value].length === 0
      ) {
        fetchMetaDataSrc(
          selectedReconUnit,
          gds[1].value,
          ruleDTO,
          callbackFetchMetaDataSrc
        );
      } else {
        populateLabelledRuleObject(ruleDTO); ///TEST!!!!-done
      }

      setRuleDTOLoading(false);
    } else {
      setRuleDTOLoading(false);
      showFailureNotification(API_FETCH_RULEDTO_FAILURE, callbackNotification);
    }
  };

  const callbackFetchMetaDataSrc = (
    metaDataSrcColNames,
    metaDataSrcColIds,
    dataSourceValue,
    selectedReconUnit,
    ruleDTO,
    apiStatus
  ) => {
    globalMetaDataSrcColNames[dataSourceValue] = metaDataSrcColNames;
    globalMetaDataSrcColIds[dataSourceValue] = metaDataSrcColIds;

    const payload = {
      globalMetaDataSrcColNames,
    };
    ReconXContextData.saveReconProcessesData(payload);
    if (
      validateDataSourceMetaData(
        globalMetaDataSrcColNames,
        globalMetaDataSrcColIds
      )
    ) {
      //here we need to populate the ruleDTO which is having the business Names instead of technical columns in the DTO
      populateLabelledRuleObject(ruleDTO);
    } else {
      console.log("Validation meta datasource populated:::-FAILED");
    }
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleShowMatches = () => {
    if (ruleUnlabelledDTO === undefined || ruleUnlabelledDTO.length >= 0) {
      setRuleDTOLoading(true);
      fetchRuleDTO(selectedReconUnit, callbackFetchRuleDTO);
    } else {
      populateLabelledRuleObject();
    }
    setShowSuggestMatches(!showSuggestMatches); //shows the popup based on setting of this prop or state object for suggested match rule
  };

  return (
    <CardBody style={{ paddingTop: 0, minHeight: 450 }}>
      <div className={classes.reconMatchWrapper}>
        <Collapse in={formToggle}>
          <Box
            className={classes.reconMatchTabs}
            sx={{ width: "100%", marginBottom: "16px" }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="recon match tabs"
              >
                <Tab label="Record Details" {...a11yProps(0)} />
                {auditData?.length > 0 && (
                  <Tab label="Audit Info" {...a11yProps(1)} />
                )}
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div
                className={classes.dynamicForm}
                style={{ marginBottom: auditData?.length > 0 ? 0 : 20 }}
              >
                <DynamicFormClass dataObj={rowDetailFormObject} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {auditData?.length > 0 && (
                <div style={{}} className={classes.auditWrapper}>
                  <div>
                    <AuditInfo {...{ auditData }} />
                  </div>
                </div>
              )}
            </TabPanel>
          </Box>
        </Collapse>

        <Box
          justifyContent={`${
            reconItemLabel === "Suggested Matches" ? "center" : "left"
          }`}
          alignItems="center"
          mb={2}
        >
          <CheckAccess {...props} action={"suggest matches"}>
            {reconItemLabel === "Suggested Matches" ? (
              <div className={classes.matchingHeader}>
                <h4
                  style={{
                    fontSize: "16px",
                    margin: 0,
                    padding: "0px 0 0 0px",
                    fontWeight: "600",
                    display: "flex",
                  }}
                >
                  <span style={{ marginLeft: "0px" }}>
                    {reconItemLabel} - Click Link button [
                    <b
                      style={{
                        position: "relative",
                        top: "5px",
                      }}
                    >
                      <AddLinkOutlined />
                    </b>
                    ] to manual match
                  </span>
                </h4>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    if (reconItemLabel === "Suggested Matches") {
                      handleShowMatches();
                    }
                  }}
                  style={{ marginTop: 0 }}
                >
                  <TuneOutlined sx={{ mr: 1, fontSize: "18px" }} />
                  {reconItemLabel}
                </Button>
              </div>
            ) : (
              <h4
                style={{
                  fontSize: "16px",
                  margin: 0,
                  padding: "0px 0 0 0px",
                  fontWeight: "600",
                  display: "flex",
                }}
              >
                {/* <AddLinkOutlined /> */}
                <span style={{ marginLeft: "0px" }}>
                  {reconItemLabel} - Click Delink [
                  <b
                    style={{
                      position: "relative",
                      top: "5px",
                      zoom: "0.8",
                    }}
                  >
                    <LayersClearOutlined />
                  </b>
                  ] button to unmatch
                </span>
              </h4>
            )}
          </CheckAccess>
        </Box>
        {reconItemLabel !== "Suggested Matches" ? (
          <div className={classes.linkTableWrapper}>
            <div style={{ marginBottom: "20px" }}>
              <SAYATable
                title={`${globalDataSrcData[0]?.label}`}
                data={tableLinksData}
                columns={tableLinksColumns}
                options={linkTableOptions}
              />
            </div>
            <SAYATable
              title={`${globalDataSrcData[1]?.label}`}
              data={tableLinksData2}
              columns={tableLinksColumns2}
              options={linkTableOptions}
            />
          </div>
        ) : (
          <div className={classes.suggestedMatchTable}>
            <SAYATable
              title={``}
              data={tableLinksData}
              columns={tableLinksColumns}
              options={suggestOptions}
            />
          </div>
        )}
        <SuggestMatch
          open={showSuggestMatches}
          dialogTitle={"Modify Rule"}
          labelledRuleDTO={labelledRuleDTO}
          handleRuleChange={handleRuleChange}
          handleClose={handleSuggestMatchesClose}
          loadingState={isRuleDTOLoading}
        />
      </div>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message}
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </CardBody>
  );
}

export default React.memo(ReconXMatches);
