import { API, FlowAPI, ReconResolveAPI, SAYAFLOW } from "URL.js";
import axios from "axios";

export const getUIComponents = (lookupName, callback) => {
  axios
    .get(`${API}/lookup/getUIComponentsForDataType?lookupName=${lookupName}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback(response.data || [], false);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback([], false);
    });
};

export const saveMetaDataConfig = (payload, callback) => {
  axios
    .post(`${API}/recon/saveMetaDataConfig`, payload)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback(response.data || [], false);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback([], false);
    });
};

export const fetchMetaConfig = (metaDataId, callback) => {
  axios
    .get(`${API}/recon/fetchMetaConfig?metaDataId=${metaDataId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback(response.data || [], false);
      }
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback([], false);
    });
};

// export const fetchMetaConfig = (metaDataId, dataSrcConfigId, callback) => {
//   axios
//     .get(
//       `${API}/recon/metaUIConfig?reconUnitId=${reconUnitId}&dataSrcConfigId=${dataSrcConfigId}`
//     )
//     .then((response) => {
//       if (response.status === 200) {
//         callback(response.data, true);
//       } else {
//         callback(response.data || [], false);
//       }
//     })
//     .catch((error) => {
//       console.log("Exception occured::{}", error);
//       callback([], false);
//     });
// };
