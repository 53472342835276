const initLoginFormState = {
  input: {
    userName: "",
    password: ""
  },
  validationErrs: {
    userName: "",
    password: ""
  },
  isSubmiting: false
};

const initConfirmPasswordState = {
  input: {
    userName: "",
    confirmationCode: "",
    password: ""
  },
  validationErrs: {
    userName: "",
    confirmationCode: "",
    password: ""
  },
  isSubmiting: false
};


const userLoginDetails = {
    isLoggedIn: false,
    userName: "",
    password: "",
    userAuthToken: "",
    isSuccess: false,
    errorMessage: "",
    challengeResult: ""
  }

const forgotPasswordDetails = {
  isSuccess: false,
  userName: "",
  errorMessage: ""
}

var userDetailsInitialState = {
  isLoggedIn: false,
  userId: undefined,
  userEmail: "",
  loginName: sessionStorage.getItem("loginName"),
  firstName:"",
  lastName:"",
  userToken: sessionStorage.getItem("token"),
  departmentId:undefined,
  departmentName:"",
  clientOrgId:0,
  parentClientOrgId:0,
  clientShortName:"",
  roleId:"",
  errors:""
}

module.exports = {
    userLoginDetails,
    initLoginFormState,
    userDetailsInitialState,
    forgotPasswordDetails,
    initConfirmPasswordState
}