import React from "react";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';

import PropTypes from "prop-types";
// @mui/icons-material

import Check from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

//core components
import styles from "../../assets/jss/material-dashboard-react/checkboxAdnRadioStyle";

const useStyles = makeStyles(styles);

export default function CustomCheckBox(props) {
  const classes = useStyles();

  const { labelText, id, handleToggle, value } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          tabIndex={-1}
          onClick={handleToggle}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          id={id}
          color="primary"
          checked={value}
          //checked = {state.input[id]}
          classes={{
            checked: classes.checked,
          }}
        />
      }
      label={
        <Typography style={styles.formControlLabel}>{labelText}</Typography>
      }
    />
  );
}
CustomCheckBox.propTypes = {
  labelText: PropTypes.string,
  handleToggle: PropTypes.func,
  value: PropTypes.bool,
};
