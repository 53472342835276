import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationOverlay = (props) => {
  const { isOverlayOpen, closeOverlay, handler, title = '', message = '', actions = true } = props;

  const handleClose = () => {
    closeOverlay();
  };

  return (
    <div>
      <Dialog
        open={isOverlayOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        {actions && (
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              Disagree
            </Button>
            <Button variant="contained" onClick={handler}>
              Agree
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default React.memo(ConfirmationOverlay);
