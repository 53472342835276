import axios from "axios";
import { USERAPI } from "URL.js";

export const fetchServiceProvidersById = (userId, callback) => {
  axios
    .get(`${USERAPI}/service-provider/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback([response.data]);
      }
    })
    .catch((error) => {
      console.log("Error while fetching ServiceProviders", error);
      callback([]);
    });
};

export const uploadImage = async (data, icon, spId, callback) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const response = await axios.post(
      `${USERAPI}/service-provider/${icon}?serviceProviderId=${spId}`,
      data,
      config
    );
    if (response.status === 200) {
     
      callback(response.data);
    }
  } catch (error) {
    console.error(error);
    callback(`${error.message}`, "error");
  }
};

export const modifyServiceProvider = async (payload, callback) => {
  axios
    .post(`${USERAPI}/service-provider/modify`, payload)
    .then((response) => {
      if (response.status === 200) {
        callback(false, "Successfully updated Service Provider Details");
      }
    })
    .catch((error) => {
      console.log("Error while modify ServiceProvider=====>", error);
      callback(true, "Something went wrong while update Service Provider");
    });
}
// export const createModifyServiceProvider = async (
//   params,
//   isModify,
//   callBack
// ) => {
//   const contentType = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   try {
//     let apiType = isModify ? "modify" : "create";
//     const response = await axios.post(
//       USERAPI + "/service-provider/" + apiType,
//       params
//     );
//     if (response.status === 200) {
//       console.log("create response.data", response.data);
//       callBack(true);
//     }
//   } catch (error) {
//     callBack(false);
//     console.error(error.message);
//   }
// };

// export const deleteServiceProvider = async (orgId, callBack) => {
//   try {
//     const response = await axios.post(
//       USERAPI + "/service-provider/delete/" + orgId
//     );
//     if (response.status === 200) {
//       callBack(response.data);
//     }
//   } catch (error) {
//     callBack(false);
//     console.error(error.message);
//   }
// };

// export const getServiceProviderIdValues = async (fetchOrgListValues) => {
//   try {
//     const response = await axios.get(USERAPI + "/service-provider/id-values");
//     console.log(response);
//     if (response.status === 200) {
//       fetchOrgListValues(response.data);
//     }
//   } catch (error) {
//     console.error(error.message);
//   }
// };

// export const getServiceProviderbyUserId = async (
//   userId,
//   fetchOrgListValues
// ) => {
//   try {
//     const response = await axios.get(
//       USERAPI + "/service-provider/id-values/" + userId
//     );
//     console.log(response);
//     if (response.status === 200) {
//       fetchOrgListValues(response.data);
//     }
//   } catch (error) {
//     console.error(error.message);
//   }
// };
