import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Box } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

function WorkflowCreateEdit({ workflow, updateWorkflow }) {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Box>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/* {fieldError && (
                <p className={classes.errorMessgage}>
                  Please enter Required fields
                </p>
              )} */}
              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <TextField
                  required
                  id="outlined-required"
                  label="Workflow Name"
                  variant="outlined"
                  size="large"
                  name="processName"
                  defaultValue={workflow.processName}
                  onInput={(e) => updateWorkflow(e)}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <TextField
                  required
                  id="outlined-required"
                  label="Workflow Description"
                  variant="outlined"
                  size="large"
                  name="processDesc"
                  defaultValue={workflow.processDesc}
                  onInput={(e) => updateWorkflow(e)}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                <TextField
                  id="apiContextPath-required"
                  label="Workflow Path"
                  variant="outlined"
                  size="large"
                  name="apiContextPath"
                  defaultValue={workflow.apiContextPath}
                  onInput={(e) => updateWorkflow(e)}
                />
              </FormControl>
            </GridItem>
          </GridContainer>
        </Box>
      </GridItem>
    </GridContainer>
  );
}

export default WorkflowCreateEdit;
