import React, { useState, createContext, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

// Context to manage the drawer state
const DrawerContext = createContext();

export function useDrawer() {
    return useContext(DrawerContext);
}

export const DrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <DrawerContext.Provider value={{ isOpen, toggleDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};

// Reusable Drawer component with dynamic IconButton
export const CustomDrawer = ({ anchorPos, children, icon: Icon = MenuIcon, onIconClick, iconButtonProps }) => {
    const { isOpen, toggleDrawer } = useDrawer();

    const handleIconClick = () => {
        if (onIconClick) {
            onIconClick();
        }
        toggleDrawer();
    };

    return (
        <>
            <IconButton {...iconButtonProps} onClick={handleIconClick} sx={{borderRadius: 'none'}}>
                <Icon />
            </IconButton>
            <Drawer anchor={anchorPos} open={isOpen} onClose={toggleDrawer}>
                {children}
            </Drawer>
        </>
    );
};

// // Example of using the CustomDrawer with a dynamic icon
// import MenuIcon from '@mui/icons-material/Menu'; // Default icon
// import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Another icon example

// function App() {
//     return (
//         <DrawerProvider>
//             <CustomDrawer
//                 icon={AccountCircleIcon}
//                 onIconClick={() => console.log('Icon clicked')}
//                 iconButtonProps={{ edge: "start", color: "inherit", 'aria-label': "menu" }}
//             >
//                 <div>Your content here</div>
//             </CustomDrawer>
//         </DrawerProvider>
//     );
// }


