import * as React from "react";
import { TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemIcon from "@mui/material/ListItemIcon";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import styles from "../../views/ReconXWorkspace/ReconStyles";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReconXContext } from "context/ReconX/ReconXContext";

const options = [
  { label: "Last 1 Day", value: "1D" },
  {
    label: "Last 7 Days",
    value: "1W",
  },
  { label: "Last 30 Days", value: "1M" },
  { label: "Last 90 Days", value: "3M" },
  { label: "Last 180 Days", value: "6M" },
  { label: "Last 270 Days", value: "9M" },
  { label: "Last 360 Days", value: "12M" },
];

export default function DaysDatePicker({ onDateChange, showFormObject }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const ReconXContextData = React.useContext(ReconXContext);

  const { tenorPeriod } = ReconXContextData.state;

  const targetValue = tenorPeriod?.tenorPeriodVal || "1D";
  const tpIndex = options.findIndex((option) => option.value === targetValue);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [customDate, setCustomDate] = React.useState(false);
  const [validateDate, setValidateDate] = React.useState(false);
  const [tenorPeriodObj, setTenorPeriod] = React.useState({
    tenorPeriodVal: targetValue,
    startDate: tenorPeriod?.startDate || null,
    endDate: tenorPeriod?.endDate || null,
  });
  const [selectedOption, setSelectedOption] = React.useState(options[tpIndex]); // Default selected option
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    if (!showFormObject) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (event, option) => {
    setSelectedOption(option);
    setStartDate(null);
    setEndDate(null);
    setCustomDate(false);
    setAnchorEl(null);
  };

  const handleCustomMenuItemClick = (event, option) => {
    setCustomDate(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  React.useEffect(() => {
    if (selectedOption) {
      setTenorPeriod(() => ({
        startDate: null,
        endDate: null,
        tenorPeriodVal: selectedOption.value,
      }));
      setCustomDate(false);
    }
  }, [selectedOption]);

  React.useEffect(() => {
    if (startDate && endDate) {
      const startDateFormat = new Date(startDate?.$d);
      const endDateFormat = new Date(endDate?.$d);
      const startDateVal = formatDateToYYYYMMDD(startDateFormat);
      const endDateVal = formatDateToYYYYMMDD(endDateFormat);

      setTenorPeriod(() => ({
        startDate: startDateVal,
        endDate: endDateVal,
        tenorPeriodVal: "CUSTOM",
      }));

      if (startDate > endDate) {
        setAnchorEl(null);
      }
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    if (
      tenorPeriodObj?.tenorPeriodVal === "CUSTOM" &&
      !(tenorPeriodObj?.startDate >= tenorPeriodObj?.endDate)
    ) {
      setValidateDate(true);
    } else {
      setValidateDate(false);
      onDateChange(tenorPeriodObj);
    }
  }, [tenorPeriodObj]);

  const disablePastDates = (date) => {
    const today = dayjs();
    return date > today;
  };

  return (
    <div>
      <Typography variant="div" sx={{ fontSize: "13px", fontWeight: "bold" }}>
        Period
      </Typography>
      <List
        component="nav"
        aria-label="days date"
        sx={{ bgcolor: "background.paper", minWidth: "200px" }}
      >
        <ListItem
          button
          id="date-button"
          aria-haspopup="listbox"
          aria-controls="date-menu"
          aria-label="days date item"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClickListItem}
        >
          <ListItemIcon>
            <CalendarMonthIcon />
          </ListItemIcon>
          <ListItemText
            primary={""}
            secondary={
              <Typography
                variant="div"
                sx={{ display: "block", fontSize: "12px", fontWeight: "400" }}
              >
                {tenorPeriodObj?.tenorPeriodVal === "CUSTOM"
                  ? `${tenorPeriodObj?.startDate} - ${tenorPeriodObj?.endDate}`
                  : selectedOption.label}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Menu
        id="days-date-menu"
        sx={{ minWidth: "200px" }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "days-date-button",
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            sx={{ minWidth: "200px" }}
            selected={option.value === selectedOption?.value && !customDate}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option.label}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={(event) => handleCustomMenuItemClick(event)}>
          <Typography
            variant="div"
            sx={{ fontSize: "13px", fontWeight: "bold" }}
          >
            Custom{" "}
            {validateDate && (
              <span
                style={{
                  marginLeft: "20px",
                  color: "red",
                  fontWeight: "300",
                }}
              >
                <b style={{ fontWeight: "500" }}>To Date</b> should be lessthan{" "}
                <b style={{ fontWeight: "500" }}>From Date</b>
              </span>
            )}
          </Typography>
        </MenuItem>

        {customDate && (
          <Grid
            container
            xs={10}
            spacing={1}
            sx={{ marginLeft: "10px", marginRight: "10px" }}
            className={classes.datePickerGrid}
          >
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="From Date"
                  value={startDate}
                  inputFormat="YYYY/MM/DD"
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  shouldDisableDate={disablePastDates}
                  // slotProps={{
                  //   textField: {
                  //     InputProps: { size: "medium", fullWidth: "100px" },
                  //   },
                  // }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="To Date"
                  value={endDate}
                  inputFormat="YYYY/MM/DD"
                  onChange={(date) => setEndDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  shouldDisableDate={disablePastDates}
                  // slotProps={{ textField: { InputProps: { size: "medium" } } }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        )}
      </Menu>
    </div>
  );
}
