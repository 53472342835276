import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
const styles = (theme) => ({
  searchBox: {
    marginTop: 5,
    "& .MuiOutlinedInput-root": {
      height: "44px",
    },
  },
});

const useStyles = makeStyles(styles);
const SAYASearchBox = ({ searchText, onChange }) => {
  const classes = useStyles();
  return (
    <TextField
      id="filled-search"
      size="small"
      label="Search"
      type="search"
      placeholder="Name"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      value={searchText}
      onChange={onChange}
      className={classes.searchBox}
      sx={{ fontSize: 12 }}
    />
  );
};

export default SAYASearchBox;
