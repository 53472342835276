import TableCellPopover from "components/Table/TableCellPopover";

export function checkAccessHandler(action, permissionData) {
  if (permissionData && permissionData.includes(action)) {
    return true;
  }
  return false;
}

export function imageUpload(
  e,
  callback,
  imgWidth = 100,
  imgHeight = 100,
  whFlag,
  sizeFlag
) {
  //Get reference of FileUpload.
  //var fileUpload = document.getElementById("fileUpload");
  const imageVal = e.target.value;
  const eleName = e.target.name;
  const fileSize = e.currentTarget.files[0].size;
  const maxSize = 1024 * 1024; // 1MB

  //Check whether the file is valid Image.
  var regex = new RegExp("(.*?).(jpg|jpeg|png|svg|gif|JPG|JPEG|PNG|SVG|GIF)$");
  if (regex.test(e.target.value.toLowerCase())) {
    //Check whether HTML5 is supported.
    if (typeof e.target.files != "undefined") {
      //Initiate the FileReader object.
      var reader = new FileReader();
      let fileData = e.target.files[0];
      //Read the contents of Image File.
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
        //Initiate the JavaScript Image object.
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (whFlag && (height > imgHeight || width > imgWidth)) {
            callback(
              false,
              `Width and Height must be ${imgWidth} x ${imgHeight}.`,
              '', eleName
            );
            return false;
          } else if (sizeFlag && fileSize > maxSize) {
            callback(
              false,
              `File is too large. Please select a file less than 1MB in size.`,
              '', eleName
            );

            return false;
          }
          //return true;
          callback(true, reader.result, fileData, eleName);
        };
      };
    } else {
      console.log("This browser does not support HTML5.");
      return false;
    }
  } else {
    callback(false, "Please select a valid Image file.");
    return false;
  }
}

export const containsUppercase = (str) => {
  return /^[A-Z]+$/.test(str);
};

export const transformColumnName = (columnData = []) => {
  const columns = columnData?.length
    ? Object.keys(columnData[0]).map((column) => {
        return {
          name: column,
          label: column.replace(/[A-Z]/g, " $&").trim().toUpperCase(),
          accessorKey: column,
          header: column.replace(/[A-Z]/g, " $&").trim().toUpperCase(),
          // .replace(/^./, (x) => x.toUpperCase()),
          options: {
            filter: column === "caseDescription" ? false : true,
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta?.columnData?.name === "caseDescription" ? (
                <div className="desc" style={{ maxWidth: "250px" }}>
                  <TableCellPopover limit={60} content={value} />
                </div>
              ) : (
                value
              ),
          },
        };
      })
    : [];
  return columns;
};

export const transformToCapitalize = (string = "") => {
  return string
    .replace(/[A-Z]/g, " $&")
    .trim()
    .replace(/^./, (x) => x.toUpperCase());
};

export const transformToUpperCase = (string = "") => {
  const res = containsUppercase(string.split(" ").join(""));
  if (res) {
    return string;
  }
  if (string.includes("_")) {
    return string
      .replace(/[^a-zA-Z0-9 ]/g, " ")
      .trim()
      .toUpperCase();
  }
  return string.replace(/[A-Z]/g, " $&").trim().toUpperCase();
};

export const transformHeader = (headerData = [], key) => {
  const columns = headerData?.length
    ? headerData.map((rowData) => {
        if (containsUppercase(rowData[key])) {
          return {
            ...rowData,
          };
        } else {
          return {
            ...rowData,
            [key]: transformToUpperCase(rowData[key]),
          };
        }
      })
    : [];
  return columns;
};

export const muiTableOptions = () => {
  const perPageRows = 100;
  const currentPage = 0;

  return {
    textLabels: {
      body: {
        noMatch: "No Data Found!!!",
      },
    },
    filterType: "dropdown",
    pagination: true,
    selectableRows: "none", //single |multiple| none
    rowsPerPage: perPageRows,
    resizableColumns: false,
    draggableColumns: {
      enabled: true,
    },
    page: currentPage,
    rowsPerPageOptions: [100, 500, 1000],
    jumpToPage: false,
    // onChangePage: (page) => {
    //   handleChangePage(page);
    // },
    // onTableInit: (status, data) => {
    //   if (data?.data.length > 0) {
    //     setResolveXLoading(false);
    //   } else {
    //     setResolveXLoading(true);
    //   }
    // },
    print: false,
    tableBodyMaxHeight: "450px",
    tableBodyHeight: "450px",
    //serverside is set as true just for pagination
    //we need to explicitly set to to false to have the filters work properly
    // we can revert it back to true always once the server side filter is done
    serverSide: false,
    responsive: "standard", //TODO scroll is depricated it needs to be replaced with standard however the fixed header or sticky header to be worked out
    fixedHeader: true,
    hint: "Double click on row for actions on the row item",
    // setRowProps: (row, dataIndex) => ({
    //   style: {
    //     cursor: checkAccessHandler("double click records", allowedPermissions)
    //       ? "pointer"
    //       : "default",
    //   },
    //   onDoubleClick: (evt) => {
    //     if (checkAccessHandler("double click records", allowedPermissions)) {
    //       handleRowDoubleClick(row);
    //     } else {
    //       return false;
    //     }
    //   },
    // }),
    // onRowClick: (rowData) => mobileRowClickHandler(rowData),
  };
};

export const fetchLoggedInUserDTO = () => {
  const {
    userId,
    loginName,
    mstRole: { roleId },
  } = JSON.parse(sessionStorage.userData);

  return {
    loggedInUserId: userId,
    loginName,
    roleId,
  };
};

const statusStyles = {
  fontWeight: "500",
  padding: "0 10px",
  fontSize: "13px",
};

export function statusTextHandler(statusId) {
  return statusHandler(statusId);
}
export const statusHandler = (cellVal) => {
  let statusVal = "";
  switch (cellVal) {
    case 0:
      statusVal = (
        <span
          style={{
            backgroundColor: "#faeaeb",
            color: "#cd5253",
            ...statusStyles,
          }}
        >
          OPEN
        </span>
      );
      break;
    case 1:
      statusVal = (
        <span
          style={{
            backgroundColor: "#effaf1",
            color: "#67c779",
            ...statusStyles,
          }}
        >
          AUTO MATCHED
        </span>
      );
      break;
    case 2:
      statusVal = (
        <span
          style={{
            backgroundColor: "#edeaf6",
            color: "#957dff",
            ...statusStyles,
          }}
        >
          ONE TO MANY
        </span>
      );
      break;
    case 3:
      statusVal = (
        <span
          style={{
            backgroundColor: "#ece2f0",
            color: "#790083",
            ...statusStyles,
          }}
        >
          MANY TO ONE
        </span>
      );
      break;
    case 4:
      statusVal = (
        <span
          style={{
            backgroundColor: "#fbf1d1",
            color: "#b98d00",
            ...statusStyles,
          }}
        >
          MANY TO MANY MATCHING
        </span>
      );
      break;
    case 5:
      statusVal = (
        <span
          style={{
            backgroundColor: "#def1f7",
            color: "#4ba6c3",
            ...statusStyles,
          }}
        >
          MANUAL MATCHED
        </span>
      );
      break;
    default:
      statusVal = <span style={{ color: "greenyellow" }}>{cellVal}</span>;
  }
  return statusVal;
};

export const changeColor = (value = "OPEN") => {
  if (value === "OPEN") {
    return {
      sx: {
        backgroundColor: "#faeaeb",
        color: "#cd5253",
        ...statusStyles,
      },
    };
  } else if (value === "AUTO MATCHED") {
    return {
      sx: {
        backgroundColor: "#effaf1",
        color: "#67c779",
        ...statusStyles,
      },
    };
  } else if (value === "ONE TO MANY") {
    return {
      sx: {
        backgroundColor: "#edeaf6",
        color: "#957dff",
        ...statusStyles,
      },
    };
  } else if (value === "MANY TO ONE") {
    return {
      sx: {
        backgroundColor: "#ece2f0",
        color: "#790083",
        ...statusStyles,
      },
    };
  } else if (value === "MANY TO MANY MATCHING") {
    return {
      sx: {
        backgroundColor: "#fbf1d1",
        color: "#b98d00",
        ...statusStyles,
      },
    };
  } else if (value === "MANUAL MATCHED") {
    return {
      sx: {
        backgroundColor: "#def1f7",
        color: "#4ba6c3",
        ...statusStyles,
      },
    };
  }
};
export const cellHandler = (cellVal) => {
  switch (cellVal) {
    case 0:
      return "OPEN";
    case 1:
      return "AUTO MATCHED";
    case 2:
      return "ONE TO MANY";
    case 3:
      return "MANY TO ONE";
    case 4:
      return "MANY TO MANY MATCHING";
    case 5:
      return "MANUAL MATCHED";
    default:
      return cellVal;
  }
};

export const reconStatusHandler = (rColumnData) => {
  const updatedColumns = rColumnData;
  updatedColumns.forEach((item) => {
    if (item.name === "reconStatusId") {
      item.header = "RECON STATUS";
      item["Cell"] = ({ cell }) => <>{statusHandler(cell.getValue())}</>;
    } else {
      item["Cell"] = ({ cell }) => (
        <>
          {cell?.getValue()?.length > 30 ? (
            <div className="desc" style={{ maxWidth: "250px" }}>
              <TableCellPopover limit={30} content={cell.getValue()} />
            </div>
          ) : (
            cell.getValue()
          )}
        </>
      );
    }
  });
  const reconStatusIndex = updatedColumns.findIndex(
    (item) => item.id === "reconStatusId"
  );
  if (reconStatusIndex !== -1) {
    const reconStatusItem = updatedColumns.splice(reconStatusIndex, 1)[0];
    updatedColumns.unshift(reconStatusItem);
  }

  return updatedColumns;
};
