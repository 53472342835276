import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Typography, Menu, MenuItem, Button, Box } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AddBoxOutlined, DragIndicator } from "@mui/icons-material";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { WorkflowContext } from "context/Workflow/WorkflowContext";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import { muiTableOptions } from "components/utils";
import { transformToUpperCase } from "components/utils";
import Overlay from "components/Overlay/Overlay";
import WorkflowCreateEdit from "./WorkflowCreateEdit";
import { saveWorkflows } from "action-handlers/WorkflowActionHandler";
import { showSuccessNotification } from "action-handlers/NotificationsHandler";
import { notificationParams } from "variables/general";
import Snackbar from "components/Snackbar/Snackbar";
import { showFailureNotification } from "action-handlers/NotificationsHandler";
import { fetchworkflows } from "action-handlers/WorkflowActionHandler";
import { fetchLoggedInUserDTO } from "components/utils";
import ConfirmationOverlay from "components/ConfirmOverlay/ConfirmationOverlay";
import { deleteWorkflow } from "action-handlers/WorkflowActionHandler";

import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import {
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from "@mui/material";

function Workflow() {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [workflowData, setWorkflowData] = useState([]);
  const [tableOptions, setTableOptions] = useState(muiTableOptions);
  const [anchorElAction, setAnchorElAction] = React.useState(null);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const [searchText, setSearchText] = useState("");

  const [workflow, setWorkflow] = useState({
    processName: "",
    processDesc: "",
    processType: "WORKFLOW",
    apiContextPath: "/saya-recon-resolve",
  });

  const [showOverlay, setOverlay] = useState(false);

  const wfRef = useRef({ selectedWorkflow: null });
  const newEditRef = useRef({ new: true });
  const currentWorkflow = useRef({ wfDetails: null });
  const actionMenuPosition = useRef({ left: 0, top: 0 });
  const [showConfirmOverlay, setConfirmOverlay] = useState(false);
  const userContextData = useContext(UserAuthDetailsContext);
  const { userId, loginName, roleId } = userContextData.state;
  const WorkflowDataContext = useContext(WorkflowContext);

  const navigate = useNavigate();
  const { workflows } = WorkflowDataContext.state;

  let allowedPermissions = [];
  try {
    allowedPermissions =
      !location.state || location.state === null
        ? JSON.parse(sessionStorage.selectedItem)?.allowedPermissions
        : JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];
  } catch (ex) {
    console.log("Error while reading location.state===>", ex);
  }

  const handleOpenActionMenu = (event, itemData) => {
    console.log("event", event);
    setAnchorElAction(event.currentTarget);
    currentWorkflow.current.wfDetails = itemData;
    actionMenuPosition.current.left = event?.clientX;
    actionMenuPosition.current.top = event?.clientY;
    // wfRef.current.selectedWorkflow = workflows[index];
  };

  const handleCloseActionMenu = () => {
    setAnchorElAction(null);
  };

  const saveWorkflowData = (updatedWorkflows = [], flag = true) => {
    WorkflowDataContext.saveWorkflows(updatedWorkflows);
    if (!newEditRef.current.new) {
      return false;
    }
    if (flag && currentWorkflow.current.wfDetails !== null) {
      const workflow = updatedWorkflows.filter(
        (wf) =>
          wf.processName.toLowerCase() ===
          currentWorkflow.current.wfDetails.processName.toLowerCase()
      )[0];
      navigate(
        `/saya-platform/admin/workflows/configure?wfid=${workflow?.wfProcessId}`,
        {
          state: {
            selectedWorkflow: workflow,
            allowedPermissions: allowedPermissions,
            workflows: updatedWorkflows,
          },
        }
      );
    }
  };
  const columns = [
    {
      name: "",
      label: "",
      options: {
        sort: false,
      },
    },
    {
      name: "wfProcessId",
      label: "PROCESS ID",
      options: {
        sort: false,
      },
    },
    {
      name: "processName",
      label: "PROCESS NAME",
      options: {
        sort: false,
      },
    },
    {
      name: "processDesc",
      label: "PROCESS DESCRIPTION",
      options: {
        sort: false,
      },
    },
    {
      name: "processType",
      label: "PROCESS TYPE",
      options: {
        sort: false,
      },
    },
    {
      name: "apiContextPath",
      label: "API CONTEXT PATH",
      options: {
        sort: false,
      },
    },
  ];

  useEffect(() => {
    if (workflows.length > 0) {
      const actions = {
        name: "Actions",
        label: `ACTIONS`,
        options: {
          sort: false,
          filter: false,
          display: true,
          viewColumns: true,
        },
      };
      columns.push(actions);
      setTableColumns(columns);
      setTableOptions((prevOptions) => ({
        ...prevOptions,
        download: false,
        viewColumns: false,
        search: false,
        filter: false,
        draggableColumns: {
          enabled: false,
        },
      }));
    } else if (roleId) {
      fetchworkflows(roleId, saveWorkflowData);
    }
    setTableData(workflows);
    setWorkflowData(workflows);
  }, [workflows]);

  const hideSearchHandler = () => {
    setSearchText("");
    setWorkflowData(tableData);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      setTableOptions((prevOptions) => ({
        ...prevOptions,
        customToolbar: () => {
          return (
            <div>
              <TextField
                id="filled-search"
                size="small"
                label="Search"
                type="search"
                placeholder="Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={searchText}
                onChange={searchTableData}
                sx={{ fontSize: 12, marginTop: {xs:'20px', sm:'0'} }}
              />
              {/* {searchText?.length > 0 && (
                <ClearOutlinedIcon onClick={hideSearchHandler} />
              )} */}
              {/* <input
                type="text"
                value={searchText}
                onChange={searchTableData}
                placeholder="Search"
              />
               {searchText?.length > 0 && <ClearOutlinedIcon onClick={hideSearchHandler}/>} */}
            </div>
          );
        },
      }));
    }
  }, [tableData, searchText]);
  
  const searchTableData = (event) => {
    const { value } = event.target;
    setSearchText(value);
  
    // Convert the search value to lowercase for case-insensitive comparison
    const searchValue = value.toLowerCase();
  
    // Filter workflow data based on PROCESS ID, PROCESS NAME, and PROCESS DESCRIPTION
    const searchResults = tableData.filter((obj) =>
      obj.wfProcessId.toString().toLowerCase().includes(searchValue) || 
      obj.processName.toLowerCase().includes(searchValue) || 
      obj.processDesc.toLowerCase().includes(searchValue)
    );
  
    setWorkflowData(searchResults);
  };

  const closeOverlay = () => {
    setWorkflow({
      processName: "",
      processDesc: "",
      processType: "WORKFLOW",
      apiContextPath: "/saya-recon-resolve",
    });
    // currentWorkflow.current.wfDetails = null;
    handleCloseActionMenu();
    setOverlay(false);
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const saveWorkflow = (sortData) => {
    const isSort = sortData?.length > 0;
    if (
      !isSort > 0 &&
      (workflow.processName.trim() === "" || workflow.processDesc.trim() === "")
    ) {
      showFailureNotification(
        "Please enter Required data",
        callbackNotification
      );
      return;
    }
    console.log("workflow", tableData);
    const payload = {
      workflowProcess: sortData?.length > 0 ? sortData : [workflow],
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };

    saveWorkflows(payload, async (data, apiStatus) => {
      if (apiStatus) {
        showSuccessNotification(data.responseMessage, callbackNotification);
        await fetchworkflows(roleId, (data) => {
          saveWorkflowData(data, isSort ? false : true);
        });
        closeOverlay();
      } else {
        showFailureNotification(data.responseMessage, callbackNotification);
      }
    });
  };

  const updateWorkflow = (event) => {
    setWorkflow({ ...workflow, [event.target.name]: event.target.value });
    currentWorkflow.current.wfDetails = {
      ...workflow,
      [event.target.name]: event.target.value,
    };
  };
  const createNewWorkflow = () => {
    newEditRef.current.new = true;
    setOverlay(true);
  };

  const editWorkflow = () => {
    newEditRef.current.new = false;
    // currentWorkflow.current.wfDetails = wfRef.current.selectedWorkflow;
    setWorkflow(currentWorkflow.current.wfDetails);
    setOverlay(true);
  };

  const closeConfirmationOverlay = () => {
    setConfirmOverlay(false);
    handleCloseActionMenu();
  };

  const deleteWorkflowHandler = () => {
    setConfirmOverlay(true);
  };

  const deleteWF = () => {
    const payload = {
      workflowProcess: [currentWorkflow.current.wfDetails],
      loggedInUserDTO: fetchLoggedInUserDTO(),
    };
    deleteWorkflow(payload, (res, status) => {
      if (status) {
        showSuccessNotification(res.responseMessage, callbackNotification);
        fetchworkflows(roleId, (data) => {
          saveWorkflowData(data, false);
        });
        closeConfirmationOverlay();
      } else {
        showFailureNotification(res.responseMessage, callbackNotification);
      }
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const [movedItem] = newData.splice(oldIndex, 1);
      newData.splice(newIndex, 0, movedItem);
      console.log("newData", newData);
      saveWorkflow(newData);
      return newData;
    });
  };

  const SortTableRow = ({ data, indexVal }) => {
    let columnItemData = data;
    let filteredObject = {};

    columns?.forEach((item) => {
      if (columnItemData.hasOwnProperty(item.name)) {
        filteredObject[item.label] = columnItemData[item.name];
      }
    });

    const DragHandle = sortableHandle(() => (
      <span className="dragHandle">
        <DragIndicator />
      </span>
    ));

    return (
      <TableRow>
        <TableCell>
          <DragHandle />
        </TableCell>
        {Object.values(filteredObject).map((value, valueIndex) => (
          <TableCell key={valueIndex}>{value}</TableCell>
        ))}
        <TableCell>
          <Typography
            key={"regTypo"}
            className="action-menu"
            onClick={(e) => handleOpenActionMenu(e, data)}
            noWrap
            sx={{
              mr: 2,
              cursonr: "pointer",
              flexGrow: 1,
              fontWeight: 300,

              cursor: "pointer",
            }}
          >
            <MoreHorizIcon />
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  const SortableTableRow = SortableElement(({ item, index }) => (
    <SortTableRow data={item} indexVal={index} />
  ));

  const SortableTableBody = SortableContainer(({ items }) => (
    <TableBody>
      {items?.length > 0 ? (
        items.map((item, index) => (
          <SortableTableRow key={index} index={index} item={item} />
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={7} sx={{ textAlign: "center" }}>
            No Data Found!!!
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  ));

  return (
    <>
      <Menu
        sx={{ mt: "14px" }}
        key={"menu"}
        id={"menu-appbar"}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: actionMenuPosition?.current?.top,
          left: actionMenuPosition?.current?.left,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElAction)}
        onClose={handleCloseActionMenu}
      >
        {allowedPermissions.includes("modify") && (
          <MenuItem
            style={{ color: "#7f9915" }}
            key={"workflow_edit"}
            onClick={editWorkflow}
          >
            <span style={{ marginLeft: "5px" }}>Edit</span>
          </MenuItem>
        )}
        {(allowedPermissions.includes("view") ||
          allowedPermissions.includes("modify") ||
          allowedPermissions.includes("delete")) && (
          <MenuItem
            style={{ color: "#7f9915" }}
            key={"workflow_configure"}
            onClick={() =>
              navigate(
                `/saya-platform/admin/workflows/configure?wfid=${currentWorkflow.current.wfDetails?.wfProcessId}`,
                {
                  state: {
                    selectedWorkflow: currentWorkflow.current.wfDetails,
                    allowedPermissions: allowedPermissions,
                    workflows: workflows,
                  },
                }
              )
            }
          >
            <span style={{ marginLeft: "5px" }}>Flow Designer</span>
          </MenuItem>
        )}
        {allowedPermissions.includes("delete") && (
          <MenuItem
            style={{ color: "#7f9915" }}
            key={"workflow_configure"}
            onClick={deleteWorkflowHandler}
          >
            <span style={{ marginLeft: "5px" }}>Delete</span>
          </MenuItem>
        )}
      </Menu>
      {allowedPermissions.includes("add") && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: "10px" }}>
          <Button onClick={createNewWorkflow} variant="contained">
            <AddBoxOutlined sx={{ mr: 1 }} /> Add New Workflow
          </Button>
        </Box>
      )}
      {/* <SAYAMUIDataTable
        title={"Workflows"}
        data={tableData}
        columns={tableColumns}
        options={tableOptions}
      /> */}

      {tableData?.length > 0 && (
        <>
          <MUIDataTable
            className="sort-wf-table"
            title={"Workflows"}
            data={workflowData}
            columns={tableColumns}
            options={tableOptions}
            components={{
              TableBody: (props) => (
                <SortableTableBody
                  helperClass="sortable-helper"
                  useDragHandle
                  {...props}
                  items={workflowData}
                  onSortEnd={onSortEnd}
                />
              ),
            }}
          />
        </>
      )}
      {showOverlay && (
        <Overlay
          {...{
            isOverlayOpen: showOverlay,
            closeOverlay,
            title: `${
              newEditRef.current.new ? "Create Workflow" : "Edit Workflow"
            }`,
            actions: true,
            handler: saveWorkflow,
          }}
        >
          <WorkflowCreateEdit {...{ workflow, updateWorkflow }} />
        </Overlay>
      )}
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
      {showConfirmOverlay && (
        <ConfirmationOverlay
          {...{
            isOverlayOpen: showConfirmOverlay,
            closeOverlay: closeConfirmationOverlay,
            handler: deleteWF,
            title: "DELETE",
            message: `Are you sure you want to delete Workflow. You will lose all associated data`,
          }}
        />
      )}
    </>
  );
}

export default Workflow;
