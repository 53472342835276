import React, { useContext, useEffect } from "react";
import Button from "../../components/CustomButtons/Button";
import saya from "../../assets/img/SAYA.svg";
import { makeStyles } from "@mui/styles";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";

/**
 * Displays a successful logout message.
 *
 * @returns the logout page JSX element
 */
const styles = (theme) => {
  return {
    wrapper: {
      height: "90vh",
      marginLeft: "-60px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
      },
      "& img": {
        maxHeight: "300px",
        width: "30%",
        display: "block",
        margin: "10% auto 0 auto",
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
      },
    },
    text: {
      margin: "auto",
      textAlign: "center",
      width: "50%",
      marginTop: 20,
      border: "1px solid #4caf50",
      padding: "20px 0",
      color: "rgb(56, 113, 58)",
      backgroundColor: "rgba(76, 175, 80, 0.05)",
      borderRadius: "3px",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },
    btnWrapper: {
      textAlign: "center",
      marginTop: 20,
    },
  };
};

const useStyles = makeStyles(styles);

export default function IdPLogoutPage() {
  const classes = useStyles();
  const UserAuthContext = useContext(UserAuthDetailsContext);

  const logoimgType =
    JSON.parse(localStorage.getItem("spDetails"))?.logoimgType || "";
  const logoContent =
    JSON.parse(localStorage.getItem("spDetails"))?.logoContent || "";

  useEffect(() => {
    // This condition handles the reload of logout page. Without this,
    // the login page is displayed but the logout route.
    const loginName = sessionStorage.getItem("loginName");
    if (loginName && loginName.trim() !== "") {
      // Clear auth state only if loginName is found.
      UserAuthContext.clearUserDetailsAuthState();
    } else {
      window.location.href = "/saya-platform/login";
    }
  }, []);

  const backToLogin = () => {
    window.location.reload(false);
  };

  return (
    <div className={classes.wrapper}>
      <img
        data-src=""
        alt="logo"
        style={{}}
        src={logoContent ? `data:${logoimgType};base64,${logoContent}` : saya}
        data-holder-rendered="true"
      />

      <p className={classes.text} style={{}}>
        You are logged out of SAYA platform successfully!!
      </p>

      <p color="warning" className={classes.btnWrapper}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ padding: "10px 36px" }}
          onClick={backToLogin}
        >
          Login
        </Button>
      </p>
    </div>
  );
}
