import { createContext } from "react";
const userDetailsInitialState = {
  isLoggedIn: false,
  userId: undefined,
  userEmail: "",
  loginName: sessionStorage.getItem("loginName"),
  firstName: "",
  lastName: "",
  userToken: sessionStorage.getItem("token"),
  departmentId: 0,
  departmentName: "",
  clientOrgId: 0,
  parentClientOrgId: 0,
  clientShortName: "",
  roleId: "",
  errors: "",
  reconUnitId: "",
  reconDataSource: "",
  resolveUnitId: "",
  reconUnitId: "",
  themeType: "light",
  menuHierarchy: "",
  originalMenuHierarchy: [],
  activeMenuId: "",
  state: "",
  rowsPerPage: '',
  formData:[]
};
export const UserAuthDetailsContext = createContext(userDetailsInitialState);
