import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  whiteColor,
  hexToRgb,
  numberBgColor,
  numberColor
} from "assets/jss/material-dashboard-react.js";
import { useTheme } from '@mui/material/styles';

export default function cardHeaderStyle() {
  const theme = useTheme();
  return {
  cardHeader: {
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    zIndex: "3 !important",
    "&$cardHeaderPlain,&$cardHeaderIcon,&$cardHeaderStats,&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      margin: "0 15px",
      padding: "0",
      position: "relative"
    },
    "&:first-child": {
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"
    },
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      "&:not($cardHeaderIcon)": {
        borderRadius: "3px",
        marginTop: "0px",
        padding: "15px 0px"
      }
    },
    "&$cardHeaderStats svg": {
      fontSize: "36px",
      lineHeight: "auto",
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        width: "22px",
        height: "22px"
      },
      width: "36px",
      height: "36px",
      margin: "10px 10px 4px"
    },
    "&$cardHeaderStats i,&$cardHeaderStats .material-icons": {
      fontSize: "36px",
      lineHeight: "56px",
      width: "56px",
      height: "56px",
      textAlign: "center",
      overflow: "unset",
      marginBottom: "1px",
      color: theme.palette.icons.blue,
      [theme.breakpoints.down("md")]: {
        width: "40px",
        height: "40px",
        lineHeight: "42px",
        fontSize: "22px"
      }
    },
    "&$cardHeaderStats$cardHeaderIcon": {
      textAlign: "right",
      minHeight: "72px",
      marginTop: "15px",
      [theme.breakpoints.down('lg')]: {
        minHeight: "82px",
      },
    },
    "& h4":{
      [theme.breakpoints.down("sm")]: {
        margin: '10px 0'
      }
    }
  },
  cardHeaderPlain: {
    marginLeft: "0px !important",
    marginRight: "0px !important"
  },
  cardHeaderStats: {
    "& $cardHeaderIcon": {
      textAlign: "right",
      minHeight: "88px"
    },
    "& p": {
      minHeight: "38px",
      lineHeight: "18px",
      [theme.breakpoints.down('lg')]: {
        fontSize: 12
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 14
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        fontWeight: "400"
      }
    },
    "& h1,& h2,& h3,& h4,& h5,& h6": {
      margin: "0 !important",
      [theme.breakpoints.down("md")]: {
        background: "none",
        textAlign: "right",
        paddingRight: "0",
        fontSize: "13px",
        maxWidth: "75%",
        position: "absolute",
        right: "0",
        bottom: "8px"
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        fontWeight: "500"
      },
      background: "rgba(" + hexToRgb(theme.palette.grey[300]) + ",.4)",
      width: "auto",
      float: "right",
      textAlign: "center",
      padding: "0 10px",
      fontSize: "16px",
      fontWeight: "500",
      color: theme.palette.primary.text
    }
  },
  cardHeaderIcon: {
    "&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader": {
      background: "transparent",
      boxShadow: "none"
    },
    "& i,& .material-icons": {
      width: "33px",
      height: "33px",
      textAlign: "center",
      lineHeight: "33px"
    },
    "& svg": {
      width: "24px",
      height: "24px",
      textAlign: "center",
      lineHeight: "33px",
      margin: "5px 4px 0px",
      color: theme.palette.primary.main
    }
  },
  warningCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderIcon)": {
      ...warningCardHeader
    }
  },
  successCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderIcon)": {
      ...successCardHeader
    }
  },
  dangerCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderIcon)": {
      ...dangerCardHeader
    }
  },
  infoCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderIcon)": {
      ...infoCardHeader
    }
  },
  primaryCardHeader: {
    zIndex: "999 !important",
    "& fieldset": {
      borderColor: theme.palette.grey[500] + " !important"
    },
    "&:not($cardHeaderIcon)": {
      background: 'transparent',
      boxShadow: 'none'
    }
  },
  roseCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderIcon)": {
      ...roseCardHeader
    }
  },
  pageHeader: {
    marginBottom: '10px !important',
    "& > div": {
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center'
      },     
      
      [theme.breakpoints.down('md')]: {
        alignItems: 'center'
      },
    },
    "& svg": {
      color: theme.palette.primary.main
    }
  }
}
};

