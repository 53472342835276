import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import SAYACustomInput from "../../components/CustomInput/SAYACustomInput";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";

import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";

//custom hook for ConfirmPassword
import useConfirmPassword from "../../hooks/useConfirmPassword";

//import actions from action handlers
import { confirmForgotPasswordHandler } from "../../action-handlers/LoginActionsHandler";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ConfirmForgotPassword(props) {
  const classes = useStyles();
  const [isConfirmPasswordSuccess, setConfirmPasswordSuccess] = useState(false);
  const navigate = useNavigate();

  const submit = (values) => {
    //console.log("state.input.userName", state.input.userName);
    console.log("RP FINAL SUBMIT", values);
    confirmForgotPasswordHandler(values, callBackUserLoginDetails);
  };

  const callBackUserLoginDetails = (userLoginDetails) => {
   
    setConfirmPasswordSuccess(userLoginDetails.isSuccess);

    if (!userLoginDetails.isSuccess) {
      handleForgotPasswordFailed();
    }
   
  };
  const { state, handleChange, handleSubmit, handleForgotPasswordFailed } =
    useConfirmPassword(submit); // add validation func, initState as argum
  

  useEffect(() => {
    console.log("Insider ConfirmForgot Password useEffect");
  }, []);

  return isConfirmPasswordSuccess ? (
    // <Redirect to="/saya-platform/login" />
    navigate("/saya-platform/login")
  ) : (
    <div>
      <form onSubmit={handleSubmit} noValidate xs={12} sm={12} md={8}>
        <GridContainer justifyContent="center" style={{marginTop: '60px'}}>
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="primary">
                <h4>
                  Confirm Forgot Password
                </h4>
                <p>
                  Please change the password after entering the confirmation
                  code.
                </p>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={1} sm={1} md={3}></GridItem>
                  <GridItem xs={12} sm={9} md={6}>
                    <p style={{margin: 0}}>User Name</p>
                    <SAYACustomInput
                      state={state}
                      handleChange={handleChange}
                      labelText=""
                      id="userName"
                      position="center"
                      inputProps={{
                        style: { textAlign: "center", marginTop: '5px', marginBottom: '5px' },
                        //defaultValue: state.input.userName
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      margin="normal"
                    />
                    <span
                      style={{ color: "red" }}
                      className="validation-errors"
                    >
                      {state.validationErrs.userName}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={1} sm={1} md={3}></GridItem>
                  <GridItem xs={12} sm={9} md={6}>
                  <p style={{margin: 0}}>Confirmation Code</p>
                    <SAYACustomInput
                      state={state}
                      handleChange={handleChange}
                      labelText=""
                      id="confirmationCode"
                      position="center"
                      inputProps={{
                        style: { textAlign: "center", marginTop: '5px',marginBottom: '5px'   },
                        //defaultValue: state.input.userName
                      }}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      margin="normal"
                    />
                    <span
                      style={{ color: "red" }}
                      className="validation-errors"
                    >
                      {state.validationErrs.userName}
                    </span>
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={1} sm={1} md={3}></GridItem>
                  <GridItem xs={12} sm={9} md={6}>
                  <p style={{margin: 0}}>New Password</p>
                    <SAYACustomInput
                      state={state}
                      handleChange={handleChange}
                      labelText=""
                      id="password"
                      position="center"
                      //value={String(state.input.password)}

                      inputProps={{
                        type: "password",
                        style: { textAlign: "center", marginTop: '5px'  },
                        //defaultValue: state.input.password,
                        //onChange: {handleChange}
                      }}
                      formControlProps={{
                        fullWidth: true,
                        position: "center",
                      }}
                    />
                    <span
                      style={{ color: "red" }}
                      className="validation-errors"
                    >
                      {state.validationErrs.password}
                    </span>
                  </GridItem>
                </GridContainer>
                <div>
                  <br />
                  <GridContainer>
                    <GridItem xs={1} sm={1} md={2}></GridItem>

                    <GridItem xs={2} sm={2} md={3}></GridItem>
                    <GridItem xs={12} sm={4} md={4}>
                      <Button variant="contained" color="primary" type="submit">
                        Submit
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>

              <CardFooter>
                <br />
                <Footer />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}
