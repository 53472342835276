import { alpha } from "@mui/material/styles";
import { hexToRgb } from "assets/jss/material-dashboard-react.js";

const styles = (theme) => {
  //const theme = useTheme();
  return {
    "@global": {
      ".action-buttons": {},
    },
   
    reconTableWrapper: {
      "& table tr div": {
        fontFamily: "Poppins, sans-serif !important",
      },
      "& table tr td": {
        fontFamily: "Poppins, sans-serif !important",
      },
      "& table tr th": {
        fontFamily: "Poppins, sans-serif !important",
      },
      "& table tr td": {
        padding: "0.5rem !important",
        whiteSpace: "nowrap",
        //maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis !important",
        display: "inline-block",
      },
      "& table tr th": {
        padding: "0.5rem !important",
      },
      "& table tr:last-child td:last-child": {
        // backgroundColor: 'transparent !important'
      },
    },
    matchingHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    formObjectBtn: {
      border: "1px solid #d4186d",
      padding: "5px",
      borderRadius: "5px",
      position: "absolute",
      lineHeight: "11px",
      fontSize: "12px",
      cursor: "pointer",
      right: "12px",
      minWidth: "30px",
      height: "25px",
      display: "inline-flex",
      backgroundColor:
      theme.palette.type === "dark" ? "#1a1546" : "#fff",
      verticalAlign: "middle",
      "& > b": {
        lineHeight: "13px",
        fontWeight: "400",
      },
      "& span svg ": {
        position: "absolute",
        right: "2px",
        top: "0",
        transform: "rotate(0deg)",
        transformOrigin: "center",
      },
      "& span.active svg ": {
        transform: "rotate(180deg)",
        display: "inline",
      },
    },
    suggestedMatchTable: {
      "& table thead th ": {
        padding: "6px 10px !important",
        backgroundColor:
          theme.palette.type === "dark" ? "transparent" : "#f2f2f2 !important",
        "& > div": {
          whiteSpace: "nowrap",
        },
        "& div span": {
          padding: "2px !important",
        },
      },
      "& table td ": {
        width: "48px",
      },
      "& table td > div": {
        whiteSpace: "nowrap",
      },
      "& table td span": {
        padding: "0px 3px",
      },
    },
    auditInfoWrapper: {
      marginTop: "15px",
      width: "90%",
      maxHeight: "300px",
      overflow: "auto",
      border: "1px solid #e6e6e6",
      "& table": {
        tableLayout: "auto",
      },
      "& table thead tr th:first-child": {
        width: "90px !important",
      },
      "& table thead tr th:last-child": {
        width: "290px !important",
      },
      "& table thead tr th": {
        backgroundColor:
          theme.palette.type === "dark" ? "transparent" : "#f5f5f5 !important",
      },
      "& table td ": {
        padding: "10px 15px",
      },
    },
    reconMatchTabs: {
      paddingLeft: "0px",
      "& > div > div ": {
        minHeight: "30px",
        padding: 0,
      },
      "& > div > div button": {
        minHeight: "30px",
        padding: "5px",
        backgroundColor: "transparent !important",
      },
    },
    formObjectHeader: {
      backgroundColor: theme.palette.type === "dark" ? "transparent" : "#fff",
      border: "1px solid #e0e0e0",
      alignItems: "center",
      padding: "0px 10px",
      position: "absolute",
      zIndex: -1,
      height: "42px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      "@media (min-width: 0px) and (max-width: 1279.95px)": {
        width: "50% !important",
        position: "relative",
        "& > div": {
          display: "none",
        },
        "& > div:last-child": {
          display: "block !important",
          left: 10,
          maxWidth: "130px",
        },
      },
      "& > div": {
        marginRight: 15,
        fontWeight: "600",
      },
      "& > div span": {
        marginLeft: 3,
        fontWeight: 400,
      },
    },
    linkTableWrapper: {
      "& table thead tr th > div": {
        transition: "transform 0.3s, font-weight 0.3s",
      },
      "& table thead th.reconMatchCell1": {
        cursor: "pointer",
      },
      // "& table thead th.reconMatchCell2": {
      //   cursor: "pointer",
      // },
      "& table thead th .cellIcon": {
        color: "#d4186d",
        display: "none",
      },
      "& table thead th.activeCell .cellIcon": {
        display: "inline",
      },
      "& table thead th.activeCell": {
        "& div": {
          // transform: "scale(1.1)",
          // fontWeight: "bold",
          //filter: "brightness(0.8)",
        },
      },
      "& table thead tr": {
        cursor: "default",
      },
      "& table thead tr th": {
        backgroundColor:
          theme.palette.type === "dark" ? "transparent" : "#f2f2f2 !important",
        "& button:hover": {
          backgroundColor: "transparent !important",
        },
      },
      "& table thead tr div": {
        //border: "1px solid #fff",
        backgroundColor:
          theme.palette.type === "dark" ? "transparent" : "#f2f2f2",
      },
      "& table tr": {
        cursor: "pointer",
        userSelect: "none",
      },
      "& table tr:hover td": {
        backgroundColor:
          theme.palette.type === "dark"
            ? "transparent"
            : "rgb(243 243 255) !important",
      },
      "& table tfoot tr:hover td": {
        backgroundColor: "transparent !important",
      },
    },
    ruleHeader: {
      padding: "5px 10px",
      border: "2px solid #fff",
      borderRadius: "5px",
      width: "100%",
      minWidth: "164px",
      justifyContent: "center",
      borderRadius: "5px",
      height: "60px",
      display: "inline-flex",
      alignItems: "center",
    },
    ruleDefaultHeader: {
      backgroundColor: "#eee",
    },
    datePickerGrid: {
      "& label": {
        fontSize: 13,
      },
      "& input": {
        paddingBottom: 12,
      },
    },
    actionHeader: {
      margin: 0 + " !important",
    },
    tabsWrapper: {
      minHeight: "38px",
      "& button": {
        fontSize: "14px",
        padding: "5px 16px",
        minHeight: "36px",
        borderTopLeftRadius: "6px",
        borderTopRightRadius: "6px",
      },
      "& button.Mui-selected": {
        border: "1px solid #C4CDD5",
      },
    },
    reconTitle: {
      margin: 0,
      position: "absolute",
      marginTop: "10px",
      zIndex: "9",
      left: "40px",
      fontWeight: "400",
      "& + div table tr": {
        cursor: "pointer",
        userSelect: "none",
      },
      "& + div table tr:hover td": {
        backgroundColor:
          theme.palette.type === "dark"
            ? "#605d77 !important"
            : "rgb(246 246 247) !important",
      },
      "& + div table tr td:first-child span": {
        border: "1px solid transparent",
      },
      "& + div table tr:hover td:first-child span": {
        border: "1px solid #e2e2e2",
      },
      "& + div": {
        border: "1px solid #C4CDD5",
        borderRadius: "0",
      },
      "@media (min-width: 0px) and (max-width: 767px)": {
        left: "28px",
        maxWidth: "140px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      "& > span": {
        "@media (min-width: 0px) and (max-width: 767px)": {
          display: "none",
        },
      },
    },
    reconComboWrapper: {
      "@media (min-width: 0px) and (max-width: 767px)": {
        flexDirection: "column",
      },
      "& > div": {
        "@media (min-width: 0px) and (max-width: 767px)": {
          marginBottom: "10px",
        },
      },
      "& > div label + div": {
        maxWidth: "initial",
        "@media (min-width: 768px) and (max-width: 1279.95px)": {
          minWidth: "160px !important",
          maxWidth: "160px",
        },
      },
    },
    backBtn: {
      backgroundColor: theme.palette.primary.main + " !important",
      display: "inline-flex",
      minHeight: "41px",
      border: "3px solid #fff",
      borderRadius: "7px",
      alignItems: "center",
      minWidth: "41px",
      justifyContent: "center",
      marginLeft: "30px",
      "& svg": {
        color: "#fff",
      },
      "@media (min-width: 0px) and (max-width: 1279.95px)": {
        minWidth: "36px",
        minHeight: "36px",
      },
    },
    downloadButton: {
      padding: "0",
      borderRadius: "0",
      width: "100%",
      textAlign: "center",
      height: "100%",
      "&:hover": {
        background: "transparent",
      },
      "& > svg": {
        marginRight: "0 !important",
        fontSize: "32px !important",
      },
    },
    actionGrid: {
      position: "relative",
      paddingRight: "0px",
      display: "flex",
      alignItems: "baseline",
      "@media (min-width: 0px) and (max-width: 1279.95px)": {
        alignItems: "center",
      },
    },
    disabledstate: {
      "& > div": {
        opacity: 0.6,
        pointerEvents: "none",
      },
    },
    datePicker: {
      marginTop: "-5px",
      "& > div > span": {
        fontWeight: "400",
        fontSize: "10px",
      },

      "& > div": {
        marginRight: "18px",
      },
      "& div nav": {
        padding: 0,
      },
      "& div nav > div": {
        border: "1px solid #ccc",
        backgroundColor: theme.palette.type === "dark" ? "transparent" : "#fff",
        height: "39.6px",
        marginTop: "0px",
        borderRadius: "5px",
        // "@media (min-width: 0px) and (max-width: 1279.95px)": {
        //   padding: "6px",
        //   height: "auto",
        // },
      },
      "& div nav > div > div + div": {
        marginLeft: "-20px",
        "& > span": {
          fontSize: "13px",
          color: "#919EAB",
          marginTop: "2px",
        },
      },
    },
    actionWrapper: {
      display: "inline-flex",
      float: "right",
      paddingRight: "5px",
      paddingLeft: "0px",
      borderRadius: "8px",
      alignItems: "center",
      marginTop: 0,
      "& button.ai-ethics-btn": {
        [theme.breakpoints.down("lg")]: {
          padding: "5px 5px 5px 15px",
          right: "10px",
          minWidth: "auto !important",
          left: "0",
        },
        "& > span": {
          [theme.breakpoints.down("lg")]: {
            marginRight: "0 !important",
          },
        },
      },
      "& button span.ai-btn-text": {
        [theme.breakpoints.down("lg")]: {
          display: "none",
        },
      },
      "& > div": {
        marginLeft: "12px",
        borderRadius: "6px",
        display: "inline-flex",
        background:
          theme.palette.type === "dark"
            ? "transparent"
            : theme.palette.common.white,
        [theme.breakpoints.down("md")]: {
          width: "auto",
          height: "auto",
        },
        [theme.breakpoints.down("sm")]: {
          width: "auto",
          height: "auto",
        },
      },
      "& > div > div": {
        display: "inline-flex",
        alignItems: "center",
        borderWidth: "2px",
        borderColor:
          theme.palette.type === "dark"
            ? theme.palette.primary.main
            : "rgb(255, 255, 255)",
        borderStyle: "solid",
        borderRadius: "6px",
        background: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.09)",
        boxShadow: theme.palette.common.shadow,
        padding: 5,
        height: "40px",
        width: "40px",

        [theme.breakpoints.down("lg")]: {
          width: "36px",
          height: "36px",
        },

        [theme.breakpoints.down("sm")]: {
          marginLeft: "0px",
        },
        "& svg": {
          marginRight: "12px",
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
        },
        "&:hover": {
          background: theme.palette.common.white,
        },
      },
      "& > div, & > svg": {
        "&:empty": {
          marginRight: "0",
          display: "none",
        },

        [theme.breakpoints.down("lg")]: {
          width: "36px",
          height: "36px",
        },
      },
      "& > svg:last-child": {
        marginRight: "0",
        position: "relative",
        top: "-5px",
        marginLeft: "8px",
        [theme.breakpoints.down("lg")]: {
          top: "0px",
        },
      },
      "@media (min-width: 0px) and (max-width: 599.95px)": {
        marginTop: 10,
        marginBottom: 15,
      },
      "& svg": {
        "@media (min-width: 0px) and (max-width: 767px)": {
          fontSize: "1.5rem",
          marginRight: 3,
        },
        "@media (min-width: 768px) and (max-width: 1279.95px)": {
          fontSize: "1.5rem",
          marginRight: 5,
        },
      },
      "@media (min-width: 0px) and (max-width: 767px)": {
        paddingRight: "5px",
        paddingLeft: "5px",
        "& > div": {
          height: "20px",
        },
        "& > div, & > svg": {
          marginRight: "12px",
        },

        "& > div, & > svg": {
          marginRight: "5px",
        },
      },
      "@media (max-width: 1279.95px)": {
        background: "none",
      },
    },
    filterSubmitButton: {
      position: "absolute",
      top: 0,
    },
    inprogressBtn: {
      opacity: 0.5,
    },
    rightAlign: {
      textAlign: "right",
      paddingRight: "18px",
    },
    switchInput: {
      [theme.breakpoints.down("lg")]: {
        justifyContent: "start",
      },
      "& button": {
        height: 44,
        background: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.09)",
        border: `2px solid ${theme.palette.common.white}`,
        boxShadow: theme.palette.common.shadow,
        [theme.breakpoints.down("lg")]: {
          height: 36,
          padding: 6,
        },
        [theme.breakpoints.down("md")]: {
          height: 36,
          padding: 6,
        },
        "& svg": {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
      },
      "& > div": {
        alignItems: "end",
      },
      marginTop: 0,
      "@media (min-width: 0px) and (max-width: 599.95px)": {
        marginTop: 10,
        marginBottom: 15,
      },
      "& > label": {
        margin: "0 0 0 22px",
        "@media (min-width: 0px) and (max-width: 1279.95px)": {
          "& div": {
            paddingLeft: "10px",
            fontSize: "10px",
          },
          zoom: 0.8,
          marginLeft: 0,
          width: "140px",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        },
      },
    },
    uploadIcon: {
      marginTop: 16,
      "& > div > div": {
        boxShadow: "none",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 16,
      },
    },
    dynamicForm: {
      padding: "5px 2px 8px 2px",
      backgroundColor: theme.palette.background.card,
      "& input": {
        fontSize: 13,
        padding: "10px 14px",
        [theme.breakpoints.down("lg")]: {
          fontSize: 12,
          padding: 10,
        },
      },
    },
    headerLabel: {
      minWidth: 162,
      marginTop: "24px",
      padding: "9px",
      border: "2px solid " + theme.palette.border.primary,
      marginLeft: "-2px",
      borderTopRightRadius: "6px",
      borderBottomRightRadius: "6px",
      color: alpha(theme.palette.text.primary, 0.8),
      boxShadow: theme.palette.common.shadow,
      [theme.breakpoints.down("lg")]: {
        minWidth: "auto",
        fontSize: "12px",
        display: "none",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    actionBtn: {
      background: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    auditWrapper: {
      padding: "0px 10px 10px 25px",
      background: "#fff",
      marginBottom: "20px",
      "& > div": {
        //display: "inline-block",
        //border: "1px solid #e1e1e1",
        padding: "10px 10px 10px 10px",
        borderRadius: "8px",
        position: "relative",
        color: "#637381",
        "& > div": {
          color: "#637381",
        },
        // "& > span": {
        //   fontWeight: "500",
        //   position: "absolute",
        //   top: "-11px",
        //   background: "#fff",
        //   color: "#637381",
        // },
      },
    },

  };
};

export default styles;
