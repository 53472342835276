import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
// core components
import Snackbar from "../../components/Snackbar/Snackbar";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import SAYACustomInput from "../../components/CustomInput/SAYACustomInput";
import Footer from "../../components/Footer/Footer";
import Button from "../../components/CustomButtons/Button";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CardFooter from "../../components/Card/CardFooter";
import saya from "../../assets/img/SAYA.svg";
import backgroundImg from "../../assets/img/background.png";

//custom hook for userLoginPage
import useLoginForm from "../../hooks/useLoginForm";
import { LOGIN_FAILED } from "../../messages/DashboardMessages";
//import actions from action handlers
import {
  loginToCognito,
  fetchUserDetailsByLogin,
} from "../../action-handlers/LoginActionsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { showFailureNotification } from "../../action-handlers/NotificationsHandler";
import { notificationParams } from "../../variables/general";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import network from "../../assets/img/network.png";
import { fetchworkflows } from "action-handlers/WorkflowActionHandler";
import { WorkflowContext } from "context/Workflow/WorkflowContext";

const styles = (theme) => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
  },
  loginHeader: {
    backgroundColor: '#225073',
    backgroundSize: 'cover'
    //backgroundImage: "../assets/img/network.jpg"
  },
  loginCard: {
    background: theme.palette.common.white,
    boxShadow: '0 0 20px #aabacd'
  },
  link: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const classes = useStyles();
  const [isLoginSuccess, setLoginSuccess] = useState(false);
  const [isFirtTimeLogin, setFirstTimeLogin] = useState(false);
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const WorkflowDataContext = useContext(WorkflowContext);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const navigate = useNavigate();
  const submit = (values) => {
    loginToCognito(values, callBackUserLoginDetails);
  };

  const callBackUserLoginDetails = (userLoginDetails) => {
    if (!userLoginDetails.isSuccess) {
      handleLoginFailed();
      return;
    }
    if (userLoginDetails.challengeResult === "NEW_PASSWORD_REQUIRED") {
      setFirstTimeLogin(true);
      //isFirtTimeLogin = true;
    } else {
      fetchUserDetailsByLogin(
        userLoginDetails.userName,
        callBackFetchUserDetailsByLogin
      ); //TODo REplacement by implementing hooks
    } 
  };

  const saveWorkflowData = (Workflows) => {
    WorkflowDataContext.saveWorkflows(Workflows);
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      setLoginSuccess(userDetailsState.isLoggedIn);
      fetchworkflows(userDetailsState.roleId, saveWorkflowData);
    } else {
      showFailureNotification(LOGIN_FAILED, callbackNotification);
      setLoginSuccess(apiStatus);
    }
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const { state, handleChange, handleSubmit, handleLoginFailed } =
    useLoginForm(submit); // add validation func, initState as argum

  /* const handleForgotPassword = e => {
    console.log("handleForgotPassword called");
    e.preventDefault();
    window.location.assign("/saya-platform/forgot-password"); 
  };
*/

  useEffect(() => {
    console.log("Insider LoginPage useEffect");
  }, []);

  return (
    //<userDetailContext.Provider value={userDetailsInitialState}>
    isFirtTimeLogin ? (
      // <Redirect
      //   to={{
      //     pathname: "/saya-platform/change-temp-password",
      //     state: { firstLogin: true }
      //   }}
      // />
      <Navigate
        to="/saya-platform/change-temp-password"
        state={{ firstLogin: true }}
      />
    ) : isLoginSuccess ? (
      // <Redirect to="/saya-platform/dashboard" />
      <Navigate to="/saya-platform/dashboard" />
    ) : (
      <div>
        
        <form onSubmit={handleSubmit} noValidate>
          <GridContainer justifyContent="center" style={{ marginTop: "100px" }}>
            <GridItem xs={12} sm={10} md={5}>
              <Card className={classes.loginCard}>
                <CardHeader
                  color=""
                  className={classes.loginHeader}
                  style={{
                    backgroundImage: "url(" + network + ")",
                    borderRadius: "12px  12px 0 0",
                   
                  }}
                >
                  <img
                    className={classes.cardImgTop}
                    data-src="holder.js/100px180/"
                    alt="100%x180"
                    src={saya}
                    data-holder-rendered="true"
                    style={{
                      height: "181px",
                      width: "75%",
                      display: "block",
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                  />
                </CardHeader>

                <CardBody>
                  <GridContainer
                    justifyContent="center"
                    style={{ marginTop: "30px" }}
                  >
                    <GridItem xs={12} sm={10} md={8}>
                      <GridContainer>
                        <p style={{ margin: 0 }}>User Name</p>
                        <SAYACustomInput
                          state={state}
                          handleChange={handleChange}
                          labelText=""
                          id="userName"
                          inputProps={{
                            style: { textAlign: "left", marginTop: "5px" },
                            type: "text",
                            maxLength: "4",
                            //defaultValue: state.input.userName
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          margin="normal"
                        />
                        <span
                          style={{ color: "red" }}
                          className="validation-errors"
                        >
                          {state.validationErrs.userName}
                        </span>
                      </GridContainer>
                      <GridContainer style={{ marginTop: "10px" }}>
                        <p style={{ margin: 0 }}>Password</p>
                        <SAYACustomInput
                          state={state}
                          handleChange={handleChange}
                          labelText=""
                          id="password"
                          position="left"
                          inputProps={{
                            type: "password",
                            style: { textAlign: "left", marginTop: "5px" },
                            //defaultValue: state.input.password,
                            //onChange: {handleChange}
                          }}
                          formControlProps={{
                            fullWidth: true,
                            position: "center",
                          }}
                        />
                        <span
                          style={{ color: "red" }}
                          className="validation-errors"
                        >
                          {state.validationErrs.password}
                        </span>
                      </GridContainer>
                      <GridContainer
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {/*<Button color="warning" onClick= {handleForgotPassword}>Forgot Password</Button>*/}

                        <Link
                          to="/saya-platform/forgot-password"
                          state={{ ...state }}
                        >
                          <p className={classes.left} color="warning">
                            <span
                              className={classes.link}
                              style={{ color: "warning" }}
                            >
                              Forgot Password
                            </span>
                          </p>
                        </Link>

                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          style={{ padding: "10px 36px" }}
                        >
                          Login
                        </Button>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <CardFooter style={{ display: "block" }}>
                  <Footer />
                </CardFooter>
              </Card>
            </GridItem>
            <Snackbar
              place={notificationObj.place}
              color={notificationObj.color}
              icon={notificationObj.icon}
              message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
              open={notificationObj.open}
              closeNotification={handleCloseNotification}
              close={notificationObj.close}
            />
          </GridContainer>
        </form>
      </div>
    )
  );
}
