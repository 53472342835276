// ----------------------------------------------------------------------

export default function Tooltip(theme) {
  return {
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: theme.palette.primary.main
        },
        root: {
          marginRight: 15,
          marginTop: 15,
          "& +span": {
            marginTop: 10,
          },
        },
      },
    },
  };
}
