import React, { useState, useEffect } from "react";

export const CheckAccess = (props) => {
  let { children, action, wrapEnabled, elemClass } = props;
  let { allowedPermissions } = props && props.routeData;

  let [render, setRender] = useState(false);

  useEffect(() => {
    if (allowedPermissions && allowedPermissions.includes(action)) {
      setRender(true);
    }
  }, [action, allowedPermissions]);

  try {
    if (render) {
      return wrapEnabled && children ? <div className={elemClass || ""}><div>{children}</div></div> : <div  className={elemClass || ""}>{children}</div>;
    }
  } catch {
    return null;
  }
  return null;
};
