import { API, USERAPI } from "URL.js";
import axios from "axios";

export const deleteReconUnitData = (reconUnitId, userId, callback) => {
  let isSuccess = false;
  axios
    .post(API + "/reconunits/close/" + reconUnitId + "/" + userId)
    .then((response) => {
      if (response.status === 200) {
        isSuccess = true;
        callback(isSuccess);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(isSuccess);
    });
};

export const saveReconUnitDetails = (
  reconProcess,
  selectedDepts,
  contextState,
  callback
) => {
  let payload = {};
  let data = {
    clientReconName: reconProcess.input.clientReconName,
    reconUnitId: reconProcess.input.reconUnitId,
    userId: contextState?.userId,
    dataSourceId1: reconProcess.input.dataSourceId1,
    dataSrcConfigId1: reconProcess.input.dataSrcConfigId1,
    dataSourceId2: reconProcess.input.dataSourceId2,
    dataSrcConfigId2: reconProcess.input.dataSrcConfigId2,
    identicalData: reconProcess.input.identicalData,
    headerData: reconProcess.input.headerData,
    multimatchReqd: reconProcess.input.multimatchReqd,
    workflowEnabled: reconProcess.input.workflowEnabled,
    reconEnabled: reconProcess.input.reconEnabled,
    oneToMany: reconProcess.input.oneToMany,
    manyToOne: reconProcess.input.manyToOne,
    multimatchAggrBuffer: reconProcess.input.multimatchAggrBuffer,
    createdBy: contextState?.userId,
    mlEnabled: reconProcess.input.mlEnabled,
    parentOrgId: contextState?.clientOrgId,
    createdDate: reconProcess.input.createdDate,
    updatedDate: reconProcess.input.updatedDate,
    formLayout: reconProcess.input.formLayout || "1-COLUMN",
  };
  payload.clientReconUnit = data;
  payload.departmentIdList = selectedDepts;
  payload.wfProcessList = reconProcess.input.wfProcessList;
  let isSuccess = false;

  axios
    .post(API + "/reconunits/create", payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        isSuccess = true;
        callback(isSuccess);
      } else {
        isSuccess = false;
        callback(isSuccess, response.data?.responseMessage);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(false);
    });
};

export const getDepartmentsById = (userId, callback) => {
  axios
    .get(`${USERAPI}/department/all/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((error) => {
      console.log("Error inside getDepartmentsById function", error);
      callback(false, []);
    });
};

export const getReconProcessDepartments = (reconUnitId, callback) => {
  axios
    .get(`${API}/reconunits/reconunitdepartments/${reconUnitId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(true, response.data);
      }
    })
    .catch((error) => {
      console.log("Error inside getReconProcessDepartments function", error);
      callback(false, []);
    });
};
