import React, { useState, useEffect, useContext } from "react";
import { CloudUpload } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import styles from "./ReconStyles";
import { API_DATA_UPLOAD_SUCCESS } from "messages/DashboardMessages";
import { notificationParams } from "variables/general";
import { CheckAccess } from "components/Permissions/CheckAccess";
import SayaDialogFileUpload from "components/CustomDialog/SayaDialogFileUpload";
import Snackbar from "components/Snackbar/Snackbar";
import { ReconXContext } from "context/ReconX/ReconXContext";
import { uploadCSVFile } from "action-handlers/ReconXActionHandler";
import { showSuccessNotification } from "action-handlers/NotificationsHandler";
import { showFailureNotification } from "action-handlers/NotificationsHandler";
import { API_FILE1_UPLOAD_FAILED } from "messages/DashboardMessages";
import { API_FILE2_UPLOAD_FAILED } from "messages/DashboardMessages";

function ReconXUpload(props) {
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [fileNameForDataSrc1, setFileNameForDataSrc1] = useState("");
  const [fileNameForDataSrc2, setFileNameForDataSrc2] = useState("");
  const [fileObjectSrc1, setFileObjectSrc1] = useState();
  const [fileObjectSrc2, setFileObjectSrc2] = useState();
  const [isFilesUploading, setFilesUploading] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);

  let fileSrc1Uploaded = false;
  let fileSrc2Uploaded = false;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const ReconXContextData = useContext(ReconXContext);
  const { globalDataSrcData, selectedReconUnit } = ReconXContextData.state;

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };

  const dataSrc2FileUpload = (e) => {
    setFileNameForDataSrc2(e.target.files[0].name);
    setFileObjectSrc2(e.target.files[0]);
  };

  const dataSrc1FileUpload = (e) => {
    //let src1FileName = {...fileNameForDataSrc1};
    setFileNameForDataSrc1(e.target.files[0].name);
    setFileObjectSrc1(e.target.files[0]);
  };

  const uploadSelectedFiles = () => {
    setFilesUploading(true);
    if (fileNameForDataSrc1 !== "" && fileNameForDataSrc1 !== undefined) {
      let dataSrc = 1;
      uploadCSVFile(
        fileObjectSrc1,
        selectedReconUnit,
        dataSrc,
        callbackUploadCSVFile1
      );
    } else {
      fileSrc1Uploaded = true;
    }

    if (fileNameForDataSrc2 !== "" && fileNameForDataSrc2 !== undefined) {
      let dataSrc = 2;
      uploadCSVFile(
        fileObjectSrc2,
        selectedReconUnit,
        dataSrc,
        callbackUploadCSVFile2
      );
    } else {
      fileSrc2Uploaded = true;
    }
  };

  const validateBothFilesUploaded = () => {
    if (fileSrc1Uploaded && fileSrc2Uploaded) {
      showSuccessNotification(API_DATA_UPLOAD_SUCCESS, callbackNotification);
      setFileUploadDialogOpen(false);
      props.setFetchData(true);
      setFilesUploading(false);

      setFileNameForDataSrc1("");
      setFileObjectSrc1("");
      setFileNameForDataSrc2("");
      setFileObjectSrc2("");
      fileSrc1Uploaded = false;
      fileSrc2Uploaded = false;
    }
  };

  const callbackUploadCSVFile2 = (apiStatus) => {
    if (apiStatus) {
      fileSrc2Uploaded = true;
    } else {
      fileSrc2Uploaded = false;
      showFailureNotification(API_FILE2_UPLOAD_FAILED, callbackNotification);
    }
    validateBothFilesUploaded();
  };

  const callbackUploadCSVFile1 = (apiStatus) => {
    if (apiStatus) {
      fileSrc1Uploaded = true;
    } else {
      fileSrc1Uploaded = false;
      showFailureNotification(API_FILE1_UPLOAD_FAILED, callbackNotification);
    }
    validateBothFilesUploaded();
  };

  const uploadCSV = (e) => {
    setFileUploadDialogOpen(true);
  };

  const handleFileDialogClose = () => {
    setFileUploadDialogOpen(false);
    setFileNameForDataSrc1("");
    setFileObjectSrc1("");
    setFileNameForDataSrc2("");
    setFileObjectSrc2("");
    fileSrc1Uploaded = false;
    fileSrc2Uploaded = false;
    setFilesUploading(false);
  };

  return (
    <>
      <Box
        sx={{ display: { xs: "inline-block", sm: "inline-block" } }}
        className={`${classes.uploadIcon} ${classes.actionWrapper}`}
      >
        {" "}
        <div>
          <CheckAccess {...props} action={"upload csv"}>
            <Tooltip title="Upload CSV file">
              <CloudUpload
                className="mr-4 MuiSvgIconRoot"
                style={{ cursor: "pointer" }}
                fontSize="large"
                onClick={uploadCSV}
              />
            </Tooltip>
          </CheckAccess>
        </div>
        <SayaDialogFileUpload
          open={fileUploadDialogOpen}
          dialogTitle={"SAYA ReconX File Upload (only CSV Files)"}
          handleClose={handleFileDialogClose}
          uploadFile1ButtonLabel={
            globalDataSrcData[0]?.label || "Data Source - 1"
          }
          fileName1={fileNameForDataSrc1}
          fileUpload1={dataSrc1FileUpload}
          uploadFile2ButtonLabel={
            globalDataSrcData[1]?.label || "Data Source - 2"
          }
          fileName2={fileNameForDataSrc2}
          fileUpload2={dataSrc2FileUpload}
          submitFiles={uploadSelectedFiles}
          filesUploading={isFilesUploading}
        />
      </Box>
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={notificationObj.icon}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </>
  );
}

export default ReconXUpload;
