import * as Yup from "yup";

export const signUpValidationsForm = Yup.object().shape({
  userName: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Enter a valid email").required("Email is required"),
  organisationId: Yup.string().required("Select your Organization"),
  departmentId: Yup.string().required("Select your Department"),
  roleId: Yup.string().required("Select your Role")
});

export const departmentValidations = Yup.object().shape({
  departmentName: Yup.string().required("Required"),
  departmentDlEmail: Yup.string().email("Enter a valid email"),
  escalationEmailAddress: Yup.string().email("Enter a valid email")
  //createdDate: Yup.string().required("Enter a valid date")
});
