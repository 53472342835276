import { USERAPI } from "URL";
import { API } from "URL.js";
import axios from "axios";

export const fetchAnalytiXData = (userId, orgId, roleId, callback) => {
  let fetchedAnalytixConfig = [];

  axios
    .get(
      API +
        "/reconanalytix/fetchanalytixconfig?userId=" +
        userId +
        "&orgId=" +
        orgId +
        "&roleId=" +
        roleId
    )
    .then((response) => {
      response.data.forEach((item) => {
        fetchedAnalytixConfig.push({
          ...item,
          analytixId: item.analytixId,
          analytixName: item.analytixName,
          analytixIframeLink: item.analytixLink,
        });
      });
      callback(fetchedAnalytixConfig, true);
    })
    .catch((error) => {
      console.log("Exception occured::{}", error);
      callback(fetchedAnalytixConfig, false);
    });
};

export const getGuestToken = (payload, callback) => {
  axios
    .post(`${USERAPI}/superset/guest-token`, payload)
    .then((response) => {
      callback(response.data);
    })
    .catch((ex) => {
      callback(ex);
    });
};
