import AxiosService from "AxiosService.js";
const axiosService = AxiosService.getService();
export default (state, action) => {
  let token;
  switch (action.type) {
    case "SUCCESS_SAVE_USER_TOKEN":
      token = action.payload.token;
      if (token) {
        sessionStorage.setItem("token", token);
      }
      return {
        ...state,
        userId: action.payload.userId,
        userEmail: action.payload.email,
        firstName: action.payload.firstName,
        departmentId: action.payload.departmentId,
        departmentName: action.payload.departmentName,
        clientOrgId: action.payload.clientOrgId,
        parentClientOrgId: action.payload.parentClientOrgId,
        clientShortName: action.payload.shortName,
        roleId: action.payload.roleId,
        loginName: sessionStorage.getItem("loginName"),
        isLoggedIn: true,
        userToken: sessionStorage.getItem("token"),
      };
    case "LOGOUT_STATE_DATA_CLEAR":
      sessionStorage.setItem("token", "");
      sessionStorage.setItem("loginName", "");
      sessionStorage.clear();
      axiosService.clearToken();
      return {
        userId: undefined,
        userEmail: "",
        firstName: "",
        departmentId: undefined,
        departmentName: "",
        clientOrgId: undefined,
        parentClientOrgId: undefined,
        clientShortName: "",
        roleId: undefined,
        loginName: "",
        isLoggedIn: false,
        userToken: "",
        themeType: "light",
      };
    case "FAILURE_FETCH_USER_DATA":
      //token = action.payload.token;
      axiosService.setToken("");
      //sessionStorage.setItem("token", token);
      return {
        ...state,
        isLoggedIn: false,
        //userToken: sessionStorage.getItem("token"),
      };

    case "SAVE_USER_AUTH_DATA":
      token = axiosService.getAccessToken();
      if (token) {
        sessionStorage.setItem("token", token);
      }
      let loginName = action.payload.loginName;

      sessionStorage.setItem("loginName", loginName);
      return {
        ...state,
        userId: action.payload.userId,
        userEmail: action.payload.email,
        firstName: action.payload.firstName,
        departmentId: action.payload.departmentId,
        departmentName: action.payload.departmentName,
        clientOrgId: action.payload.clientOrgId,
        parentClientOrgId: action.payload.parentClientOrgId,
        clientShortName: action.payload.shortName,
        roleId: action.payload.roleId,
        loginName: sessionStorage.getItem("loginName"),
        isLoggedIn: true,
        userToken: sessionStorage.getItem("token"),
      };

    case "SET_RECON_UNIT_ID":
      return {
        ...state,
        reconUnitId: action.payload,
      };

    case "SET_RECON_DATA_SOURCE":
      return {
        ...state,
        reconDataSource: action.payload,
      };

    case "SET_RESOLVE_UNIT_ID":
      return {
        ...state,
        resolveUnitId: action.payload,
      };
    case "SET_RECON_UNIT_ID":
      return {
        ...state,
        reconUnitId: action.payload,
      };
    case "SET_THEME_TYPE":
      return {
        ...state,
        themeType: action.payload,
      };
    case "SAVE_MENU_HIERARCHY_DATA":
      return {
        ...state,
        menuHierarchy: action.payload,
      };
    case "SAVE_ORIGINAL_MENU_HIERARCHY_DATA":
      return {
        ...state,
        originalMenuHierarchy: action.payload,
      };
    case "SAVE_PAGINATION_ROWS_PER_PAGE":
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    case "SAVE_FORM_DATA":
      return {
        ...state,
        formData: action.payload,
      };

    default:
      return state;
  }
};
