import { USERAPI } from "URL.js";
import axios from "axios";

export const getDepartmentIdValues = async (userId, fetchDepartmentValues) => {
  try {
    const response = await axios.get(USERAPI + "/department/id-values/" + userId);
   
    if (response.status === 200) {
      fetchDepartmentValues(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const getDepartmentByDepartmentId = async (depmtId, fetchDepartmentValuesByDepartmentId) => {
  try {
    const response = await axios.get(USERAPI + "/department/" + depmtId);
   
    if (response.status === 200) {
      fetchDepartmentValuesByDepartmentId(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const createModifyDepartment = async (params, isModify, callBack) => {
  try {
    let apiType = isModify ? "modify" : "create";
    const response = await axios.post(USERAPI + "/department/" + apiType, params);
    if (response.status === 200) {
    
      callBack(true);
    }
  } catch (error) {
    callBack(false);
  }
};

export const deleteDepartment = async (deptId, callBack) => {
  try {
    const response = await axios.post(USERAPI + "/department/delete/" + deptId);
    if (response.status === 200) {
      callBack(response.data);
    }
  } catch (error) {
    callBack(false);
  }
};
