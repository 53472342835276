import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Button,
  Input,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Popover,
  Radio,
  Tooltip,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { alpha } from "@mui/material/styles";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { fetchWordflowFormData } from "../../action-handlers/ResolveXActionHandler";
import { saveTxnDataApi } from "../../action-handlers/ResolveXActionHandler";
import {
  sendToResolveX,
  fetchReconWorkflowDetails,
} from "../../action-handlers/ReconXActionHandler";
import { WorkflowContext } from "../../context/Workflow/WorkflowContext";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { Puff } from "react-loader-spinner";
import { useTheme } from "@mui/material/styles";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { API } from "URL.js";
import ToastMessage from "components/UtilComponents/ToastMessage";
const styles = {
  mainWrapper: {
    backgroundColor: "#fff",
    padding: "50px",
  },
  formWrapper: {
    width: "92%",
    overflow: "auto",
    backgroundColor: "#f3f4f7",
    border: "1px solid",
    borderColor: "#ccc",
    padding: "32px",
    margin: "0 auto",
    borderRadius: "10px",
  },
  formGridWrapper: {
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
    },

    "& .MuiInputBase-input::placeholder": {
      color: "#000", // Customize the placeholder color here
      opacity: 0.3, // Customize the opacity here
    },
    marginTop: "20px",
    "& > div > div": {
      marginBottom: "12px",
      alignSelf: "stretch",
      "& .MuiTypography-root": {
        fontWeight: "500",
      },
    },
    "& > div input": {
      color: "#6a6a6a",
      backgroundColor: "#fff",
      borderRadius: "5px",
      border: "1px solid #dbdfe6",
      //background: 'linear-gradient(161deg, hsl(0deg 0% 99.06%) 0%, hsl(223.58deg 49.3% 92.66% / 51%) 100%)'
    },
    "& > div fieldset": {
      borderColor: "transparent !important",
    },
  },
  selectInput: {
    backgroundColor: "#fff",
    border: "1px solid #dbdfe6",
    "& > div": {
      paddingBottom: "7px !important",
      paddingTop: "7px !important",
    },
  },
  uploadBtn: {
    backgroundColor: "#fff",
    border: "1px solid #dbdfe6",
  },
  mandatoryCls: {
    color: "red",
    opacity: "0.7",
    paddingLeft: "4px",
  },
};

const useStyles = makeStyles(styles);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const FormModal = ({
  modalOpenState,
  modalCloseHandler,
  reconUnitId,
  workflowId,
  loggedUserId,
  userLoginName,
  userRoleId,
  successHandler,
}) => {
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dynamicFormData, setDynamicFormData] = useState([]);
  const [currentFormData, setCurrentFormData] = useState([]);
  const [reconUnitObj, setReconUnitObj] = useState(null);
  const [reconUnitData, setReconUnitData] = useState({
    reconUnitFlowKey: "",
    reconUnitFlowName: "",
  });
  const [metaFromData, setMetaFormData] = useState([]);
  const [metaConfigData, setMetaConfigData] = useState([]);
  const [submitAttempted, setSubmitAttempted] = useState(false); // State to track if submission was attempted
  const [toastData, setToastData] = React.useState({
    isOpen: false,
    message: "",
    color: "",
  });

  const metaConfigRef = useRef({ data: {} });
  const theme = useTheme();
  const modalStyle = {
    // position: "absolute",
    // minHeight: "400px",
    // maxHeight: "85vh",
    // overflow: "auto",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    width: "100%",
    overflow: "auto",
    bgcolor: theme.palette.type === "dark" ? "#1a1546" : "#fff",
    border: "1px solid",
    borderColor: theme.palette.type === "dark" ? "#1a1546" : "#ccc",
    //boxShadow: 24,
    p: 4,
  };
  const WorkflowDataContext = useContext(WorkflowContext);
  const [file, setFile] = useState(null);

  const handleUpload = async (rawDataId, callback) => {
    if (!file) {
      callback(false);
      //setErrorMessage('Please select a file to upload.');
      setSubmitAttempted(true);
      return;
    }

    const apiCalls = Object.entries(file).map((key) => {
      if (key[1]) {
        const formData = new FormData();
        formData.append("file", key[1]);
        const metaObject = metaFromData?.filter(
          (mfd) => mfd.columnId === key[0]
        )[0];
        const params = {
          reconUnitId: reconUnitId,
          rawDataId: rawDataId,
          metaDataId: metaObject?.metaDataId,
        };
        return axios.post(`${API}/uploadData/uploadFile`, formData, {
          params,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
    });

    Promise.all(apiCalls)
      .then((results) => {
        callback(true);
      })
      .catch((error) => {
        console.error("Error with one of the API calls:", error);
      });
  };

  const formReset = () => {
    const formObj = {};
    metaFromData
      .filter((form) => form.visible)
      .forEach((item) => {
        const key = item.columnId;
        formObj[key] = { ...item, value: "", errorMessage: "" };
      });
    setCurrentFormData(formObj);
  };

  useEffect(() => {
    formReset();
  }, [metaFromData]);

  useEffect(() => {
    if (
      dynamicFormData.length > 0 &&
      metaFromData?.length !== dynamicFormData.length
    ) {
      setMetaFormData(dynamicFormData);
    }
  }, [dynamicFormData]);

  useEffect(() => {
    setOpen(modalOpenState);
  }, [modalOpenState]);

  const fetchMetaConfig = async (reconUnitId, dsid) => {
    try {
      const response = await axios.get(
        `${API}/recon/metaUIConfig/${reconUnitId}/${dsid}`
      );
      if (response.status === 200) {
        metaConfigRef.current.data = response.data;
        const metaDataIds = response.data
          .filter(
            (field) =>
              field.fieldType === "select" ||
              field.fieldType === "multiple choice"
          )
          .map((md) => md.reconMetaData.metaDataId);

        if (metaDataIds.length === 0) {
          setMetaConfigData(response.data);
        }
        const apiCalls = metaDataIds.map((id) =>
          axios.get(`${API}/recon/fetchMetaConfig?metaDataId=${id}`)
        );

        Promise.all(apiCalls)
          .then((results) => {
            const finalConfigData = metaConfigRef.current.data.map((config) => {
              const options =
                results.filter(
                  (res) =>
                    res.data?.metaDataUIConfig?.configId === config.configId
                )[0]?.data?.metaDataUISubConfigList || [];
              return {
                ...config,
                options,
              };
            });
            setMetaConfigData(finalConfigData);
          })
          .catch((error) => {
            // Handle any error that occurred in any of the promises
            console.error("Error with one of the API calls:", error);
          });
      }
    } catch (ex) {
      console.log("Exception in fetchMetaConfig", ex);
    }
  };

  const callbackLoadFormData = (data = []) => {
    setLoader(false);
    setDynamicFormData(data);
    // fetchMetaConfig1(data);
    fetchMetaConfig(reconUnitId, 1);
    setReconUnitObj(data[0].clientReconUnit);
  };

  const loadFormData = () => {
    setLoader(true);
    fetchWordflowFormData(reconUnitId, 1, callbackLoadFormData);
  };

  useEffect(() => {
    if (open) {
      loadFormData();
    }
    // if (!open) {
    //   modalCloseHandler();
    // } else {
    //   loadFormData();
    // }
  }, [open, reconUnitId]);

  const handleClose = (e) => {
    modalCloseHandler();
    setCurrentFormData([]);
    formReset();
    setSubmitAttempted(false);
  };

  const handleToastClose = React.useCallback(() => {
    setToastData({ isOpen: false, message: "" });
  }, []);

  useEffect(() => {
    if (reconUnitId && reconUnitId !== 0) {
      getWorkflowDetails(reconUnitId);
    }
  }, [reconUnitId]);

  const callbackFetchReconWorkflowDetails = (
    reconWorkflowFlag,
    flowDefnKeyName,
    flowDefnName,
    apiStatus
  ) => {
    if (apiStatus) {
      setReconUnitData({
        reconUnitFlowKey: flowDefnKeyName,
        reconUnitFlowName: flowDefnName,
      });
    } else {
      setReconUnitData({
        reconUnitFlowKey: "",
        reconUnitFlowName: "",
      });
    }
  };

  const callbackSendToResolveX = (status, data) => {
    if (status) {
      setToastData({
        isOpen: true,
        message: data?.responseMessage,
      });
    } else {
      setToastData({
        isOpen: true,
        message: data?.responseMessage || "Something went wrong",
        color: "error",
      });
    }
    setTimeout(() => {
      successHandler();
      setLoader(false);
      handleClose();
    }, 100);
  };

  const sentToSSFL = (rawDataIdVal) => {
    let workflowData = WorkflowDataContext.state.workflows?.filter(
      (item) => item.wfProcessId === +workflowId
    )[0];

    const payload = {
      rawDataId: rawDataIdVal,
      flowDefnName: workflowData?.processName,
      flowDefnKeyName: workflowData?.wfProcessId,
      loggedInUserId: loggedUserId,
      loggedInUserName: userLoginName,
      flowDefnId: workflowData?.wfProcessId,
      roleId: userRoleId,
      userNotes: reconUnitObj?.clientReconName
        ? reconUnitObj?.clientReconName + "-Workflow"
        : "",
      reconUnitId: reconUnitId,
      dataSrcConfigId: 1,
    };
    sendToResolveX(payload, callbackSendToResolveX);
  };

  const getWorkflowDetails = (recoUid) => {
    fetchReconWorkflowDetails(recoUid, callbackFetchReconWorkflowDetails);
  };

  const fileUploadSuccessHandler = (flag) => {
    console.log("fileUploadSuccessHandler", flag);
  };

  const fileUploadAPIHandler = (rawDtid) => {
    handleUpload(rawDtid, fileUploadSuccessHandler);
  };

  const saveCallbackHandler = (data) => {
    fileUploadAPIHandler(data?.rawDataId);
    sentToSSFL(data?.rawDataId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: value,
        errorMessage: value ? "" : prevFormData[name].errorMessage,
      },
    }));
  };

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    setFile({ ...file, [name]: e.target.files[0] });
    let errorMessage = "";
    const localfile = files[0];
    if (!localfile) {
      errorMessage = currentFormData?.mandatory ? "File is required" : "";
    } else {
      // Validate file size and type based on colData requirements
      const colData = metaFromData.find((col) => col.columnId === name);
      if (localfile.size > Number(colData.documentSize) * 1000 * 1024) {
        errorMessage = `File size should not exceed ${colData.documentSize} MB`;
      }

      const configData = metaConfigData.filter(
        (config) => config.fieldLabel === colData.columnName
      )[0];

      if (!localfile.type.includes(configData?.allowedFileTypes || "pdf")) {
        errorMessage = `Invalid file type. Allowed types are: ${
          configData?.allowedFileTypes || "pdf"
        }`;
      }
    }

    setCurrentFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: localfile,
        type: "upload",
        errorMessage: errorMessage,
      },
    }));
  };

  const validateForm = () => {
    const updatedFormData = { ...currentFormData };
    let isValid = true;
    Object.keys(updatedFormData).forEach((key) => {
      const field = metaFromData.find((field) => field.columnId === key);
      if (field && field.mandatory && !updatedFormData[key].value) {
        updatedFormData[key].errorMessage =
          field.validationMessage || "This field is required";
        isValid = false;
      }
      if (field?.dataType === "DOCUMENT") {
        const metadata = (metaConfigRef.current.data || []).filter(
          (md) => md.fieldLabel === updatedFormData[key]?.columnName
        )[0];

        const allowedFileTypes = metadata?.allowedFileTypes || "pdf";

        if (updatedFormData[key]?.value) {
          if (
            !updatedFormData[key].value?.name
              ?.toLowerCase()
              ?.includes(allowedFileTypes)
          ) {
            isValid = false;
            updatedFormData[
              key
            ].errorMessage = `Allowed file type(s) ${allowedFileTypes}`;
          }
        }
      }
    });
    setCurrentFormData(updatedFormData);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitAttempted(true); // Set the submission flag to true

    if (validateForm()) {
      let formJson = currentFormData;

      Object.keys(formJson).forEach((key) => {
        if (key.startsWith("column")) {
          formJson[key] =
            typeof formJson[key].value !== "object"
              ? formJson[key]?.value
              : formJson[key].value?.name; // Assume value exists and map it directly
        }
      });

      let paramObj = {};
      paramObj = { ...formJson };

      paramObj["reconLinkId"] = "";
      paramObj["reconStatusId"] = 0;
      paramObj["reconNotes"] = "";
      paramObj["reconUnitId"] = reconUnitObj.reconUnitId;
      paramObj["dataSrcConfigId"] = reconUnitObj.dataSourceId1;
      paramObj["caseStatus"] = "";
      paramObj["ingestDate"] = "";
      paramObj["loggedInUserId"] = loggedUserId;
      saveTxnDataApi(paramObj, saveCallbackHandler);
    } else {
      console.log("Validation failed");
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState("");

  const renderInput = (colData) => {
    const {
      columnId: id,
      mandatory,
      columnName,
      documentSize,
      fileTypes,
      visible,
      clientReconUnit,
    } = colData;

    const gridNumberHandler = (layoutType) => {
      if (layoutType === "1-COLUMN") {
        return 12;
      } else if (layoutType === "2-COLUMN") {
        return 6;
      }
      return 4;
    };

    const gridNum = gridNumberHandler(clientReconUnit?.formLayout);
    console.log("gridNum", gridNum);

    const targetObj = metaConfigData.filter(
      (config) => config?.reconMetaData?.columnId === colData.columnId
    )[0];
    console.log("targetObj", targetObj);
    // const fieldType = targetObj?.fieldType || colData.dataType || "STRING";
    let fieldType = "";
    const dataType = colData.dataType || "STRING";
    if (dataType === "STRING") {
      fieldType = targetObj?.fieldType || colData.dataType || "STRING";
    } else if (
      dataType === "NUMERIC" ||
      dataType === "DATE" ||
      dataType === "DOCUMENT"
    ) {
      fieldType = colData.dataType || "STRING";
    }
    const placeHolder =
      targetObj?.placeHolder || `Please enter ${colData?.columnName}`;
    const fieldLabel = targetObj?.fieldLabel || colData?.columnName;
    const helpTextMsg = targetObj?.helpText;
    let options = [];
    if (fieldType === "multiple choice") {
      options =
        metaConfigData.filter(
          (mcd) => mcd.reconMetaData?.columnId === colData.columnId
        )[0]?.options || [];
    } else {
      options =
        metaConfigData.filter(
          (mcd) => mcd.reconMetaData?.columnId === colData.columnId
        )[0]?.options || [];
    }

    //console.log('options22', options)

    const validationMessage = mandatory
      ? targetObj?.validationMessage || `${fieldLabel} is Required`
      : "";

    const inputType =
      fieldType?.includes("text") || fieldType?.includes("STRING")
        ? "text"
        : fieldType?.includes("NUMERIC")
        ? "number"
        : fieldType?.includes("select")
        ? "select"
        : fieldType?.includes("date") || fieldType?.includes("DATE")
        ? "date"
        : fieldType?.includes("email")
        ? "email"
        : //   : fieldType?.includes("hidden")
          //   ? "hidden"
          "text";

    const inputProps = fieldType?.includes("NUMERIC")
      ? { step: "0.01", fontSize: "12x" }
      : {};
    inputProps.minLength = targetObj?.minLength || 1;
    inputProps.maxLength = targetObj?.maxLength || 24;

    if (fieldType?.includes("text") || fieldType?.includes("STRING")) {
      inputProps.minLength = targetObj?.minLength || 1;
      inputProps.maxLength = targetObj?.maxLength || 24;
    }
    if (fieldType?.includes("NUMERIC")) {
      console.log("inputProps", inputProps);
    }

    const fieldValue = currentFormData[id]?.value || "";
    const fileDetails = currentFormData[id]?.file;
    let errorMessage = !fieldValue ? validationMessage : ""; // Display error only if submission was attempted
    if (currentFormData[id]?.type === "upload") {
      errorMessage = currentFormData[id]?.errorMessage;
    }
    //const errorMessage = !fieldValue?.length > 0 ? validationMessage : "";

    const toolTipStyles = {
      border: "1px solid rgba(145, 158, 171, 1)",
      borderRadius: "50%",
      textAlign: "center",
      width: "18px",
      display: "inline-flex",
      height: "18px",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "11px",
      color: "#000",
      lineHeight: "19px",
      padding: "3px",
      cursor: "pointer",
      marginTop: "2px",
      opacity: "0.7",
    };

    const FieldLabelWrapper = ({
      fieldLabel,
      mandatory,
      helpTextMsg,
      allowedFileTypes = "",
    }) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontSize: "14px" }}>
            {`${fieldLabel}`}
            {mandatory ? <span className={classes.mandatoryCls}>*</span> : ""}
            {allowedFileTypes ? ` (${allowedFileTypes})` : ''}
          </Typography>
          {helpTextMsg && (
            <Tooltip title={helpTextMsg} placement="top" arrow>
              <span style={toolTipStyles}>
                <svg
                  style={{ opacity: "0.6" }}
                  height="15px"
                  width="15px"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <g id="info">
                        <g>
                          <path
                            style={{ fill: "#030104" }}
                            d="M10,16c1.105,0,2,0.895,2,2v8c0,1.105-0.895,2-2,2H8v4h16v-4h-1.992c-1.102,0-2-0.895-2-2L20,12H8 v4H10z"
                          ></path>
                          <circle
                            style={{ fill: "#030104" }}
                            cx="16"
                            cy="4"
                            r="4"
                          ></circle>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </Tooltip>
          )}
        </Box>
      );
    };

    if (fieldType.toLowerCase() === "document" && visible) {
      return (
        <GridItem key={fieldLabel} xs={12} md={gridNum}>
          <FieldLabelWrapper
            fieldLabel={fieldLabel}
            mandatory={mandatory}
            helpTextMsg={helpTextMsg}
            allowedFileTypes={targetObj?.allowedFileTypes || "pdf"}
          />

          <FormControl
            fullWidth
            margin="dense"
            size="small"
            error={!!errorMessage}
          >
            <Button
              component="label"
              className={classes.uploadBtn}
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              <Input
                id={id}
                name={id}
                type="file"
                onChange={handleChangeFile}
                sx={{
                  clip: "rect(0 0 0 0)",
                  clipPath: "inset(50%)",
                  height: 1,
                  overflow: "hidden",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  whiteSpace: "nowrap",
                  width: 1,
                }}
                error={submitAttempted && !!errorMessage}
              />
              <span
                style={{
                  justifyContent: "flex-start",
                  maxWidth: "150px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {fieldValue?.name || "Add File"}
              </span>
            </Button>
            {/* <Button onClick={handleUpload}>Upload</Button> */}

            {submitAttempted && errorMessage && (
              <FormHelperText>
                {(mandatory || currentFormData[id].errorMessage) &&
                  errorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </GridItem>
      );
    } else if (fieldType === "select" && visible) {
      return (
        <GridItem key={fieldLabel} xs={12} md={gridNum}>
          <FieldLabelWrapper
            fieldLabel={fieldLabel}
            mandatory={mandatory}
            helpTextMsg={helpTextMsg}
          />
          <FormControl
            fullWidth
            margin="dense"
            size="small"
            error={!!errorMessage}
          >
            {/* <InputLabel id={`${id}-label`} sx={{ fontSize: "13px" }}>
              {fieldLabel} {mandatory ? "*" : null}
            </InputLabel> */}
            <Select
              className={classes.selectInput}
              labelId={`${id}-label`}
              //label={fieldLabel}
              //required={mandatory}
              value={fieldValue}
              onChange={handleChange}
              name={id}
              error={!!currentFormData[id]?.errorMessage}
            >
              {options.map((option) => (
                <MenuItem key={option.fieldValue} value={option.fieldValue}>
                  {option.fieldKey}
                </MenuItem>
              ))}
            </Select>
            {mandatory && submitAttempted && errorMessage && (
              <FormHelperText>{mandatory && errorMessage}</FormHelperText>
            )}
          </FormControl>
        </GridItem>
      );
    } else if (fieldType === "multiple choice" && visible) {
      return (
        <>
          <GridItem key={fieldLabel} xs={12} md={gridNum}>
            <FormControl
              component="fieldset"
              fullWidth
              margin="normal"
              error={!!errorMessage}
              sx={{
                background: "#fff",
                //background:
                // "linear-gradient(161deg, hsl(0deg 0% 99.06%) 0%, hsl(223.58deg 49.3% 92.66% / 51%) 100%)",
                padding: "20px",
                border: "1px solid #e2e1ff",
                borderRadius: "10px",
                height: "92%",
              }}
            >
              <Typography
                component=""
                sx={{ color: "#000", fontWeight: "500", marginBottom: "10px" }}
              >
                {fieldLabel}
                {mandatory ? (
                  <span className={classes.mandatoryCls}>*</span>
                ) : (
                  ""
                )}

                {helpTextMsg && (
                  <div style={{ display: "inline-block", marginLeft: "10px" }}>
                    <Tooltip title={helpTextMsg} placement="top" arrow>
                      <span style={toolTipStyles}>
                        <svg
                          style={{ opacity: "0.6" }}
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g id="info">
                                <g>
                                  <path
                                    style={{ fill: "#030104" }}
                                    d="M10,16c1.105,0,2,0.895,2,2v8c0,1.105-0.895,2-2,2H8v4h16v-4h-1.992c-1.102,0-2-0.895-2-2L20,12H8 v4H10z"
                                  ></path>
                                  <circle
                                    style={{ fill: "#030104" }}
                                    cx="16"
                                    cy="4"
                                    r="4"
                                  ></circle>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </Tooltip>
                  </div>
                )}
              </Typography>
              <FormGroup>
                {options.map((option) => (
                  <div
                    key={option.fieldValue}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={fieldValue === option.fieldValue}
                          onChange={handleChange}
                          name={id}
                          value={option.fieldValue}
                        />
                      }
                      label={option.fieldKey}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                          color: "#626262",
                        },
                      }}
                    />
                    <Tooltip title={option.helpText} placement="right-end">
                      <span style={toolTipStyles}>
                        <svg
                          style={{ opacity: "0.6" }}
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                          fill="#000000"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g id="info">
                                <g>
                                  <path
                                    style={{ fill: "#030104" }}
                                    d="M10,16c1.105,0,2,0.895,2,2v8c0,1.105-0.895,2-2,2H8v4h16v-4h-1.992c-1.102,0-2-0.895-2-2L20,12H8 v4H10z"
                                  ></path>
                                  <circle
                                    style={{ fill: "#030104" }}
                                    cx="16"
                                    cy="4"
                                    r="4"
                                  ></circle>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </Tooltip>
                  </div>
                ))}
              </FormGroup>
              {mandatory && submitAttempted && errorMessage && (
                <FormHelperText>{mandatory && errorMessage}</FormHelperText>
              )}
            </FormControl>
          </GridItem>
        </>
      );
    } else if (visible) {
      return (
        <GridItem key={fieldLabel} xs={12} md={gridNum}>
          <FieldLabelWrapper
            fieldLabel={fieldLabel}
            mandatory={mandatory}
            helpTextMsg={helpTextMsg}
          />
          <TextField
            //label={`${fieldLabel} ${mandatory ? "*" : ""}`}
            //required={mandatory}
            type={inputType}
            name={id}
            size="small"
            placeholder={`${placeHolder}`}
            value={fieldValue}
            onChange={handleChange}
            InputLabelProps={
              fieldType?.toLowerCase()?.includes("date")
                ? { shrink: true }
                : {
                    sx: {
                      fontSize: "13px", // Adjust the font size as needed
                    },
                  }
            }
            inputProps={inputProps}
            fullWidth
            margin="dense"
            error={mandatory && submitAttempted && !!errorMessage}
            helperText={mandatory && submitAttempted && errorMessage}
          />
        </GridItem>
      );
    }
  };

  const isVisible =
    dynamicFormData.filter((column) => column.visible).length > 0;

  return (
    <div className={classes.mainWrapper}>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      <Box
        sx={modalStyle}
        component="form"
        onSubmit={handleSubmit}
        className={classes.formWrapper}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ paddingLeft: "15px", display: "flex" }}
          >
            <PostAddOutlinedIcon />
            <span
              style={{
                fontSize: "16px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            >
              Add {reconUnitObj?.clientReconName} Form
            </span>
          </Typography>
          <KeyboardBackspace
            onClick={handleClose}
            style={{ cursor: "pointer", marginRight: "10px" }}
            fontSize="large"
          />
        </Box>
        {dynamicFormData?.length > 0 ? (
          <Box className={classes.formGridWrapper}>
            <GridContainer
              spacing={0}
              style={{ alignItems: "start" }}
              // sx={{
              //   "& > div": {
              //     alignSelf: "stretch",
              //     marginBottom: "20px",
              //   },
              // }}
            >
              {dynamicFormData?.map((column) => renderInput(column))}
            </GridContainer>
          </Box>
        ) : (
          <Box style={{ display: "flex" }} justifyContent={"center"}>
            {loader ? (
              <Puff
                visible={true}
                height="80"
                width="80"
                color={alpha(theme.palette.primary.main, 0.6)}
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              <p
                style={{
                  textAlign: "center",
                  minHeight: "200px",
                  fontSize: "13px",
                  color: "#bbb",
                  paddingTop: "20px",
                }}
              >
                No data available
                <div style={{ marginTop: "20px" }}>
                  <Link
                    to={`/saya-platform/admin/metadata?ruid=${reconUnitId}`}
                    key={"Add Meta Data"}
                  >
                    Create Form Meta Data
                  </Link>
                </div>
              </p>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {dynamicFormData?.length > 0 && isVisible && (
            <Button
              type="submit"
              variant="contained"
              sx={{ marginRight: "10px" }}
            >
              Submit
            </Button>
          )}
          {!loader && (
            <Button variant="outlined" onClick={handleClose}>
              Back
            </Button>
          )}
        </Box>
      </Box>
      {/* </Modal> */}
      {toastData.isOpen && (
        <ToastMessage {...toastData} handleToastClose={handleToastClose} />
      )}
    </div>
  );
};

export default FormModal;
