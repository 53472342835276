import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMediaQuery, useTheme } from "@mui/material";

function a11yProps(index) {
  return {
    id: `reg-tab-${index}`,
    "aria-controls": `reg-tabpanel-${index}`,
  };
}

function MetadataTabs({
  tabsData = {},
  handleDSTabChange,
  changeTab,
  activeTab,
  totalRowCount,
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderBottom: "1px solid ",
        borderColor: "divider",
        background: "white",
        paddingTop: "20px",
        paddingLeft: "16px",
      }}
    >
      <Tabs
        sx={{
          minHeight: "38px !important",
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
            height: "38px !important",
            "& button": {
              padding: "5px 15px",
              color: "#919EAB",
              minHeight: "26px",
              opacity: "0.8 !important",
              fontWeight: "500",
              border: `2px solid transparent`,
            },
            "& .tab-active": {
              // textDecoration: 'underline',
              color: theme.palette.primary.main,
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              //backgroundColor: theme.palette.primary.main,
              border: `2px solid #e6bdd0`,
              borderBottom: "0px",
            },
          },
        }}
        className="tabsWrapper"
        value={activeTab}
        onChange={handleDSTabChange}
        aria-label="message center status"
        TabIndicatorProps={{
          sx: {
            display: "none",
            top: 0,
            height: 3,
          },
        }}
      >
        {tabsData?.reconEnabled && tabsData?.workflowEnabled ? (
          <>
            <Tab
              iconPosition="start"
              label={`${tabsData.dataSrcConfigId1} ${
                activeTab == 0 && totalRowCount !== 0
                  ? "(" + totalRowCount + ")"
                  : ""
              }`}
              {...a11yProps(0)}
              onClick={() => changeTab(tabsData.dataSrcConfigId1, 0)}
              key={"0"}
              index={0}
              value={0}
              className={`${activeTab === 0 ? "tab-active" : ""}`}
            />
            <Tab
              iconPosition="start"
              label={`${tabsData.dataSrcConfigId2} ${
                activeTab == 1 && totalRowCount !== 0
                  ? "(" + totalRowCount + ")"
                  : ""
              }`}
              {...a11yProps(0)}
              onClick={() => changeTab(tabsData.dataSrcConfigId2, 1)}
              key={"1"}
              index={1}
              value={1}
              className={`${activeTab === 1 ? "tab-active" : ""}`}
            />
          </>
        ) : (
          <Tab
            iconPosition="start"
            label={`${tabsData.dataSrcConfigId1} ${
              activeTab == 0 && totalRowCount !== 0
                ? "(" + totalRowCount + ")"
                : ""
            }`}
            {...a11yProps(0)}
            onClick={() => changeTab(tabsData.dataSrcConfigId1, 0)}
            key={"0"}
            value={0}
            className={`${activeTab === 0 ? "tab-active" : ""}`}
          />
        )}
      </Tabs>
    </Box>
  );
}
export default React.memo(MetadataTabs);
