/*!
=========================================================
* SAYA Platform from 3CORTEX TECHNOLOGIES - v1.9.0
=========================================================

* Product Page: https://saya.3cortex.com
* Copyright 2021 3CORTEX TECHNOLOGIES PRIVATE LIMITED (http://www.3cortex.com)

* Coded by 3CORTEX TECHNOLOGIES PRIVATE LIMITED

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import axios from "axios";

const AxiosService = (function () {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }
  function _setToken(tokenObj) {
    console.debug("Obtained token object and access token", tokenObj, tokenObj.access_token);
    sessionStorage.setItem('access_token', tokenObj.access_token);
    //sessionStorage.setItem('refresh_token', tokenObj.refresh_token);
  }
  function _getAccessToken() {
    return sessionStorage.getItem('access_token');
  }
  function _getRefreshToken() {
    return sessionStorage.getItem('refresh_token');
  }
  function _clearToken() {
    sessionStorage.removeItem('access_token');
    //sessionStorage.removeItem('refresh_token');
  }
  function _postAxios() {

  }
  function _getAxios(endPoint, userId) {
    axios.get(endPoint + userId)

  }

  function _setIamProvider(iamProvider) {
    sessionStorage.setItem("iamProvider", iamProvider);
  }

  function _getIamProvider() {
    return sessionStorage.getItem("iamProvider");
  }

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    postAxios: _postAxios,
    getAxios: _getAxios,
    getIamProvider: _getIamProvider,
    setIamProvider: _setIamProvider
  }
})();
export default AxiosService;