import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";
//import FormControl from "@material-ui/core/FormControl";
//import InputLabel from "@material-ui/core/InputLabel";
//import Select from "@material-ui/core/Select";
//import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ListItemIcon } from "@mui/material";

// @mui/icons-material
import { Clear, Check } from "@mui/icons-material";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customComboStyle";

const useStyles = makeStyles(styles);

export default function SAYAComboBox(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    groupText,
    id,
    labelProps,
    inputProps,
    error,
    success,
    handleChange,
    value,
    optionsData,
    headerCombo,
    formCombo,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });

  const selectWrapperClasses = classNames({
    [" " + classes.headerComboWrapper]: headerCombo,
    [" " + classes.formComboWrapper]: formCombo,
  });

  const selectClasses = classNames({
    [" " + classes.headerCombobox]: headerCombo,
    [" " + classes.formCombobox]: formCombo,
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl
      {...formControlProps}
      variant="standard"
      className={classes.formControl + selectWrapperClasses}
      //sx={{ minWidth: {sm: 'auto', md: '220px', lg: '220px', xl:"220px"} }}
    >
      {labelText !== undefined ? (
        <InputLabel

          className={classes.labelRoot + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        
        classes={{
          root: classes.control,
          disabled: classes.disabled,
        }}
        className={selectClasses}
        //native
        id={id}
        value={value}
        label={labelText}
        onChange={handleChange}
        MenuProps={MenuProps}
        {...inputProps}
      >
        {/* <optgroup label={groupText}> */}
        {optionsData !== undefined ? (
          optionsData.map((option, i) => (
            <MenuItem
              key={option.label}
              value={option.value}
              disabled={option.isdisabled}
              className={`${option?.icon ? classes.iconOption : ""}`}
            >
              {option?.icon && (
                <ListItemIcon>
                  <Icon>{option.icon}</Icon>
                </ListItemIcon>
              )}
              <div style={{minWidth: '150px'}}>{option.label}</div>
            </MenuItem>
          ))
        ) : (
          <MenuItem key={"1"} value=""></MenuItem>
        )}
        {/* </optgroup> */}
      </Select>
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

SAYAComboBox.propTypes = {
  labelText: PropTypes.node,
  groupText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  handleChange: PropTypes.func,
  value: PropTypes.number,
  optionsData: PropTypes.arrayOf(PropTypes.object),
};
