import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ConfigureWFRoles({
  roles = [],
  selectedWFRoles,
  setSelectedWFRoles,
}) {
  const theme = useTheme();
  const handleChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      const newRole = roles.filter((role) => role.roleId === +name)[0];
      setSelectedWFRoles([...selectedWFRoles, newRole.roleId]);
    } else {
      const updatedRoles = selectedWFRoles.filter((roleId) => roleId !== +name);
      setSelectedWFRoles(updatedRoles);
    }
  };

  const checkBoxStyles = {
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: "9px",
    fontSize: "12px",
    padding: "1px 8px",
    fontSize: "12px",
    marginRight: "5px",
    marginBottom: "5px",
    "& .MuiCheckbox-root.Mui-checked": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  };

  function CheckboxItem({ value }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PersonOutlineOutlinedIcon style={{ fontSize: 16 }} />
        {value}
      </div>
    );
  }

  return (
    <FormGroup className="roles-wrapper">
      {/* <FormLabel>Select Roles for Workflow</FormLabel> */}
      {roles.map((role, index) => {
        return (
          <FormControlLabel
            key={index}
            name={role.roleId}
            control={
              <Checkbox
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                icon={<CheckboxItem value={role.roleName} />}
                checkedIcon={<CheckboxItem value={role.roleName} />}
                checked={
                  selectedWFRoles.filter((roleId) => roleId === role.roleId)
                    .length > 0
                }
              />
            }
            // label={role.roleName}
          />
        );
      })}
    </FormGroup>
  );
}
