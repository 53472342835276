import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { makeStyles } from "@mui/styles";
import {
  AppsOutlined,
  AccountBalance,
  Groups,
  DisplaySettings,
  ManageAccounts,
  AdminPanelSettingsOutlined,
  KeyboardDoubleArrowRight,
  AccountTreeOutlined,
} from "@mui/icons-material";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CardIcon from "../../components/Card/CardIcon";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardFooter from "../../components/Card/CardFooter";
import { notificationParams } from "../../variables/general";
import Snackbar from "../../components/Snackbar/Snackbar";
import MuiIcon from "../../components/ICONS";

const useStyles = makeStyles(styles);

const iconMapping = {
  organisations: AccountBalance,
  users: ManageAccounts,
  departments: Groups,
  "ops process": DisplaySettings,
  "service provider": AdminPanelSettingsOutlined,
  "flow designer": AccountTreeOutlined,
  default: CloudUploadOutlinedIcon,
};

const ShowDataIngestionTiles = ({ menuItem, clickHandler }) => {
  const classes = useStyles();

  if (!menuItem?.allowedPermissions?.includes("view")) return null;

  const IconComponent = iconMapping[menuItem.menuIdentifier.toLowerCase()] || iconMapping.default;

  return (
    <GridItem
      xs={12}
      sm={6}
      md={3}
      key={menuItem.menuId}
      style={{ cursor: "pointer" }}
      onClick={clickHandler}
    >
      <Card>
        <CardHeader color="primary" stats icon>
          <CardIcon color="primary" plain>
            <MuiIcon icon={IconComponent} />
          </CardIcon>
          <p className={classes.cardCategory}>
            {menuItem.menuIdentifier} Entities
          </p>
          <h3 className={`${classes.cardTitle} ${classes.adminCardTitle}`}>
            {menuItem.menuIdentifier}
          </h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>
            <TuneIcon />
            View {menuItem.menuIdentifier}
          </div>
          <KeyboardDoubleArrowRight />
        </CardFooter>
      </Card>
    </GridItem>
  );
};

export default function DataIngestion({ routeData }) {
  const defaultSubMenus = [
    {
      menuId: 14,
      parentMenuId: 7,
      menuIdentifier: "Data Upload",
      subMenus: null,
      allowedPermissions: ["add", "modify", "delete", "view"],
    },
  ];

  const subMenus = routeData?.subMenus || defaultSubMenus;
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const navigate = useNavigate();
  const ref = useRef({ selectedItem: {} });

  useEffect(() => {
    return () => {
      console.log("Data Ingestion component unloading cleanup.");
    };
  }, []);

  const handleCloseNotification = () => {
    setNotificationObj(prev => ({ ...prev, open: false }));
  };

  const navigationHandler = (menuItem) => {
    const selectedItem = subMenus.find(
      menu => menu.menuIdentifier.toLowerCase() === menuItem.menuIdentifier.toLowerCase()
    ) || {};

    ref.current.selectedItem = selectedItem;
    window.sessionStorage.setItem("selectedItem", JSON.stringify(selectedItem));

    const pathNameMapping = {
      "data upload": "data-upload",
    };

    const pathName = pathNameMapping[selectedItem.menuIdentifier.toLowerCase()] || "";

    if (pathName) {
      navigate(`/saya-platform/datax/${pathName}`, {
        state: { selectedItem: JSON.stringify(ref.current.selectedItem) },
      });
    }
  };

  return (
    <div>
      <GridContainer>
        {subMenus.map((menuItem, index) => (
          <ShowDataIngestionTiles
            menuItem={menuItem}
            key={index}
            clickHandler={() => navigationHandler(menuItem)}
          />
        ))}
        <Snackbar
          place={notificationObj.place}
          color={notificationObj.color}
          icon={notificationObj.icon}
          message={notificationObj.message}
          open={notificationObj.open}
          closeNotification={handleCloseNotification}
          close={notificationObj.close}
        />
      </GridContainer>
    </div>
  );
}
