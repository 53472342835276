import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { UserAuthDetailsContext } from "context/LoginPageContext/UserAuthDetailsContext";
import axios from "axios";
import { API, DATAX } from "URL";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";
import ToastMessage from "components/UtilComponents/ToastMessage";
import DataIngestionHistory from "./DataIngestionHistory";

export default function ManualUpload() {
  const [opsProcesses, setOPSProcesses] = useState([]);
  const [selectedOPSProcess, setSelectedOPSProcess] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [filesA, setFilesA] = useState([]);
  const [filesB, setFilesB] = useState([]);
  const [toastData, setToastData] = useState({
    isOpen: false,
    message: "",
    color: "",
  });
  const [tabIndex, setTabIndex] = useState(0);

  const UserAuthContext = useContext(UserAuthDetailsContext);
  const { userId } = UserAuthContext.state;
  let orgId = "";
  try {
    const ud = JSON.parse(sessionStorage.userData);
    orgId = ud?.mstOrganisation?.organisationId;
  } catch (ex) {
    console.log("Error while parsing JSON", ex.message);
  }

  const onDropA = useCallback((acceptedFiles) => {
    setFilesA((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const onDropB = useCallback((acceptedFiles) => {
    setFilesB((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps: getRootPropsA, getInputProps: getInputPropsA } =
    useDropzone({
      onDrop: onDropA,
      multiple: true,
    });

  const { getRootProps: getRootPropsB, getInputProps: getInputPropsB } =
    useDropzone({
      onDrop: onDropB,
      multiple: true,
    });

  const fileUploadHandler = async (ds, inputFiles) => {
    setUploading(true);
    try {
      const res = await axios.post(
        `${DATAX}/uploads/${selectedOPSProcess}/${ds}?userId=${userId}&organisationId=${orgId}`,
        inputFiles,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            accept: "*/*",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      if (res.status === 200) {
        // fetchAuditData(selectedOPSProcess);
        ds === 1 ? setFilesA([]) : setFilesB([]);
        setToastData({
          isOpen: true,
          message: res.message || "File upload success",
          color: "success",
        });
      }
    } catch (ex) {
      console.error("Exception in handleUpload", ex.message);
      setToastData({
        isOpen: true,
        message: ex.message || "File upload unsuccessful",
        color: "error",
      });
    } finally {
      setUploading(false);
      setProgress(0);
    }
  };

  const handleUpload = () => {
    if (filesA.length > 0) {
      const inputFilesA = new FormData();
      filesA.forEach((file) => inputFilesA.append("files", file));
      fileUploadHandler(1, inputFilesA);
    }
    if (filesB.length > 0) {
      const inputFilesB = new FormData();
      filesB.forEach((file) => inputFilesB.append("files", file));
      fileUploadHandler(2, inputFilesB);
    }
  };

  const removeFile = (setFiles, index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const renderFileList = (files, setFiles) => (
    <List sx={{ maxHeight: "250px", overflow: "auto" }}>
      {files.map((file, index) => (
        <ListItem
          key={index}
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: "4px",
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 16px",
          }}
        >
          <Box display="flex" alignItems="center">
            <DescriptionIcon sx={{ mr: 2, color: "#4caf50" }} />
            <Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {file.name}
              </Typography>
              <Typography
                variant="body2"
                color="#aaa"
                sx={{ fontSize: "14px" }}
              >
                {`${(file.size / 1024 / 1024).toFixed(2)} MB`}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => removeFile(setFiles, index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const fetchOPSProcesses = async (userId) => {
    try {
      const res = await axios.get(`${API}/reconunits/byuser/${userId}`);
      if (res.status === 200) {
        const finalRes = res.data.map((ele) => {
          return {
            ...ele,
            label: ele.clientReconName,
            value: ele.reconUnitId,
          };
        });
        setOPSProcesses(finalRes);
        setSelectedOPSProcess(finalRes[0]?.reconUnitId);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
      setOPSProcesses([]);
    }
  };

  useEffect(() => {
    if (userId !== 0) {
      fetchOPSProcesses(userId);
    }
  }, [userId]);

  const handleOPSProcessChange = (event) => {
    const reconUnitSelected = Number(event.target.value);
    setSelectedOPSProcess(reconUnitSelected);
  };

  const fetchAuditData = async (reconId) => {
    try {
      const res = await axios.get(`${DATAX}/uploads/${reconId}/audit`);
      if (res.status === 200) {
        setAuditData(res.data);
      }
    } catch (ex) {
      console.error("Exception in fetchOPSProcesses", ex.message);
      setAuditData([]);
    }
  };

  useEffect(() => {
    if (selectedOPSProcess && filesA.length === 0 && filesB.length == 0) {
      fetchAuditData(selectedOPSProcess);
    }
  }, [selectedOPSProcess, filesA, filesB]);

  const handleToastClose = useCallback(() => {
    setToastData({ isOpen: false, message: "" });
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "700px",
          display: "flex",
        }}
      >
        <Box
          sx={{
            width: "250px",
            margin: "20px 0",
          }}
        >
          <SAYAComboBox
            optionsData={opsProcesses}
            value={selectedOPSProcess}
            defaultValue={selectedOPSProcess}
            labelText="Ops Processes"
            groupText="Selected Recon Process"
            name="selectedOPSProcess"
            handleChange={handleOPSProcessChange}
            className="Select"
            height="20px"
            headerCombo
          />
        </Box>
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        variant="standard"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          label="Upload Data"
          icon={<CloudUploadOutlinedIcon />}
          iconPosition="start"
          sx={{ minHeight: "auto", padding: "10px 15px" }}
        />
        <Tab
          label="Data Upload History"
          icon={<ListAltOutlinedIcon />}
          iconPosition="start"
          sx={{ minHeight: "auto", padding: "10px 15px" }}
        />
      </Tabs>
      {tabIndex === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="70vh"
          bgcolor="#fff"
          p={3}
        >
          <Box
            maxWidth={700}
            width="100%"
            p={3}
            boxShadow={3}
            borderRadius={2}
            bgcolor="#fbfbff"
            sx={{ border: "1px solid #dcdcdc" }}
          >
            <Box textAlign="center" mb={2}>
              <Typography variant="h5" component="h1" gutterBottom>
               Upload Data
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Supported formats:{" "}
                <span style={{ color: "#ce518c" }}>Excel, CSV, SWIFT</span>
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Box width="48%">
                <Box
                  {...getRootPropsA()}
                  p={2}
                  border="2px dashed #cccccc"
                  textAlign="center"
                  bgcolor="#fff"
                  borderRadius="4px"
                  sx={{ cursor: "pointer" }}
                >
                  <input {...getInputPropsA()} />
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Data Source 1
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontSize: "12px" }}
                  >
                    Drag & drop files here or click to select files
                  </Typography>
                </Box>
                {renderFileList(filesA, setFilesA)}
              </Box>
              <Box width="48%">
                <Box
                  {...getRootPropsB()}
                  p={2}
                  border="2px dashed #cccccc"
                  textAlign="center"
                  bgcolor="#fff"
                  borderRadius="4px"
                  sx={{ cursor: "pointer" }}
                >
                  <input {...getInputPropsB()} />
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Data Source 2
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ fontSize: "12px" }}
                  >
                    Drag & drop files here or click to select files
                  </Typography>
                </Box>
                {renderFileList(filesB, setFilesB)}
              </Box>
            </Box>
            <Divider sx={{ mb: 2 }} />
            {uploading && (
              <Box width="100%" p={2} pt={0}>
                <LinearProgress variant="determinate" value={progress} />
                <Box
                  sx={{ textAlign: "center", padding: "10px 10px 5px 10px" }}
                >
                  <span>{uploading ? `Uploading (${progress}%)` : ""}</span>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center" mb={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
                disabled={uploading || (!filesA.length && !filesB.length)}
              >
                <CloudUploadOutlinedIcon sx={{ marginRight: "10px" }} /> Upload
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box
          sx={{ background: "#fff", paddingBottom: "20px", paddingTop: "20px", minHeight: '450px' }}
        >
          <DataIngestionHistory {...{ auditData }} />
        </Box>
      )}
      {toastData.isOpen && (
        <ToastMessage {...toastData} handleToastClose={handleToastClose} />
      )}
    </Box>
  );
}
