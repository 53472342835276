import React from "react";
// @material-ui/core components
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
//import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@mui/material/Box';
import PropTypes from "prop-types";

import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Button from "../CustomButtons/Button";

import { useTheme } from "@mui/material/styles";

const styles = () => {
  const theme = useTheme();
  return {
    uploadWrapper: {
      uploadButtons: {
        "& .MuiButtonBase-root ": {
          background: theme.palette.primary.main,
          boxShadow: theme.palette.common.shadow,
          color: theme.palette.primary.main
        },
      },
      "& .MuiDialog-paper": {
        minHeight: "250px",
      },
    },
  };
};

const useStyles = makeStyles(styles);

export default function SayaDialogFileUpload(props) {
  const classes = useStyles();
  const {
    open,
    handleClose,
    dialogTitle,
    uploadFile1ButtonLabel,
    fileUpload1,
    fileName1,
    uploadFile2ButtonLabel,
    fileUpload2,
    fileName2,
    submitFiles,
    filesUploading,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.uploadWrapper}
    >
      <DialogTitle id="responsive-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent
        style={{
          minHeight: "250px",
        }}
      >
        <GridContainer className={classes.uploadButtons}>
          <GridItem xs={12} sm={9} md={6} style={{ borderRight: "4px solid" }}>
            <div>
              <input
                accept=".csv"
                id="file1"
                name="myfile1"
                type="file"
                onChange={fileUpload1}
                style={{
                  display: "none",
                }}
              />
              <label htmlFor="file1">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon color="white" />}
                >
                  {uploadFile1ButtonLabel}
                </Button>
              </label>
              {fileName1 ? (
                <p className="mt-3 text-center">{fileName1}</p>
              ) : null}
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <div>
              <input
                accept=".csv"
                id="file2"
                name="myfile2"
                type="file"
                onChange={fileUpload2}
                style={{
                  display: "none",
                }}
              />
              <label htmlFor="file2">
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<CloudUploadIcon color="white" />}
                >
                  {uploadFile2ButtonLabel}
                </Button>
              </label>
              {fileName2 ? (
                <p className="mt-3 text-center">{fileName2}</p>
              ) : null}
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <div className="text-center">
              <div style={{ minHeight: "110px" }}>
                {filesUploading === true ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress />
                    <span
                      style={{
                        justifyContent: "center",
                        position: "relative",
                        top: "55%",
                      }}
                    >
                      Files uploading...please wait
                    </span>
                  </div>
                ) : null}
              </div>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Button variant="contained" color="primary" onClick={submitFiles}>
                  Submit
                </Button>
                <Button variant="outlined" color="primary" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </div>
          </GridItem>
        </GridContainer>
      </DialogContent>
    </Dialog>
  );
}

SayaDialogFileUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string,
  uploadFile1ButtonLabel: PropTypes.string,
  fileUpload1: PropTypes.func,
  fileName1: PropTypes.string,
  uploadFile2ButtonLabel: PropTypes.string,
  fileUpload2: PropTypes.func,
  fileName2: PropTypes.string,
  submitFiles: PropTypes.func,
  filesUploading: PropTypes.bool,
};
