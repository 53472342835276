
const resolveDropDownData = [];
const emailObjectData = {
    input: {
      emailTo: "",
      emailSubject: "",
      emailObject: ""
  },
  validationErrs: {
    emailTo: "",
    emailSubject: "",
  },
  isSubmiting: false
  };
  

module.exports = {
    resolveDropDownData,
    emailObjectData
}