import { useReducer, useEffect } from "react";
import { initConfirmPasswordState } from "../variables/UserDetails";

const useConfirmPassword = (callback) => {
  //State
  

  // Reducer
  const reducer = (state, action) => {
    
    switch (action.type) {
      case "INPUT_CHANGE":
        return {
          ...state,
          input: {
            ...state.input,
            ...action.payload,
            //...returnIdValue(state, action.id, action.value)
          },
          validationErrs: {
            ...state.validationErrs,
            ...validateOnTouch(state, action),
          },
        };
      case "SUBMIT":
        return {
          ...state,
          validationErrs: {
            userName: "",
            password: "",
            ...validateOnSubmit(state),
          },
          isSubmiting: true,
        };
      case "STOP_SUBMIT":
        return {
          ...state,
          validationErrs: {
            ...state.validationErrs,
            ...action.payload,
          },
          isSubmiting: false,
        };
      case "FORGOT_PASSWORD_FAILED":
        return {
          ...state,
          validationErrs: {
            ...state.validationErrs,
            ...action.payload,
          },
          isSubmiting: false,
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initConfirmPasswordState);

  const handleForgotPasswordFailed = () => {
    let passwordLabelId = "password";
 
    dispatch({
      type: "FORGOT_PASSWORD_FAILED",
      payload: {
        [passwordLabelId]:
          "Please check the details entered userName, confirmation code and password reset rules!!!",
      },
    });
  };

  const handleChange = (e) => {
 
    dispatch({
      type: "INPUT_CHANGE",
      payload: { [e.target.id]: e.target.value },
    });
    
  };
  const handleSubmit = (e) => {
  
    e.preventDefault();
    dispatch({ type: "SUBMIT" });
  };

  useEffect(() => {
  
    if (
      Object.values(state.validationErrs).find((err) => err.length) &&
      state.isSubmiting
    )
      dispatch({ type: "STOP_SUBMIT" });
    if (
      !Object.values(state.validationErrs).find((err) => err.length) &&
      state.isSubmiting
    )
      callback(state.input); // submit logic
  }, [state.isSubmiting]);

  return { state, handleChange, handleSubmit, handleForgotPasswordFailed };
};

export default useConfirmPassword;

// Validation functions
function validateOnSubmit(state) {
  const { input } = state;
  let validationErrs = {};
 
  if (!input.userName) validationErrs.userName = "User Name is required";
  /*else if (!/\S+@\S+\.\S+/.test(input.userName))
    validationErrs.userName = "Should be an user name";*/
  if (!input.password) validationErrs.password = "Password is required";
  /*else if (input.password.length < 10)
    validationErrs.password = "Password must be at least 10 characters";*/
  return validationErrs;
}

function validateOnTouch(state, action) {
  let validationErr = {};
 
  for (let keyName in action.payload) {
   
    if (state.input[keyName].length > 0 && action.payload[keyName].length === 0)
      validationErr[keyName] = "This field is required";
   
    if (state.input[keyName].length === 0 && action.payload[keyName].length > 0)
      validationErr[keyName] = "";
  
  }
  return validationErr;
}
