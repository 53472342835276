/*!

=========================================================
* SAYA Platform from 3CORTEX TECHNOLOGIES - v1.9.0
=========================================================

* Product Page: https://saya.3cortex.com
* Copyright 2021 3CORTEX TECHNOLOGIES PRIVATE LIMITED (http://www.3cortex.com)

* Coded by 3CORTEX TECHNOLOGIES PRIVATE LIMITED

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import axios from "axios";
import { AuthProvider } from "@asgardeo/auth-react";
import CircularProgress from "@mui/material/CircularProgress";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// core components
import SAYALayout from "./layouts/SayaSTDLayout";
import "./assets/css/material-dashboard-react.css?v=1.9.0";
import AxiosService from "./AxiosService";
import UserAuthDetailsProvider from "./context/LoginPageContext/UserAuthDetailsState";
import WorkflowState from "context/Workflow/WorkflowState";
import ReconXState from "context/ReconX/ReconXState";
import IdentityService from "./IdentityService";
import saya from "./assets/img/SAYA.svg";
import ThemeProvider from "./theme";

import { USERAPI } from "../src/URL";

const axiosService = AxiosService.getService();
const queryClient = new QueryClient();

axios.interceptors.request.use(
  (config) => {
    const token = axiosService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    // Add CSRF token for all requests after reading it from cookies
    const csrfToken = getCookie("SAYA-Token");
    config.headers["SAYA-Csrf-Token"] = csrfToken;

    return config;
  },
  (error) => {
    //console.log("It is error while request is being placed")
    Promise.reject(error);
  }
);

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  } else {
    return "";
  }
}
const validateToken = async () => {
  const token = sessionStorage.getItem("access_token");
  //const tokenKey = token?.split(" ")[1];

  if (!token) {
    return false;
  }

  try {
    await axios
      .post(`${USERAPI}/security/validatetoken/${token}`)
      .then((response) => {
        if (response?.status !== 200) {
          setTimeout(() => {
            handleLogout();
          }, 100);
        }
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
  } catch (error) {
    console.error(error.message);
  }
};

const handleLogout = async () => {
  document.getElementById("app-logout").click();
};

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error && error?.config.url.includes("validatetoken")) {
      return false;
    }

    if (
      error &&
      (error?.config.url.includes("saya-recon") ||
        error?.config.url.includes("saya-user-cortex") ||
        error?.config.url.includes("sayacom") ||
        error?.config.url.includes("saya-recon-resolve") ||
        error?.config.url.includes("saya-dataingestion"))
    ) {
      if (error.config.headers.Authorization) {
        validateToken();
      } else {
        return false;
      }
    }

    return Promise.reject(error);
  }
);

export default function App() {
  const [identityConfig, setIdentityConfig] = useState(null);
  const [configFetchError, setConfigFetchError] = useState(false);
  const identiyService = IdentityService.getInstance();

  // Fetch identity configuration
  useEffect(() => {
    const loadIdentityConfig = async () => {
      let identityConfig = await identiyService.fetchIdentityConfig();

      if (identityConfig && identityConfig.status === 200) {
        console.debug("Fetched identity config ", identityConfig.data);
        setIdentityConfig(identityConfig.data);
        setConfigFetchError(false);

        // Store iam provider in session storage for later purposes.
        axiosService.setIamProvider(identityConfig.data.provider);
      }
    };

    loadIdentityConfig().catch((error) => {
      console.debug("Error fetching identity config", error);
      setConfigFetchError(true);
    });
  }, []);

  return identityConfig ? (
    <AuthProvider config={identityConfig.integration}>
      <QueryClientProvider client={queryClient}>
        <UserAuthDetailsProvider>
          <WorkflowState>
            <ReconXState>
              <ThemeProvider>
                <BrowserRouter>
                  <SAYALayout />
                </BrowserRouter>
              </ThemeProvider>
            </ReconXState>
          </WorkflowState>
        </UserAuthDetailsProvider>
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </AuthProvider>
  ) : configFetchError ? (
    <div
      style={{
        height: "90vh",
      }}
    >
      <img
        data-src="holder.js/100px180/"
        alt="SAYA platform logo"
        style={{
          height: "500px",
          width: "50%",
          display: "block",
          margin: "0px auto",
        }}
        src={saya}
        data-holder-rendered="true"
      />
      <p
        style={{
          width: "30%",
          margin: "0 auto",
          color: "#9f3a38",
          border: "1px solid #e0b4b4",
          padding: "20px 12px",
          textAlign: "center",
          borderRadius: "3px",
          backgroundColor: "#fff6f6",
        }}
      >
        Failed to login to SAYA platform. Please try again!!
      </p>
    </div>
  ) : (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginTop: "20%",
      }}
    >
      <CircularProgress />
    </div>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
