import {
  defaultFont,
  dangerColor,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
import { alpha } from "@mui/material/styles";
import dropdownStyle from "assets/jss/material-dashboard-react/dropdownStyle.js";

import { useTheme } from "@mui/material/styles";
export default function headerLinksStyle() {
  const theme = useTheme();
  return {
    ...dropdownStyle(theme),
    search: {
      "& > div": {
        marginTop: "0",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "10px 15px !important",
        float: "none !important",
        paddingTop: "1px",
        paddingBottom: "1px",
        padding: "0!important",
        width: "60%",
        marginTop: "40px",
        "& input": {
          color: whiteColor,
        },
      },
    },
    linkText: {
      zIndex: "4",
      ...defaultFont,
      fontSize: "14px",
      margin: "0px",
    },
    buttonLink: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        margin: "10px 15px 0",
        width: "-webkit-fill-available",
        "& svg": {
          width: "24px",
          height: "30px",
          marginRight: "15px",
          marginLeft: "-15px",
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
          fontSize: "24px",
          lineHeight: "30px",
          width: "24px",
          height: "30px",
          marginRight: "15px",
          marginLeft: "-15px",
        },
        "& > span": {
          justifyContent: "flex-start",
          width: "100%",
        },
      },
    },
    searchButton: {
      [theme.breakpoints.down("sm")]: {
        top: "-50px !important",
        marginRight: "22px",
        float: "right",
      },
    },
    margin: {
      zIndex: "4",
      margin: "0",
    },
    searchIcon: {
      width: "17px",
      zIndex: "4",
    },
    notifications: {
      zIndex: "4",
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        top: "2px",
        border: "1px solid " + whiteColor,
        right: "4px",
        fontSize: "9px",
        background: dangerColor[0],
        color: whiteColor,
        minWidth: "16px",
        height: "16px",
        borderRadius: "10px",
        textAlign: "center",
        lineHeight: "16px",
        verticalAlign: "middle",
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        ...defaultFont,
        fontSize: "14px",
        marginRight: "8px",
      },
    },
    manager: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        position: "relative",
        left: "-5px",
      },
      display: "inline-block",
    },
    searchWrapper: {
      [theme.breakpoints.down("sm")]: {
        width: "-webkit-fill-available",
        margin: "10px 15px 0",
      },
      display: "flex",
      alignItems: "center",
    },
    welcomeText: {
      marginRight: "0px",
      display: "inline-flex",
      cursor: "pointer",
      alignItems: "center",
      color: theme.palette.text.primary,
      paddingRight: "0px",
      "& > div":{
        display: "flex",
        flexDirection: 'column',
        fontSize: '12px',
        paddingRight: '15px',     
      },
      "& > div:first-child":{
        marginRight: 10,    
        paddingRight: '0px',  
      },
      "& > div > p": {
        marginRight: 15,
        paddingRight: 0,
        display: "flex",
        alignItems: "center",       
        "& > span":{
          paddingRight: 10
        }
      },
      "& > svg": {
        marginLeft: "5px",
        color: theme.palette.text.primary,
      },
      "& p": {
        margin: 0,
        fontSize: 13,
        color: theme.palette.text.primary,
        lineHeight: "25px",
        textAlign: "right",
        paddingRight: "15px",
      },
    },
    welcomeTextLg: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    welcomeTextXs: {
      [theme.breakpoints.up("xs")]: {
        display: "none",
      },
      [theme.breakpoints.down("xs")]: {
        display: "inline",
      },
    },
    iconWrapper: {
      display: "inline-block",
      background: "rgba(" + hexToRgb(theme.palette.grey[300]) + ",.5)",
      padding: "5px",
      cursor: 'pointer',
      borderRadius: "5px",
      lineHeight: "10px",
      marginLeft: '15px',
      border: '1px solid ' + "rgba(" + hexToRgb(theme.palette.grey[600]) + ",.2)",
      "& svg": {
        color: theme.palette.text.primary + " !important"
      },
      "&:hover": {
        background: "rgba(" + hexToRgb(grayColor[5]) + ",.80)",
      },
    },
    userText: {
      textTransform: "capitalize",
    },
    headerMenu: {
      marginTop: 24,

      [theme.breakpoints.up("md")]: {
        marginLeft: "-36px",
      },
      "& hr": {
        borderColor: theme.palette.border.secondary,
      },

      "& ul li": {
        "&:first-child + li":{
          borderTop: '1px solid ' + theme.palette.border.secondary,
          minHeight: 48
        },
        "&.Mui-disabled": {
          fontSize: 11,
          color: theme.palette.text.primary,
        },
        color: theme.palette.text.primary,
        paddingTop: 10,
        paddingBottom: 8,
        fontSize: 13,
        "& span": {
          fontSize: 13,
        }
      },
    },
    headerPopover: {
      minWidth: 200,
      background: whiteColor,
      color: grayColor[2],
    },
    dropdownItem: {
      padding: "6px 12px",
      fontSize: "14px",
    },
    exitSvgIcon: {
      marginTop: -2,
      marginLeft: 10,
      color: theme.palette.primary.main,
    },
    menuIcon: {
      marginRight: 10,
      color: theme.palette.grey[600],
    },
    themeSwitch: {
      borderTop: `1px solid ${theme.palette.border.secondary}`,
      "& > label": {
        flexDirection: "row-reverse",
        width: "100%",
        justifyContent: "space-between",
        margin: "0",
        "& .MuiSwitch-thumb": {
          color: theme.palette.primary.main,
        },
        "& .Mui-checked .MuiSwitch-thumb": {
          color: theme.palette.grey[500],
        },
        "& .MuiSwitch-track": {
          backgroundColor: theme.palette.grey[400] + " !important",
        },
      },
    },
    hamburgerMobileIcon: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
      position: "absolute",
      left: "14px",
    },
    switchWrapper: {
      [theme.breakpoints.down("sm")]: {
        "& > label": {
          marginLeft: "-30px",
        },
        "& > label span:last-child > div": {
          fontSize: 9,
        },
      },
    },
    img: {
      display: "inline-block",
      fontWeight: "300",
      lineHeight: "2",
      textAlign: "center",
      minWidth: "50px",
      minHeight: "50px",
      position: "relative",
      "&:before": {
        content: "",
        width: "100%",
        height: "calc(100% + 10px)",
        backgroundColor: "#ccc",
        borderRadius: "10px",
        position: "absolute",
        top: "50%",
        left: "-2px",
        transform: "translateY(-50%)",
      },
      "&:after": {
        // content: ' "\2639" " " attr(alt)',
        fontSize: "18px",
        color: "rgb(100, 100, 100)",
        display: "block",
        position: "absolute",
        zIndex: 2,
        top: "5px",
        left: 0,
        width: "100%",
        height: "100%",
      },
    },
    logoImage: {
      width: "50px",
      height: "50px",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      borderRadius: "10px",
      border: "2px solid #edecec",
      backgroundColor: theme.palette.common.white,
      backgroundPosition: "center center",
    },
    switchInput: {
      [theme.breakpoints.down("lg")]: {
        justifyContent: "start",
      },
      "& button": {
        height: 44,
        background: "rgba(" + hexToRgb(theme.palette.primary.main) + ",.09)",
        border: `2px solid ${theme.palette.common.white}`,
        boxShadow: theme.palette.common.shadow,
        [theme.breakpoints.down("lg")]: {
          height: 36,
          padding: 6,
        },
        [theme.breakpoints.down("md")]: {
          height: 36,
          padding: 6,
        },
        "& svg": {
          color: theme.palette.primary.main,
          fill: theme.palette.primary.main,
        },
      },
      "& > div": {
        alignItems: "end",
      },
      marginTop: 0,
      "@media (min-width: 0px) and (max-width: 599.95px)": {
        marginTop: 10,
        marginBottom: 15,
      },
      "& > label": {
        margin: "0 0 0 22px",
        "@media (min-width: 0px) and (max-width: 1279.95px)": {
          "& div": {
            paddingLeft: "10px",
            fontSize: "10px",
          },
          zoom: 0.8,
          marginLeft: 0,
          width: "140px",
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        },
      },
    },
    dynamicForm: {
      padding: 10,
      backgroundColor: theme.palette.background.card,
      marginBottom: 20,
    },
    headerLabel: {
      minWidth: 162,
      marginTop: "24px",
      padding: "9px",
      border: "2px solid " + theme.palette.common.white,
      marginLeft: "-2px",
      borderTopRightRadius: "6px",
      borderBottomRightRadius: "6px",
      color: alpha(theme.palette.text.primary, 0.8),
      boxShadow: theme.palette.common.shadow,
      [theme.breakpoints.down("md")]: {
        padding: 6,
      },
    },
    resolveHeader: {
      "& > div": {
        [theme.breakpoints.down("md")]: {
          alignItems: "flex-end",
        },
      },
    },
    actionbtn: {
      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    popoverWrap: {
      "& i": {
        color: "#000",
        cursor: "pointer",
        display: "inline-block",
        fontSize: "14px",
        borderRadius: "5px",
        backgroundColor: "#e7e6e6",
        width: "20px",
        height: "20px",
        textAlign: "center",
        lineHeight: "20px",
      },
    },
    errorMessgage: {
      fontSize: 12,
      color: theme.palette.error.main,
      margin: "0px 0px 10px 0",
    },
    userProfileStyles: {
      width: 40,
      height: 40,
      borderRadius: "50%",
      border: '1px solid '+ theme.palette.grey[400]
    },
    nameCircle: {
      borderRadius: "50%",
      width: 36,
      height: 36,
      padding: 4,
      background: "#ffdae5",
      border: "2px solid #ffdae5",
      color: "#ff2366",
      textAlign: "center",
      fontWeight: "bold",
    },
    userName: {
      fontSize: 12,
      opacity: 0.6,
      lineHeight: '13px'
    },
    icons:{
      color: theme.palette.text.primary
    },
    headerInputWrapper:{
      fontSize: 12,
    }
  };
}
