import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, FormLabel } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useTheme } from "@mui/material/styles";

export default function ConfigureQueueRoles({
  roles,
  selectedWFRoles,
  selectedWFQueueRoles,
  setSelectedWFQueueRoles,
}) {
  // useEffect(() => {
  //   setSelectedWFQueueRoles(selectedWFRoles);
  // }, [selectedWFRoles]);
  const theme = useTheme();
  const handleChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      const newrole = selectedWFRoles.filter((role) => role === +name)[0];
      setSelectedWFQueueRoles([...selectedWFQueueRoles, newrole]);
    } else {
      const updatedRoles = selectedWFQueueRoles.filter(
        (role) => role !== +name
      );
      setSelectedWFQueueRoles(updatedRoles);
    }
  };

  const checkBoxStyles = {
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: "9px",
    fontSize: "12px",
    padding: "1px 8px",
    fontSize: "12px",
    marginRight: "5px",
    marginBottom: "5px",
    "& .MuiCheckbox-root.Mui-checked": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  };

  function CheckboxItem({ value }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PersonOutlineOutlinedIcon style={{ fontSize: 16 }} />
        {value}
      </div>
    );
  }

  return (
    <FormGroup className="roles-wrapper">
      {/* <FormLabel>Select Roles for Queue</FormLabel> */}
      {selectedWFRoles.map((wfqr, index) => {
        const label =
          roles.filter((role) => role.roleId === wfqr)[0]?.roleName || wfqr;

        return (
          <FormControlLabel
            key={index}
            name={wfqr}
            control={
              <Checkbox
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                icon={<CheckboxItem value={label} />}
                checkedIcon={<CheckboxItem value={label} />}
                checked={
                  selectedWFQueueRoles.filter((role) => role === wfqr).length >
                  0
                }
              />
            }
            // label={wfqr}
          />
        );
      })}
    </FormGroup>
  );
}
