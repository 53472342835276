const API_CALL_FAILED =
  "Unable to communicate with server, please check with support!!!";
const API_RESOLVEX_TRENDS_FAILED =
  "API call for Fetching the ResolveX Trends failed!!!";
const API_RECONX_TRENDS_FAILED =
  "API call for Fetching the ReconX Trends failed!!!";
const API_RECON_PROCESS_FAILED =
  "API call for Fetching the Recon Processes Data failed!!!";
const API_RECONX_KEYMETRIC_FAILED =
  "API call for Fetching the ReconX Key Mertrics Data failed!!!";
const API_RESOLVEX_KEYMETRIC_FAILED =
  "API call for Fetching the ResolveX Key Metrics failed!!!";
const API_DELETE_API_WARNING =
  "Delete cannot be called, for record which is not saved!!!";
const API_RECON_QUERY_FAILED = "API call for Fetching the Recon Data failed!!!";
const API_RECON_OPTIMIZE_SUBMITTED_SUCCESS =
  "API call for Recon Optimizer process submitted successfully, please wait for few minutes and validate rules generated";
const API_RECON_OPTIMIZE_FAILED =
  "API call for Recon Optimizer process failed !!!, contact support";
const API_RECON_OPTIMIZE_SUBMITTED =
  "API call for Recon Optimizer process submitted for execution!!!";
const API_RECON_SUBMITTED =
  "API call for Recon process submitted for execution!!!";
const API_RECON_SUCCESS = "API call for Recon process executed successfully!!!";
const API_RECON_FAILED = "API call for Recon process failed!!!";
const API_FILE2_UPLOAD_FAILED = "Data Source 2, file upload failed!!!";
const API_FILE1_UPLOAD_FAILED = "Data Source 1, file upload failed!!!";
const API_DATA_UPLOAD_SUCCESS = "File upload successful !!!";
const API_DELINK_SUCCESS = "Delink of selected records done successfully!";
const API_DELINK_FAILURE = "Delink of selected records failed!!!";
const API_FETCH_RULEDTO_FAILURE =
  "Unable to fetch the rules setup for recon process!!! Contact SAYA support!";
const API_LINK_FAILURE = "Linking of selected records failed!!!";
const API_LINK_SUCCESS = "Linked selected records successfully!";
const API_SAVE_MODIFY_RULE_SUCCESS =
  "Rules for Recon Process saved successfully!";
const API_SAVE_MODIFY_RULE_FAILED = "Rules saving for Recon Process Failed!!!";
const MULTI_MATCH_EXCEPTION =
  "Multiple columns for multi-match aggregation not allowed!!! Plese select only one and save again";
const API_LOADFORM_FAILED = "Loading the form failed!!!";
const API_RESOLVEX_QUEUES_FAILED = "API to fetch ResolveX Queues failed!!!";
const API_RECON_WORKFLOW_FAILED =
  "API for fetching the ResolveX Details for Recon Process failed!!!";
const API_SENDTORESOLVEX_FAILED = "API for sending to ResolveX failed!!!";
const API_SENDTORESOLVEX_SUCCESS =
  "Successfully sent the selected record to ResolveX, please check in ResolveX";
const API_AUDITINFO_FAILED =
  "Unable to load the API data for flow Item selected, you can still work on this ResolveX Item";
const USER_SESSION_TIME_OUT =
  "User Session Expired, please relogin. Routing to login screen ...";
const LOGIN_FAILED =
  "Unable to login to SAYA Platform, please reach out to support team";
const API_FETCH_ANALYTIX_CONFIG_FAILED =
  "API to fetch the AnalytiX Config failed !!!";
const API_FETCH_RESOLVE_ACTIONS_CONFIG_FAILED =
  "API to fetch the ResolveX Actions Config failed !!!";
const ATC_SUBMITTED_SUCCESS =
  "Autonomous Tolerance Process submitted successfully";
const ATC_FAILED =
  "API call for Autonomous Tolerance Process failed !!!, contact support";
const ATC_SUBMITTED =
  "API call for Autonomous Tolerance Process submitted for execution!!!";
module.exports = {
  API_CALL_FAILED,
  API_RESOLVEX_TRENDS_FAILED,
  API_RECONX_TRENDS_FAILED,
  API_RECON_PROCESS_FAILED,
  API_RECONX_KEYMETRIC_FAILED,
  API_RESOLVEX_KEYMETRIC_FAILED,
  API_DELETE_API_WARNING,
  API_RECON_QUERY_FAILED,
  API_RECON_OPTIMIZE_SUBMITTED_SUCCESS,
  API_RECON_OPTIMIZE_SUBMITTED,
  API_RECON_OPTIMIZE_FAILED,
  API_RECON_SUBMITTED,
  API_RECON_SUCCESS,
  API_RECON_FAILED,
  API_FILE2_UPLOAD_FAILED,
  API_FILE1_UPLOAD_FAILED,
  API_DATA_UPLOAD_SUCCESS,
  API_DELINK_SUCCESS,
  API_DELINK_FAILURE,
  API_FETCH_RULEDTO_FAILURE,
  API_LINK_FAILURE,
  API_LINK_SUCCESS,
  API_SAVE_MODIFY_RULE_SUCCESS,
  API_SAVE_MODIFY_RULE_FAILED,
  MULTI_MATCH_EXCEPTION,
  API_LOADFORM_FAILED,
  API_RESOLVEX_QUEUES_FAILED,
  API_RECON_WORKFLOW_FAILED,
  API_SENDTORESOLVEX_FAILED,
  API_SENDTORESOLVEX_SUCCESS,
  API_AUDITINFO_FAILED,
  USER_SESSION_TIME_OUT,
  LOGIN_FAILED,
  API_FETCH_ANALYTIX_CONFIG_FAILED,
  API_FETCH_RESOLVE_ACTIONS_CONFIG_FAILED,
  ATC_SUBMITTED_SUCCESS,
  ATC_FAILED,
  ATC_SUBMITTED,
};
