import { USERAPI } from "URL.js";
import axios from "axios";
import {
  userLoginDetails,
  forgotPasswordDetails,
  userDetailsInitialState,
} from "variables/UserDetails.js";
import AxiosService from "AxiosService.js";

const axiosService = AxiosService.getService();

export const loginToCognito = (logincredentials, callback) => {
  axios
    .post(USERAPI + "/user/login", logincredentials)
    .then((response) => {
      if (response === false) {
        userLoginDetails.errorMessage =
          "Either user email or password is wrong!!";
        userLoginDetails.isLoggedIn = false;
        userLoginDetails.isSuccess = false;
        userLoginDetails.userName = logincredentials.userName;
        callback(userLoginDetails);
      }
      if (response.status === 200) {
        userLoginDetails.isLoggedIn = true;
        userLoginDetails.userName = logincredentials.userName;
        userLoginDetails.userAuthToken = response.data.accessToken;

        userLoginDetails.isSuccess = true;
        userLoginDetails.errorMessage = "";
        let tokenObj = {};
        tokenObj.access_token = response.data.accessToken;
        //localStorageService.setToken(userAuthState.userAuthToken);
        userLoginDetails.challengeResult = response.data.challengeResult;
        axiosService.setToken(tokenObj);
        callback(userLoginDetails);
      }
    })
    .catch((error) => {
      console.log("Exception occured::", error);
      userLoginDetails.errorMessage =
        "Either user email or password is wrong!!";
      userLoginDetails.isLoggedIn = false;
      userLoginDetails.isSuccess = false;
      userLoginDetails.userName = logincredentials.userName;
      callback(userLoginDetails);
    });
};

export const forgotPasswordHandler = (loginName, callback) => {
  axios
    .post(USERAPI + "/user/forgotpassword", loginName)
    .then((response) => {
      if (response.status === 200) {
        forgotPasswordDetails.userName = loginName.userName;

        forgotPasswordDetails.isSuccess = true;
        forgotPasswordDetails.errorMessage = "";
        callback(forgotPasswordDetails);
      } else {
        forgotPasswordDetails.errorMessage = "User Name is not valid !";
        forgotPasswordDetails.isSuccess = false;
        forgotPasswordDetails.userName = loginName.userName;

        callback(forgotPasswordDetails);
      }
    })
    .catch((error) => {
      //console.log("Exception occured::",error);
      console.log("step1");
      forgotPasswordDetails.errorMessage = "User Name is not valid !";
      console.log("step2");
      forgotPasswordDetails.isSuccess = false;
      console.log("step3");
      forgotPasswordDetails.userName = loginName.userName;
      console.log("step4");
      console.log(
        "calling the call back method for username",
        loginName.userName
      );
      callback(forgotPasswordDetails);
    });
};

export const confirmForgotPasswordHandler = (
  confirmForgotPasswordData,
  callback
) => {
  axios
    .post(USERAPI + "/user/confirmforgotpassword", confirmForgotPasswordData)
    .then((response) => {
      if (response.status === 200) {
        forgotPasswordDetails.userName = confirmForgotPasswordData.userName;

        forgotPasswordDetails.isSuccess = true;
        forgotPasswordDetails.errorMessage = "";
        callback(forgotPasswordDetails);
      } else {
        forgotPasswordDetails.errorMessage =
          "Please check the details entered userName, confirmation code and password reset rules!!!";
        forgotPasswordDetails.isSuccess = false;
        forgotPasswordDetails.userName = confirmForgotPasswordData.userName;

        callback(forgotPasswordDetails);
      }
    })
    .catch((error) => {
      //console.log("Exception occured::",error);
      forgotPasswordDetails.errorMessage =
        "Please check the details entered userName, confirmation code and password reset rules!!!";
      forgotPasswordDetails.isSuccess = false;
      forgotPasswordDetails.userName = confirmForgotPasswordData.userName;
      console.log(
        "calling the call back method for username",
        confirmForgotPasswordData.userName
      );
      callback(forgotPasswordDetails);
    });
};

export const fetchUserDetailsByLogin = (userName, callback) => {
  //fetching the user details by passing Email address or login name

  axios
    .get(USERAPI + "/user/byemailaddr/" + userName)
    .then((response) => {
      if (response.status === 200) {
        window.sessionStorage.setItem(
          "userData",
          JSON.stringify(response.data)
        );
        userDetailsInitialState.isLoggedIn = true;
        userDetailsInitialState.userId = response.data.userId;
        userDetailsInitialState.userEmail = response.data.email;
        userDetailsInitialState.loginName = response.data.loginName;
        userDetailsInitialState.firstName = response.data.firstName;
        //userDetailsInitialState.lastName= response.data.lastName;
        userDetailsInitialState.userToken = sessionStorage.getItem("token");
        userDetailsInitialState.departmentId =
          response?.data?.mstDepartment?.departmentId;
        userDetailsInitialState.departmentName =
          response?.data?.mstDepartment?.departmentName;
        userDetailsInitialState.clientOrgId =
          response?.data?.mstOrganisation?.organisationId;
        userDetailsInitialState.orgnizationId =
          response?.data?.mstRole?.organisationId;
        userDetailsInitialState.parentClientOrgId =
          response?.data?.mstOrganisation?.serviceProviderId;
        userDetailsInitialState.clientShortName =
          response?.data?.mstOrganisation?.organisationName;
        userDetailsInitialState.roleId = response?.data?.mstRole?.roleId;
        userDetailsInitialState.errors = "";
      }

      callback(userDetailsInitialState, true);
    })
    .catch((error) => {
      console.log("Got into catch of byemailiaddr1");
      userDetailsInitialState.isLoggedIn = false;
      userDetailsInitialState.errors =
        "Unable to fetch the user details!!!, please check userName";
      callback(userDetailsInitialState, false);
      console.log(error);
    });
};

export const createUser = (userinfo, callback) => {
  try {
    axios.post(USERAPI + "/user", userinfo).then(
      (response) => {
        if (response.status === 200) {
          callback(true);
        }
      },
      (error) => {
        console.log("error", error);
        callback(false);
      }
    );
  } catch (error) {
    callback(false);
  }
};

export const logoutToCognito = (loginName, callback) => {
  axios
    .post(`${USERAPI}/user/logout`, { userName: loginName })
    .then((response) => {
      if (response.status === 200) {
        callback(true);
      }
    })
    .catch((error) => {
      console.log("Exception occured::", error);
      callback(false);
    });
};

export const changeEmail = (username, email) => {
  try {
    axios.post(`${USERAPI}/user/change_email/${username}/${email}`).then(
      (response) => {
        if (response.status === 200) {
          callback(true);
        }
      },
      (error) => {
        console.log("error", error);
        callback(false);
      }
    );
  } catch (error) {
    callback(false);
  }
};
