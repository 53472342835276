import axios from "axios";
import { SAYAFLOW, USERAPI } from "URL.js";

export const fetchworkflows = async (userId, callback) => {
  axios
    .get(`${SAYAFLOW}/config/fetchworkflows/?roleId=${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data);
      }
    })
    .catch((error) => {
      console.log("Error while fetching Workflows", error);
      callback([]);
    });
};

export const fetchUserRoles = (userId, callback) => {
  axios
    .get(`${USERAPI}/role/${userId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while fetching User Roles", error);
      callback([], false);
    });
};

export const mapRolesWithWorkflows = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/mapRolesWithWorkflows`, payload)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while fetching User Roles", error);
      callback([], false);
    });
};

export const fetchMappedRolesForWFProcess = (wfProcessId, callback) => {
  axios
    .get(
      `${SAYAFLOW}/config/fetchMappedRolesForWFProcess?wfProcessId=${wfProcessId}`
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while fetching mapped Roles", error);
      callback([], false);
    });
};

export const saveWorkflows = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/saveworkflows`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const saveWorkflowProceeQueues = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/savewfprocessqueues`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const unmapRolesWithQueue = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/unmapRolesWithQueue`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const saveWorkflowActions = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/savewfactions`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const unmapRolesWithAction = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/unmapRolesWithAction`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const fetchUnmappedRolesForQueue = (queueId, callback) => {
  axios
    .get(`${SAYAFLOW}/config/fetchUnMappedRolesForQueue?queueId=${queueId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const fetchUnmappedRolesForAction = (actionId, callback) => {
  axios
    .get(`${SAYAFLOW}/config/fetchUnMappedRolesForAction?actionid=${actionId}`)
    .then((response) => {
      if (response.status === 200) {
        callback(response.data, true);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const deleteWorkflowProcessQueues = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/deletewfprocessqueues`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const deleteWorkflowActions = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/deletewfactions`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};

export const fetchWFQueues = (wfProcessId, callback) => {
  axios
    .get(`${SAYAFLOW}/config/wfprocessqueues?wfProcessId=${wfProcessId}`)
    .then((response) => {
      callback(response.data, true);
    })
    .catch((error) => {
      console.log("Exception occured while fetching Queues::{}", error);
      callback([], false);
    });
};

export const deleteWorkflow = (payload, callback) => {
  axios
    .post(`${SAYAFLOW}/config/deleteworkflow`, payload)
    .then((response) => {
      if (
        response.status === 200 &&
        response.data.responseStatus === "SUCCESS"
      ) {
        callback(response.data, true);
      } else if (
        response.status === 200 &&
        response.data.responseStatus === "FAILURE"
      ) {
        callback(response.data, false);
      } else {
        callback([], false);
      }
    })
    .catch((error) => {
      console.log("Error while saving workflows", error);
      callback([], false);
    });
};
