import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  FormControl,
  FormGroup,
  TextField,
  Switch,
  Typography,
  Button,
  Select,
  MenuItem,
  Grid,
  IconButton,
  InputLabel,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFormik } from "formik";
import * as yup from "yup";
import { saveMetaDataConfig } from "action-handlers/MetaDataActionHandler";
import ToastMessage from "components/UtilComponents/ToastMessage";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

// const validationSchema = yup.object().shape({
//   fieldType: yup.string().required("Fieldtype is Required"),
//   fieldLabel: yup
//     .string("Enter your fieldLabel")
//     .required("FieldLabel is required"),
//   documentSize: yup.number().when("fieldType", {
//     is: (value) => value === "document",
//     then: (schema) =>
//       schema
//         .required("Document is required")
//         .max(10, "Value should not be more than 10"),
//     otherwise: (schema) => schema.notRequired(),
//   }),
//   fileTypes: yup.string().when("fieldType", {
//     is: (value) => value === "document",
//     then: (schema) => schema.required("File type is required"),
//     otherwise: (schema) => schema.notRequired(),
//   }),
// });

const validationSchema = yup.object().shape({
  fieldType: yup.string().required("Fieldtype is Required"),
  fieldLabel: yup
    .string("Enter your fieldLabel")
    .required("FieldLabel is required")
    .max(256, "FieldLabel should be 256 characters or less"),
  documentSize: yup.number().when("fieldType", {
    is: (value) => value === "document",
    then: (schema) =>
      schema
        .required("Document is required")
        .max(10, "Value should not be more than 10"),
    otherwise: (schema) => schema.notRequired(),
  }),
  fileTypes: yup.string().when("fieldType", {
    is: (value) => value === "document",
    then: (schema) => schema.required("File type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  fieldValue: yup
    .string()
    .max(256, "Field Value should be 256 characters or less"),
  minLength: yup
    .number()
    .min(0, "Minimum length should be 0")
    .max(256, "Maximum length should be 256"),
  maxLength: yup
    .number()
    .min(0, "Minimum length should be 0")
    .max(256, "Maximum length should be 256"),
  placeHolder: yup
    .string()
    .max(256, "PlaceHolder should be 256 characters or less"),
  helpText: yup.string().max(256, "Help Text should be 256 characters or less"),
  validationMessage: yup
    .string()
    .max(256, "Validation Message should be 256 characters or less"),
});

export default function MetaForm({
  rowData,
  uiComponents,
  metaDataConfig = {},
  metaDataConfigOptions = [],
  fetchMetaDataForColumn,
}) {
  const [selectError, setSelectError] = useState({
    error: false,
    message: "",
  });

  const UserAuthContext = React.useContext(UserAuthDetailsContext);

  const [toastData, setToastData] = React.useState({
    isOpen: false,
    message: "",
    color: "",
  });

  const ALLOWED_FILE_TYPES = ["pdf", "png", "svg", "jpg", "jpeg"];
  const [formData, setFormData] = React.useState({
    fieldLabel: rowData?.columnName || "",
    fieldValue: rowData?.columnName || "",
    mandatory: rowData?.mandatory || false,
    minLength: 1,
    maxLength: 24,
    placeHolder: `Please enter ${rowData?.columnName}`,
    helpText: "",
    fieldType: uiComponents[0]?.lookupValue || "textbox", // rowData?.dataType === "DATE" ? "date" : "textbox",
    options: [], // For select field type
    validationMessage: `${rowData?.columnName} is Required`, // Validation text message for mandatory field
    id: rowData?.columnId,
    allowedFileTypes: ALLOWED_FILE_TYPES,
    documentSize: 1, // Document size in MB
    fileTypes: "pdf", // File type for document uploads
    // dateFormat: "dd-mm-yyyy",
    multiSelect: false,
  });
  const containerRef = useRef(null);

  useEffect(() => {
    const optionsData = metaDataConfigOptions.map((opt) => {
      return {
        ...opt,
        fieldKey: opt.fieldKey,
        fieldValue: opt.fieldValue,
      };
    });

    setFormData({
      ...formData,
      ...metaDataConfig,
      fieldType: metaDataConfig?.fieldType || uiComponents[0]?.lookupValue, //uiComponents[0]?.lookupValue || "textbox",
      fileTypes: metaDataConfig?.allowedFileTypes || "pdf",
      validationMessage:
        metaDataConfig?.validationMessage || formData.validationMessage,
      options: optionsData,
    });
    formik.setValues({
      ...formData,
      ...metaDataConfig,
      fieldType: metaDataConfig?.fieldType || uiComponents[0]?.lookupValue, //uiComponents[0]?.lookupValue || "textbox",
      fileTypes: metaDataConfig?.allowedFileTypes || "pdf",
      validationMessage:
        metaDataConfig?.validationMessage || formData.validationMessage,
    });
  }, [metaDataConfig, uiComponents]);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {};
      payload.loggedInUserID = userId;
      payload.metaDataUIConfig = {
        fieldLabel: values.fieldLabel,
        fieldType: values.fieldType,
        fieldValue: values.fieldValue,
        helpText: values.helpText,
        id: values.id,
        columnName: values.fieldLabel,
        mandatory: values.mandatory,
        maxLength: values.maxLength,
        minLength: values.minLength,
        placeHolder: values.placeHolder,
        validationMessage: formData.validationMessage, //values.validationMessage,
        reconMetaData: rowData,
        configId: metaDataConfig?.configId,
      };
      if (
        values.fieldType === "select" ||
        values.fieldType === "multiple choice"
      ) {
        if (formData.options.length === 0) {
          setSelectError({
            error: true,
            message: "Please select at least 1 option",
          });
          return;
        }
        const isValid = formData.options.every(
          (item) => item.fieldKey.trim() !== "" && item.fieldValue.trim() !== ""
        );

        if (!isValid) {
          setSelectError({
            error: true,
            message: "Please enter all key and value data",
          });
          return;
        } else {
          setSelectError({
            error: false,
            message: "",
          });
        }
        payload.metaDataUIConfig.options = formData.options;
        payload.metaDataUISubConfigList = formData.options;
      } else if (values.fieldType === "document") {
        payload.metaDataUIConfig.documentSize = formData.documentSize;
        payload.metaDataUIConfig.reconMetaData.documentSize =
          formData.documentSize;
        payload.metaDataUIConfig.allowedFileTypes = formData.fileTypes;
        payload.metaDataUIConfig.reconMetaData.allowedFileTypes =
          formData.fileTypes;
      }

      saveMetaDataConfig(payload, (data, status) => {
        if (status) {
          setToastData({
            isOpen: true,
            message: data?.responseMessage,
          });
          fetchMetaDataForColumn(rowData);
        } else {
          setToastData({
            isOpen: true,
            message: data?.responseMessage,
            color: "error",
          });
        }
      });
    },
  });

  const { userId } = UserAuthContext.state;
  const fillDefaultValues = (event) => {
    let payload = {};

    if (event.target.value === "on") {
      payload.validationMessage = `${formData.fieldLabel} is Required`;
      return payload;
    }

    if (formData.mandatory && formData.validationMessage.trim() === "") {
      payload.validationMessage = `${formData.fieldLabel} is Required`;
    }

    if (
      (formData.fieldType === "textbox" ||
        formData.fieldType === "number" ||
        formData.fieldType === "email" ||
        formData.fieldType === "date" ||
        formData.fieldType === "select") &&
      event.target.name === "fieldLabel"
    ) {
      payload.placeHolder = `Please enter ${event.target.value}`;
      if (formData.fieldType === "textbox") {
        payload.minLength = 1;
        payload.maxLength = 24;
        payload.helpText = `This is type of ${formData.fieldType}. You can enter any data`;
      } else if (formData.fieldType === "number") {
        payload.minLength = 1;
        payload.maxLength = 16;
        payload.helpText = `This is type of ${formData.fieldType}. You have to enter only numbers and decimals while filling the form`;
      } else if (formData.fieldType === "email") {
        payload.helpText = `This is type of ${formData.fieldType}. You have to include @ and . (dot) while filling the form`;
      } else if (formData.fieldType === "select") {
        payload.helpText = `This is type of ${formData.fieldType}. You can select value from the dropdown`;
      }
    }
    return payload;
  };

  const fillDefaultValues2 = (event) => {
    let payload = {};
    if (
      event.target.name === "fieldType" &&
      formData.fieldLabel.trim() !== ""
    ) {
      if (event.target.value === "textbox") {
        payload.minLength = 1;
        payload.maxLength = 24;
        payload.helpText = `This is type of ${event.target.value}. You can enter any data`;
      } else if (event.target.value === "number") {
        payload.minLength = 1;
        payload.maxLength = 16;
        payload.helpText = `This is type of ${event.target.value}. You have to enter only numbers and decimals while filling the form`;
      } else if (event.target.value === "email") {
        payload.helpText = `This is type of ${event.target.value}. You have to include @ and . (dot) while filling the form`;
      } else if (formData.fieldType === "select") {
        payload.helpText = `This is type of ${formData.fieldType}. You can select value from the dropdown`;
      }
    }

    if (event.target.value === "document") {
      payload.fileTypes = "pdf";
    }
    payload.multiSelect = false;
    return payload;
  };

  const handleChange = (event) => {
    let payload = {};
    if (event.target.name === "fieldType") {
      payload = fillDefaultValues2(event);
    } else {
      payload = fillDefaultValues(event);
    }
    formik.handleChange(event);

    setFormData({
      ...formData,
      ...payload,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleOptionChange = (value, index) => {
    const newOptions = formData.options.map((option, i) =>
      i === index ? { ...option, fieldValue: value } : option
    );
    setFormData({
      ...formData,
      options: newOptions,
    });
  };

  const handleOptionKeyChange = (key, index) => {
    const newOptions = formData.options.map((option, i) =>
      i === index ? { ...option, fieldKey: key } : option
    );
    setFormData({
      ...formData,
      options: newOptions,
    });
  };

  const addOption = () => {
    setFormData({
      ...formData,
      options: [
        ...formData.options,
        { fieldKey: "", fieldValue: "", helpText: "" },
      ],
    });
    setSelectError({
      error: false,
      message: "",
    });
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const removeOption = (index) => {
    const newOptions = formData.options.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      options: newOptions,
    });
  };

  const handleToastClose = React.useCallback(() => {
    setToastData({ isOpen: false, message: "" });
  }, []);

  const handleOptionHelpTextChange = (helpText, index) => {
    const newOptions = formData.options.map((option, i) =>
      i === index ? { ...option, helpText: helpText } : option
    );
    setFormData({
      ...formData,
      options: newOptions,
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        component="fieldset"
        variant="standard"
        sx={{ display: "block" }}
      >
        <FormGroup sx={{ display: "flex", width: "60%", margin: "0 auto" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} container alignItems="center">
              <TextField
                label="Field Type"
                select
                name="fieldType"
                value={formData.fieldType}
                onChange={handleChange}
                size="small"
                fullWidth
                margin="normal"
                sx={{ maxWidth: "160px", marginRight: "15px" }}
                InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
              >
                {uiComponents.map((option) => {
                  return (
                    <MenuItem value={option.lookupValue} key={option.lookupId}>
                      {option.lookupValue.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </TextField>
              {(formData.fieldType === "select" ||
                formData.fieldType === "multiple choice") && (
                <Button onClick={addOption} variant="contained" color="primary">
                  Add Option
                </Button>
              )}
            </Grid>
            {/* Dynamic Options for Select Field Type */}
            {(formData.fieldType === "select" ||
              formData.fieldType === "multiple choice") &&
              formData.options?.length > 0 && (
                <div style={{ width: "100%" }}>
                  <Box
                    ref={containerRef}
                    sx={{
                      maxHeight: "300px",
                      overflowY: "scroll",
                      marginLeft: "20px",
                      marginTop: "15px",
                      borderRadius: "10px",
                      padding: "0px 0px 15px 15px",
                      backgroundColor: "#f1f3f7",
                      paddingBottom: "50px",
                    }}
                  >
                    {formData.options.map((option, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={4} md={3}>
                            <TextField
                              label="Key"
                              type="text"
                              value={option.fieldKey}
                              onChange={(e) =>
                                handleOptionKeyChange(e.target.value, index)
                              }
                              size="small"
                              fullWidth
                              margin="dense"
                              InputLabelProps={{ style: { fontSize: 13 } }}
                              inputProps={{
                                style: {
                                  fontSize: 13,
                                  backgroundColor: "#fff",
                                },
                                maxLength: 256,
                              }}
                            />
                          </Grid>
                          <Grid item xs={4} md={3}>
                            <TextField
                              label="Value"
                              type="text"
                              value={option.fieldValue}
                              onChange={(e) =>
                                handleOptionChange(e.target.value, index)
                              }
                              size="small"
                              fullWidth
                              margin="dense"
                              InputLabelProps={{ style: { fontSize: 13 } }}
                              inputProps={{
                                style: {
                                  fontSize: 13,
                                  backgroundColor: "#fff",
                                },
                                maxLength: 256,
                              }}
                            />
                          </Grid>
                          {formData.fieldType === "multiple choice" && (
                            <Grid item xs={4} md={5}>
                              <TextField
                                label="Help Text"
                                type="text"
                                value={option.helpText}
                                onChange={(e) =>
                                  handleOptionHelpTextChange(
                                    e.target.value,
                                    index
                                  )
                                }
                                size="small"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ style: { fontSize: 13 } }}
                                inputProps={{
                                  style: {
                                    fontSize: 13,
                                    backgroundColor: "#fff",
                                  },
                                  maxLength: 256,
                                }}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} md={1}>
                            <IconButton
                              onClick={() => removeOption(index)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Box>
                </div>
              )}
            {selectError.error && (
              <div className="error alert" style={{ color: "red" }}>
                {selectError.message}
              </div>
            )}

            {formData.fieldType === "document" && (
              <React.Fragment>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Document Size (MB)"
                    // required
                    type="number"
                    name="documentSize"
                    value={formik.values.documentSize}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.documentSize &&
                      Boolean(formik.errors.documentSize)
                    }
                    helperText={
                      formik.touched.documentSize && formik.errors.documentSize
                    }
                    size="small"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 }, min: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="File Type"
                    select
                    name="fileTypes"
                    value={formData.fileTypes}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 } }}
                    multiSelect={true}
                  >
                    {ALLOWED_FILE_TYPES.map((item) => (
                      <MenuItem value={item}>{item.toUpperCase()}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12} md={6}>
              <TextField
                label="Field Label"
                type="text"
                name="fieldLabel"
                value={formik.values.fieldLabel}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fieldLabel && Boolean(formik.errors.fieldLabel)
                }
                helperText={
                  formik.touched.fieldLabel && formik.errors.fieldLabel
                }
                size="small"
                fullWidth
                margin="normal"
                InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Field Value"
                type="text"
                name="fieldValue"
                error={
                  formik.touched.fieldValue && Boolean(formik.errors.fieldValue)
                }
                helperText={
                  formik.touched.fieldValue && formik.errors.fieldValue
                }
                value={formData.fieldValue}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                size="small"
                fullWidth
                margin="normal"
                InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
              />
            </Grid>
            {(formData.fieldType === "textbox" ||
              formData.fieldType === "number") && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Min Length"
                    type="number"
                    name="minLength"
                    value={formData.minLength}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    margin="normal"
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.minLength &&
                      Boolean(formik.errors.minLength)
                    }
                    helperText={
                      formik.touched.minLength && formik.errors.minLength
                    }
                    InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 }, min: 0 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Max Length"
                    type="number"
                    name="maxLength"
                    value={formData.maxLength}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    margin="normal"
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.maxLength &&
                      Boolean(formik.errors.maxLength)
                    }
                    helperText={
                      formik.touched.maxLength && formik.errors.maxLength
                    }
                    InputLabelProps={{ style: { fontSize: 13 } }}
                    inputProps={{ style: { fontSize: 13 }, max: 256 }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                label="PlaceHolder"
                type="text"
                name="placeHolder"
                value={formData.placeHolder}
                onChange={handleChange}
                size="small"
                fullWidth
                margin="normal"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.placeHolder &&
                  Boolean(formik.errors.placeHolder)
                }
                helperText={
                  formik.touched.placeHolder && formik.errors.placeHolder
                }
                InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Help Text"
                type="text"
                name="helpText"
                value={formData.helpText}
                onChange={handleChange}
                size="small"
                fullWidth
                margin="normal"
                onBlur={formik.handleBlur}
                error={
                  formik.touched.helpText && Boolean(formik.errors.helpText)
                }
                helperText={formik.touched.helpText && formik.errors.helpText}
                InputLabelProps={{ style: { fontSize: 13 } }}
                inputProps={{ style: { fontSize: 13 } }}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              {/* <Switch
                checked={formData.mandatory}
                onChange={handleChange}
                name="mandatory"
              />
              <Typography sx={{fontSize: '14px'}}>Required Message</Typography> */}
              {formData.mandatory && (
                <TextField
                  label="Validation Message"
                  type="text"
                  name="validationMessage"
                  value={formData.validationMessage}
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  margin="normal"
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.validationMessage &&
                    Boolean(formik.errors.validationMessage)
                  }
                  helperText={
                    formik.touched.validationMessage &&
                    formik.errors.validationMessage
                  }
                  InputLabelProps={{ style: { fontSize: 13 } }}
                  inputProps={{ style: { fontSize: 13 }, maxLength: 100 }}
                  sx={{ marginLeft: "0px" }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                sx={{ minWidth: "150px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
      {toastData.isOpen && (
        <ToastMessage {...toastData} handleToastClose={handleToastClose} />
      )}
    </form>
  );
}
