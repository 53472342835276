import {
  blackColor,
  whiteColor,
  hexToRgb,
  cardBoxShadow
} from "assets/jss/material-dashboard-react.js";
import { useTheme } from '@mui/material/styles';

export default function cardStyle() {
  const theme = useTheme();
return{

  card: {
    border: "0",
    
    [theme.breakpoints.down('lg')]: {
      minHeight: 163,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
    marginBottom: "30px",
    background: "rgba(" + hexToRgb(theme.palette.background.card) + ", 0.6)",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 0.5s ease",
    boxShadow: theme.customShadows.card,
    borderRadius: Number(theme.shape.borderRadius) * 2,
    "&:hover": {
      boxShadow: "none"
    }
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none",
    marginBottom: 0
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  },
  borderVariant: {
    border: "1px solid " + "rgba(" + hexToRgb(blackColor) + ", 0.1)"
  },
  headerCard: {
    paddingBottom: "10px"
  }
}};


