import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { boxShadow } from "assets/jss/material-dashboard-react";
// ----------------------------------------------------------------------

export default function Button(theme) {
  //const theme = useTheme();

  return {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.main,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.6),
          },
        },
        containedSecondary: {
          backgroundColor: theme.palette.grey[400],
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&:hover, &:focus, &:active": {
            backgroundColor: theme.palette.common.white,
          },
          "&.Mui-selected": {
            "&:hover": {
              backgroundColor: theme.palette.common.white,
              svg: {
                fill: theme.palette.primary.main,
              },
            },
            svg: {
              fill: theme.palette.common.white,
            },
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            boxShadow: `0px 0px 12px inset ${theme.palette.primary.main}`,
          },
          "&.Mui-disabled": {
            opacity: 0.35,
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiTab-root": {
            color:
              theme.palette.type === "dark"
                ? alpha(theme.palette.common.white, 0.5)
                : theme.palette.grey[500],
          },
          "&.MuiTab-root.Mui-selected": {
            backgroundColor:
              theme.palette.type === "dark"
                ? theme.palette.background.paper
                : theme.palette.common.white,
          },
        },
      },
    },
  };
}
