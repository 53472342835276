import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  wrapper: {
    '& p':  {
        color: "#c01567",
    }
    
  },
}));

export default function PopoverComponent(props) {
 
  const { msgTxt, imgSrc, imgWidth, wrapperWidth, wrapperHeight } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ display: "inline" }} className={props.className}>
      <i
        title="Click to expand"
        aria-describedby={id}
        style={{ ...props.style }}
        onClick={handleClick}
      >
        i
      </i>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {" "}
        <div
          className={classes.wrapper}
          style={{
            maxWidth: wrapperWidth || 200,
            maxHeight: wrapperHeight || 200,
            overflow: "hidden",
          }}
        >
          {msgTxt && (
            <p style={{ fontSize: 12, padding: "0px 10px 0 10px" }}>
              {" "}
              {msgTxt}
            </p>
          )}
          {imgSrc && (
            <img
              src={imgSrc}
              alt="Image"
              style={{ width: imgWidth || 100, maxWidth: "none" }}
            />
          )}
        </div>
      </Popover>
    </div>
  );
}
