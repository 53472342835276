import { USERAPI } from "URL.js";
import axios from "axios";

export const getRoleIdValues = async (userId, fetchRoleValues) => {
  try {
    const response = await axios.get(USERAPI + "/role/id-values/" + userId);
   
    if (response.status === 200) {
      fetchRoleValues(response.data);
    }
  } catch (error) {
    console.error(error.message);
  }
};
