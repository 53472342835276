import React, { useState, useEffect } from 'react';
import { Box, Grid, Stack, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ShowToastMessage from '../../../components/UtilComponents/ToastMessage';
import FormInput from '../../../components/FormComponents/FormInput';
import Schema from './CreateColumnSchema';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableWrapper: {
    margin: '0 auto',
    maxWidth: '570px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '510px',
      overflow: 'auto',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      overflow: 'auto',
      width: '100%',
    },
    '& > div': {
      maxHeight: '300px',
      overflow: 'auto',
    },
    '& button': {
      minWidth: 50,
      paddingRight: '0px !important',
      paddingLeft: '0px !important',
    },
  },
  table: {
    background: '#fff',
    border: '1px solid #eee',
    minWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& td input': {
      width: 100,
      padding: '8px',
      backgroundColor: '#f7f7f7',
      border: '1px solid #ccc',
    },
    '& th': {
      padding: '12px',
    },
  },
  actionCell: {
    paddingRight: '60px !important',
  },
  snackbar: {
    bottom: '104px',
  },
  saveBtn: {
    '& + button': {
      display: 'none',
    },
  },
  saveButton: {
    textAlign: 'center',
    padding: 20,

    '& button': {
      '& svg': {
        marginRight: 5,
      },
      fontSize: 13,
      minWidth: 120,
    },
  },
}));

function CreateColumn({ columnRow, setColumnRow }) {
  const [isLoading, setLoading] = useState(false);
  const [toastData, setToastData] = React.useState({ isOpen: false, message: '', color: '' });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      // ...projectScopeDetails,
    },
  });

  const captureLabelData = (event) => {
    if (event.target.name === 'columnName') {
      setColumnRow({ ...columnRow, [event.target.name]: event.target.value.toUpperCase() });
    }
    // } else if (event.target.name === 'toolTip') {
    //   setColumnRow({ ...columnRow, [event.target.name]: event.target.value });
    // }
  };

  const handleToastClose = React.useCallback(() => {
    setToastData({ isOpen: false, message: '' });
  }, []);

  if (isLoading) {
    return <div>Loading...Please wait...</div>;
  }
  return (
    <Stack
      component="form"
      noValidate
      autoComplete="off"
      sx={{ minWidth: '300px', background: '#ggg', padding: { md: '15px', xs: '8px' }, border: '1px solid #bababa' }}
    >
      <div>
        <Grid container sx={{ pb: 1, pt: 1 }} spacing={1}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="columnName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormInput
                  label="Column Name"
                  placeholder="Column Name"
                  name="columnName"
                  handler={captureLabelData}
                  {...field}
                  value={columnRow.columnName}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12} sm={12}>
            <Controller
              name="toolTip"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormInput
                  label="tooltip"
                  placeholder="tooltip"
                  name="toolTip"
                  handler={captureLabelData}
                  {...field}
                  value={columnRow.toolTip}
                />
              )}
            />
          </Grid> */}
        </Grid>

        {toastData.isOpen && <ShowToastMessage {...toastData} handleToastClose={handleToastClose} />}
      </div>
    </Stack>
  );
}

export default CreateColumn;
