import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const Overlay = (props) => {
  const {
    children,
    isOverlayOpen,
    closeOverlay,
    handler,
    title = "",
    message = "",
    sbmBtn = "Submit",
    cancelBtn = "Cancel",
    actions = true,
  } = props;

  const handleClose = () => {
    closeOverlay();
  };

  return (
    <div>
      <Dialog
      fullWidth
        maxWidth={"sm"}
        open={isOverlayOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {title}{" "}
          <span
            onClick={handleClose}
            style={{ cursor: "pointer", display: "inline-block" }}
          >
            <CloseIcon />
          </span>
        </DialogTitle>
        <DialogContent style={{overflowY: "clip"}}>{children}</DialogContent>
        {actions && (
          <DialogActions sx={{pr:3, pb:2}}>
            <Button
              variant="outlined"
              
              onClick={handleClose}
              autoFocus
              style={{ background: "transparent" }}
            >
              {cancelBtn}
            </Button>
            <Button variant="contained" onClick={handler}>
              {sbmBtn}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default React.memo(Overlay);
