import React, { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, FormLabel } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useTheme } from "@mui/material/styles";

export default function ConfigureActions({
  roles,
  selectedWFQueueRoles,
  selectedWFActionRoles,
  setSelectedWFActionRoles,
}) {
  // useEffect(() => {
  //   setSelectedWFActionRoles(selectedWFQueueRoles);
  // }, [selectedWFQueueRoles]);
  const theme = useTheme();
  const handleChange = (event) => {
    const { checked, name } = event.target;
    if (checked) {
      const newrole = selectedWFQueueRoles.filter((role) => role === +name)[0];
      setSelectedWFActionRoles([...selectedWFActionRoles, newrole]);
    } else {
      const updatedRoles = selectedWFActionRoles.filter(
        (role) => role !== +name
      );
      setSelectedWFActionRoles(updatedRoles);
    }
  };

  function CheckboxItem({ value }) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <PersonOutlineOutlinedIcon style={{ fontSize: 16 }} />
        {value}
      </div>
    );
  }

  return (
    <FormGroup className="roles-wrapper">
      {/* <FormLabel>Select Roles for Queue</FormLabel> */}
      {selectedWFQueueRoles.map((wfqr, index) => {
        const label =
          roles.filter((role) => role.roleId === wfqr)[0]?.roleName || wfqr;
        return (
          <FormControlLabel
            key={index}
            name={wfqr}
            control={
              <Checkbox
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                icon={<CheckboxItem value={label} />}
                checkedIcon={<CheckboxItem value={label} />}
                checked={
                  selectedWFActionRoles.filter((role) => role === wfqr).length >
                  0
                }
              />
            }
            // label={wfqr}
          />
        );
      })}
    </FormGroup>
  );
}
