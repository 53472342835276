import * as yup from 'yup';

const requiredMsg = 'This field is Required';

const Schema = yup
  .object()
  .shape({
    paramName: yup.string().required(requiredMsg),
  })
  .required();

export default Schema;
