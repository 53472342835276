/*eslint-disable*/
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
// core components
import { ReactComponent as LoaderLogo } from "../../assets/img/saya-logo-letter-vector.svg";

import "../../assets/css/global-styles.css";

const styles = () => {
  const theme = useTheme();
  return {
    loaderColor: {
      color: theme.palette.primary.main,
    },
  };
};

const useStyles = makeStyles(styles);

export default function Loader(props) {
  const classes = useStyles();
  const { content, size, textColor, alphaVal } = props;

  return (
    <div
      className="loader-wrapper"
      style={{ display: "flex", alignItems: "center" }}
    >
      <LoaderLogo
        className="rotate-hor-center"
        style={{
          opacity: alphaVal || 0.8,
          marginRight: 10,
          width: `${size || 40}px`,
        }}
      />
      <span className={classes.loaderColor}>
        {content || "Loading..."}
      </span>
    </div>
  );
}
