// Set the base URIs depending on the environment. To preserve current behvaiour,
// use production URIs as default.

const env = process.env.REACT_APP_ENV;
let reconApi, userApi, flowApi, resolveApi, dataIngestionApi, sayaflow, datax;

if (env === "local") {
  console.info("It's local environment. Setting localhost URLs");
  reconApi = "http://localhost:8085/saya-recon";
  userApi = "http://localhost:8087/saya-user-cortex";
  flowApi = "http://localhost:8085/saya-flow";
  resolveApi = "http://localhost:8085/saya-recon-resolve";
  dataIngestionApi = "http://localhost:8085/saya-dataingestion";
  sayaflow = "http://localhost:8085/sayaflow";
  datax = "http://localhost:8085/datax";
} else {
  // Consider the origin saya.3cortex.com as default if running in
  // localhost and REACT_APP_ENV is not present. This preserves the existing behaviour.
  const origin =
    window.location.hostname === "localhost"
      ? "https://sayadev.3cortex.com"
      : window.location.origin;

  console.info(
    "It's production environment. Setting production URLs with origin",
    origin
  );
  reconApi = `${origin}/saya-recon`;
  userApi = `${origin}/saya-user-cortex`;
  flowApi = `${origin}/saya-flow`;
  resolveApi = `${origin}/saya-recon-resolve`;
  dataIngestionApi = `${origin}/saya-dataingestion`;
  sayaflow = `${origin}/sayaflow`;
  datax = `${origin}/datax`;
}

const API = reconApi;
const USERAPI = userApi;
const FlowAPI = flowApi;
const ReconResolveAPI = resolveApi;
const DATAINGESTIONAPI = dataIngestionApi;
const SAYAFLOW = sayaflow;
const DATAX = datax;

module.exports = {
  API,
  USERAPI,
  FlowAPI,
  ReconResolveAPI,
  DATAINGESTIONAPI,
  SAYAFLOW,
  DATAX,
};
