import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";

import SortableTree, {
  getFlatDataFromTree,
  getTreeFromFlatData,
} from "react-sortable-tree";

//import FileExplorerTheme from "react-sortable-tree-theme-minimal";
import "react-sortable-tree/style.css"; // Import the default styles

const DndWorkflow = ({
  data,
  queueDataChangeHandler,
  actionDataChangeHandler,
}) => {
  const [treeData, setTreeData] = useState([data]);
  const [selectedQueueId, setSelectedQueueId] = useState("");

  useEffect(() => {
    setTreeData([data]);
  }, [data]);

  // Function to handle "Expand All" button click
  const handleExpandAll = (data) => {
    const newData = [data];
    const updatedTreeData = newData.map((node) => ({
      ...node,
      expanded: true,
      ...(node.children
        ? {
            children: node.children.map((child) => ({
              ...child,
              expanded: false,
            })),
          }
        : {}),
    }));
    setTreeData(updatedTreeData);
  };

  useEffect(() => {
    handleExpandAll(data);
  }, [data]);

  // Recursive function to add "orderSeq" for all levels of nodes
  const addOrderSeqRecursive = (nodeArray, startIndex) => {
    let order = startIndex;
    return nodeArray.map((node) => {
      const updatedNode = { ...node, orderSeq: order++ };
      if (updatedNode.children) {
        updatedNode.children = addOrderSeqRecursive(updatedNode.children, 0);
      }
      return updatedNode;
    });
  };

  useEffect(() => {
    if (selectedQueueId) {
      console.log("treeData", treeData);
    }
  }, [selectedQueueId, treeData]);

  // Function to generate props for each node

  const generateNodeProps = ({ node }) => {
    // For other nodes, return an object with default props
    return {
      className: `dnd-node level${node.level}`,
      title: node.name,
    };
  };

  // Function to check if a node can be dropped as a child of another node
  const canDrop = ({
    node,
    prevParent,
    prevPath,
    prevTreeIndex,
    nextPath,
    nextParent,
    nextTreeIndex,
  }) => {
    if (
      !(
        prevParent?.level === 1 &&
        nextParent?.level === 1 &&
        node?.level === 2
      ) &&
      !(
        prevParent?.level === 2 &&
        nextParent?.level === 2 &&
        prevParent?.id === nextParent?.id &&
        node?.level === 3
      )
    ) {
      return false;
    }
    return true;
  };

  const canDrag = ({ node, nextParent }) => {
    if (node.level === 1 || node.level === 4) {
      return false;
    }
    return true;
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleOnMoveNode = (args) => {
    const { node, treeData } = args;
    let updatedTreeData = addOrderSeqRecursive(treeData, 0);
    setTreeData(updatedTreeData);
    setSelectedQueueId(node?.queueId);
    if (node?.level === 3) {
      actionDataChangeHandler(node, updatedTreeData);
    }
    if (node?.level === 2) {
      queueDataChangeHandler(updatedTreeData);
    }
  };

  return (
    <div className="dnd-tree-wrapper">
      <SortableTree
        isVirtualized={false}
        treeData={treeData}
        getNodeKey={getNodeKey}
        canDrag={canDrag}
        canDrop={canDrop}
        generateNodeProps={generateNodeProps}
        //onChange={handleTreeDataChange}
        onMoveNode={handleOnMoveNode}
        onChange={(newTreeData) => setTreeData(newTreeData)}
        //theme={FileExplorerTheme}
      />
    </div>
  );
};

export default DndWorkflow;
