import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@asgardeo/auth-react";
import { fetchUserDetailsByLogin } from "../../action-handlers/LoginActionsHandler";
import { showFailureNotification } from "../../action-handlers/NotificationsHandler";
import { UserAuthDetailsContext } from "../../context/LoginPageContext/UserAuthDetailsContext";
import { LOGIN_FAILED } from "../../messages/DashboardMessages";
import { fetchMenuHierarchyById } from "../../action-handlers/UserEntitlementActionHandler";
import { notificationParams } from "../../variables/general.js";
import saya from "../../assets/img/SAYA.svg";
import AxiosService from "../../AxiosService";
import { makeStyles } from "@mui/styles";
import { fetchworkflows } from "action-handlers/WorkflowActionHandler";
import { WorkflowContext } from "context/Workflow/WorkflowContext";

const styles = (theme) => {
  return {
    wrapper: {
      height: "90vh",
      marginLeft: "-60px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
      },
      "& img": {
        height: "300px",
        width: "30%",
        display: "block",
        margin: "0px auto",
        [theme.breakpoints.down("sm")]: {
          width: "50%",
        },
      },
    },
    text: {
      width: "45%",
      margin: "15% auto 0 auto",
      color: theme.palette.primary.main,
      fontSize: 20,
      maxHeight: "100vh",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        width: "85%",
      },
    },
  };
};

const useStyles = makeStyles(styles);

/**
 * Component to handle sign-in using WSO2 identity provider with {@link https://github.com/asgardeo/asgardeo-auth-react-sdk Asgardeo React Auth SDK}.
 * When the page is being loaded first time, a variable named "isInitLogin" is set to true in session storage. This indicates the login is initiated.
 * Then the page gets redirected to IdP automatically as handleLogin() is called in the render method. After the authentication, the SDK tries to signin
 * again under the condition that the initial login happend. This happens in first useEffect. The second useEffect fetches the actual token and populates the
 * session state for the authenticated user.
 *
 * @returns the react JSX element
 */
export default function IdPLoginPage() {
  const classes = useStyles();
  const [isLoginSuccess, setLoginSuccess] = useState(false);
  const [isLoginFailed, setLoginFailed] = useState(false);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const UserAuthContext = useContext(UserAuthDetailsContext);
  const WorkflowDataContext = useContext(WorkflowContext);

  const { state, signIn, getBasicUserInfo, getIDToken, trySignInSilently } =
    useAuthContext();
  const axiosService = AxiosService.getService();

  const navigate = useNavigate();

  useEffect(() => {
    console.debug("Signing in by checking if the flow is initialized");
    if (getIsInitLogin()) {
      signIn().catch((error) => {
        setLoginFailed(true);
        console.error(
          "Exception authenticating user post initialization",
          error
        );
      });
    }
  }, []);

  useEffect(() => {
    console.debug("Adding auto redirecting timeout function");
    if (!getIsInitLogin()) {
      setTimeout(() => {
        console.debug(
          "Auto redirecting into IdP login page as signin is not initialized"
        );
        handleLogin();
      }, 250);
    }
  }, []);

  useEffect(() => {
    console.debug("Current log in state from authentication context", state);
    if (state.isAuthenticated) {
      loadTokenAndBasicUserInfo();
    }
  }, [state.isAuthenticated]);

  const getIsInitLogin = () => {
    return sessionStorage.getItem("isInitLogin") === "true";
  };

  const setIsInitLogin = (value) => {
    sessionStorage.setItem("isInitLogin", value);
  };

  const handleLogin = () => {
    console.debug("Initializing the signin flow");
    setIsInitLogin("true");

    signIn().catch((error) => {
      window.location.reload();
      setTimeout(() => {
        setLoginFailed(true);
      }, 1000);

      console.error(
        "Exception authenticating user during initialization",
        error
      );
    });
  };

  const saveWorkflowData = (Workflows) => {
    WorkflowDataContext.saveWorkflows(Workflows);
  };

  const loadTokenAndBasicUserInfo = async () => {
    console.debug("Loading token and basic user information");
    const basicUserInfo = await getBasicUserInfo();
    const idToken = await getIDToken();

    axiosService.setToken({
      access_token: idToken,
    });
    sessionStorage.setItem("loginName", basicUserInfo.username);

    fetchUserDetailsByLogin(
      basicUserInfo.username,
      callBackFetchUserDetailsByLogin
    );
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      setLoginSuccess(userDetailsState.isLoggedIn);
      fetchMenuHierarchyById(
        userDetailsState.userId,
        callBackFetchMenuHierarchy
      );
      fetchworkflows(userDetailsState.roleId, saveWorkflowData);
    } else {
      showFailureNotification(LOGIN_FAILED, callbackNotification);
      setLoginSuccess(apiStatus);
      setLoginFailed(true);
    }
  };
  const callBackFetchMenuHierarchy = (resObj, apiStatus) => {
    console.log("resObj", resObj);
  };
  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  return isLoginSuccess ? (
    // <Redirect to="/saya-platform/dashboard" />
    navigate("/saya-platform/dashboard")
  ) : isLoginFailed ? (
    <div
      style={{
        height: "90vh",
        marginLeft: "-60px",
      }}
    >
      {/* <img
        data-src="holder.js/100px180/"
        alt="SAYA platform logo"
        style={{
          height: "300px",
          width: "20%",
          display: "block",
          margin: "0px auto",
        }}
        src={saya}
        data-holder-rendered="true"
      /> */}
      <p
        style={{
          width: "45%",
          margin: "auto",
          color: "#9f3a38",
          maxHeight: "100vh",
          border: "1px solid #e0b4b4",
          padding: "20px 12px",
          textAlign: "center",
          borderRadius: "3px",
          backgroundColor: "#fff6f6",
        }}
      >
        Failed to login to Application. Please try again!!
      </p>
    </div>
  ) : (
    <div className={classes.wrapper}>
      {/* <img
        data-src="holder.js/100px180/"
        alt="SAYA platform logo"
  
        src={saya}
        data-holder-rendered="true"
      /> */}
      <h3 className={classes.text}>Loading ...</h3>
    </div>
  );
}
