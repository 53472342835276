import React from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";

const CreateNEditMetadataColumn = ({
  columnMetadata,
  dataTypes,
  requiredData,
  visibilityData,
  updateMetaDataColumnData,
  isEdit,
}) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "14px", paddingLeft: "0px" }}>
            <b>{columnMetadata.columnId}</b>
          </Typography>
        </Grid>

        <Grid item xs={6} sx={{display:'flex', flexDirection: 'column'}}>
          <label
            htmlFor={columnMetadata.columnName}
            style={{ fontSize: "11px", color: "#637381", marginBottom: '3px' }}
          >
            Column Name
          </label>
          <TextField
            id={columnMetadata.columnName}
            size="small"
            name="columnName"
            value={columnMetadata.columnName}
            onChange={updateMetaDataColumnData}
            sx={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={6}>
          <SAYAComboBox
            labelText="Data Type"
            optionsData={dataTypes}
            value={columnMetadata.dataType || "STRING"}
            groupText="Data Type"
            name="dataType"
            height="18px"
            headerCombo
            handleChange={(event) =>
              updateMetaDataColumnData(event, "dataType")
            }
            inputProps={{
              readOnly: isEdit,
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <SAYAComboBox
            labelText="Required"
            optionsData={requiredData}
            value={columnMetadata.mandatory}
            groupText="Data Type"
            name="mandatory"
            height="18px"
            headerCombo
            handleChange={(event) =>
              updateMetaDataColumnData(event, "mandatory")
            }
          />
        </Grid>

        <Grid item xs={6}>
          <SAYAComboBox
            labelText="Visibility"
            optionsData={visibilityData}
            value={columnMetadata.visible}
            groupText="Data Type"
            name="visible"
            height="18px"
            headerCombo
            handleChange={(event) => updateMetaDataColumnData(event, "visible")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(CreateNEditMetadataColumn);
