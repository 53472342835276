import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import {
  AddAlert,
  DeviceHub,
  AddToPhotos,
  EditRounded,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "../../../assets/jss/material-dashboard-react/views/dashboardStyle";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import CardIcon from "../../../components/Card/CardIcon";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import { notificationParams } from "../../../variables/general";
import Snackbar from "../../../components/Snackbar/Snackbar.js";
import CardFooter from "../../../components/Card/CardFooter.js";
import { fetchReconUnitsData } from "../../../action-handlers/DashboardActionHandler.js";
import { reconUnitFormData } from "../../../variables/ReconUnitLevelData";
import {
  API_RECON_PROCESS_FAILED,
  USER_SESSION_TIME_OUT,
} from "../../../messages/DashboardMessages.js";
import { UserAuthDetailsContext } from "../../../context/LoginPageContext/UserAuthDetailsContext";
import { fetchUserDetailsByLogin } from "../../../action-handlers/LoginActionsHandler.js";
import {
  showAlertNotification,
  showFailureNotification,
} from "../../../action-handlers/NotificationsHandler.js";
import Button from "../../../components/CustomButtons/Button";
import SAYAMUIDataTable from "components/Table/MuiDataTable";
import {
  ButtonGroup,
  IconButton,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SAYASearchBox from "components/CustomSearchBox/CustomSearchBox";
import SAYAComboBox from "components/CustomComboBox/SAYAComboBox";

const useStyles = makeStyles(styles);

export default function ReconProcessHome() {
  const classes = useStyles();
  const [isReconProcessHomeLoading, setReconProcessHomeLoading] =
    useState(true);
  const [reconUnitsDataList, setReconUnitsDataList] = useState([]);
  const [opsProcessData, setOPSProcessData] = useState([]);
  const [notificationObj, setNotificationObj] = useState(notificationParams);
  const UserAuthContext = React.useContext(UserAuthDetailsContext);
  const [recid, setRecid] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOPS, setSelectedOPS] = useState("ReconX and ResolveX");
  var reconProcess = reconUnitFormData;

  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const query = useQuery();
  const type = query.get("type");

  useEffect(() => {
    if (type === null) {
      setSelectedOPS("ReconX and ResolveX");
      const data = opsProcessData.filter(
        (op) => op.reconEnabled && op.workflowEnabled
      );
      setReconUnitsDataList(data);
    } else {
      if (type === "ReconX") {
        const data = opsProcessData.filter((op) => op.reconEnabled);
        setReconUnitsDataList(data);
      } else if (type === "ResolveX") {
        const data = opsProcessData.filter((op) => op.workflowEnabled);
        setReconUnitsDataList(data);
      }

      setSelectedOPS(type);
    }
  }, [type, opsProcessData]);

  let allowedPermissions = [];
  try {
    allowedPermissions =
      location.state === null
        ? JSON.parse(sessionStorage.selectedItem)?.allowedPermissions
        : JSON.parse(location.state?.selectedItem)?.allowedPermissions || [];
  } catch (ex) {
    console.log("Error while reading location.state===>", ex);
  }

  const OPSTYPES = [
    { label: "ReconX", value: "ReconX" },
    { label: "ResolveX", value: "ResolveX" },
    { label: "ReconX and ResolveX", value: "ReconX and ResolveX" },
  ];

  const handleOPS = (event) => {
    const ops = event.target.value;
    setSelectedOPS(ops);
    if (ops === "ReconX") {
      const data = opsProcessData.filter((op) => op.reconEnabled);
      setReconUnitsDataList(data);
    } else if (ops === "ResolveX") {
      const data = opsProcessData.filter((op) => op.workflowEnabled);
      setReconUnitsDataList(data);
    } else {
      const data = opsProcessData.filter(
        (op) => op.workflowEnabled && op.reconEnabled
      );
      setReconUnitsDataList(data);
    }
  };

  useEffect(() => {
    if (isReconProcessHomeLoading) {
      let userId = UserAuthContext.state.userId; //userDetailsInitialState.userId;;// useContext and pass the userId from login
      if (
        UserAuthContext.state.userId === undefined ||
        UserAuthContext.state.userId === 0
      ) {
        //this means refresh or session of browser got refreshed. So lets fetch the user details from Database
        /// for doing lets get the loginName from sessionStorage see if it is available, try fetching the details

        if (
          UserAuthContext.state.loginName !== undefined ||
          UserAuthContext.state.loginName !== ""
        ) {
          //this means user had logged in earlier try fetching from sessionStorage loginName
          fetchUserDetailsByLogin(
            UserAuthContext.state.loginName,
            callBackFetchUserDetailsByLogin
          );
        }
      } else {
        userId = UserAuthContext.state.userId;
        loadInitialPageData(userId); //dont move this to outside of else loop as the above if block executes the same loadpage in callback
      }
    }
    return () => {
      console.log(
        "Component ReconProcessHome Home unloading cleanup to be done here!!!"
      );
    };
  }, []);

  const loadInitialPageData = (userId) => {
    if (
      allowedPermissions.includes("view") ||
      allowedPermissions.includes("modify") ||
      allowedPermissions.includes("delete")
    ) {
      fetchReconUnitsData(userId, callbackFetchReconUnitData);
    } else {
      setReconProcessHomeLoading(false);
    }
  };

  const callBackFetchUserDetailsByLogin = (userDetailsState, apiStatus) => {
    if (apiStatus) {
      UserAuthContext.saveUserDetailsAuthState(userDetailsState);
      loadInitialPageData(userDetailsState.userId);
    } else {
      //failure could be due to token expiry, raise an alert saying Token Expired and route to login page
      showAlertNotification(USER_SESSION_TIME_OUT, callbackNotification);
      UserAuthContext.clearUserDetailsAuthState();
      window.location.assign("/");
    }
  };

  const callbackFetchReconUnitData = (
    userId,
    assignedReconUnitData,
    reconUnitDropDownData,
    apiStatus
  ) => {
    if (apiStatus) {
      //assignedReconUnitData.push(...reconUnitsData);
      //Array.prototype.push.apply(assignedReconUnitData, reconUnitsData);
      let reconUnitsFetchedData = [];
      setOPSProcessData(reconUnitsFetchedData); //initiatlizing to flush the previous data
      for (var reconData of assignedReconUnitData) {
        reconUnitsFetchedData.push(reconData);
      }
      setOPSProcessData(reconUnitsFetchedData);

      //calling the loadTrendsData once the reconunits are fetched. Hence placed in callback method
      setReconProcessHomeLoading(false);
    } else {
      showFailureNotification(API_RECON_PROCESS_FAILED, callbackNotification);
      setReconProcessHomeLoading(false);
    }
  };

  const callbackNotification = (notificationObj) => {
    setNotificationObj(notificationObj);
  };

  const handleReconProcessCreate = (e) => {
    // e.preventDefault();
    let reconBaseData = {};
    console.log("reconUnitFormData", reconUnitFormData);
    reconBaseData = reconUnitFormData;
    reconBaseData.input.clientReconName = "";
    reconBaseData.input.reconUnitId = "";
    reconBaseData.input.multimatchAggrBuffer = 1;
    reconBaseData.input.identicalData = false;
    reconBaseData.input.headerData = true;
    reconBaseData.input.workflowEnabled = false;
    reconBaseData.input.reconEnabled = true;
    reconBaseData.input.multimatchReqd = true;
    reconBaseData.input.oneToMany = false;
    reconBaseData.input.manyToOne = false;
    reconBaseData.input.dataSourceId1 = 1;
    reconBaseData.input.dataSrcConfigId1 = "Data Source - 1";
    reconBaseData.input.dataSourceId2 = 2;
    reconBaseData.input.dataSrcConfigId2 = "Data Source - 2";
    reconBaseData.input.createdBy = 0;
    reconBaseData.input.createdDate = "";
    reconBaseData.input.updatedDate = "";
    reconBaseData.input.mlEnabled = true;
    reconProcess = reconBaseData;
  };

  const handleReconProcessModify = (reconUnitId) => {
    if (!isReconProcessHomeLoading) {
      //e.preventDefault();
      let reconSelectedData = reconUnitFormData;
      for (var reconData of reconUnitsDataList) {
        if (reconUnitId === reconData.reconUnitId) {
          reconSelectedData.input.clientReconName = reconData.clientReconName;
          reconSelectedData.input.reconUnitId = reconUnitId;

          reconSelectedData.input.identicalData = reconData.identicalData;
          reconSelectedData.input.headerData = reconData.headerData;
          reconSelectedData.input.workflowEnabled = reconData.workflowEnabled;
          reconSelectedData.input.reconEnabled = reconData.reconEnabled;
          reconSelectedData.input.multimatchReqd = reconData.multimatchReqd;
          reconSelectedData.input.multimatchAggrBuffer =
            reconData.multimatchAggrBuffer;
          reconSelectedData.input.oneToMany = reconData.oneToMany;
          reconSelectedData.input.manyToOne = reconData.manyToOne;
          reconSelectedData.input.dataSourceId1 = reconData.dataSourceId1;
          reconSelectedData.input.dataSrcConfigId1 = reconData.dataSrcConfigId1;
          reconSelectedData.input.dataSourceId2 = reconData.dataSourceId2;
          reconSelectedData.input.dataSrcConfigId2 = reconData.dataSrcConfigId2;
          reconSelectedData.input.createdBy = reconData.createdBy;
          reconSelectedData.input.createdDate = reconData.createdDate;
          reconSelectedData.input.updatedDate = reconData.updatedDate;
          reconSelectedData.input.mlEnabled = reconData.mlEnabled;
          reconSelectedData.input.formLayout = reconData.formLayout;

          break;
        }
      }
      reconProcess = reconSelectedData;
    }
  };

  const handleCloseNotification = () => {
    let notificationDet = { ...notificationParams };
    notificationDet.open = false;
    setNotificationObj(notificationDet);
  };
  const [showTableView, setShowTableView] = useState(false);
  const handleButtonClick = (viewType) => {
    setShowTableView(viewType === "table");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, recid) => {
    setAnchorEl(event.currentTarget);
    setRecid(recid);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return isReconProcessHomeLoading ? (
    <>
      <GridContainer>
        {[1, 2, 3].map((index) => (
          <GridItem xs={12} sm={6} md={4} key={index}>
            <Card style={{ width: "100%", marginRight: 20 }}>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary" style={{ background: "transparent" }}>
                  <Skeleton variant="circular" width={40} height={40} />
                </CardIcon>

                <p className={classes.cardCategory}>
                  <Skeleton
                    animation="wave"
                    style={{ float: "right" }}
                    width={140}
                  />
                </p>
                <h3 className={classes.cardTitle}>
                  <Skeleton animation="wave" width={50} />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Skeleton animation="wave" width={150} />
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </>
  ) : (
    <div>
      <GridContainer style={{ marginBottom: "15px" }}>
        {allowedPermissions.includes("add") && (
          <GridItem xs={12} sm={12} md={6} lg={6}>
            <Link
              to="/saya-platform/admin/ops-home/ops-crud"
              state={{
                ...reconProcess,
                selectedItem:
                  location?.state?.selectedItem || sessionStorage.selectedItem,
                new: true,
              }}
            >
              <Button onClick={handleReconProcessCreate} variant="contained">
                <AddToPhotos />
                Create new Ops process
              </Button>
            </Link>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {allowedPermissions.includes("view") && (
              <div style={{ marginTop: "-8px", marginRight: "20px" }}>
                <SAYAComboBox
                  optionsData={OPSTYPES}
                  value={selectedOPS}
                  defaultValue={selectedOPS}
                  labelText="OPS Process Type"
                  groupText="Selected  OPS Process Type"
                  name="selectedReconUnit"
                  handleChange={handleOPS}
                  className="SelectSS"
                  height="30px"
                  headerCombo
                />
              </div>
            )}
            {!showTableView ? (
              <div style={{ paddingRight: "10px" }}>
                <SAYASearchBox
                  searchText={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            ) : null}
            <ButtonGroup>
              <Button
                variant={showTableView ? "outlined" : "contained"}
                onClick={() => handleButtonClick("card")}
              >
                <Tooltip title="Card View">
                  <GridViewIcon />
                </Tooltip>
              </Button>

              <Button
                variant={showTableView ? "contained" : "outlined"}
                onClick={() => handleButtonClick("table")}
              >
                <Tooltip title="Table View">
                  <TableRowsIcon />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </div>
        </GridItem>
      </GridContainer>
      {showTableView ? (
        <>
          {allowedPermissions.includes("view") ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <SAYAMUIDataTable
                  title="Ops Process Data"
                  data={reconUnitsDataList}
                  options={{
                    selectableRows: "none",
                    filter: true,
                    viewColumns: true,
                    print: true,
                    download: true,
                  }}
                  columns={[
                    {
                      name: "reconUnitId",
                      label: "Ops Process ID",
                    },
                    {
                      name: "clientReconName",
                      label: "Ops Process Name",
                    },
                    ...(allowedPermissions.includes("modify")
                      ? [
                          {
                            name: "actions",
                            label: "Actions",
                            options: {
                              sort: false,
                              filter: false,
                              customBodyRender: (value, tableMeta) =>
                                allowedPermissions.includes("view") ||
                                allowedPermissions.includes("modify") ||
                                allowedPermissions.includes("delete") ? (
                                  <div>
                                    <IconButton
                                      id="basic-button"
                                      aria-controls={
                                        open ? "basic-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) =>
                                        handleClick(e, tableMeta.rowData[0])
                                      }
                                    >
                                      <MoreHorizIcon />
                                    </IconButton>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={open}
                                      onClose={handleClose}
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <Link
                                        to="/saya-platform/admin/ops-home/ops-crud"
                                        state={{
                                          ...reconProcess,
                                          selectedItem:
                                            location?.state?.selectedItem ||
                                            sessionStorage.selectedItem,
                                          new: false,
                                        }}
                                        style={{ color: "inherit" }}
                                      >
                                        <MenuItem
                                          onClick={handleReconProcessModify(
                                            recid
                                          )}
                                        >
                                          Edit
                                        </MenuItem>
                                      </Link>
                                    </Menu>
                                  </div>
                                ) : null,
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              </GridItem>
            </GridContainer>
          ) : null}
        </>
      ) : (
        <>
          {allowedPermissions.includes("view") ? (
            <Link
              to="/saya-platform/admin/ops-home/ops-crud"
              state={{
                ...reconProcess,
                selectedItem:
                  location?.state?.selectedItem || sessionStorage.selectedItem,
                new: false,
              }}
            >
              <GridContainer>
                {reconUnitsDataList
                  .filter(
                    (reconUnitData) =>
                      String(reconUnitData.reconUnitId)
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      reconUnitData.clientReconName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                  )
                  .map((reconUnitData, index) => (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={4}
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleReconProcessModify(reconUnitData.reconUnitId)
                      }
                    >
                      <Card>
                        <CardHeader color="primary" stats icon>
                          <CardIcon color="primary" plain>
                            <DeviceHub />
                          </CardIcon>
                          <p className={classes.cardCategory}>
                            Ops Process Id: {reconUnitData.reconUnitId}
                          </p>
                          <h3 className={classes.cardTitle}>
                            {reconUnitData.clientReconName}
                          </h3>
                        </CardHeader>
                        <CardFooter stats>
                          <div className={classes.stats}>
                            <EditRounded />
                            View/ Modify Details...
                          </div>
                        </CardFooter>
                      </Card>
                    </GridItem>
                  ))}
              </GridContainer>
            </Link>
          ) : null}
        </>
      )}
      {reconUnitsDataList.length > 0 &&
        reconUnitsDataList.filter(
          (reconUnitData) =>
            String(reconUnitData.reconUnitId)
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            reconUnitData.clientReconName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        ).length === 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <h4 style={{ textAlign: "center" }}>No results found</h4>
          </GridItem>
        )}
      <Snackbar
        place={notificationObj.place}
        color={notificationObj.color}
        icon={AddAlert}
        message={notificationObj.message} //"Welcome to MATERIAL DASHBOARD React - a beautiful freebie for every web developer."
        open={notificationObj.open}
        closeNotification={handleCloseNotification}
        close={notificationObj.close}
      />
    </div>
  );
}
