import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function Input(theme) {

  return {

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            '& svg': { color: theme.palette.text.disabled },
          }
        },
        input: {
          fontSize: 14,
          '&.MuiOutlinedInput-inputMarginDense': {
            padding: '18px 14px'
          },
          color: theme.palette.text.disabled,
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled,
          },
        }
      },
    },
    MuiSelect:{
      styleOverrides: {
        select: {
          paddingLeft: 14,
          fontSize: 14
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:hover': {
            '&:before': {
               borderBottomColor: alpha(theme.palette.primary.main, 0.56) + '!important',
            }
          },
          '&:before': {            
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
         
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(theme.palette.grey[500], 0.12),
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.action.focus,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
          },
        },
        underline: {
          '&:before': {
            borderBottomColor: alpha(theme.palette.grey[500], 0.56),
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {     

        root: {
         
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: alpha(theme.palette.grey[500], 0.32),
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
           fontSize: '14px',
          '&.Mui-selected':{
            '&:hover, &:focus, &:active': {
              backgroundColor:  alpha(theme.palette.grey[500], 0.32),
            },
            backgroundColor: alpha(theme.palette.grey[500], 0.32),
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root':{
            color: theme.palette.text.primary
          }
        }
      }
    }
  };
}
